<template>
  <GlobalLayout>
    <div class="data-wrap">
      <!-- 厂商统计 -->
      <div class="firm">
        <div class="firmNum">
          <span class="count">厂商统计：<span v-text="companyCount"></span></span>
          <!-- 手动分配厂商 -->
          <!-- <span class="handDis">手动分配的厂商：<span>38</span></span> -->
          <!-- 自动注册的厂商 -->
          <!-- <span class="autoDis">自动注册的厂商：<span>0</span></span> -->
        </div>
        <div class="firm_deviceTit">厂商设备数量排名Top10</div>
        <div style="" id="firm_echarts" class="firm_echarts"></div>
      </div>

      <!-- 产品统计 -->
      <div class="product">
        <div class="productNum">
          产品统计：<span v-text="this.productNum"></span>
        </div>
        <div class="product_deviceTit">产品设备数量排名Top10</div>
        <div style="" id="product_echarts" class="product_echarts"></div>
      </div>

      <!-- 设备统计 -->
      <div class="device">
        <div class="deviceNum">
          <span class="count"
            >设备统计：<span v-text="deviceCount"></span
          ></span>
          <!-- 在线 -->
          <span class="outline">在线：<span v-text="online"></span></span>
          <!-- 离线 -->
          <span class="outline">离线：<span v-text="outline"></span></span>
          <!-- 未激活 -->
          <span class="noactive">未激活：<span v-text="inactive"></span></span>
        </div>
        <div class="device_onlineTit">在线设备百分比排名Top10</div>
        <div style="" id="device_echarts" class="device_echarts"></div>
      </div>
    </div>
  </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";
// import echarts from "echarts";
import * as echarts from 'echarts'
import { mutations,mapGetters } from "vuex";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      productNum: 0,
      // 产品设备柱状图数据
      product_option: {
        // ----  标题 -----
        title: {
          text: "产品设备数量排名Top10",
          textStyle: {
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          },
          x: "top",
          y: "bottom",
          padding: [0, 0, 10, 100], // 位置
        },
        // ---- legend ----
        // legend: {
        //   type: "plain", // 图列类型，默认为 'plain'
        //   top: "1%", // 图列相对容器的位置 top\bottom\left\right
        //   left:'70%',
        //   selected: {
        //     // '销量': true  // 图列选择，图形加载出来会显示选择的图列，默认为true
        //   },
        //   name: "产品设备数量",
        //   icon: "circle",
        //   textStyle: {
        //     // 图列内容样式
        //     color: "#4F81BD", // 字体颜色
        //     backgroundColor: "#F5F7F9", // 字体背景色
        //   },
        //   //   data: [
        //   //     // 图列内容
        //   //     {
        //   //       name: "销量",
        //   //       icon: "circle",
        //   //       textStyle: {
        //   //         color: "red", // 单独设置某一个图列的颜色
        //   //         backgroundColor: "#fff", // 单独设置某一个图列的字体背景色
        //   //       },
        //   //     },
        //   //   ],
        // },
        // ---  提示框 ----
        tooltip: {
          show: true, // 是否显示提示框，默认为true
          trigger: "item", // 数据项图形触发
          // formatter: "{b}: {c}<br />{b}: {c}",
          axisPointer: {
            // 指示样式
            type: "shadow",
            // axis: "auto",
          },
          padding: 5,
          textStyle: {
            // 提示框内容的样式
            color: "#ffffff",
          },
        },
        // ---- gird区域 ---
        gird: {
          show: false, // 是否显示直角坐标系网格
          top: 80, // 相对位置 top\bottom\left\right
          width: 800,
          containLabel: false, // gird 区域是否包含坐标轴的刻度标签
          tooltip: {
            show: true,
            trigger: "item", // 触发类型
            textStyle: {
              color: "#666",
            },
          },
        },
        //  ------  X轴 ------
        xAxis: {
          interval: 0,
          rotate: 0, //-15度角倾斜显示
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          // name: "日期", // 轴名称
          nameLocation: "end", // 轴名称相对位置
          nameTextStyle: {
            // 坐标轴名称样式
            color: "#666",
            padding: [5, 0, 0, -5],
          },
          name: "产品",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          nameRotate: 0, // 坐标轴名字旋转
          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置
            // ------   线 ---------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴 刻度
            show: true, // 是否显示
            inside: true, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              // 默认取轴线的样式
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 5, // 刻度标签与轴线之间的距离
            color: "#666", // 默认取轴线的颜色
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              // color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: [],
        },
        //   ------   y轴  ----------
        yAxis: {
          scale: true,
          max: null,
          min: 0,
          // interval:10,
          // name: "百分比(%)", // 轴名称
          splitNumber: 10,
          show: true, // 是否显示
          position: "left", // y轴位置
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          nameLocation: "end", // 轴名称相对位置value
          nameTextStyle: {
            // 坐标轴名称样式
            color: "black",
            padding: [5, 58, 0, 5], // 坐标轴名称相对位置
          },
          name: "数量(台)",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          //nameRotate: 270, // 坐标轴名字旋转

          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            //  -----   箭头 -----
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置

            // ----- 线 -------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴的刻度
            show: true, // 是否显示
            inside: false, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              color: "#666", // 默认取轴线的颜色
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴的标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#666", // 默认轴线的颜色
            formatter: "{value}",
          },
          splitLine: {
            // gird 区域中的分割线
            show: true, // 是否显示
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        //  -------   内容数据 -------
        series: [
          {
            name: "产品设备", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: true,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 30, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
            data: [
              // 2,3,5,4,2,1,3,5,1,0,0,0,3,5,0,0,3,0,1,0,2,0
            ],
          },
        ],
      },

      // 厂商设备柱状图数据
      firm_option: {
        // ----  标题 -----
        title: {
          text: "厂商设备数量排名Top10",
          textStyle: {
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          },
          x: "top",
          y: "bottom",
          padding: [0, 0, 10, 100], // 位置
        },
        // ---- legend ----
        // legend: {
        //   type: "plain", // 图列类型，默认为 'plain'
        //   top: "1%", // 图列相对容器的位置 top\bottom\left\right
        //   left:'70%',
        //   selected: {
        //     // '销量': true  // 图列选择，图形加载出来会显示选择的图列，默认为true
        //   },
        //   name: "产品设备数量",
        //   icon: "circle",
        //   textStyle: {
        //     // 图列内容样式
        //     color: "#4F81BD", // 字体颜色
        //     backgroundColor: "#F5F7F9", // 字体背景色
        //   },
        //   tooltip: {
        //     // 图列提示框，默认不显示
        //     show: true,
        //     color: "red",
        //   },
        //   //   data: [
        //   //     // 图列内容
        //   //     {
        //   //       name: "销量",
        //   //       icon: "circle",
        //   //       textStyle: {
        //   //         color: "red", // 单独设置某一个图列的颜色
        //   //         backgroundColor: "#fff", // 单独设置某一个图列的字体背景色
        //   //       },
        //   //     },
        //   //   ],
        // },
        // ---  提示框 ----
        tooltip: {
          show: true, // 是否显示提示框，默认为true
          trigger: "item", // 数据项图形触发
          // formatter: "{b}: {c}<br />{b}: {c}",
          axisPointer: {
            // 指示样式
            type: "shadow",
            // axis: "auto",
          },
          padding: 5,
          textStyle: {
            // 提示框内容的样式
            color: "#ffffff",
          },
        },
        // ---- gird区域 ---
        gird: {
          show: false, // 是否显示直角坐标系网格
          top: 80, // 相对位置 top\bottom\left\right
          width: 800,
          containLabel: false, // gird 区域是否包含坐标轴的刻度标签
          tooltip: {
            show: true,
            trigger: "item", // 触发类型
            textStyle: {
              color: "#666",
            },
          },
        },
        //  ------  X轴 ------
        xAxis: {
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          // name: "日期", // 轴名称
          nameLocation: "end", // 轴名称相对位置
          nameTextStyle: {
            // 坐标轴名称样式
            color: "#666",
            padding: [5, 0, 0, -5],
          },
          name: "厂商",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          nameRotate: 0, // 坐标轴名字旋转
          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置
            // ------   线 ---------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴 刻度
            show: true, // 是否显示
            inside: true, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              // 默认取轴线的样式
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 5, // 刻度标签与轴线之间的距离
            color: "#666", // 默认取轴线的颜色
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              // color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: [],
        },
        //   ------   y轴  ----------
        yAxis: {
          scale: true,
          max: null,
          min: 0,
          // interval:10,
          // name: "百分比(%)", // 轴名称
          splitNumber: 10,
          show: true, // 是否显示
          position: "left", // y轴位置
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          nameLocation: "end", // 轴名称相对位置value
          nameTextStyle: {
            // 坐标轴名称样式
            color: "black",
            padding: [5, 58, 0, 5], // 坐标轴名称相对位置
          },
          name: "数量(台)",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          //nameRotate: 270, // 坐标轴名字旋转

          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            //  -----   箭头 -----
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置

            // ----- 线 -------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴的刻度
            show: true, // 是否显示
            inside: false, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              color: "#666", // 默认取轴线的颜色
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴的标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#666", // 默认轴线的颜色
            formatter: "{value}",
          },
          splitLine: {
            // gird 区域中的分割线
            show: true, // 是否显示
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        //  -------   内容数据 -------
        series: [
          {
            name: "厂商设备", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: true,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 30, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
            data: [
              // 2,3,5,4,2,1,3,5,1,0,0,0,3,5,0,0,3,0,1,0,2,0
            ],
          },
        ],
      },

      // 在线设备百分比柱状图
      device_option: {
        // ----  标题 -----
        title: {
          text: "在线设备百分比排名Top10",
          textStyle: {
            color: "black",
            fontSize: 16,
            fontWeight: "bold",
          },
          x: "top",
          y: "bottom",
          padding: [0, 0, 10, 100], // 位置
        },
        // ---- legend ----
        // legend: {
        //   type: "plain", // 图列类型，默认为 'plain'
        //   top: "1%", // 图列相对容器的位置 top\bottom\left\right
        //   left:'70%',
        //   selected: {
        //     // '销量': true  // 图列选择，图形加载出来会显示选择的图列，默认为true
        //   },
        //   name: "产品设备数量",
        //   icon: "circle",
        //   textStyle: {
        //     // 图列内容样式
        //     color: "#4F81BD", // 字体颜色
        //     backgroundColor: "#F5F7F9", // 字体背景色
        //   },
        //   tooltip: {
        //     // 图列提示框，默认不显示
        //     show: true,
        //     color: "red",
        //   },
        //   //   data: [
        //   //     // 图列内容
        //   //     {
        //   //       name: "销量",
        //   //       icon: "circle",
        //   //       textStyle: {
        //   //         color: "red", // 单独设置某一个图列的颜色
        //   //         backgroundColor: "#fff", // 单独设置某一个图列的字体背景色
        //   //       },
        //   //     },
        //   //   ],
        // },
        // ---  提示框 ----
        tooltip: {
          show: true, // 是否显示提示框，默认为true
          trigger: "item", // 数据项图形触发
          // formatter: "{b}: {c}<br />{b}: {c}",
          axisPointer: {
            // 指示样式
            type: "shadow",
            // axis: "auto",
          },
          padding: 5,
          textStyle: {
            // 提示框内容的样式
            color: "#ffffff",
          },
        },
        // ---- gird区域 ---
        gird: {
          show: false, // 是否显示直角坐标系网格
          top: 80, // 相对位置 top\bottom\left\right
          width: 800,
          containLabel: false, // gird 区域是否包含坐标轴的刻度标签
          tooltip: {
            show: true,
            trigger: "item", // 触发类型
            textStyle: {
              color: "#666",
            },
          },
        },
        //  ------  X轴 ------
        xAxis: {
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          // name: "日期", // 轴名称
          nameLocation: "end", // 轴名称相对位置
          nameTextStyle: {
            // 坐标轴名称样式
            color: "#666",
            padding: [5, 0, 0, -5],
          },
          name: "设备",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          nameRotate: 0, // 坐标轴名字旋转
          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置
            // ------   线 ---------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴 刻度
            show: true, // 是否显示
            inside: true, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              // 默认取轴线的样式
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 5, // 刻度标签与轴线之间的距离
            color: "#666", // 默认取轴线的颜色
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              // color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: [],
        },
        //   ------   y轴  ----------
        yAxis: {
          scale: true,
          max: 100,
          min: 0,
          // interval:10,
          // name: "百分比(%)", // 轴名称
          splitNumber: 10,
          show: true, // 是否显示
          position: "left", // y轴位置
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          nameLocation: "end", // 轴名称相对位置value
          nameTextStyle: {
            // 坐标轴名称样式
            color: "black",
            padding: [5, 58, 0, 5], // 坐标轴名称相对位置
          },
          name: "在线率(%)",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          //nameRotate: 270, // 坐标轴名字旋转

          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            //  -----   箭头 -----
            symbol: ["none", "arrow"], // 是否显示轴线箭头
            symbolSize: [8, 8], // 箭头大小
            symbolOffset: [0, 7], // 箭头位置

            // ----- 线 -------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴的刻度
            show: true, // 是否显示
            inside: false, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              color: "#666", // 默认取轴线的颜色
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴的标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#666", // 默认轴线的颜色
            formatter: "{value}",
          },
          splitLine: {
            // gird 区域中的分割线
            show: true, // 是否显示
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        //  -------   内容数据 -------
        series: [
          {
            name: "在线设备", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: true,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",       
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 30, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
            data: [
            ],
          },
        ],
      },

      // 设备统计
      deviceCount: 0,
      online: 0,
      outline: 0,
      inactive: 0,
      companyCount:0,
    };
  },
  mounted() {
    // 获取产品总数
    this.getProjectCount();
    // 获取产品设备数量排名top10
    this.getProductDevice();

    // 获取厂商统计数量排名Top10
    this.getCompany();

    // 获取设备数量
    this.getDeviceCount();

    // this.getDeviceInfo()
    this.getDevicePercent()
  },
  methods: {
    ...mapGetters(["companyCode"]),
    // 获取产品总数
    getProjectCount() {
      axios
        .request({
          module: "SMP",
          url: "/product/count",
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 0) {
            // console.log(res.data);
            this.productNum = res.data.data;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取产品设备数量排名TOP10
    // /device/group/count?group=product&top=10
    getProductDevice() {
      this.product_option.series.data = [];
      this.product_option.xAxis.data = [];
      let base_url;
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          url:'/device/group/count?group=product&top=10',
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 0) {
            // console.log(res.data.data);
            var product_device = res.data.data;
            product_device.forEach((item) => {
              this.product_option.series[0].data.push(item.deviceCount);
              this.product_option.xAxis.data.push(item.productName);
            });
            // 基于准备好的dom，初始化echarts实例
            let product_echarts = echarts.init(
              document.getElementById("product_echarts")
            );
            // 绘制图表,this.echarts_option是数据
            product_echarts.setOption(this.product_option);
            setTimeout(function () {
              window.onresize = function () {
                product_echarts.resize();
              };
            }, 0);

            // 厂商设备数量排名Top10
            // 基于准备好的dom，初始化echarts实例
            let firm_echarts = echarts.init(
              document.getElementById("firm_echarts")
            );
            // 绘制图表,this.echarts_option是数据
            firm_echarts.setOption(this.firm_option);
            setTimeout(function () {
              window.onresize = function () {
                firm_echarts.resize();
              };
            }, 0);

            // 在线设备百分比排名Top10
            // 基于准备好的dom，初始化echarts实例
            let device_echarts = echarts.init(
              document.getElementById("device_echarts")
            );
            // 绘制图表,this.echarts_option是数据
            device_echarts.setOption(this.device_option);
            setTimeout(function () {
              window.onresize = function () {
                device_echarts.resize();
              };
            }, 0);
          }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取厂商统计设备数量Top10
    getCompany() {
      this.firm_option.series.data = [];
      this.firm_option.xAxis.data = [];
      axios
        .request({
          module: "XAIR",
          url: "/device/group/count?group=company&top=10",
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 0) {
            // console.log(res.data.data)
            this.companyCount=res.data.data.length
            var firm_device = res.data.data;
            firm_device.forEach((item) => {
              // console.log(item)
              this.firm_option.series[0].data.push(item.deviceCount);
              this.firm_option.xAxis.data.push(item.shortName);
            });
            // 厂商设备数量排名Top10
            // 基于准备好的dom，初始化echarts实例
            let firm_echarts = echarts.init(
              document.getElementById("firm_echarts")
            );
            // 绘制图表,this.echarts_option是数据
            firm_echarts.setOption(this.firm_option);
            setTimeout(function () {
              window.onresize = function () {
                firm_echarts.resize();
              };
            }, 0);
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 统计设备数量
    getDeviceCount() {
      axios
        .request({
          module: "SMP",
          url: "/device/count",
          method: "get",
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.deviceCount = res.data.data.count;
            this.online = res.data.data.online;
            this.outline = res.data.data.actived - res.data.data.online;
            this.inactive = res.data.data.count - res.data.data.actived;
            // this.productNum = res.data.data;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 在线设备百分比
    getDevicePercent(){
      this.device_option.series.data = [];
      this.device_option.xAxis.data = [];
      axios.request({
        module:'XAIR',
        method:'get',
        url:'/device/group/count?group=company&top=10&orderType=1',
      }).then(res=>{
        if(res.data.code==0){
            let device = res.data.data;
            let per
            device.forEach(item=>{
              // console.log(item)
              per=((item.onlineCount/item.deviceCount)*100).toFixed(2)
              this.device_option.series[0].data.push(per);
              this.device_option.xAxis.data.push(item.shortName);
            })
            
            // 在线设备百分比排名Top10
            // 基于准备好的dom，初始化echarts实例
            let device_echarts = echarts.init(
              document.getElementById("device_echarts")
            );
            // 绘制图表,this.echarts_option是数据
            device_echarts.setOption(this.device_option);
            setTimeout(function () {
              window.onresize = function () {
                device_echarts.resize();
              };
            }, 0);
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      })
    },


    // getDeviceInfo(){
    //   axios.request({
    //     module:'XAIR',
    //     method:'get',
    //     url:'/device/list'
    //   }).then(res=>{
    //     console.log(res.data)
    //   }).catch(err=>{
    //     console.log(err)
    //   })
    // },
  },
};
</script>

<style lang="less" scoped>
@import "datastatistic.less";
</style>