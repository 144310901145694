<template>
  <GlobalLayout>
    <div class="device-wrap">
      <div class="device_title">
        <span class="title"
          ><router-link to="/control/all/device">设备</router-link>
          <span style="color: #383838">/ 设备详情</span></span
        >
      </div>
      <div class="paths">
        <router-link
          :to="
            '/control/all/device/detail/deviceInfo?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode +
            '&status=' +
            $route.query.status
          "
          >基本信息</router-link
        >
        <router-link
          :to="
            '/control/all/device/detail/wechat?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode 
          "
          >微信信息</router-link
        >
        <router-link
          :to="
            '/control/all/device/detail/deviceData?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode +
            '&status=' +
            $route.query.status
          "
          >设备数据</router-link
        >
        <router-link
          :to="
            '/control/all/device/detail/deviceHistory?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode +
            '&status=' +
            $route.query.status
          "
          >历史数据</router-link
        >
        <router-link
          v-if="deviceInfo.productType == '2'"
          :to="
            '/control/all/device/detail/child?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode +
            '&status=' +
            $route.query.status
          "
          >子设备管理</router-link
        >
        <router-link
          :to="
            '/control/all/device/detail/firmware?deviceCode=' +
            $route.query.deviceCode +
            '&productCode=' +
            $route.query.productCode +
            '&status=' +
            $route.query.status+'&version='+this.deviceInfo.firmwareVersion
          "
          v-if="firmwareUpdateAvaliable"
          >版本信息</router-link
        >
      </div>
      <hr style="color: #e5e5e5; opacity: 0.2" />

      <keep-alive>
        <router-view></router-view>
      </keep-alive>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      deviceInfo: [],
      thingModel:null
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
    firmwareUpdateAvaliable(){
      console.log(`this.thingModel=${this.thingModel}`)
      if(null!=this.thingModel)
        return null!=this.thingModel.services.find(s=>s.code=='FirmwareUpdate');
      return false;
    },
  },
  mounted() {
    this.getProductModel(()=>{
      this.getDeviceInfo();
    });
    
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    //加载产品物模型
    getProductModel(callback) {
      axios
        .request({
          url: "/product/thingmodel",
          method: "get",
          params: { productCode: this.$route.query.productCode },
        })
        .then((res) => {
          if (res.data.code == 0) {
            let thingModel = res.data.data;
            this.thingModel = thingModel;
            if(callback){
              callback(null,thingModel)
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    
    getDeviceInfo() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/device/basicinfo?companyCode=" +
          localStorage.getItem("companyCode");
      } else if (this.getUserType() == 1) {
        base_url = "/device/basicinfo?companyCode=" + this.companyCode();
      } else {
        base_url = "/device/basicinfo?companyCode=" + this.companyList[0].code;
      }
      // console.log(base_url)
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
          params: {
            deviceCode: this.$route.query.deviceCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            res.data.data.lastOnlineTime = formatDate(
              new Date(res.data.data.lastOnlineTime),
              "yyyy-MM-dd hh:mm:ss"
            );
            this.deviceInfo = res.data.data;
            // this.drawBound()
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.deviceInfo = {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.device-wrap {
  padding: 4rem;
  .device_title {
    .title {
      font-size: 3rem;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #1960e1;
      a {
        color: #1960e1;
      }
    }
  }
  .paths {
    margin: 2.4rem 0;

    a {
      margin-right: 5rem;
      display: inline-block;
      line-height: 3rem;
      color: #222222;
      font-size: 2rem;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;

      &.active {
        color: #1960e1;
        font-weight: 600;
      }
    }
  }
}
</style>