<template>
  <div class="header-wrap flex hside vcenter borderbox" @click="closeMenu">
    <div class="left">
      <!-- <font-awesome-icon :icon="['fas', 'bars']" class="fontIcon" @click="openMenu()" /> -->
      <!-- <div class="tarbar" v-if="this.$route.path!='/admin' && this.$route.path!='/admin/company' && this.$route.path!='/admin/user' && this.$route.path!='/admin/user/add' && this.$route.path!='/admin/user/detail' && this.$route.path!='/admin/cost' && this.$route.path!='/admin/order' && this.$route.path!='/admin/datastatitic' && this.$route.path!='/admin/company/add' && this.$route.path!='/admin/company/detail' && this.$route.path!='/admin/page' && this.$route.path!='/admin/register'">
        <img src="./img/topBar.png" alt="" @click="openMenu()" id="headermenu"/>
      </div> -->
      <h3>{{systemTitle}}</h3>
      <!-- <div class="avatar flex vcenter">
        <img src="./img/logo.png" alt="海润" />
      </div> -->
    </div>

    <div class="menu"> 
      <ul>
        <li style="width:30rem;" v-if="this.$route.path=='/control/datastatic'">{{timestamp}}</li>
        <li v-if="this.$route.path!='/control/datastatic'">
          <a class="control" @click="selectCompany" :class="isControl?yesControl:noControl">控制台</a>
        <!-- <router-link to="/control" class="control">控制台</router-link> -->
        </li>
        <li v-if="getUserType()==1 && isRootGroupUser()==true && this.$route.path!='/control/datastatic'"><router-link to="/cost/cost" class="cost">费用</router-link></li>
        <li v-if="getUserType()==1 && isRootGroupUser()==true && this.$route.path!='/control/datastatic'"><router-link to="/order/order" class="order">工单</router-link></li>
        <li v-if="isAdmin()==true&&getUserType()==0 && this.$route.path!='/control/datastatic'">
          <a class="superAdmin" @click="toAdmin" :class="isAd?yesAd:noAd">超级管理</a>
        <!-- <router-link to="/admin" class="superAdmin" id="superAdmin">超级管理</router-link> -->
        </li>

      </ul>
    </div>

    <div class="right">
      <img class="header" src="./img/topBar.png" alt="">
      <div class="userInfo">
        <p class="username">{{username}}</p>
        <p class="company" v-if="isAdmin()==false">{{getGroup().name}}</p>
      </div>
      <div class="more"> 
        <user></user>
      </div>
    </div>
     <Modal v-model="selectCompanyFlag" width="350" :footer-hide="true" title="进入控制台">
        <label><b>选择厂商:</b></label>
        <Select
          v-model="selectedCompanyCode"
          placeholder="请选择要进入的厂商"
          class="selector"
          style="width: 20rem"
          @on-change="pickCompany"
        >
          <Option
            v-for="(item, index) in companyList"
            :key="index"
            :value="item.code"
          >
            {{ item.showName }}
          </Option>
        </Select>
      </Modal>
    <!-- <h1 style="
font-size: 14px;
font-family: PingFangHK-Regular, PingFangHK;
font-weight: 400;
color: #404040;">{{ username }}</h1> -->
  </div>
</template>

<script>
import Vue from 'vue';
import acceptTitle from '../../views/sendTitle.js'
import { mapGetters } from "vuex";
import User from '../user/user.vue';
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import {iviewMixin} from '@/mixins/iviewMixin';
export default {
  mixins: [iviewMixin],
  components:{
    User,
  },
  data() {
    return {
      username: "",
      menuShow:false,
      companyList:[],
      showName:'',
      timestamp :'',
      systemTitle:'',
      newTitle:'',
      selectCompanyFlag:false,
      selectedCompanyCode:null,
      isAd:true,
      yesAd:'yesad',
      noAd:'noad',
      isControl:true,
      yesControl:'yesControl',
      noControl:'noControl',
    };
  },
  created(){
    this.setPreLoadData(false);
    if(this.isAdmin()){
      this.isAd=true
      this.isControl=false
    }else{
      this.isAd=false
      this.isControl=true
    }
  },
  mounted() {
    console.log(`group:${JSON.stringify(this.getGroup())}`);
    this.username = localStorage.getItem("userName");
    // console.log(this.isAdmin())
    if(this.isAdmin()==true){
      this.getCompanyList()
    }else{
      this.getTitle()
    }
    this.timestamp=formatDate(new Date(),'yyyy-MM-dd hh:mm:ss')
    acceptTitle.$on('title',function(val){
      this.systemTitle=val.settingValue
    })
    // console.log(JSON.stringify(this.$route.path).indexOf('/admin'))
    if(JSON.stringify(this.$route.path).indexOf('/admin')!=-1){
      // console.log(111)
      this.isAd=true
    }else{
      this.isAd=false
    }
    if(JSON.stringify(this.$route.path).indexOf('/control')!=-1){
      // console.log(111)
      this.isControl=true
    }else{
      this.isControl=false
    }
    // console.log(this.getControlModeInfo())
  },
  computed: {
    // getUsername(){
    //     return this.$store.getters.getUsername
    // },
    ...mapGetters({
      getUsername: "getUsername",
    }),
    ...mapGetters('user',['isAdmin','isPerson','isCompanyUser','isGroupUser','isRootGroupUser','getCompanyCode','isRootGroupUser','getControlModeInfo','getCompanyName','setControlMode','isControlMode','getUserType'])
  },
  watch:{
    systemTitle(newtitle,oldtitle){
      // this.systemTitle=newtitle
      // console.log(newtitle,oldtitle)
    },
  },
  methods: {
    ...mapGetters(["usertype",'companyName','groupId',"companyCode","groupName"]),
    closeMenu(event){
      let hm=document.getElementById('headermenu')
      console.log(hm.contains(event.target))
      if(hm){
        if(!hm.contains(event.target)){
          this.menuShow=false
        }else{
          this.menuShow=!this.menuShow
        }
        this.$emit('openSideMenu',this.menuShow)
      }
    },
    selectCompany(){
      if(this.isAdmin()){
        this.selectCompanyFlag = true;
      }else{
        Vue.ls.set("companyCode",this.getCompanyCode())
        localStorage.setItem("companyCode",this.getCompanyCode());
        this.setControlMode(true,this.getCompanyCode(),this.getCompanyName())
        this.$router.push("/control")
      }
    },
    pickCompany(){
       Vue.ls.set("companyCode",this.selectedCompanyCode)
       localStorage.setItem("companyCode",this.selectedCompanyCode);
       let companyName=null;
       if(null!=this.companyList){
         this.companyList.forEach(company=>{
           if(company.code==this.selectedCompanyCode){
             companyName=company.showName;
              Vue.ls.set("companyName",company.showName)
           }
         })
       }
       this.setControlMode(true,this.selectedCompanyCode,companyName);
       this.$router.push(`/control?companyCode=${this.selectedCompanyCode}`)
      console.log(this.$route.path)
      if(this.$route.path=='/control/all/device'){
        this.$router.go(0)
      }
       this.selectCompanyFlag=false;
    },
    toAdmin(){
      this.setControlMode(false,null,null);
      // console.log(this.setControlMode)
      this.$router.push('/admin')
    },
    getCompanyList(){
      axios.request({
        module:'XAIR',
        method:'get',
        url:'/company/list'
      }).then(res=>{
        if(res.data.code==0){
          this.companyList=res.data.data
          this.showName=this.companyList[0].showName
          
          this.getTitle()
          this.$forceUpdate()
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    openMenu(){
      // console.log('打开菜单')
      // this.menuShow=!this.menuShow
      // this.$emit('openSideMenu',this.menuShow)
    },
    openMore(){

    },
    changeMenu(){
      console.log('--------------')
      this.topbar=false
    },
    getTitle(){
      let base_url
      if(this.isAdmin()==true){
        base_url='/company/setting/title?companyCode='+this.companyList[0].code
      }else{
        base_url='/company/setting/title?companyCode='+this.companyCode()
      }
      axios.request({
        module:'XAIR',
        method:'get',
        url:base_url
      }).then(res=>{
        // console.log(res.data)
        if(res.data.code==0){
          // console.log(res.data.data)
          this.systemTitle=res.data.data.settingValue
          localStorage.setItem('systemTitle',res.data.data.settingKey)
          document.title = this.systemTitle;
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
};
</script>

<style lang="less" scoped>
@import "./header.less";
</style>    