<template>
  <GlobalLayout>
    <div class="set_warp">
      <div class="set-title">系统设置</div>

      <div class="paths">
        <router-link to="/control/setting/system"
          >系统设置</router-link
        >
        <!-- <router-link to="/control/setting/warn"
          >告警设置</router-link
        >
        <router-link to="/control/setting/bigScreen"
          >大屏设置</router-link
        > -->
      </div>
      <hr style="color: #e5e5e5; opacity: 0.2" />
    <keep-alive>
                <router-view></router-view>
            </keep-alive>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
@import "./set.less";
</style>