import axios from '@/libs/api.request'

export const login = ({username, password,moduleCode}) => {
    const data = {
        userName: username,
        password: password,
        moduleCode:moduleCode
    }    

    return axios.request({
        module:"XAIR",
        url:'/access/login',
        data,
        method: 'post',
    })
}
export const logout = () => {
    const data = {}

    return axios.request({
        module:"XAIR",
        url: '/access/logout',
        data,
        method: 'POST'
    })
}

export const get = (module,url, parameter) => {
    
    return axios.request({
        module,
        url: url,
        params: parameter,
        method: 'get'
    })
}

export const post = (module,url, parameter) => {
    
    return axios.request({
        module,
        url: url,
        data: parameter,
        method: 'post'
    })
}
