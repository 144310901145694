import Vue from 'vue'
import { USER_INFORMATION,CONTROL_MODE_INFORMATION } from "@/store/mutation-types"
import axios from '@/libs/api.request'
import { setToken,getToken, formatDate } from '@/libs/util'

export default{
    namespaced: true,
    state: {
        userInformation: null,     
    },
    mutations: {
        SAVE_USERINFO: (state, userInfo) => {
            state.userInformation = userInfo
            Vue.ls.set(USER_INFORMATION, userInfo)
        },
    },
    actions: {
        saveUserInfo({ commit, dispatch, getters },authentication) {
            // console.log(`saveUserInfo = ${JSON.stringify(authentication)}`);//*
            setToken(authentication.token.token)
            var user = {
                token: authentication.token.token,
                expireTime: authentication.token.expireTime,
                userName: authentication.user.userName,
                userCode: authentication.user.code,
                userType: authentication.user.userType,
                companyCode:authentication.user.companyCode,
                moduleCode:authentication.module.code,
                rights:authentication.rights,
                group:authentication.group,
            }
            if(authentication.user.userType==1){
                user = Object.assign(user,{companyName:authentication.company.showName});
            }
            // console.log(`saveUserInfo = ${JSON.stringify(user)}`);//*
            commit('SAVE_USERINFO',user);

        },
        clearUserInfo({state,commit}) {
            commit('SAVE_USERINFO', null)
        },       
        getCurrentUser(){
            return (Vue.ls.get(USER_INFORMATION)||{});
        },
        handleLogout(){
            return axios.request({
                url:"/access/logout",
                method:"post",
                module:"AUTH"
            },this)
        },
        
    },
    getters: {
        isAdmin:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return false;
            }
            return userInformation.userType==0;
        },
        isCompanyUser:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return false;
            }
            return userInformation.userType==1;
        },
        isGroupUser:state=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return false;
            }
            if( userInformation.userType==1){
                let group = userInformation.group;
                return null!=group;
            }
            return false;
        },
        isRootGroupUser:state=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            // console.log(userInformation.group)
            if(null==userInformation){
                return false;
            }
            if( userInformation.userType==1){
                let group = userInformation.group;
                if(null!=group){
                    return null==group.parentId||group.parentId=='0'||group.parentId==''
                }
            }
            return false;
        },
        getGroup:state=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return null;
            }
            if( userInformation.userType==1){
                let group = userInformation.group;
                return group;
            }
            return null;
        },
        isPerson:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return false;
            }
            return userInformation.userType==2;
        },
        getCompanyCode:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null!=userInformation){
                if(userInformation.userType==1){
                    let companyCode = userInformation.companyCode;
                    return companyCode;
                }
                if(userInformation.userType==0){
                    let controlModeInfo = Vue.ls.get(CONTROL_MODE_INFORMATION);
                    if(null==controlModeInfo){
                        return null;
                    }
                    let result =  controlModeInfo.isControlMode?controlModeInfo.companyCode:null;
                    return result;
                    // return Vue.ls.get('companyCode');
                }
                return null;
            }
            return null;
        },
        getCompanyName:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null!=userInformation){
                if(userInformation.userType==1){
                    let companyName = userInformation.companyName;
                    return companyName;
                }
                if(userInformation.userType==0){
                    let controlModeInfo = Vue.ls.get(CONTROL_MODE_INFORMATION);
                    if(null==controlModeInfo){
                        return null;
                    }
                    return controlModeInfo.isControlMode?controlModeInfo.companyName:null;
                    // return Vue.ls.get('companyName');
                }
            }
            return null;
        },
        getUserType:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            // console.log(userInformation)
            if(null!=userInformation){
                let userType = userInformation.userType;
                return userType;
            }
            return null;
        },
        getRights:(state)=>()=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return [];
            }
            let rights = userInformation.rights;
            if(null==rights){
                return [];
            }
            return rights;
        },
        hasRight:(state)=>(rightCode,aclCode)=>{
            let userInformation = Vue.ls.get(USER_INFORMATION);
            if(null==userInformation){
                return false;
            }
            let rights = userInformation.rights;
            // console.log(rights)
            if(null==rights||rights.length==0){
                return false;
            }
            for(let i in rights){
                if(rights[i].code==rightCode){
                    if(null!=aclCode){
                        if(null!=rights[i].aCls){
                            if(rights[i].aCls.includes(aclCode)){
                                return true
                            }
                        }
                    }else{
                        return true;
                    }
                }
            }
            return false;
        } ,
        setControlMode:(state)=>(isControlMode,companyCode,companyName)=>{
            let controlModeInfo = Vue.ls.get(CONTROL_MODE_INFORMATION);
            if(null==controlModeInfo){
                controlModeInfo = {isControlMode:false};
            }
            controlModeInfo = Object.assign(controlModeInfo,{isControlMode,companyCode,companyName});
            Vue.ls.set(CONTROL_MODE_INFORMATION,controlModeInfo);
        },
        isControlMode:(state)=>()=>{
            let controlModeInfo = Vue.ls.get(CONTROL_MODE_INFORMATION);
            if(null==controlModeInfo){
                return false;
            }
            return controlModeInfo.isControlMode;
        },
        getControlModeInfo:state=>()=>{
            let controlModeInfo = Vue.ls.get(CONTROL_MODE_INFORMATION);
            return   controlModeInfo;
        }
    }
}