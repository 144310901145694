<template>
  <div class="user-wrap">
    <div class="top flex hside vcenter user-choose">
      <div class="userTotal" style="width: 70rem">
        用户列表({{ userCount }})
      </div>
      <div class="choose">
        <Select
          class="white"
          v-model="groupid"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="所属群组"
          @on-change="handleSelectChoose"
        >
          <Option
            v-for="item in groupList"
            :value="item.groupId"
            :key="item.groupId"
          >
            {{ item.groupName }}
          </Option>
        </Select>
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="用户名"
          v-model="userName"
        />
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="使用人"
          v-model="nickName"
        />
        <Button
          type="primary"
          style="margin-left: 1.5rem; background: #1960e1; border: none"
          @click="selectUser()"
          icon="ios-search"
          >搜索</Button
        >
        <Button
          type="primary"
          style="margin-left: 1.5rem; background: #1960e1; border: none"
          @click="addUser()"
          >新建</Button
        >
      </div>
    </div>
    <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有用户'"
      :loading="loading"
      class="notLineTable"
    >
      <template slot-scope="{ row, index }" slot="operate">
        <router-link
          :to="
            '/control/user/detail?code=' +
            row.code +
            '&groupId=' +
            row.groupId +
            '&userName=' +
            row.userName +
            '&nickName=' +
            row.nickName +
            '&phone=' +
            row.phone +
            '&password=' +
            row.password+
            '&groupLevel='+
            row.groupLevel+
            '&mnCascade='+
            row.mnCascade
          "
          class="operationTxt"
          >查看</router-link
        >
        <a class="operationTxt" @click="resetPassword(row)">重置密码</a>
        <a class="operationTxt" @click="deleteUser(row)">删除</a>
      </template>
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>

      <!-- 重置用户密码对话框 -->
      <Modal
        v-model="resetPwd"
        width="560"
        :mask-closable="false"
        :footer-hide="true"
      >
        <h5 class="title">重置密码</h5>
        <Form
          ref="resetPasswordRef"
          :model="password"
          :rules="rules"
          :label-width="80"
        >
        <FormItem label="新密码" prop="newPassword">
          <Input
            type="password"
            v-model="password.newPassword"
            placeholder="请输入新密码"
          ></Input>
        </FormItem>
          <FormItem label="确认密码" prop="resetPassword">
            <Input
              type="password"
              v-model="password.resetPassword"
              placeholder="再次输入新密码"
            ></Input>
          </FormItem>
        </Form>
        <p class="tip" ref="tips" style="color:red;margin-left:8rem"></p>
        <div class="btns" style="display: flex; justify-content: space-around">
          <Button style="width: 20%" @click="dissmissResetModal()">取消</Button>
          <Button
            style="width: 60%; background: #0060ff"
            type="primary"
            @click="resetModalMakeSure()"
            >确认</Button
          >
        </div>
      </Modal>
    <!-- 删除用户对话框 -->
    <Modal
      v-model="deleteuser"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">删除用户</h5>
      <p
        class="tip"
        ref="tip"
        style="
          font-size: 2.4rem;
          color: #e02020;
          margin: 0px auto 5rem auto;
          text-align: center;
        "
      >
        确认要删除该用户吗？
      </p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="closeLDelete()">取消</Button>
        <Button
          style="width: 20%; background: #0060ff"
          type="primary"
          @click="isDelete()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
import md5 from "md5-node";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      userCount: 0,
      groupList: [],
      userName: "",
      groupid: "",
      nickName: "",
      columns: [
        {
          title: "用户名",
          key: "userName",
          align: "center",
        },
        {
          title: "使用人",
          key: "nickName",
          align: "center",
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
          // render: (h, params) => {
          //   if (params.row.status == 0) {
          //     return h(
          //       "div",
          //       {
          //         style: {
          //           color: "#999999"
          //         }
          //       },
          //       "离线"
          //     );
          //   }else if(params.row.status == 1){
          //     return h(
          //       "div",
          //       {
          //         style: {
          //           color: "#00C869"
          //         }
          //       },
          //       "在线"
          //     );
          //   }
          //   else if(params.row.status == 3){
          //     return h(
          //       "div",
          //       {
          //         style: {
          //           color: "#f7941d"
          //         }
          //       },
          //       "未激活"
          //     );
          //   }
          // }
        },
        {
          title: "所属群组",
          key: "groupName",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          render: (h, params) => {
            if (params.row.createTime) {
              return h(
                "div",
                formatDate(
                  new Date(params.row.createTime),
                  "yyyy-MM-dd hh:mm:ss"
                )
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      resetPwd: false,
      resetNewPwd: "",
      userCode: "",
      password: {
        newPassword: "",
        resetPassword: "",
      },
      passwordrules: [
        {
          required: true,
          message: "不能为空",
          trigger: "blur",
          min: 6,
          max: 16,
        },
      ],
      deleteuser: false,
    };
  },
  computed: {
    ...mapGetters("user", [
      "hasRight",
      "isAdmin",
      "isCompanyUser",
      "isPerson",
      "getCompanyCode",
      "getCompanyName",
    ]),
    rules() {
      return {
        newPassword: this.passwordrules,
        resetPassword: this.passwordrules,
      };
    },
  },
  mounted() {
    // console.log(localStorage.getItem('companyCode'))
    // console.log(this.groupId())
    // this.getUserList();
    // this.getGroupList()
    this.getUserList();
    this.getGroupList();
  },
  methods: {
    ...mapGetters(["companyCode", "usertype", "groupId"]),
    //   获取用户列表
    getUserList(groupId, userName, nickName) {
      this.loading=true;
      let base_url;
      if (this.getCompanyCode() != null) {
        base_url = "/user/list?companyCode=" + this.getCompanyCode();
        // console.log(base_url)
      } else if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/user/list?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      }
      var data = {
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
        groupId: groupId,
        userName: userName,
        nickName,
        nickName,
      };
      axios
        .request({
          module: "AUTH",
          url: base_url,
          method: "get",
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data);
            this.userCount = res.data.data.total;
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.data.length; i++) {
              d[i] = {
                userName: res.data.data.data[i].userName,
                nickName: res.data.data.data[i].nickName,
                phone: res.data.data.data[i].phone,
                groupName: res.data.data.data[i].groupName,
                createTime: res.data.data.data[i].createTime,
                code: res.data.data.data[i].code,
                groupId: res.data.data.data[i].groupId,
                password: res.data.data.data[i].password,
                groupLevel: res.data.data.data[i].groupLevel,
                mnCascade:res.data.data.data[i].mnCascade,
              };
            }
            this.dataSource = d;
            // console.log(this.dataSource)
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
      this.loading=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGroupList() {
      let base_url;
      if (this.getCompanyCode() != null) {
        base_url = "/group/query?companyCode=" + this.getCompanyCode();
        // console.log(base_url)
      } else if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            // groupId:this.groupId()
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectChoose() {
      // console.log(this.groupid)
      this.getUserList(this.groupid);
    },
    selectUser() {
      // console.log(this.userName,this.nickName)
      this.userName = this.userName.trim();
      this.nickName = this.nickName.trim();
      this.getUserList(this.groupid, this.userName, this.nickName);
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getUserList();
    },
    // 重置密码对话框
    resetPassword(row) {
      this.userCode = row.code;
      this.resetPwd = true;
    },
    // 确认
    resetModalMakeSure() {
      this.$refs.tips.innerText = "";
      this.$refs.resetPasswordRef.validate((valid) => {
        console.log(this.password.newPassword == this.password.resetPassword)
        if (this.password.newPassword == this.password.resetPassword) {
          axios
          .request({
            module: "AUTH",
            method: "post",
            url: "/user/pwd/reset",
            data: {
              code: this.userCode,
              password: md5(this.password.resetPassword),
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$Message.info("更新用户密码完成");
              this.resetPwd = false;
              this.password.resetPassword = "";
              this.getUserList();
            } else if(res.data.code==105){
              this.$Message.warning('系统时间有误，请重设系统时间')
            }else{
              this.$Message.error(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }else{
          this.$refs.tips.innerText = "两次密码输入不一致";
        }
        // console.log(valid)
        // console.log(this.userCode,this.password.resetPassword)

        // if(valid==true){
        
        // }
      });
    },
    // 取消
    dissmissResetModal() {
      this.resetPwd = false;
    },
    // 删除用户
    deleteUser(row) {
      (this.userCode = row.code), (this.deleteuser = true);
    },
    isDelete() {
      axios
        .request({
          module: "AUTH",
          method: "post",
          url: "/user/delete",
          data: {
            code: this.userCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.info("删除用户完成");
            this.getUserList();
            this.deleteuser = false;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.deleteuser = false;
          }
        });
    },
    closeLDelete() {
      this.deleteuser = false;
    },

    addUser() {
      this.$router.push({ path: "/control/user/add" });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./userList.less";
</style>