import Vue from 'vue'
import Vuex from 'vuex'
import { USER_INFO,USERTYPE_ADMIN } from "@/store/mutation-types"
import user from './module/user'

// import getters from './getters.js'


Vue.use(Vuex)
import {
  login,
  logout,
} from '@/api/user'
export default new Vuex.Store({
  state: {
    username: '',
        userInfo: null,
        usercode: '',
        companyCode: '',
        projectcode: '',
        contact: '',  
        projectName: '', 
        groupId:'',
        groupName:'',
  },
  mutations: {
    SET_USERNAME: (state, name) =>{
      state.username = name
    },
    SET_USERINFO: (state, userInfo) => {
        state.userInfo = userInfo
        Vue.ls.set(USER_INFO, userInfo)
    },
    SET_ROUTERS: (state, data) => {
        
    },
    SET_ROLE: (state, role) => {
        state.role = role
    },
    SET_COMPANYCODE: (state, code) => {
        // console.log(code)
        state.companyCode = code
    },
    SET_GROUPID: (state, id) => {
        // console.log(code)
        state.groupId = id
    },
    SET_GROUPNAME: (state, name) => {
        // console.log(code)
        state.groupName = name
    },
    SET_PROJECTCODE: (state, projectcode) => {
        state.projectcode = projectcode
    },
    SET_PROJECTNAME: (state, projectName) => {
        state.projectName = projectName
    },
  },
  actions: {
    isAdmin({commit,dispath,getters}){
        let user = Vue.ls.get(USER_INFO);
        if(null==user){
            return false;
        }
        return user.userType==USERTYPE_ADMIN;
    },
    handleLogin({ commit, dispatch, getters }, { username, password ,moduleCode}){
      // 去除用户名头尾空格
      username = username.trim()
      return new Promise((resolve,reject)=>{
        login({
          username,
          password,
          moduleCode,
      }).then(res => {
        if(res.data.code == 0){
          var data = res.data.data; 
          // console.log(data)
          let user = {
              token:data.token.token,
              expireTime:data.token.expireTime,
              userCode:data.user.code,
              userName:data.user.userName,
              userType:data.user.userType,
              nickName:data.user.nickName||data.user.userName,
              companyCode:null!=data.company?data.company.code:data.user.companyCode,
              companyName:null!=data.company?data.company.shortName:"",
              moduleCode:data.module.code  ,
              groupId:null!=data.group?data.group.groupId:'',
              groupName:null!=data.group?data.group.groupName:'',
              rights:null!=data.rights?data.rights:null
              // companyName:data.company.showName
          };       
          commit('SET_USERINFO', user)
          commit('SET_USERNAME', user.userName)
          // commit('SET_ROLE', data.role)
          commit('SET_COMPANYCODE', data.companyCode)
          commit('SET_GROUPID', data.groupId)
          commit('SET_GROUPNAME', data.groupName)
          // commit('SET_PROJECTCODE', data.projectCode)
          localStorage.setItem("userName",data.user.userName);
          localStorage.setItem("token",data.token.token);
          if(data.project){
              commit('SET_PROJECTNAME', data.project.projectName)
          }
          

      }

      resolve(res)
      }).catch(err => {
          reject(err)
      })
      })
    },
    handleLogout({ state, commit }){
      return new Promise((resolve, reject) => {
          logout().then(res => {       
              commit('SET_USERINFO', null)
              commit('SET_USERNAME', null)
              commit('SET_ROLE', null)
              commit('SET_COMPANYCODE', null)
              commit('SET_GROUPID', null)
              commit('SET_GROUPNAME', null)
              localStorage.removeItem("userName");
              localStorage.removeItem("token");
              resolve(res)
          }).catch(err => {
              reject(err)
          })
      })
      
  },

    
    // login({
    //   commit
    // }, {
    //   username,
    //   password
    // }) {
    //   // 去除用户名头尾空格
    //   username = username.trim()
    //   return new Promise(resolve,reject=>{
    //     commit('setUsername', user.userName)
    //     commit('setPassword', user.password)
    //     localStorage.setItem("userName", username);
    //     localStorage.setItem("passWord", password);
    //   }).catch(err=>{
    //     reject(err)
    //   })
    // },
  },
  modules: {
    user
  },
  getters:{
    usertype(state){
      state=Vue.ls.get(USER_INFO)
      return state.userType
    },
    username(state){
      state=Vue.ls.get(USER_INFO)
      return state.userName
    },
    usercode(state){
      state=Vue.ls.get(USER_INFO)
      return state.userCode
    },
    companyCode(state){
      state=Vue.ls.get(USER_INFO)
      return state.companyCode
    },
    companyName(state){
      state=Vue.ls.get(USER_INFO)
      return state.companyName
    },
    groupId(state){
      state=Vue.ls.get(USER_INFO)
      return state.groupId
    },
    groupName(state){
      state=Vue.ls.get(USER_INFO)
      return state.groupName
    },
    rights(state){
      state=Vue.ls.get(USER_INFO)
      return state.rights
    }
    // username: state => {state.user.username = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).userName; return state.user.username},
    // usertype: state => {state.user.userType = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).userType; return state.user.userType},
    // role: state => {state.user.role = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).role; return state.user.role},
    // usercode: state => {state.user.usercode = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).userCode; return state.user.usercode},
    // contact: state => { state.user.contact = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).contact; return state.user.contact}, 
    // projectcode: state => { state.user.projectcode = Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).projectCode; return state.user.projectcode}, //projectCode
    // projectName: state => { 
    //     if (Vue.ls.get(USER_INFO) && Vue.ls.get(USER_INFO).project){
    //         state.user.projectName = Vue.ls.get(USER_INFO).project.projectName; 
    //         return state.user.projectName
    //     }
    // }
  },
})