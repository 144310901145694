<template>

    <div>
        <div class="top flex hside vcenter" style="margin-bottom:1rem;margin-top:1rem">
            <div  class="flex">
                <Select
                    class="white"
                    v-model="queryParam.productCode"
                    style="width: 22rem;margin-right:1rem"
                    clearable
                    filterable
                    placeholder="请选择产品"
                    @on-change="getDeviceErrors"
              >
                <Option
                  v-for="item in productList"
                  :value="item.productCode"
                  :key="item.productCode"
                >
                  {{ item.productName }}
                </Option>
              </Select>
              <Select
                class="white"
                v-model="queryParam.status"
                style="width: 22rem"
                clearable
                filterable
                placeholder="请选择状态"
                @on-change="getDeviceErrors"
              >
                <Option :value="1">
                  未恢复
                </Option>
                <Option :value="0">
                  已恢复
                </Option>
                <Option :value="2">
                  处理中
                </Option>
              </Select>
              <Input 
                  style="margin: 0 0 0 2rem;width:15rem"
                  v-model="queryParam.errorCode"
                  placeholder="请输入故障码"
              />
              <Input 
                  style="margin: 0 2rem 0 2rem;width:20rem;"
                  v-model="queryParam.deviceCode"
                  placeholder="请输入设备码"
              />
              <Button type="primary" @click="getDeviceErrors">查询</Button>
      </div>
        </div>
       <Table stripe ref="selection" :columns="columns" :data="dataSource" :no-data-text="'没有设备故障记录'" :loading="loading" class="notLineTable" >
           <template slot-scope="{ row, index }" slot="device">
            <router-link :to="
                `/control/all/device/detail?deviceCode=${row.deviceCode}&productCode=${row.productCode}`"
            class="operationTxt"
            >{{row.deviceCode}}</router-link
            >
           </template>
        </Table>
        <div class="page flex hcenter">
        <Page
            :total="ipagination.total"
            :page-size="ipagination.pagesize"
            prev-text="上一页"
            next-text="下一页"
            @on-change="changePage"
            show-total
        />
        </div>
    </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
import  Constants  from "@/libs/constants";
import {iviewMixin} from '@/mixins/iviewMixin';
    export default {
        mixins:[iviewMixin],
        components: {
            GlobalLayout,
        },
        data:()=>{
            return {
                productList:[],
                columns:[
                {
                    title: '设备码',
                    key: 'deviceCode',
                    align: 'left',
                    slot:"device",
                    width:220,
                },
                {
                    title: '产品码',
                    key: 'productCode',
                    align: 'center',
                    width:150
                },
                {
                    title: '产品名称',
                    key: "productName",
                    align: 'center',
                },
                {
                    title: '故障码',
                    key: 'errorCode',
                    align: 'center',
                    width:100
                },
                {
                    title: '故障描述',
                    key: 'description',
                    align: 'center'
                },
                {
                    title: '故障值',
                    key: 'content',
                    align: 'center',
                    render(h,params){
                      let v = params.row.content;
                      if(v){
                        return h(`div`,v);
                      }else{
                        return h(`div`,`无`)
                      }
                    }
                },
                {
                    title: '状态',
                    key: 'status',
                    align: 'center',
                    width:100,
                    render(h,params){
                      let status = params.row.status;
                      let desc="--";
                      switch(status){
                        case 0:
                          desc='已恢复';
                          break;
                        case 1:
                          desc='未恢复';
                          break;
                        case 2:
                          desc='处理中';
                          break;
                        default:break;
                      }
                      return h('div',desc);
                    }
                },
                {
                    title: '故障时间',
                    align: 'center',
                    render: (h, params) => {
                      let time = params.row.time;
                      if(time){
                        return h('div',
                            formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss')
                        )
                      }else{
                        return h('div', "--")
                      }
                    }
                },
                {
                    title: '恢复时间',
                    align: 'center',
                    render: (h, params) => {
                      let time = params.row.processTime;
                      if(time){
                        return h('div',
                            formatDate(new Date(time), 'yyyy-MM-dd hh:mm:ss')
                        )
                      }else{
                        return h('div', "--")
                      }
                    }
                }
                ]
            };
        },
        created(){
            this.setPreLoadData(false);
            this.getDeviceErrors();
            this.refreshProductList();
        },
        mounted:{

        },
        methods:{
            refreshProductList(companyCode){
                this.productList=[];
                this.queryParam.productCode = null;
                this.productList = this.getProducts(companyCode);
            },
            getProducts(companyCode,productType, notNetProtocol) {
                var params = {
                productType: productType,
                notNetProtocol: notNetProtocol,
                };
                params= Object.assign(params,{companyCode});
                axios.request({
                    url: "/product/query",
                    method: "get",
                    params,
                })
                .then((res) => {
                    // console.log(res)
                    if (res.data && res.data.code == 0) {
                        this.productList = res.data.data;
                    }
                })
                .catch((err) => {
                    console.error(err)
                });
            },
            changePage(pageNo){
                if(null==pageNo){
                    this.ipagination.current
                }else{
                    this.ipagination.current = pageNo;
                }
                this.getDeviceErrors();
            },
            getDeviceErrors(){
                let params = {
                        pageSize:this.ipagination.pagesize,
                        pageNo:this.ipagination.current||1,
                    };
                params = Object.assign(params,this.queryParam);
                axios.request({
                    url:'/device/errors/query',
                    module:Constants.Modules.SMP,
                    method:'GET',
                    params
                }).then(res=>{
                    //调用成功
                    if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
                        this.wrapPage(res.data.data);
                    }else{
                        this.$Notice.warning({
                            title:`故障管理`,
                            desc:`获取故障清单错误,信息:${res.data.message},请联系维护人员修复!`
                        })
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
@import './errors.less';
</style>