var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GlobalLayout',[_c('div',{staticClass:"device-wrap"},[_c('div',{staticClass:"device_title"},[_c('span',{staticClass:"title"},[_c('router-link',{attrs:{"to":"/control/all/device"}},[_vm._v("设备")]),_c('span',{staticStyle:{"color":"#383838"}},[_vm._v("/ 设备详情")])],1)]),_c('div',{staticClass:"paths"},[_c('router-link',{attrs:{"to":'/control/all/device/detail/deviceInfo?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode +
          '&status=' +
          _vm.$route.query.status}},[_vm._v("基本信息")]),_c('router-link',{attrs:{"to":'/control/all/device/detail/wechat?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode}},[_vm._v("微信信息")]),_c('router-link',{attrs:{"to":'/control/all/device/detail/deviceData?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode +
          '&status=' +
          _vm.$route.query.status}},[_vm._v("设备数据")]),_c('router-link',{attrs:{"to":'/control/all/device/detail/deviceHistory?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode +
          '&status=' +
          _vm.$route.query.status}},[_vm._v("历史数据")]),(_vm.deviceInfo.productType == '2')?_c('router-link',{attrs:{"to":'/control/all/device/detail/child?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode +
          '&status=' +
          _vm.$route.query.status}},[_vm._v("子设备管理")]):_vm._e(),(_vm.firmwareUpdateAvaliable)?_c('router-link',{attrs:{"to":'/control/all/device/detail/firmware?deviceCode=' +
          _vm.$route.query.deviceCode +
          '&productCode=' +
          _vm.$route.query.productCode +
          '&status=' +
          _vm.$route.query.status+'&version='+this.deviceInfo.firmwareVersion}},[_vm._v("版本信息")]):_vm._e()],1),_c('hr',{staticStyle:{"color":"#e5e5e5","opacity":"0.2"}}),_c('keep-alive',[_c('router-view')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }