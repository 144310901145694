<template>
  <GlobalLayout></GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { USER_INFO } from "@/store/mutation-types";
import Vue from "vue";

export default {
  components: {
    GlobalLayout,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {
    // console.log(Vue.ls.get(USER_INFO));
    // console.log(location.hash)
    let userinfo = Vue.ls.get(USER_INFO);
    localStorage.setItem(userinfo.token, userinfo.token);
    console.log(localStorage.getItem(userinfo.token))
    if (Vue.ls.get(USER_INFO)) {
      if (userinfo.userType == 0) {
        this.$router.push({
          name: "admin",
        });
      } else {
        this.$router.push({
          name: "control",
        });
      }
    } else {
      this.$router.push({
        name: "login",
      });
    }
  },
  watch: {},
  methods: {},
};
</script>

<style scoped lang="less" rel="stylesheet/less">
</style>
