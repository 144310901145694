<template>
  <GlobalLayout>
    <div class="userdetail_wrap">
      <div class="user_title">
        <span class="title"
          ><router-link to="/control/user">用户-群组用户 </router-link>
          <span style="color: #383838">/ 用户详情</span></span
        >
      </div>

      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 30px; margin-left: 50px"
        :rules="formItemRules"
      >
        <Form-item label="群组" :label-width="150" prop="groupId">
          <Select
            v-model="formItem.groupId"
            placeholder="请选择群组"
            class="selector"
            style="width: 200px"
          >
            <Option
              v-for="(item, index) in groupList"
              :key="item.groupId"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>
        <Form-item label="是否管理级联群组" :label-width="150" prop="isManage">
          <RadioGroup v-model="formItem.isManage">
            <Radio label="1">
              <span>是</span>
            </Radio>
            <Radio label="0">
              <span>否</span>
            </Radio>
          </RadioGroup>
        </Form-item>
        <Form-item label="E-Mail" :label-width="150" prop="userName">
          <Input
            v-model="formItem.userName"
            placeholder="请输入E-Mail"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="密码" :label-width="150" prop="password">
          <Input
            v-model="formItem.password"
            placeholder="请输入密码"
            class="dark"
            type="password"
            style="width: 25rem"
          ></Input>
          <!-- <Button
            type="primary"
            class="submitbutton"
            @click="randomPassword()"
            style="
              margin-left: 1rem;
              background: #f5f7f9;
              border: 1px solid #1960e1;
              color: #1960e1;
            "
            >随机生成</Button
          > -->
        </Form-item>
        <Form-item label="使用人" :label-width="150" prop="nickName">
          <Input
            v-model="formItem.nickName"
            placeholder="请输入使用人"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="电话" :label-width="150" prop="phone">
          <Input
            v-model="formItem.phone"
            placeholder="请输入电话"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <!-- <Form-item label="权限" :label-width="150" prop="permission">
            <CheckboxGroup v-model="permissionList" v-for="(item,index) in formItem.permission" @on-change="changeBox">
              <Checkbox :label="item.code" true-value=false>{{item.name}}
                  <CheckboxGroup>
                      <Checkbox v-for="(e,i) in item.aCLs" :label="e.name">
                      </Checkbox>
                  </CheckboxGroup>
              </Checkbox>
          </CheckboxGroup>
        </Form-item> -->
        <div v-if="!this.isAdmin()">
          <span style="margin-left: 11rem">权限</span>
          <Form-item
            :label-width="150"
            prop="permission"
            v-for="(item, index) in userPer"
            :key="item.code"
          >
            <Checkbox v-model="item.checkAll" @on-change="changeBox"
              >{{ item.name }}
            </Checkbox>
            <br />
            <!-- <CheckboxGroup v-model="formItem.permission"> -->
            <Checkbox
              v-for="e in item.allRule"
              :key="e.code"
              :label="e.name"
              v-model="e.isCheck"
              :disabled="e.isDisabled"
            >
              {{ e.name }}
            </Checkbox>
            <!-- </CheckboxGroup> -->
          </Form-item>
        </div>

        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="addUserConfirm('formItemRef')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -40rem;
              width: 10rem;
            "
            >保存</Button
          >
        </Form-item>
      </Form>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
import md5 from "md5-node";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      formItem: {
        groupId: "",
        userName: "",
        password: "",
        nickName: "",
        phone: "",
        email: "",
        permission: [],
        isManage: "1",
      },
      groupList: [],
      formItemRules: {
        groupId: [{ required: false, message: "请选择群组", trigger: "blur" }],
        isManage: [{ required: true, message: "", trigger: "blur" }],
        userName: [
          { required: true, message: "E-Mail不能为空", trigger: "blur" },
          { type: "email", message: "请输入正确的E-Mail,E-Mail同时作为用户登录账号使用", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码长度不能小于6位",
            trigger: "blur",
          },
        ],
        nickName: [
          { required: false, message: "请输入使用人", trigger: "blur" },
        ],
        phone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号号码",
            trigger: ["blur", "change"],
          },
        ]
      },
      userPer: [],
      permissionList: [],
      isDisabled: false,
      companycode: "",
    };
  },
  mounted() {
    // console.log(this.isAdmin());
    // console.log(this.getGroup())
    if (localStorage.getItem("companyCode")) {
      this.companycode = localStorage.getItem("companyCode");
    } else {
      this.companycode = this.companyCode();
    }
    this.formItem.groupId = this.$route.query.groupId;
    this.formItem.userName = this.$route.query.userName;
    this.formItem.nickName = this.$route.query.nickName;
    this.formItem.password = this.$route.query.password;
    this.formItem.phone = this.$route.query.phone;
    // console.log(this.isGroupUser())
    if (this.isGroupUser() == true) {
      this.getGroupList(this.getGroup().groupId);
    } else {
      this.getGroupList();
    }
    // this.getUserInfo();
    console.log(this.rights());
    let rights = this.rights();
    let rightCodes = [];
    let idxs = [];
    for(let i in rights){
      let r = rights[i];
      if(rightCodes.includes(r.code)){
        continue;
      }else{
        rightCodes.push(r.code);
         idxs.push(i);
      }
     
    }
    let tmpRights = [];
    for(let i in idxs){
      let idx = idxs[i];
      tmpRights.push(rights[idx]);
    }
    this.userPer = tmpRights
      .filter((right) => {
        return !right.managed && (right.aCLs && right.aCLs.length>0);
      })
      .map((v) => ({
        ...v,
        checkAll: false,
        allRule: v.aCLs.map((i) => ({
          ...i,
          isCheck: false,
          isDisabled: true,
        })),
      }));
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
      "isRootGroupUser",
      "getGroup",
      'isGroupUser'
    ]),
  },
  methods: {
    ...mapGetters(["companyCode", "usertype", "rights"]),
    ...mapActions("user", ["hasRight"]),

    getGroupList(code) {
      if (this.isRootGroupUser() == true) {
        code = this.getGroup().groupId;
      }
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            groupId: code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList = [];
            // console.log(code)
            res.data.data.forEach((e) => {
              if (e.groupId != code) {
                // console.log(e)
                this.groupList.push(e);
              }
            });
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addUserConfirm(name) {
      console.log(name);
      let rights = [];
      this.userPer.forEach((item) => {
        if (item.checkAll == true) {
          let info = {};
          info["code"] = item.code;
          // console.log(info)
          let aclsinfo = [];
          for (var i = 0; i < item.allRule.length; i++) {
            // console.log(item.allRule[i].code)
            if (item.allRule[i].isCheck == true) {
              aclsinfo.push(item.allRule[i].code);
              info["acls"] = aclsinfo;
            }
          }
          // console.log(info)
          rights.push(info);
        }
      });
      console.log(rights);
      this.$refs[name].validate((valid) => {
        console.log(valid);
        if (valid) {
          let mnCas;
          if (this.formItem.isManage == "0") {
            mnCas = false;
          } else {
            mnCas = true;
          }
          // this.$Message.success("Success!");\
          axios
            .request({
              module: "XAIR",
              method: "post",
              url: "/user/addition",
              data: {
                //   code:'SJKDKDK',
                userName: this.formItem.userName,
                password: md5(this.formItem.password),
                nickName: this.formItem.nickName,
                phone: this.formItem.phone,
                email:this.formItem.email,
                groupId: this.formItem.groupId,
                rights: rights,
                userType: 1,
                companyCode: this.companycode,
                mnCascade: mnCas,
              },
            })
            .then((res) => {
              // console.log(res.data)
              if (res.data.code == 0) {
                console.log(res.data.data);
                this.$Message.success("添加成功");
                this.$router.push({
                  path: "/control/user",
                });
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            });
        } else {
          this.$Message.error("失败!");
        }
      });
    },
    checkAllGroupChange(val) {
      console.log(val);
    },
    changeBox(val) {
      // console.log(val);
      this.userPer.forEach((item) => {
        if (item.checkAll == false) {
          for (var i = 0; i < item.allRule.length; i++) {
            item.allRule[i].isCheck = false;
            item.allRule[i].isDisabled = true;
          }
        } else {
          for (var i = 0; i < item.allRule.length; i++) {
            item.allRule[i].isCheck = true;
            item.allRule[i].isDisabled = false;
          }
        }
      });
      console.log(this.userPer);
    },

    // 随机生成密码
    randomPassword() {
      let ranPwd = "";
      for (var i = 0; i < 6; i++) {
        let num = Math.floor(Math.random() * 10);
        ranPwd += num;
      }
      this.formItem.password = ranPwd;
    },
  },
};
</script>

<style lang="less" scoped>
.userdetail_wrap {
  padding: 4rem;
}
</style>