import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Storage from 'vue-ls';
import {getToken} from '@/libs/util'

Vue.config.productionTip = false
import 'jquery'


import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)



import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);
import './index.less'

import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
Vue.prototype.$axios = axios;

import md5 from 'js-md5'
Vue.prototype.$md5=md5


// import Clipboard from 'v-clipboard'
// Vue.use(Clipboard)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{
  al:'pwKitZCXHIBzd8Kq0AgwGc9ibt9NW6pF'
})

  




Vue.use(Storage, {
  namespace: 'hike_xf',
  name: 'ls',
  storage: 'local'
})




new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
