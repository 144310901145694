import { get,post } from '@/api/user'
import axios from '@/libs/api.request'
import {mapActions,mapGetters} from "vuex";

export const iviewMixin = {
    data() {
        return {
            dataSource: [],
            columns: [],
            loading: false, //加载...
            ipagination: {
                current: 1,
                total: 0,
                pagesize: 10
            },
            is_admin: false,
            url: {},
            queryParam: {},
            preLoadData:true
        }
    },
    created() {

    },
    mounted() {
        setTimeout(() => {
            if(this.preLoadData==true){
                this.loadData()
            }
        }, 500);
        this.setInitialStatus();
    },
    computed: {
        ...mapGetters('user',['isAdmin','getCurrentUser','getCompanyCode']),
    },
    methods: {
        wrapPage(pageData){
            this.dataSource = pageData.data;
            this.ipagination.total = pageData.total;
        },
        setPreLoadData(preLoadData){
            this.preLoadData = preLoadData;
        },
        setInitialStatus() {
            if(this.isAdmin()){
                this.is_admin = true;

                this.queryParam.companyCode = this.getCompanyCode();
                      
            }
        },
        loadData(arg,module) {
            if (!this.url.list) {
                this.$Message.warning('请设置url.list属性！')
                return
            }
            if(this.url.list == "noget") { //不请求
                return;
            }
            if(!module){
                module = this.url.module;
            }
            if (arg === 1) {
                this.ipagination.current = 1
            }
            var params = this.getQueryParams();  //条件 
            // console.log('params=====')
            // console.log(params)
            this.loading = true;
            module = module||'XAIR';
            get(module,this.url.list, params).then(res => {
                if (res.data.code == 0) {
                    var result = res.data.data
                    this.ipagination.total = result.total
                    // result.pageIdx
                    this.dataSource = result.data
                }
                this.loading = false;
            })

        },
        getQueryParams() {
            var param = {};
            param = Object.assign({}, this.queryParam)
            param.pageSize = this.ipagination.pagesize;
            param.pageNo = this.ipagination.current;
            return param;
        },
        changePage(val) {
            this.ipagination.current = val;
            this.loadData()
        },
        handleDelete(ObjParamID) {
            if (!this.url.delete) {
                this.$Message.error('请设置url.delete属性！')
                return;
            }

            this.$Modal.confirm({
                title: '确认删除？',
                onOk: () => {
                    var data = ObjParamID
                    axios.request({
                        url: this.url.delete,
                        data,
                        method: 'POST'
                    })
                        .then(res => {
                            if (res.data.code == 0) {
                                this.$Message.success('删除成功')
                                this.loadData()
                            }
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            });
        },
    }
}