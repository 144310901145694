<template>
  <div id="app">
    <div id="nav">
      <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    </div>
    <router-view/>
  </div>
</template>

<style>
*{
  padding: 0;
  margin: 0;
}
</style>
<script>
export default {
  data:()=> {
    return {

    };
  },
  mounted(){
    let width=$("body").width();
    //标准fontSize
    let sFontSize = 10;
    if(width>=750){
      // 为当前屏幕的基数字体，相对于设计稿计算得到的。
      let fontsize=width/1920*sFontSize;//fontsize
      $("html").css("font-size",`${fontsize}px`)
      // 当加载页⾯的时候设置⽣效
    }//
    // 当页⾯尺⼨改变的时候⽣效
    window.onresize=()=>{//
      return(()=>{
        let width=$("body").width();
        console.log(`resize width:${width}`)
        if(width>=750){
          let fontsize=width/1920*sFontSize;
          $("html").css("font-size",`${fontsize}px`)
        }
      })()
    }
  }
}
</script>

