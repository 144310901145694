<template>
  <div class="group_detail">
    <div class="tree">
        <Table row-key="groupId" stripe ref="groupTable" :update-show-children="true" :columns="columns" :data="dataSource" :no-data-text="'没有数据显示'" :loading="loading">
            
            <template slot-scope="{ row, index }" slot="operate" >
                <!-- <div  class="flex center" style="width:300px"> -->
                    <span class="pointer fontcolor" style="margin-right: 2rem" @click="clickNode(row)">编辑</span>
                    <span v-if="isAdmin()&&(!isDefault(row)&&isRoot(row))" class="pointer fontcolor" style="margin-right: 20px" @click="setCompanyDefault(row)">置为默认</span>
                    <span class="pointer" @click="openDeleteGroup(row.groupId)" >删除</span>
                <!-- </div> -->
            </template>
        </Table>
    </div>

    <Modal
      v-model="setGroup"
      width="400"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h6 class="title">操作群组</h6>
      <div class="buttons">
        <Button class="btn" @click="openResetName(groupId, groupName)"
          >重命名</Button
        >
      </div>
      <div class="buttons">
        <Button class="btn" @click="addRootGroup(groupId, groupName)"
          >新建子群组</Button
        >
      </div>
      <div class="buttons" v-if="this.parentId != '0'">
        <Button class="btn" @click="openDeleteGroup(groupId, groupName)"
          >删除群组</Button
        >
      </div>
    </Modal>

    <!-- 重命名对话框 -->
    <Modal
      v-model="resetname"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">群组重命名</h5>
      <Form
        :label-width="80"
        style="display: flex; justify-content: space-around"
      >
        <FormItem label="群组名称:">
          <Input
            type="text"
            v-model="resetName"
            :placeholder="resetName"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="resetModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>

    <!-- 删除群组对话框 -->
    <Modal
      v-model="deletegroup"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">删除群组</h5>
      <p
        class="tip"
        ref="tip"
        style="
          font-size: 24px;
          color: #e02020;
          margin: 0px auto 50px auto;
          text-align: center;
        "
      >
        确认要删除该群组吗？
      </p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="closeLDelete()">取消</Button>
        <Button
          style="width: 20%; background: #0060ff"
          type="primary"
          @click="isDelete()"
          >确认</Button
        >
      </div>
    </Modal>

    <Modal v-model="addFlag" width="600" :footer-hide="true">
      <Form
        ref="groupForm"
        :model="groupForm"
        :label-width="500"
        style="margin-top: 3rem; margin-left: 5rem"
        :rules="groupFormRules"
      >
        <Form-item label="上级群组" :label-width="100" prop="parentId">
          <Select
            v-model="groupForm.parentId"
            placeholder="请选择父群组"
            class="selector"
            style="width: 20rem"
            disabled
          >
            <Option
              v-for="(item) in groupList"
              :key="item.groupId"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>

        <Form-item label="群组名称" :label-width="100" prop="groupName">
          <Input
            v-model="groupForm.groupName"
            placeholder="请输入群组名称"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="群组类型" :label-width="100" prop="groupType">
          <RadioGroup v-model="groupForm.groupType" @on-change="getSubTypeList">
            <Radio label="1">客户</Radio>
            <Radio label="2">项目</Radio>
            <Radio label="3">经销代理</Radio>
            <Radio label="9">其他</Radio>
          </RadioGroup>
        </Form-item>
        <Form-item label="群组属性" :label-width="100" prop="subType">
          <Select v-model="groupForm.subType" style="width:25rem">
            <Option v-for="(subType,idx) in subTypeList" :key="idx" :value="subType.subType">{{subType.subTypeName}}</Option>
            <Option value="0">其他</Option>
          </Select>
        </Form-item>
        <Form-item label="描述" :label-width="100" prop="groupRemarks">
          <Input
            v-model="groupForm.remarks"
            placeholder="请输入群组描述"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="confirmAddGroup('groupForm')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -40rem;
              width: 10rem;
            "
            >添加</Button
          >
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import Tree from "@/components/Tree/tree";
import { iviewMixin } from '@/mixins/iviewMixin';
export default {
  mixins:[iviewMixin],
  props: {
    // groupId:null,
    // groupName:null
  },
  components: {
    GlobalLayout,
    Tree,
  },
  data() {
    return {
      groupList: [],
      treeNode: [],
      allOpen: null,
      nodeTrigger: false,
      /*是否开启复选框*/
      checkBox: false,
      /*是否级联选中*/
      checkBoxType: false,
      hiddenLine: false,
      setGroup: false,
      resetname: false,
      resetName: "",
      nowGroupName: "",
      deletegroup: false,
      groupId: null,
      groupName: null,
      parentId: null,
      addFlag: false,
      groupForm: {
        addFlag: false,
        parentId: "0",
        groupName: "",
        groupType: "",
        groupRemarks: "",
        subType:'0'
      },
      columns: [
        {
            title: '群组名称',
            key: 'groupName',
            align: 'left',
            tree:true,
            tooltip:true,
            render:(h,params)=>{
                let content = params.row.groupName;
                if(params.row.companyDefault==true){
                    content = content+`(默认)`
                }
                return h('span',content)
            }
        },
        
        {
            title: '群组标识',
            key: 'groupId',
            align: 'center'
        },
        {
            title:"群组类型",
            align:"center",
            key: 'groupTypeName',
            render:(h,params)=>{
                let groupTypeName = params.row.groupTypeName;
                let subTypeName = params.row.subTypeName;
                let content = groupTypeName;
                let subType = params.row.subType;
                if(null!=subType&&subType!='0'){
                    content = subTypeName;
                }
                return h('span',content)
            }
        },
        {
            title: '上级群组',
            key: 'parentName',
            align: 'center',
            tooltip:true,
            render:(h,params)=>{
                return h('div',
                    params.row.parentId=='0'?"无":params.row.parentName
                )
            }
        },
        {
            title: '所属厂商',
            key: 'companyName',
            align: 'center',
            tooltip:true,
        },
        {
            title: '添加时间',
            key: 'createTime',
            align: 'center',
            tooltip:true,
            render: (h, params) => {
                return h('div',
                    formatDate(new Date(params.row.createTime), 'yyyy-MM-dd hh:mm:ss')
                )
            }
        },
        {
            title: '操作',
            slot: 'operate',
            align: 'center'
        },

    ],
      groupFormRules: {
        parentId: [
          { required: false, message: "请选择父群组", trigger: "blur" },
        ],
        groupName: [
          { required: true, message: "请输入群组名称", trigger: "blur" },
        ],
        groupType: [
          { required: true, message: "请选择群组类型", trigger: "blur" },
        ],
        subType:[
          {required:true,message:"选择群组属性",trigger:'blur'}
        ],
        groupRemarks: [
          { required: false, message: "请输入群组描述", trigger: "blur" },
        ],
      },
      subTypeList:[]
    };
  },
  created(){
    this.setPreLoadData(false);
  },
  mounted() {
    this.getGroupTree();
    this.getGroupList();
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
      "isGroupUser",
    ]),
  },
  watch: {
    groupId(newid, oldid) {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/group/query",
          params: {
            groupId: newid,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.parentId = res.data.data[0].parentId;
            // console.log(this.parentId)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  methods: {
    getSubTypeList(callback){
        axios.request(
          {
            url:"/group/subtype/query",
            method:"get",
            module:"AUTH",
            params:{
                companyCode:this.getCompanyCode(),
                groupType:this.groupForm.groupType
            }
          }
        ).then(res=>{
            if(res.data.code==0){
                this.subTypeList = res.data.data;
                this.subTypeList.sort((a,b)=>{
                  let la = a.leaf;
                  let lb = b.leaf;
                  if(la&&lb){
                    return 0;
                  }
                  if(la&&!lb){
                    return 1;
                  }
                  if(!la&&lb){
                    return -1;
                  }
                  return 0;
                });
            }
        });
    },
    beforeClick(node) {
      //可操作内部
      //console.log('beforeClick', node);
      if (this.clickFirstNode) return node.id !== "1";
      return true;
    },
    /*点击节点信息 上个点击节点信息*/
    clickNode(data, oldData) {
      // console.log(data, oldData);
      this.setGroup = true;
      this.groupName = data.groupName;
      this.groupId = data.groupId;
      this.companyCode = data.companyCode;
      this.companyName = data.companyName;
      /*if(data.children){
                data.children.push({
                    id: (new Date()).getTime(),
                    name:'新增节点',
                    children:[]
                });
                }else{
                }*/
      // console.log(data, oldData);
      //data.open=true;
      // this.opereatingAuth = {
      //     code: data.id,
      //     name: data.name2
      // }
      // this.modalauthoperate = true;
    },
    /*回调初始化后的数据 以后修改tree实现对节点的操作即可*/
    call(data) {
      // console.log('data', data);
      //this.trees = data;
    },
    getGroupList(groupId) {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/group/query",
          params: {
            groupId: groupId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList = res.data.data;
            console.log(this.groupList);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTree(groupid) {
      axios
        .request({
          module: "SMP",
          method: "get",
          url: "/device/group/tree",
          params:{
            parentId:groupid,
            tree:true,
            children:true,
            companyCode:this.getCompanyCode()
          }
        })
        .then((res) => {
          // console.log(res.data)
          if (res.data.code == 0) {
            this.dataSource = res.data.data;
            // // console.log(res.data.data)
            // this.nowGroupName = res.data.data.groupName;
            // // this.groupList=res.data.data
            // var d = [res.data.data];
            // // console.log(d)
            // if (d.length >= 0) {
            //   //this.treeNode = d;
            //   //this.resetTreeDatas(this.treeNode);
            //   const newArr = d.map((item) => this.mapTree(item));
            //   // console.log(newArr);
            //   // console.log("-------------------");
            //   // console.log("-------" + JSON.stringify(newArr));
            //   //this.currentAuthTree = newArr;
            //   this.$refs.vtree.setTreeData(newArr);
            // }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGroupTree() {
      let base_url;
      if (!this.isGroupUser() && this.isAdmin()) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        axios
          .request({
            module: "XAIR",
            method: "get",
            url: base_url,
          })
          .then((res) => {
            res.data.data.forEach((e) => {
              if (e.companyDefault == true) {
                this.getTree(e.groupId);
              }
            });
          });
      } else if (this.isGroupUser()) {
        this.getTree();
      } else if (!this.isGroupUser() && !this.isAdmin()) {
        base_url =
          "/group/query?companyCode=" + this.getCompanyCode();
        axios
          .request({
            module: "XAIR",
            method: "get",
            url: base_url,
          })
          .then((res) => {
            res.data.data.forEach((e) => {
              if (e.companyDefault == true) {
                this.getTree(e.groupId);
              }
            });
          });
      }
    },
    mapTree(org) {
      //迭代改下id,open
      // console.log("mapTree = " + org.name);
      const haveChildren =
        Array.isArray(org.children) && org.children.length > 0;
      var name = org.groupName;

      if (org.aCLs && org.aCLs.length > 0) {
        var r = "";
        for (var i = 0; i < org.aCLs.length; i++) {
          if (i == 0) {
            r = r + "(";
          }
          if (i == org.aCLs.length - 1) {
            r = r + org.aCLs[i].name;
            r = r + ")";
          } else {
            if (org.aCLs.length == 0) {
              r = r + org.aCLs[i].name;
            } else {
              r = r + org.aCLs[i].name + ",";
            }
          }
        }
        name = name;
      } else {
        name = name;
      }
      return {
        //分别将我们查询出来的值做出改变他的key
        id: org.groupId,
        companyCode: org.companyCode,
        companyName: org.companyName,
        name: name,
        name2: org.groupName,
        open: true,
        //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
        children: haveChildren ? org.children.map((i) => this.mapTree(i)) : [],
      };
    },
    // 重命名modal
    openResetName(id, name) {
      this.setGroup = false;
      this.resetname = true;
      this.resetName = name;
    },
    resetModalMakeSure() {
      console.log(this.groupId, this.resetName);
      let code;
      if (localStorage.getItem("companyCode")) {
        code = localStorage.getItem("companyCode");
      } else {
        code = this.getCompanyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/group/updating",
          data: {
            groupId: this.groupId,
            name: this.resetName,
            companyCode: code,
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data.code == 0) {
            this.$Message.success("修改群组信息完成");
            this.resetname = false;
            this.getGroupTree();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dissmissResetModal() {
      this.resetname = false;
    },

    // 新建子群组
    // openAddGroup(id, name) {
    //   this.$router.push({
    //     path: `/control/group/add?companyName=${this.companyName}&companyCode=${this.companyCode}&groupId=${id}`,
    //   });
    // },

    addRootGroup(id, name) {
      // console.log(id,name)
      this.addFlag = true;
      this.setGroup = false;
      this.groupForm.parentId = id;
      this.getGroupList();
    },

    confirmAddGroup(formName) {
      this.$refs[formName].validate((valid) => {
        // console.log(valid)
        if (valid) {
          axios
            .request({
              module: "XAIR",
              method: "post",
              url: "/group/addition",
              data: {
                companyCode: this.getCompanyCode(),
                companyName: this.getCompanyName(),
                name: this.groupForm.groupName,
                groupType: this.groupForm.groupType,
                parentId: this.groupForm.parentId,
                subType:this.groupForm.subType
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$Message.success("添加群组成功");
                this.getGroupTree();
                this.addFlag = false;
              } else {
                this.$Message.warning(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });

          // let companyName = this.getCompanyName();
          // console.log(this.getCompanyName())
          // let data = {
          //   companyCode:this.getCompanyCode(),
          //       companyName:thisgetCompanyName(),
          //       name:this.groupForm.groupName,
          //       groupType:this.groupForm.groupType,
          //       parentId:this.groupForm.parentId
          // };
          // console.log(data)
        }
      });
    },

    openDeleteGroup(id, name) {
      this.groupId = id;
      this.setGroup = false;
      this.deletegroup = true;
    },
    isDelete() {
      axios
        .request({
          module: "AUTH",
          method: "post",
          url: "/group/delete",
          data: {
            groupId: this.groupId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success("成功删除群组");
            this.closeLDelete();
            this.getGroupTree();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        });
    },
    closeLDelete() {
      this.deletegroup = false;
    },
  },
};
</script>

<style lang="less" scoped>
.group_detail {
  padding: 4rem;
  .group_title {
    .title {
      font-size: 3rem;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #1960e1;
      a {
        color: #1960e1;
      }
      .groupname {
        font-size: 2.4rem;
        font-weight: 500;
      }
    }
  }
  .tree {
    padding: 2rem 5rem;
    color: #64c7df;
    font-size: 2.4rem;
    dd {
      margin-left: 4rem;
    }
  }
}
.buttons {
  display: flex;
  .btn {
    margin: 1.5rem auto;
    font-size: 1.8rem;
    // padding: 10px 20px;
    height: 4rem;
    line-height: 4rem;
    width: 20rem;
    color: #222222;
    border-radius: 2rem;
    &:hover,
    &.active {
      background: #018eaa;
      color: white;
      // background-color:rgba(231,241,253,1);
    }
  }
}
</style>