<template>
  <div class="lineCharts" ref="lineChart"></div>
</template>

<script>
export default {
  components: {},
  props: {
    Xdata: {
      type: Array,
      default: () => {
        return [
        ];
      },
    },
    Ydata: {
      type: Array,
      default: () => {
        return [];
      },
    },
    
  },
  data() {
    return {
      colorlists: ["#64c6e3", "#66ddb3", "#6594e2", "#dfbc6b", "#d868d6"],
      end_num:0,
      dataZoom_end:0
    };
  },
  watch:{
    Xdata:{
      handler(newData,old){
        this.Xdata = newData;
      },
      immediate:true
    },
    YData:{
      handler(newData,old){
        this.YData = newData;
        this.dataZoom_end = 0;
        for(var i=0;i<this.Ydata.length;i++){
          this.dataZoom_end=this.end_num+this.Ydata[i].length
          console.log(`this.end_num:${this.dataZoom_end}`)//*
        }
      },
      immediate:true
    }
  },
  computed: {},
  created() {},
  mounted() {
    for(var i=0;i<this.Ydata.length;i++){
        this.end_num=this.end_num+this.Ydata[i].length
    }
    // console.log(this.end_num)
    this.dataZoom_end=this.end_num
    // console.log(this.dataZoom_end)
    this.$nextTick(() => {
      this.drawLine(this.$refs.lineChart, this.Xdata, this.Ydata);
    });
  },
  methods: {
    drawLine(dom, Xdata, Ydata) {
      var series = [];
      var Y1data = [],
        Y2data = [],
        Y3data = [],
        Y4data = [],
        Y5data = [];
      if (Ydata.length >= 1) {
        Y1data = Ydata[0];
      }
      if (Ydata.length >= 2) {
        Y2data = Ydata[1];
      }
      if (Ydata.length >= 3) {
        Y3data = Ydata[2];
      }
      if (Ydata.length >= 4) {
        Y4data = Ydata[3];
      }
      if (Ydata.length >= 5) {
        Y5data = Ydata[4];
      }
      if (Y1data.length > 0) {
        series.push({
          data: Y1data,
          name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 3, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
        });
      }
      if (Y2data.length > 0) {
        series.push({
          data: Y2data,
          name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 3, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
        });
      }
      if (Y3data.length > 0) {
        // console.log(3333)
        series.push({
          data: Y3data,
          name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 25, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
        });
      }
      if (Y4data.length > 0) {
        series.push({
          data: Y4data,
         name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 3, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
        });
      }
      if (Y5data.length > 0) {
        series.push({
          data: Y5data,
          name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: false,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#4F81BD",
              //   barBorderRadius: [18, 18, 0, 0],
            },
            barWidth: 3, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
        });
      }
      // console.log('series===============')
      // console.log(series)
      var myChart = this.$echarts.init(dom);
      myChart.setOption({
        tooltip: {
          show: true, // 是否显示提示框，默认为true
          trigger: "item", // 数据项图形触发
          // formatter: "{b}: {c}<br />{b}: {c}",
          axisPointer: {
            // 指示样式
            type: "shadow",
            // axis: "auto",
          },
          padding: 5,
          textStyle: {
            // 提示框内容的样式
            color: "#ffffff",
          },
        },
        // grid: {
        //   show: false,
        //   top: 100,
        //   left: 160,
        //   right: 80,
        //   bottom: 70,
        // },
        gird: {
          show: false, // 是否显示直角坐标系网格
          top: 80, // 相对位置 top\bottom\left\right
          width: 800,
          containLabel: false, // gird 区域是否包含坐标轴的刻度标签
          tooltip: {
            show: true,
            trigger: "item", // 触发类型
            textStyle: {
              color: "#666",
            },
          },
        },
        xAxis: {
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          // name: "日期", // 轴名称
          nameLocation: "end", // 轴名称相对位置
          nameTextStyle: {
            // 坐标轴名称样式
            color: "#666",
            padding: [5, 0, 0, -5],
          },
          name: "",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          nameRotate: 0, // 坐标轴名字旋转
          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            // symbolSize: [8, 8], // 箭头大小
            // symbolOffset: [0, 7], // 箭头位置
            // ------   线 ---------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴 刻度
            show: true, // 是否显示
            inside: true, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              // 默认取轴线的样式
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 30, // 旋转角度
            margin: 5, // 刻度标签与轴线之间的距离
            color: "#666", // 默认取轴线的颜色
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              // color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: this.Xdata,
        },
        //   ------   y轴  ----------
        yAxis: {
          scale: true,
          max: null,
          min: 0,
          // interval:10,
          // name: "百分比(%)", // 轴名称
          splitNumber: 6,
          show: true, // 是否显示
          position: "left", // y轴位置
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          nameLocation: "end", // 轴名称相对位置value
          nameTextStyle: {
            // 坐标轴名称样式
            color: "black",
            padding: [5, 58, 0, 5], // 坐标轴名称相对位置
          },
          name: "",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          //nameRotate: 270, // 坐标轴名字旋转

          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            //  -----   箭头 -----
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            // symbolSize: [8, 8], // 箭头大小
            // symbolOffset: [0, 7], // 箭头位置

            // ----- 线 -------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴的刻度
            show: true, // 是否显示
            inside: false, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              color: "#666", // 默认取轴线的颜色
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴的标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#666", // 默认轴线的颜色
            formatter: "{value}",
          },
          splitLine: {
            // gird 区域中的分割线
            show: true, // 是否显示
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        series: series,
      });

      myChart.resize();
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style scoped lang="less" rel="stylesheet/less">
.lineCharts {
  width: 100%;
  height: 100%;
}
</style>
