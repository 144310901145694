<template>
  <GlobalLayout>
    <div class="datastatic-wrap">
      <div class="leftData">
        <div class="device">
          <div class="online-device">
            <img src="./img/online.png" alt="" />
            <div class="num">
              <p class="online">{{ online }}</p>
              <p class="dec">在线设备</p>
            </div>
          </div>
          <div class="count-device">
            <img src="./img/count.png" alt="" />
            <div class="num">
              <p class="count">{{ count }}</p>
              <p class="dec">设备总数</p>
            </div>
          </div>
        </div>

        <div class="product">
          <p class="product_count">产品总数:{{ productCount }}</p>
          <Table
            ref="selection"
            :columns="columns"
            :data="dataSource"
            :no-data-text="'没有产品'"
            :loading="loading"
            class="productTable"
            style="margin-top: 2rem"
            border
          >
          </Table>
        </div>

        <div class="progress">
          <div class="user_box">
            <p>
              <span class="dec">用户总数</span>
              <span class="num">{{ userPercent }}</span>
            </p>
            <!-- <Progress :percent="userPercent" stroke-color="#1960e1" hide-info /> -->
          </div>

          <div class="group_box">
            <p>
              <span class="dec">群组总数</span>
              <span class="num">{{ groupPercent }}</span>
            </p>
            <!-- <Progress
              :percent="groupPercent"
              stroke-color="#00C869"
              hide-info
            /> -->
          </div>

          <div class="rank_box">

            <div
                style="width: 35.4rem; height:20.0rem;"
              id="group_echart"
              class="group_echart"
            ></div>
            <!-- <p>
              <span class="dec">群组排名</span>
              <span class="num">222</span>
            </p>
            <Progress :percent="rankPercent" stroke-color="#E15519" hide-info /> -->
          </div>
        </div>
      </div>



      <div class="middleMap">
        <p class="middle_title">设备分布</p>
          <div class="map-wrap borderbox" >
            <div class="inner" id="mapshow" disabled></div>
          </div>
      </div>
     
     
      <div class="rightStatus">
        <div class="status_title">
          <img src="./img/status.png" alt="" />
          <span style="margin-left: 3.0rem">设备实时状态</span>
        </div>
        <ul class="list">
          <li v-for="(item, index) in deviceList" :key="index">
            <span class="na">{{ item.deviceName?item.deviceName:item.deviceCode }}</span>
            <span class="cen">
              <span v-if="item.status == 0" style="color: #999999">离线</span>
              <span v-if="item.status == 1" style="color: #00c869">在线</span>
              <span v-if="item.status == 3" style="color: #f7941d">未激活</span>
            </span>
            <span class="rig">
              <span v-if="item.lastOnlineTime">{{ dateInfo(item.lastOnlineTime) }}</span>
              <span v-else>-- --</span>
            </span>
          </li>
        </ul>
        <div class="online">
          <div
            class="piechart"
            id="piechart"
            style="width: 35.4rem; height: 20.0rem;"
          ></div>
        </div>
      </div>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
// import echarts from "echarts";
import * as echarts from "echarts";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      online: 0,
      count: 0,
      productCount: 0,
      columns: [
        {
          title: "产品名称",
          key: "productName",
          align: "center",
        },
        {
          title: "设备数量",
          key: "deviceCount",
          align: "center",
        },
      ],
      dataSource: [],
      loading: false,
      deviceList: [],
      userPercent: 25,
      groupPercent: 66,
      rankPercent: 30,

      onlinePercent: 55,
      map:null,
      option: {
        dataZoom: [
          {
              show: false, //是否显示下方滚动条
              realtime: true,
              start: 0, //显示数据开始位置
              end: 30 //显示数据结束位置
          },
          {
              type: 'inside',
              realtime: true,
              start: 0,
              end: 30
          }
        ],
        // ----  标题 -----
        title: {
          text: "群组设备排名",
          textStyle: {
            color: "black",
            fontSize: 20,
            fontWeight:'normal'
          },
          x: "top",
          y: "bottom",
          padding: [0, 0, 10, 120], // 位置
        },
        // ---  提示框 ----
        tooltip: {
          show: true, // 是否显示提示框，默认为true
          trigger: "item", // 数据项图形触发
          // formatter: "{b}: {c}<br />{b}: {c}",
          axisPointer: {
            // 指示样式
            type: "shadow",
            // axis: "auto",
          },
          padding: 5,
          textStyle: {
            // 提示框内容的样式
            color: "#ffffff",
          },
        },
        // ---- gird区域 ---
        gird: {
          show: false, // 是否显示直角坐标系网格
          top: 80, // 相对位置 top\bottom\left\right
          height:300,
          width: 800,
          containLabel: false, // gird 区域是否包含坐标轴的刻度标签
          tooltip: {
            show: true,
            trigger: "item", // 触发类型
            textStyle: {
              color: "#666",
            },
          },
        },
        //  ------  X轴 ------
        xAxis: {
          interval: 0,
          rotate: 0, //-15度角倾斜显示
          show: true, // 是否显示
          position: "bottom", // x轴的位置
          offset: 0, // x轴相对于默认位置的偏移
          type: "category", // 轴类型， 默认为 'category'
          // name: "日期", // 轴名称
          nameLocation: "end", // 轴名称相对位置
          nameTextStyle: {
            // 坐标轴名称样式
            color: "#666",
            padding: [5, 0, 0, -5],
          },
          name: "",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          nameRotate: 0, // 坐标轴名字旋转
          axisLine: {
            // 坐标轴 轴线
            show: true, // 是否显示
            // symbol: ["none", "arrow"], // 是否显示轴线箭头
            // symbolSize: [8, 8], // 箭头大小
            // symbolOffset: [0, 7], // 箭头位置
            // ------   线 ---------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴 刻度
            show: true, // 是否显示
            inside: true, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              // 默认取轴线的样式
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 5, // 刻度标签与轴线之间的距离
            color: "#666", // 默认取轴线的颜色
          },
          splitLine: {
            // gird区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              // color: 'red',
              // width: 1,
              // type: 'solid'
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
          data: [],
        },
        //   ------   y轴  ----------
        yAxis: {
          scale: true,
          max: null,
          min: 0,
          // interval:10,
          // name: "百分比(%)", // 轴名称
          splitNumber:5,
          show: true, // 是否显示
          position: "left", // y轴位置
          offset: 0, // y轴相对于默认位置的偏移
          type: "value", // 轴类型，默认为 ‘category’
          nameLocation: "end", // 轴名称相对位置value
          nameTextStyle: {
            // 坐标轴名称样式
            color: "black",
            padding: [5, 58, 0, 5], // 坐标轴名称相对位置
          },
          name: "",
          nameGap: 15, // 坐标轴名称与轴线之间的距离
          //nameRotate: 270, // 坐标轴名字旋转

          axisLine: {
            // 坐标轴 轴线
            show: false, // 是否显示
            //  -----   箭头 -----

            // ----- 线 -------
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          axisTick: {
            // 坐标轴的刻度
            show: false, // 是否显示
            inside: false, // 是否朝内
            length: 3, // 长度
            lineStyle: {
              color: "#666", // 默认取轴线的颜色
              width: 1,
              type: "solid",
            },
          },
          axisLabel: {
            // 坐标轴的标签
            show: false, // 是否显示
            inside: false, // 是否朝内
            rotate: 0, // 旋转角度
            margin: 8, // 刻度标签与轴线之间的距离
            color: "#666", // 默认轴线的颜色
            formatter: "{value}",
          },
          splitLine: {
            // gird 区域中的分割线
            show: false, // 是否显示
            lineStyle: {
              color: "#666",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            // 网格区域
            show: false, // 是否显示，默认为false
          },
        },
        //  -------   内容数据 -------
        series: [
          {
            name: "", // 序列名称
            type: "bar", // 类型
            legendHoverLink: true, // 是否启用图列 hover 时的联动高亮
            label: {
              // 图形上的文本标签
              show: true,
              position: "top", // 相对位置
              rotate: 0, // 旋转角度
              color: "black",
            },
            itemStyle: {
              // 图形的形状
              color: "#E15519",
                barBorderRadius: [8, 8, 0, 0],
            },
            barWidth: 16, // 柱形的宽度
            barCategoryGap: "10%", // 柱形的间距
            data: [
              2,3,5,4,2,1,6,7,8
            ],
          },
        ],
      },
      groupList:[],
      groupId:null,
    };
  },
  computed:{
    ...mapGetters('user',['isAdmin','isPerson','isGroupUser','getGroup','isCompanyUser','getCompanyCode','getCompanyName','setControlMode','isControlMode','getUserType'])
  },
  mounted() {
//     window.onload = function () {

//   this.drawBound()

// }
  if(this.isGroupUser()){
    this.groupId = this.getGroup().groupId;
  }
    this.getDeviceInfo();
    this.drawBound()
    this.getProductInfo();
    this.getUserList();
            this.getGroupList();
            this.getProductCount();
  },
  methods: {
    ...mapGetters(["companyCode",'usertype']),
    dateInfo(date){
      return formatDate(new Date(date),"MM-dd hh:mm:ss")
    },
    drawBound() {
      var map = new AMap.Map("mapshow", {
        mapStyle: "amap://styles/darkblue",
        center: [116.70948, 40.1529],
        zoom: 4,
      });
      
      this.map = map;
    },
    getDeviceInfo() {
      let base_url;
      if (localStorage.getItem("companyCode") != null && this.getUserType()==0) {
        base_url =
          "/device/count?moduleCode=XAIR&companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else{
        base_url = "/device/count?moduleCode=XAIR&companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      if(this.isGroupUser()){
        base_url = `${base_url}&groupId=${this.groupId}`
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data)
            // for (var i = 0; i < res.data.data.data.length; i++) {
            //   if (res.data.data.data[i].status == 1) {
            //     this.online=this.online+1;
            //   }
            //   if (res.data.data.data[i].lastOnlineTime) {
            //     res.data.data.data[i].lastOnlineTime = formatDate(
            //       new Date(res.data.data.data[i].lastOnlineTime),
            //       "MM-dd hh:mm"
            //     );
            //   }
            //   this.addMarker(res.data.data.data[i])
            // }
            // this.deviceList = res.data.data.data;
            // console.log(this.deviceList)
            this.count = res.data.data.count;
            this.online = res.data.data.online;
            if(this.count>0)
            this.onlinePercent = this.online / this.count;
            else{
              this.onlinePercent = 0;
            }
            this.onlinePercent = this.onlinePercent.toFixed(2);
            let params={
                companyCode:this.companyCode(),
                moduleCode:'XAIR',
                pageSize:15,pageNo:1,status:1
              }
            if(this.isGroupUser()){
              params.groupId = this.groupId;
            }
            axios.request({
              module:"SMP",
              method:"get",
              url:'/device/query/detail',
              params,
            }).then(res=>{
              if(res.data.code ==0){
                let devices = res.data.data.data;
                this.deviceList = devices;
                this.deviceList.forEach(device=>{
                  this.addMarker(device);
                })
              }
            });
            // 基于准备好的dom，初始化echarts实例
            let options = {
              backgroundColor: "#ffffff",

              title: {
                text:
                  "{a|" + this.onlinePercent * 100 + "}\n{b|" + "在线率%" + "}",
                x: "center",
                y: "center",
                textStyle: {
                  rich: {
                    a: {
                      fontSize: 30,
                      color: "#1960e1",
                    },
                    b: {
                      fontSize: 16,
                      color: "#666666",
                      padding: [5, 0],
                    },
                  },
                },
              },
              series: [
                {
                  name: "大环",
                  type: "gauge",
                  splitNumber: 100,
                  radius: "-50%",
                  center: ["50%", "50%"],
                  startAngle: 90,
                  endAngle: -269.9999,
                  axisLine: {
                    show: false,
                    lineStyle: {
                      color: [
                        [this.onlinePercent, "#1960e1"],
                        [1, "#EEEEEE"],
                      ],
                    },
                  },
                  axisTick: {
                    show: false,
                  },
                  splitLine: {
                    show: true,
                    length: 12,
                    lineStyle: {
                      color: "auto",
                      width: 1,
                    },
                  },
                  axisLabel: {
                    show: false,
                  },
                  detail: {
                    show: false,
                  },
                },
                {
                  type: "pie",
                  name: "内层细圆环",
                  radius: ["0", "65%"],
                  center: ["50%", "50%"],
                  z: 4,
                  hoverAnimation: false,
                  startAngle: 200,
                  itemStyle: {
                    normal: {
                      color: {
                        type: "linear",
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [
                          {
                            offset: 0,
                            color: "#F7F8FC", // 0% 处的颜色
                          },
                          {
                            offset: 0.3,
                            color: "#F7F8FC", // 100% 处的颜色
                          },
                          {
                            offset: 0.4,
                            color: "#F7F8FC", // 100% 处的颜色
                          },
                          {
                            offset: 1,
                            color: "#F7F8FC", // 100% 处的颜色
                          },
                        ],
                      },
                    },
                  },
                  label: {
                    show: false,
                  },
                  data: [100],
                },
              ],
            };

            var pie = echarts.init(document.getElementById("piechart"));
            pie.setOption(options);
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addMarker(item){
      //   console.log(item.lng,item.lat)
      // console.log(item.status)
      var icon
      let positions
      if(item.status==1){
        icon = require('./img/normal.png');
      }else if(item.status==0){
        icon = require('./img/offline.png');
      }else{
        icon = require('./img/warn.png');
      }
        positions= new AMap.LngLat(item.lng, item.lat)
      // console.log(icon,positions)
        var marker = new AMap.Marker({
          position:positions,
          icon: icon,
        });
        // console.log(positions)
        // marker.setMap(this.map)
        this.map.add(marker);
        // AMap.event.addListener(marker, "click", () => {
        //   infoWindow.open(this.map, new AMap.LngLat(item.lon, item.lat));
        // });
      
    },

    getProductCount() {
      let base_url;
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      if(this.isGroupUser()){
        base_url = `${base_url}&groupId=${this.groupId}`;
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.productCount = res.data.data.length;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProductInfo() {
      let base_url;
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      if(this.isGroupUser()){
        base_url = `${base_url}&groupId=${this.groupId}`;
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.length; i++) {
              d[i] = {
                productName: res.data.data[i].productName,
                deviceCount: res.data.data[i].deviceCount,
              };
            }

            this.dataSource = d;
            // console.log(this.dataSource)
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
            this.dataSource=[]
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getUserList() {
      let base_url;
      if (localStorage.getItem("companyCode")) {
        base_url =
          "/user/list?companyCode=" + localStorage.getItem("companyCode");
      } else {
        base_url = "/user/list?companyCode=" + this.companyCode();
      }
      if(this.isGroupUser()){
        base_url = `${base_url}&groupId=${this.groupId}`;
      }
      axios
        .request({
          method: "get",
          module: "XAIR",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.userPercent = res.data.data.length;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        });
    },

    getGroupList() {
      let base_url;
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      }else{
         base_url =
          "/group/query?companyCode=" + this.getCompanyCode();
      }
      if(this.isGroupUser()){
        base_url = `${base_url}&groupId=${this.groupId}`;
      }
      axios
        .request({
          method: "get",
          module: "XAIR",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList=res.data.data
            // console.log(this.groupList)
            this.option.xAxis.data=[]
            this.option.series[0].data=[]
            this.getDeviceCount(0,this.groupList.length)
            res.data.data.forEach((item,index)=>{
              // console.log(item)
              this.option.xAxis.data.push(item.groupName)
              // axios.request({
              //   module:'SMP',
              //   method:'get',
              //   url:'/device/query',
              //   params:{
              //     groupId:item.groupId
              //   }
              // }).then(res=>{
              //   if(res.data.code==0){
              //     this.option.series[0].data.push('666')
              //     this.option.series[0].data=JSON.parse(JSON.stringify(this.option.series[0].data))
              //     console.log(this.option.series[0].data)
              //   }
              // })
            })
            let start =this.option.xAxis.data.length-4
            let end =this.option.xAxis.data.length-1
            this.option.dataZoom=[
              {
                  show: false, //是否显示下方滚动条
                  realtime: true,
                  startValue: start,
                  endValue: end //显示数据结束位置
              },
              {
                  type: 'inside',
              }
            ]
            
            this.groupPercent = res.data.data.length;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        });
    },
    getDeviceCount(i,length){
      let groupid=this.groupList[i].groupId
      axios.request({
          module:'SMP',
          method:'get',
          url:'/device/query',
          params:{
            groupId:groupid
          }
      }).then(res=>{
        this.option.series[0].data.push(res.data.data.total)
        if(++i<length){
          this.getDeviceCount(i,length)
            // 基于准备好的dom，初始化echarts实例
            let group_echart = echarts.init(document.getElementById("group_echart"));
            // 绘制图表,this.kw_echarts是数据
            group_echart.setOption(this.option);
            setTimeout(function () {
              window.onresize = function () {
                group_echart.resize();
              };
            }, 0);
        }
      })
    },
  },
  watch:{
  }
};
</script>

<style lang="less" scoped>
@import "datastatic.less";
</style>