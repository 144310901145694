<template>
    <div class="menu-wrap">
        <ul v-if="isMenu==true">
            <li v-if="this.hasRight('DATAV')">
                <router-link to="/control/datastatic" class="data_static">数据可视化</router-link>
            </li>
            <li v-if="this.hasRight('DEVICE')">
                <router-link to="/control/all" class="device">设备</router-link>
            </li>
            <li v-if="this.hasRight('GROUP')">
                <router-link to="/control/allgroup" class="groups">群组</router-link>
            </li>
            <li v-if="this.hasRight('SCENE')">
                <router-link to="/control/scene/list" class="scene">场景</router-link>
            </li>
            <li v-if="this.hasRight('X_AFS')">
                <router-link to="/control/operate" class="operations">运维</router-link>
            </li>
            <li v-if="this.hasRight('ACCESSKEY')">
                <router-link to="/control/dev/setting" class="developers">开发者</router-link>
            </li>
            <li v-if="this.hasRight('AUTH_USER')">
                <router-link to="/control/user" class="users">用户</router-link>
            </li>
            <li v-if="this.hasRight('X_SYSTEM')">
                <router-link to="/control/setting" class="setting">系统设置</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import axios from '@/libs/api.request'
    export default {
        data() {
            return {
                isMenu:true
            }
        },
        methods: {
            closeMenu(){
                this.isMenu=false
            }
        },
        computed: {
            ...mapGetters('user',['hasRight','isPerson']),
        },
        mounted() {
            // console.log(this.hasRight('ACCESSKEY'))
            // console.log(this.isPerson())
        },
    }
</script>

<style lang="less" scoped>
@import "./menu.less";
</style>