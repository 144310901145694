<template>
  <GlobalLayout>
    <div class="openapi borderbox">
      <h3 class="headline">开发者AccessKey管理</h3>
      <div class="flex vcenter hside" style="margin-top: 2rem">
        <div style="margin-right: 2rem" class="flex vcenter">
          <Button
            style="margin-left: 2rem; background: #1960e1; border: none"
            type="primary"
            @click="showAddAccessKey()"
            >添加AccessKey</Button
          >
        </div>
      </div>
      <Table
        style="margin-top: 1.5rem"
        stripe
        ref="selection"
        :columns="columns"
        :data="dataSource"
        :no-data-text="'没有数据显示'"
        :loading="loading"
      >
        <template slot-scope="{ row, index }" slot="operate">
          <span
            class="pointer fontcolor"
            id="operationTxt"
            @click="showAccessSecret(row)"
            >查看Secret</span
          >
          <span
            v-if="row.enable == true && hasRight('ACCESSKEY', 'ENABLE')"
            class="pointer fontcolor"
            id="operationTxt"
            @click="enableAccessKey(row)"
            >禁用</span
          >
          <span
            v-if="row.enable == false && hasRight('ACCESSKEY', 'ENABLE')"
            class="pointer fontcolor"
            id="operationTxt"
            @click="enableAccessKey(row)"
            >启用</span
          >

          <span
            class="pointer fontcolor"
            id="operationTxt"
            @click="showAccessKeyRenew(row)"
            >续租</span
          >
          <span
            class="pointer fontcolor"
            id="operationTxt"
            @click="deleteAccessKey(row)"
            >删除</span
          >
        </template>
      </Table>
      <!-- <div class="page flex hcenter">
        <Page
          :total="ipagination.total"
          show-total
          :page-size="ipagination.pagesize"
          :current="ipagination.current"
          prev-text="上一页"
          next-text="下一页"
          @on-change="changePage"
        />
      </div> -->
      <!-- 添加对话框 -->
      <Modal v-model="accessKeyForm.addFlag" width="600">
        <p slot="header">
          <span>添加AccessKey</span>
        </p>
        <Form
          :model="accessKeyForm"
          ref="accessKeyForm"
          label-position="left"
          :label-width="120"
          :rules="accessKeyFormRules"
        >
          <Form-item v-if="isAdmin()" label="级别" prop="level" required>
            <Select
              v-model="accessKeyForm.level"
              placeholder="选择AccessKey级别"
            >
              <Option value="1">企业级</Option>
              <Option value="2">群组级</Option>
            </Select>
          </Form-item>
          <Form-item
            v-if="parseInt(accessKeyForm.level) == 2"
            label="群组"
            prop="groupId"
          >
            <Select
              style="width: 20rem"
              v-model="accessKeyForm.groupId"
              placeholder="选择群组"
              @on-change="selectGroup()"
            >
              <Option
                v-for="(item, index) in groupList"
                :key="index"
                :value="item.groupId"
              >
                {{ item.groupName }}
              </Option>
            </Select>
          </Form-item>
          <Form-item label="到期时间" prop="expireTimeDate" required>
            <Date-picker
              type="date"
              v-model="accessKeyForm.expireTimeDate"
              placeholder="选择AccessKey到期时间"
              style="width: 20rem"
              :clearable="false"
              :options="expireTimeOptions"
            ></Date-picker>
          </Form-item>
        </Form>
        <div class="flex" slot="footer">
          <Button class="modalCancel" @click="cancelAddAccessKey">取消</Button>
          <Button class="modalMakeSure" type="primary" @click="addAccessKey()"
            >确认</Button
          >
        </div>
      </Modal>
      <!-- 续租对话框 -->
      <Modal v-model="accessKeyRenewForm.renew" width="600">
        <p slot="header">
          <span>AccessKey续租</span>
        </p>
        <Form
          :model="accessKeyRenewForm"
          ref="accessKeyRenewForm"
          label-position="left"
          :label-width="120"
          :rules="accessKeyFormRenewRules"
        >
          <Form-item label="续租到" prop="expireTimeDate" required>
            <Date-picker
              type="date"
              v-model="accessKeyRenewForm.expireTimeDate"
              placeholder="选择AccessKey到期时间"
              style="width: 20rem"
              :clearable="false"
              :options="expireTimeRenewOptions"
            ></Date-picker>
          </Form-item>
        </Form>
        <div class="flex" slot="footer">
          <Button class="modalCancel" @click="cancelAccessKeyRenew"
            >取消</Button
          >
          <Button
            class="modalMakeSure"
            type="primary"
            @click="confirmRenewAccessKey()"
            >确认</Button
          >
        </div>
      </Modal>
      <Modal v-model="accessKeyForm.showSecret" width="600">
        <p slot="header">
          <span>查看AccessSecret</span>
        </p>
        <div>
          <span style="margin-right: 2rem; width: 10rem; font-weight: bold"
            >AccessSecret</span
          >
          <span style="margin-right: 2rem; font-color: red">{{
            accessKeyForm.accessSecret
          }}</span>
          <span
            class="pointer fontcolor"
            ref="secretSpan"
            v-clipboard:copy="accessKeyForm.accessSecret"
            v-clipboard:success="onCopySecretSuccess"
            v-clipboard:error="onCopySecretError"
            >复制</span
          >
        </div>
        <div class="flex" slot="footer">
          <Button
            class="modalMakeSure"
            type="primary"
            @click="cancelShowAccessSecret()"
            >关闭</Button
          >
        </div>
      </Modal>
    </div>
  </GlobalLayout>
</template>
<script lang="javascript">
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import { iviewMixin } from "@/mixins/iviewMixin";
import Constants from "@/libs/constants";

export default {
  mixins: [iviewMixin],
  components: {
    GlobalLayout,
  },
  data() {
    return {
      dataSource: [],
      companyList: [], //厂商列表
      accessKeyForm: {
        expireTimeDate: new Date(),
        showSecret: false,
        addFlag: false,
      },
      accessKeyRenewForm: {
        renew: false,
      },
      expireTimeRenewOptions: {
        disabledDate: (date) => {
          let d = new Date();

          if (null != this.accessKeyRenewForm.expireTimeDate) {
            d = this.accessKeyRenewForm.expireTimeDate;
          }
          return date && date.valueOf() < d;
        },
      },
      expireTimeOptions: {
        disabledDate(date) {
          return date && date.valueOf() <= Date.now();
        },
      },
      queryParam: {},
      accessKeyFormRenewRules: {
        expireTimeDate: [
          {
            validator: (rule, v, cb) => {
              let expireDate = this.accessKeyRenewForm.expireTimeDate;
              if (expireDate.getTime() < new Date().getTime()) {
                cb(new Error("过期时间必须大于当前时间"));
              } else {
                cb();
              }
            },
          },
        ],
      },
      accessKeyFormRules: {
        level: [
          {
            validator: (rule, v, cb) => {
              let level = this.accessKeyForm.level;
              if (null == level) {
                cb(new Error("请选择一个级别"));
                return;
              }
              cb();
            },
          },
        ],
        groupId: [
          {
            validator: (rule, v, cb) => {
              let level = this.accessKeyForm.level;
              if (level == 1) {
                this.accessKeyForm.groupId = null;
              }
              if (level == 2) {
                if (null == this.accessKeyForm.groupId) {
                  cb(new Error("请选择一个群组"));
                  return;
                }
              }
              cb();
            },
          },
        ],
        expireTimeDate: [
          {
            validator: (rule, v, cb) => {
              let expireDate = this.accessKeyForm.expireTimeDate;
              if (null == expireDate) {
                cb(new Error("选择一个过期时间,过期时间必须大于当前时间"));
                return;
              }
              if (expireDate.getTime() < new Date().getTime()) {
                cb(new Error("过期时间必须大于当前时间"));
              } else {
                cb();
              }
            },
          },
        ],
      },
      columns: [
        {
          title: "AccessKey",
          key: "accessKey",
          align: "center",
        },
        {
          title: "启用状态",
          key: "enable",
          align: "center",
          render(h, params) {
            return h("div", params.row.enable ? "启用" : "已禁用");
          },
        },
        {
          title: "级别",
          key: "level",
          align: "center",
          render(h, params) {
            let level = params.row.level;
            let content = "未定义";
            switch (level) {
              case 0:
                content = "全局";
                break;
              case 1:
                content = "企业级";
                break;
              case 2:
                content = "群组级";
                break;
              default:
                break;
            }
            return h("div", content);
          },
        },
        {
          title: "归属群组",
          key: "group_name",
          align: "center",
          render(h, params) {
            let level = params.row.level;
            let content = "--";
            switch (level) {
              case 0:
                break;
              case 1:
                break;
              case 2:
                content = params.row.groupName;
                break;
              default:
                break;
            }
            return h("div", content);
          },
        },
        {
          title: "到期时间",
          key: "expireTime",
          align: "center",
          render(h, params) {
            return h(
              "div",
              formatDate(new Date(params.row.expireTime), "yyyy-MM-dd")
            );
          },
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          render(h, params) {
            return h(
              "div",
              formatDate(new Date(params.row.createTime), "yyyy-MM-dd")
            );
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
          width: "300",
        },
      ],
    };
  },
  created() {
    this.setPreLoadData(false);
    if (this.isCompanyUser()) {
      this.accessKeyForm.uid = this.getCompanyCode();
      this.queryParam.uid = this.getCompanyCode();
      if (this.isGroupUser()) {
        let group = this.getGroup();
        if (null != group) {
          this.queryParam.groupId = group.groupId;
        }
      }
    }
    if (this.isAdmin()) {
      if (this.isControlMode()) {
        this.accessKeyForm.uid = this.getCompanyCode();
        this.queryParam.uid = this.getCompanyCode();
      }
    }
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isCompanyUser",
      "isPerson",
      "isControlMode",
      "isGroupUser",
      "getCompanyCode",
      "getCompanyName",
      "hasRight",
      "getControlModeInfo",
      "getGroup",
    ]),
  },
  mounted() {
    this.getCompanyList(() => {
      this.loadAccessKey();
    });
  },
  methods: {
    onCopySecretSuccess() {
      this.$Message.success("AccessSecret已复制到剪切板");
    },
    onCopySecretError() {
      this.$Message.error("AccessSecret复制未成功");
    },
    loadAccessKey() {
      this.loading=true;
      this.accessKeyForm.uid = this.queryParam.uid;
      this.setUName(this.accessKeyForm.uid);
      axios
        .request({
          module: Constants.Modules.AUTH,
          url: "/accesskey/list",
          method: "get",
          params: this.queryParam,
        })
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.code == Constants.ResponseCode.CODE_SUCCESS) {
              this.dataSource = res.data.data;
            } else {
              this.$Notice.warning({
                title: "加载AccessKey",
                desc: "获取AccessKey列表失败,信息:" + res.data.message,
              });
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Notice.warning({
              title: "加载AccessKey",
              desc: "加载AccessKey列表失败,信息:" + res.data.message,
            });
          }
      this.loading=false;
        })
        .catch((error) => {
          console.error(error.message || error.toString());
        });
    },
    enableAccessKey(row) {
      let enable = row.enable;
      let enableName = enable ? "禁用" : "启用";
      this.$Modal.confirm({
        title: `${enableName}AccessKey`,
        content: `确定要${enableName}AccessKey:${row.accessKey}吗?"`,
        onOk: () => {
          axios
            .request(
              {
                module: Constants.Modules.AUTH,
                url: "/accesskey/enable",
                method: "post",
                data: { accessKey: row.accessKey, enable: !enable },
              },
              this
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.loadAccessKey();
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
    },
    cancelAccessKeyRenew() {
      this.accessKeyRenewForm.renew = false;
    },
    cancelAddAccessKey() {
      this.$refs["accessKeyForm"].resetFields();
      this.accessKeyForm.addFlag = false;
    },
    showAccessKeyRenew(row) {
      this.accessKeyRenewForm.renew = true;
      this.accessKeyRenewForm.uid = row.uid;
      this.accessKeyRenewForm.accessKey = row.accessKey;
      this.accessKeyRenewForm.expireTimeDate = new Date(row.expireTime);
    },
    showAccessSecret(row) {
      this.accessKeyForm.showSecret = true;
      this.accessKeyForm.accessSecret = row.accessSecret;
    },
    cancelShowAccessSecret() {
      this.accessKeyForm.showSecret = false;
    },
    setUName() {
      for (let i in this.companyList) {
        if (this.companyList[i].code == this.accessKeyForm.uid) {
          this.accessKeyForm.uname = this.companyList[i].shortName;
        }
      }
    },
    showAddAccessKey() {
      this.accessKeyForm.uid = this.getCompanyCode();
      if (this.isGroupUser()) {
        this.accessKeyForm.groupId = this.getGroup().groupId;
        this.accessKeyForm.level = 2;
        this.accessKeyForm.groupName = this.getGroup().groupName;
      } else {
        this.accessKeyForm.groupId = null;
      }
      this.getGroupList(
        this.accessKeyForm.uid,
        this.accessKeyForm.groupId,
        (data) => {
          this.accessKeyForm.addFlag = true;
        }
      );
    },
    getGroupList(companyCode, groupId, callback) {
      axios
        .request({
          module: Constants.Modules.AUTH,
          url: "/group/query",
          method: "get",
          params: { companyCode, groupId },
        })
        .then((res) => {
          this.groupList = res.data.data;
          if (null != callback) {
            callback(this.groupList);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    selectGroup() {
      if (null != this.groupList) {
        this.groupList.forEach((group) => {
          if (group.groupId === this.accessKeyForm.groupId) {
            this.accessKeyForm.groupName = group.groupName;
          }
        });
      }
    },
    addAccessKey() {
      this.$refs["accessKeyForm"].validate((valid) => {
        if (valid) {
          let data = {
            uid: this.accessKeyForm.uid,
            expireTime: this.accessKeyForm.expireTimeDate.getTime(),
            level: this.accessKeyForm.level,
          };
          let contentData = "";
          if (this.accessKeyForm.level == 1) {
            contentData = `${this.accessKeyForm.uname}-${this.accessKeyForm.uid}`;
          }
          if (this.accessKeyForm.level == 2) {
            contentData = `${this.accessKeyForm.groupName}`;
            data = Object.assign(data, { groupId: this.accessKeyForm.groupId });
          }
          this.$Modal.confirm({
            title: "添加AccessKey",
            content: `确定要为 [${contentData}] 添加AccessKey吗?"`,
            onOk: () => {
              axios
                .request(
                  {
                    module: Constants.Modules.AUTH,
                    url: "/accesskey/addition",
                    method: "post",
                    data: data,
                  },
                  this
                )
                .then((res) => {
                  if (res.data.code == 0) {
                    this.loadAccessKey();
                    this.cancelAddAccessKey();
                  } else if (res.data.code == 105) {
                    this.$Message.warning("系统时间有误，请重设系统时间");
                  } else {
                    this.$Message.error(res.data.message);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            },
          });
        }
      });
    },
    getCompanyList(callback) {
      //获取模块列表
      var params = {};
      axios
        .request(
          {
            module: Constants.Modules.AUTH,
            url: "/company/query",
            method: "get",
            params: params,
          },
          this
        )
        .then((res) => {
          if (res.data.code == 0) {
            var result = res.data;
            this.companyList = result.data;
            if (null != callback) {
              callback();
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Notice.warning({
              title: "获取厂商列表",
              desc:
                "获取厂商列表失败,错误码:" +
                  res.data.code +
                  ",错误信息:" +
                  res.data.message || "无",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },

    confirmRenewAccessKey() {
      axios
        .request(
          {
            module: Constants.Modules.AUTH,
            url: "/accesskey/renew",
            method: "post",
            data: {
              accessKey: this.accessKeyRenewForm.accessKey,
              expireTime: this.accessKeyRenewForm.expireTimeDate.getTime(),
            },
          },
          this
        )
        .then((res) => {
          if (res.data.code == Constants.ResponseCode.CODE_SUCCESS) {
            this.loadAccessKey();
            this.accessKeyRenewForm.renew = false;
          } else {
            this.$Notice.warning({
              title: "续租AccessKey",
              desc: "续租AccessKey失败,信息:" + res.data.message,
            });
          }
        })
        .catch((error) => {
          console.error(error.message || error.toString());
        });
    },

    deleteAccessKey(row) {
      this.$Modal.confirm({
        title: `删除AccessKey`,
        content: `确定要删除AccessKey:${row.accessKey}吗?"`,
        onOk: () => {
          axios
            .request(
              {
                module: Constants.Modules.AUTH,
                url: "/accesskey/delete",
                method: "post",
                data: { accessKey: row.accessKey },
              },
              this
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.loadAccessKey();
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.error(err);
            });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./developer.less";
</style>  