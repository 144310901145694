<template>
  <div class="user-avatar-dropdown" style="position: relative; z-index: 99999">
    <Dropdown @on-click="handleClick">
      <img src="./img/more.png" alt="" />
      <!-- <Icon :size="18" type="md-arrow-dropdown"></Icon> -->
      <DropdownMenu slot="list" style="position: relative; z-index: 3">
        <DropdownItem
          style="position: relative; z-index: 3"
          name="changePassword"
          @click="passwordShow = true"
          @mouseenter.native="changeImgSrc(1, 'hover')"
          @mouseleave.native="changeImgSrc(1, '')"
          ><img
            :src="pwdImg"
            alt=""
            style="margin-right: 15px"
          />修改密码</DropdownItem
        >
        <DropdownItem
          style="position: relative; z-index: 3"
          name="logout"
          @click="logoutShow = true"
          @mouseenter.native="changeImgSrc(2, 'hover')"
          @mouseleave.native="changeImgSrc(2, '')"
          ><img
            :src="lgImg"
            @click=""
            alt=""
            style="margin-right: 15px"
          />退出登录</DropdownItem
        >
      </DropdownMenu>
    </Dropdown>

    <Modal
      v-model="passwordShow"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">修改用户密码</h5>
      <Form
        ref="changePasswordRef"
        :model="password"
        :rules="rules"
        :label-width="80"
      >
        <FormItem label="当前密码" prop="currentPassword">
          <Input
            type="password"
            v-model="password.currentPassword"
            placeholder="请输入旧密码"
          ></Input>
        </FormItem>
        <FormItem label="新密码" prop="newPassword">
          <Input
            type="password"
            v-model="password.newPassword"
            placeholder="请输入新密码"
          ></Input>
        </FormItem>
        <FormItem label="确认密码" prop="repeatnewPassword">
          <Input
            type="password"
            v-model="password.repeatnewPassword"
            placeholder="再次确认密码"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="cancelPassword">取消</Button>
        <Button
          style="width: 60%; background: #0060ff"
          type="primary"
          @click="confirmPassword"
          >确认</Button
        >
      </div>
    </Modal>
    <Modal
      v-model="logoutShow"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">退出登录</h5>
      <p
        class="tip"
        ref="tip"
        style="
          font-size: 24px;
          color: #e02020;
          margin: 0px auto 50px auto;
          text-align: center;
        "
      >
        确认退出登录吗？
      </p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="closeLogout()">取消</Button>
        <Button
          style="width: 60%; background: #0060ff"
          type="primary"
          @click="isLogout()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import "./user.less";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import md5 from "md5-node";

export default {
  name: "User",
  props: {},
  data() {
    return {
      passwordShow: false,
      logoutShow: false,
      password: {
        currentPassword: "",
        newPassword: "",
        repeatnewPassword: "",
      },
      passwordrules: [
        {
          required: true,
          message: "不能为空",
          trigger: "blur",
          min: 6,
          max: 16,
        },
      ],
      repeatdrules: [{ required: true, message: "不能为空" }],
      pwdImg: require("./img/password.png"),
      lgImg:require('./img/logout.png')
    };
  },
  computed: {
    rules() {
      return {
        currentPassword: this.passwordrules,
        newPassword: this.passwordrules,
        repeatnewPassword: this.repeatdrules,
      };
    },
  },
  methods: {
    ...mapGetters(["usercode"]),
    ...mapActions(["handleLogout", "changePasswordAction"]),
    logout() {
      this.logoutShow = true;
    },
    isLogout() {
      localStorage.removeItem('companyCode')
      this.handleLogout().then(() => {
        this.$router.push({
          name: "login",
        });
      });
    },
    closeLogout() {
      this.logoutShow = false;
    },
    message() {
      this.$router.push({
        name: "message_page",
      });
    },
    handleClick(name) {
      switch (name) {
        case "logout":
          this.logout();
          break;
        case "changePassword":
          this.changePassword();
          break;
      }
    },
    changePassword() {
      this.passwordShow = true;
    },
    confirmPassword() {
      this.$refs.tip.innerText = "";
      var data = {
        code: this.usercode(),
        oldPassword: md5(this.password.currentPassword),
        password: (this.password.newPassword),
      };
      this.$refs.changePasswordRef.validate((vaild) => {
        if (this.password.newPassword != this.password.repeatnewPassword) {
          this.$refs.tip.innerText = "两次密码输入不一致";
          return;
        }
        axios
          .request({
            url: "/user/pwd",
            data: data,
            method: "post",
            module: "AUTH",
          })
          .then((res) => {
            console.log(res);
            if (res.data.code == 0) {
              this.passwordShow = false;
              this.$Message.success("修改密码成功");
            } else if (res.data.code == 1) {
              this.$refs.tip.innerText = res.data.msg;
            }else if(res.data.code==105){
              this.$Message.warning('系统时间有误，请重设系统时间')
            }else{
              this.$Message.error(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });

    },
    changeImgSrc(key, way) {
      let tempStr = way === "hover" ? "Hover" : "";
      switch (key) {
        case 1:
          this.pwdImg = require(`./img/password${tempStr}.png`);
          break;
        case 2:
          this.lgImg = require(`./img/logout${tempStr}.png`);
          break;
      }
    },
    cancelPassword(){
      this.passwordShow=false
    },
  },
};
</script>

<style scoped lang="less" rel="stylesheet/less">
.ivu-dropdown-item {
  display: flex;
  align-items: center;
  padding: 7px 16px 7px 12px;
}
.tip {
  line-height: 24px;
  height: 24px;
  color: red;
  font-size: 14px;
  padding-left: 30px;
  margin-top: -20px;
}
.btns {
  height: 60px;
}
</style>
