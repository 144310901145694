<template>
  <div class="user-wrap">
    <div class="top flex hside vcenter user-choose">
      <div class="userTotal" style="width: 70rem">
        用户列表({{ userCount }})
      </div>
      <div class="choose">
        
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="用户昵称"
          v-model="username"
        />
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="用户电话"
          v-model="userphone"
        />
        <Button
          type="primary"
          style="margin-left: 1.5rem; background: #1960e1; border: none"
          @click="selectUser()"
          icon="ios-search"
          >搜索</Button
        >
      </div>
    </div>
    <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有用户'"
      :loading="loading"
      class="notLineTable"
    >
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      userCount: 0,
      groupList: [],
      userName: "",
      groupid: "",
      nickName: "",
      columns: [
        {
          title: " ",
          key: "headImgUrl",
          align: "right",
          width:"60",
          render: (h, params) => {
            if (params.row.headImgUrl) {
              return h("img", {
                style:{
                  width:'4.6rem',
                  height:'4.6rem',
                  // borderRadius:'50%'
                  marginTop:'.4rem'
                },
                attrs: {
                  src:params.row.headImgUrl
                },
              });
            }
          },
        },
        {
          title: "OpenID",
          key: "openId",
          align: "center",
        },
        {
          title: "用户昵称",
          key: "nickName",
          align: "center",
        },
        {
          title: "用户电话",
          key: "phone",
          align: "center",
        },
        {
          title: "地址",
          key: "address",
          align: "center",
        },
        {
          title: "更新时间",
          key: "registerTime",
          align: "center",
          render:(h,params)=>{
            return h('div',formatDate(new Date(params.row.registerTime),'yyyy-MM-dd'))
          }
        }
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      resetPwd: false,
      resetNewPwd: "",
      userCode: "",
      deleteuser: false,
      username: "",
      userphone: "",
    };
  },
  computed: {},
  mounted() {
    this.getUserList();
    this.getGroupList();
    // console.log(localStorage.getItem('companyCode'))
    // console.log(this.groupId())
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  methods: {
    ...mapGetters(["companyCode", "usertype", "groupId"]),
    //   获取用户列表
    getUserList(nickName, phone) {
      this.loading=true;
      let base_url="/wechat/user/query";
      // if (
      //   localStorage.getItem("companyCode") != null &&
      //   this.getUserType() == 0
      // ) {
      //   base_url =
      //     "/wechat/user/query?companyCode=" +
      //     localStorage.getItem("companyCode");
      //   // console.log(base_url)
      // } else {
      //   base_url = "/wechat/user/query?companyCode=" + this.companyCode();
      //   // console.log(base_url)
      // }
      var data = {
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
        nickName,
        nickName,
        phone: phone,
      };
      axios
        .request({
          module: "SMP",
          url: base_url,
          method: "get",
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data);
            this.userCount = res.data.data.total;
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            // var d = new Array();
            // for (var i = 0; i < res.data.data.data.length; i++) {
            //   d[i] = {
            //     userName: res.data.data.data[i].nickName,
            //     phone: res.data.data.data[i].phone,
            //     address: res.data.data.data[i].address,
            //     headImgUrl: res.data.data.data[i].headImgUrl,
            //   };
            // }
            this.dataSource = res.data.data.data;
            // console.log(this.dataSource)
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
      this.loading=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGroupList() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            // groupId:this.groupId()
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectUser() {
      // console.log(this.userName,this.nickName)
      this.username = this.username.trim();
      this.userphone = this.userphone.trim();
      this.getUserList(this.username, this.userphone);
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getUserList();
    },
    // 重置密码对话框
    resetPassword(row) {
      this.userCode = row.code;
      this.resetPwd = true;
    },
    // 确认
    resetModalMakeSure() {
      this.$refs.tip.innerText = "";
      this.$refs.resetPasswordRef.validate((valid) => {
        console.log(valid);
        // if(valid==true){
        axios
          .request({
            module: "AUTH",
            method: "post",
            url: "/user/pwd/reset",
            data: {
              code: this.userCode,
              password: md5(this.password.resetPassword),
            },
          })
          .then((res) => {
            console.log(res.data);
            if (res.data.code == 0) {
              this.$Message.info("更新用户密码完成");
              this.resetPwd = false;
              this.password.resetPassword = "";
              this.getUserList();
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        // }
      });
    },
    // 取消
    dissmissResetModal() {
      this.resetPwd = false;
    },
    // 删除用户
    deleteUser(row) {
      (this.userCode = row.code), (this.deleteuser = true);
    },
    isDelete() {
      axios
        .request({
          module: "AUTH",
          method: "post",
          url: "/user/delete",
          data: {
            code: this.userCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.info("删除用户完成");
            this.getUserList();
            this.deleteuser = false;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.deleteuser = false;
          }
        });
    },
    closeLDelete() {
      this.deleteuser = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./wxUser.less";
</style>