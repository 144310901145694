<template>
  <div>
    <div class="list">
      <ul>
        <li>
          <span class="label">唯一标识</span>
          <span class="cont">{{ $route.query.deviceCode }}</span>
        </li>
        <li>
          <span class="label">设备昵称</span>
          <span
            class="cont"
            v-if="this.deviceInfo.deviceName"
            @click="
              deviceUpdate($route.query.deviceCode, deviceInfo.deviceName)
            "
            style="cursor: pointer; color: #2d8cf0"
            >{{ this.deviceInfo.deviceName }}</span
          >
          <span
            class="cont"
            v-else
            @click="
              deviceUpdate($route.query.deviceCode, deviceInfo.deviceName)
            "
            style="cursor: pointer; color: #2d8cf0"
            >--</span
          >
        </li>
        <li>
          <span class="label">在线状态</span>
          <span class="cont outline" v-if="this.deviceInfo.status == 0"
            >离线</span
          >
          <span class="cont online" v-if="this.deviceInfo.status == 1"
            >在线</span
          >
          <span class="cont inactive" v-if="this.deviceInfo.status == 3"
            >未激活</span
          >
        </li>
      </ul>

      <ul>
        <li>
          <span class="label">所属产品</span>
          <span class="cont" v-if="this.deviceInfo.productName">{{
            this.deviceInfo.productName
          }}</span>
          <span class="cont" v-else>--</span>
        </li>
        <li>
          <span class="label">节点类型</span>
          <span class="cont" v-if="this.deviceInfo.productType == 1">设备</span>
          <span class="cont" v-else-if="this.deviceInfo.productType == 2"
            >网关</span
          >
          <span class="cont" v-else-if="this.deviceInfo.productType == 3"
            >网关子设备</span
          >
          <span class="cont" v-else>--</span>
        </li>
        <li>
          <span class="label">产品码</span>
          <span class="cont" v-if="this.deviceInfo.productCode">{{
            this.deviceInfo.productCode
          }}</span>
          <span class="cont" v-else>--</span>
        </li>
      </ul>

      <ul>
        <li>
          <span class="label">设备密钥</span>
          <span><a @click="lookDeviceSecret()">点击显示</a></span>
          <!-- <span class="cont" v-if="this.deviceInfo.deviceSecret">{{this.deviceInfo.deviceSecret}}</span>
          <span class="cont" v-else>--</span> -->
        </li>
        <li>
          <span class="label">最后上线时间</span>
          <span class="cont" v-if="this.deviceInfo.lastOnlineTime">{{
            this.deviceInfo.lastOnlineTime
          }}</span>
          <span class="cont" v-else>--</span>
        </li>
        <li>
          <span class="label">最后更新时间</span>
          <span class="cont" v-if="this.deviceInfo.lastTime">{{
            this.deviceInfo.lastTime
          }}</span>
          <span class="cont" v-else>--</span>
        </li>
      </ul>
      <ul>
        <li>
          <span class="label">位置</span>
          <span
            class="cont"
            v-if="this.deviceInfo.city"
            style="cursor: pointer; color: #2d8cf0"
            @click="openMap($route.query.deviceCode, deviceInfo.deviceName)"
            >{{positionName}}</span
          >
          <span
            class="cont"
            v-else
            style="cursor: pointer; color: #2d8cf0"
            @click="openMap($route.query.deviceCode, deviceInfo.deviceName)"
            >--</span
          >
        </li>
        <li>
          <span class="label">软件版本</span>
          <span class="cont">{{null==deviceInfo.firmwareVersion?'--':deviceInfo.firmwareVersion}}</span>
        </li>
        <li>
          <span class="label">--</span>
          <span class="cont">--</span>
        </li>
      </ul>
    </div>

    <Modal
      v-model="lookSecret"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h6 class="title">查看设备密钥</h6>
      <div
        style="
          text-align: center;
          margin-top: 6rem;
          margin-bottom: 6rem;
          font-size: 2rem;
        "
      >
        <span>{{ deviceInfo.deviceSecret }}</span
        ><span
          style="color: #1960e1; cursor: pointer; margin-left: 1rem"
          v-clipboard:copy="deviceInfo.deviceSecret"
          v-clipboard:success="clipboardSuccessHandler"
          >复制</span
        >
      </div>
    </Modal>
    <!-- 重命名对话框 -->
    <Modal
      v-model="resetname"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">重命名</h5>
      <div style="text-align: center; margin-bottom: 3rem; font-size: 1.6rem">
        唯一标识:{{ resetNameCode }}
      </div>
      <Form
        :label-width="80"
        style="display: flex; justify-content: space-around"
      >
        <FormItem label="设备昵称:">
          <Input
            type="text"
            v-model="resetName"
            :placeholder="resetName"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="resetModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>

    <Modal
      v-model="mapPosition"
      width="60%"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h6 class="title" style="font-size: 2rem">位置设置</h6>

      <RadioGroup
        v-model="vertical"
        vertical
        style="margin-left: 5rem"
        @on-change="changeVertical"
      >
        <Radio label="地图选点" style="height: 50rem">
          <!-- <div style="display:flex;"> -->
            <span>地图选点</span>
            <div class="map-wrap borderbox" style="margin-left: 10rem">
              <div class="inner" id="mapshow" disabled></div>
            </div>
          <!-- </div> -->
        </Radio>
        <Radio label="手动设置">
          <span>手动设置</span>

          <!-- <Select
            class="white"
            v-model="groupId"
            style="width: 117px;margin-left:15px"
            clearable
            placeholder="所属群组"
            @on-change='handleSelectChoose'
          >
            <Option
              v-for="item in groupList"
              :value="item.groupId"
              :key="item.groupId"
            >
              {{ item.name }}
            </Option>
          </Select> -->
        </Radio>
        <Select
          v-model="provinceCode"
          placeholder="请选择省份"
          @on-change="changeProvince"
          :label-width="0"
          style="
            width: 20rem;
            margin-left: 1rem;
            margin-top: -5.5rem;
            margin-left: 12rem;
          "
          :disabled="isDisbled"
        >
          <Option
            v-for="(item, index) in provinceList"
            :key="item.code"
            :value="item.code"
            >{{ item.name }}</Option
          >
        </Select>
        <Select
          v-model="cityCode"
          placeholder="请选择市级"
          :label-width="0"
          style="width: 20rem; margin-left: 1rem; margin-top: -5.5rem"
          :disabled="isDisbled"
          @on-change="changeCity"
        >
          <Option
            v-for="(item, index) in cityList"
            :key="item.code"
            :value="item.code"
            >{{ item.name }}</Option
          >
        </Select>
        <Select
          v-model="countyCode"
          placeholder="请选择县区"
          :label-width="0"
          style="width: 20rem; margin-left: 1rem; margin-top: -5.5rem"
          :disabled="isDisbled"
          @on-change="changeCounty"
        >
          <Option
            v-for="(item, index) in countyList"
            :key="item.code"
            :value="item.code"
            >{{ item.name }}</Option
          >
        </Select>
      </RadioGroup>

      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissMapModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="mapModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import provinces from "../../../assets/provinces.json";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      deviceInfo: {},
      lookSecret: false,
      resetname: false,
      resetName: "",
      resetNameCode: "",
      mapPosition: false,
      vertical: "地图选点",
      map: null,

      provinceArr: [],
      citiesArr: [],
      countyArr: [],
      provinces: {
        province: "",
        city: "",
        county: "",
      },
      isDisbled: true,
      provinceList: [],
      provinceCode: "",
      cityCode: "",
      cityList: [],
      countyCode: "",
      countyList: [],
      regionCode: 0,
      lngMap: 0,
      latMap: 0,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
    positionName(){
      let cityName = this.deviceInfo.city;
      let district = this.deviceInfo.district;
      if(cityName==district){
        return cityName;
      }else{
        return `${cityName} ${district}`
      }
    }
  },
  mounted() {
    this.getDeviceInfo();
    // this.getDeviceInfo();
    this.provinceArr = JSON.parse(JSON.stringify(provinces)).data;
    this.getRegionList();
    // this.changeProvince();
    setTimeout(this.drawBound(), 200);
    // this.$nextTick(function(){
    //   this.drawBound()
    // })
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),

    drawBound() {
      let cen;
      if (this.deviceInfo.lng && this.deviceInfo.lat) {
        cen = [this.deviceInfo.lng, this.deviceInfo.lat];
      } else {
        cen = [116.397428, 39.90923];
      }
      var map = new AMap.Map("mapshow", {
        // mapStyle: "amap://styles/darkblue",
        // center: [116.397428, 39.90923],
        center: cen,
        zoom: 11.2,
      });
      // 指向问题
      let that = this;
      var markers=[]
      map.on("click", function (e) {
        map.remove(markers)
        that.latMap = e.lnglat.getLat();
        that.lngMap = e.lnglat.getLng();
        console.log(that.latMap + "," + that.lngMap);
        axios
          .request({
            module: "AUTH",
            method: "get",
            url: "/region/parse",
            params: {
              lat: that.latMap,
              lng: that.lngMap,
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              console.log(res.data.data);
              that.regionCode = res.data.data.regionId;
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        var icon = require("./img/normal.png");
        var marker = new AMap.Marker({
          position: new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()),
          icon: icon,
        });
        this.map = map;
        this.map.add(marker);
        markers.push(marker)
      });
      if (this.deviceInfo.lng && this.deviceInfo.lat) {
        // console.log(typeof(this.deviceInfo.lng) , this.deviceInfo.lat)
        this.deviceInfo.lng=Number(this.deviceInfo.lng)
        this.deviceInfo.lat=Number(this.deviceInfo.lat)
        var icon = require("./img/normal.png");
        var marker = new AMap.Marker({
          position: new AMap.LngLat(this.deviceInfo.lng, this.deviceInfo.lat),
          icon: icon,
        });
      this.map = map;
      this.map.add(marker);
      markers.push(marker)
      }

      // new AMap.DistrictSearch({
      //   extensions: "all",
      //   subdistrict: 0,
      // }).search("顺义区", function (status, result) {
      //   // 外多边形坐标数组和内多边形坐标数组
      //   var outer = [
      //     new AMap.LngLat(-360, 90, true),
      //     new AMap.LngLat(-360, -90, true),
      //     new AMap.LngLat(360, -90, true),
      //     new AMap.LngLat(360, 90, true),
      //   ];
      //   var holes = result.districtList[0].boundaries;

      //   var pathArray = [outer];
      //   pathArray.push.apply(pathArray, holes);
      //   var polygon = new AMap.Polygon({
      //     pathL: pathArray,
      //     strokeColor: "#00eeff",
      //     strokeWeight: 1,
      //     fillColor: "#71B3ff",
      //     fillOpacity: 0.5,
      //   });
      //   polygon.setPath(pathArray);
      //   map.add(polygon);

      //   // 地图自适应
      //   // this.map.setFitView();

      // });

    },

    getDeviceInfo() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/device/basicinfo?companyCode=" +
          localStorage.getItem("companyCode");
      } else if (this.getUserType() == 1) {
        base_url = "/device/basicinfo?companyCode=" + this.companyCode();
      }
      // console.log(base_url)
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
          params: {
            deviceCode: this.$route.query.deviceCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            // res.data.data.lastOnlineTime = formatDate(
            //   new Date(res.data.data.lastOnlineTime),
            //   "yyyy-MM-dd hh:mm:ss"
            // );
            this.deviceInfo = res.data.data;
            if(this.deviceInfo.lastOnlineTime){
              this.deviceInfo.lastOnlineTime= formatDate(
                new Date(res.data.data.lastOnlineTime),
                "yyyy-MM-dd hh:mm:ss"
              );
            }
            if(this.deviceInfo.lastTime){
              this.deviceInfo.lastTime= formatDate(
                new Date(res.data.data.lastTime),
                "yyyy-MM-dd hh:mm:ss"
              );
            }
            this.drawBound()
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.deviceInfo = {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 查看DeviceSecret
    lookDeviceSecret() {
      this.lookSecret = true;
    },
    clipboardSuccessHandler(e) {
      // console.log(e.text);
      if(e.text){
        this.$Message.success("复制DeviceSecret成功！");
      }
    },

    deviceUpdate(code, name) {
      this.resetNameCode = code;
      this.resetName = name;
      this.resetname = true;
    },

    resetModalMakeSure() {
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/device/updating",
          data: {
            deviceCode: this.resetNameCode,
            deviceName: this.resetName,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success('设备重命名成功');
            this.getDeviceInfo();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.resetname = false;
    },
    dissmissResetModal() {
      this.resetname = false;
    },

    openMap(code, name) {
      this.drawBound()
      this.mapPosition = true;
      this.resetNameCode = code;
    },
    changeVertical(ver) {
      this.vertical = ver;
      if (ver == "手动设置") {
        this.isDisbled = false;
        if (this.deviceInfo.regionId != "") {
          axios
            .request({
              module: "AUTH",
              method: "get",
              url: "/region/parents",
              params: {
                code: this.deviceInfo.regionId,
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                // console.log(res.data.data);
                let region = res.data.data;
                let district = null;
                let city = null;
                switch(region.level){
                  case 2:
                    city = region;
                    district = region;
                    break;
                  case 3:
                    district = region;
                    city = district.parent;
                    break;
                }
                let province = city.parent;
                this.provinceCode = province.code;
                this.changeProvince(this.provinceCode);
                this.cityCode = city.code;
                this.changeCity(this.cityCode);
                this.countyCode = district.code;
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        this.isDisbled = true;
      }
    },

    getRegionList(code) {
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: "/region/list",
          params: {
            code: 86,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.provinceList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeProvince(val) {
      // console.log(val)
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: "/region/list",
          params: {
            code: val,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.cityList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            // console.log(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    changeCity(val) {
      // console.log(val)
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: "/region/list",
          params: {
            code: val,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.countyCode = "";
            this.countyList = res.data.data;
            if(!this.countyList||this.countyList.length==0){
              this.regionCode = val;
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeCounty(val) {
      // console.log(val)
      this.regionCode = val;
    },
    mapModalMakeSure() {
      console.log(this.regionCode, this.resetNameCode);
      let data
      if(this.vertical=="地图选点"){
        data={
          deviceCode: this.resetNameCode,
          lat:this.latMap,
          lng:this.lngMap
        }
      }else{
        data={
          deviceCode: this.resetNameCode,
          regionId: this.regionCode,
        }
      }
      axios
        .request({
          module: "SMP",
          method: "post",
          url: "/device/updating",
          data:data
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 0) {
            this.$Message.success('设备修改位置成功');
            this.getDeviceInfo();
            this.mapPosition = false;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
          this.getDeviceInfo();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dissmissMapModal() {
      this.mapPosition = false;
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  padding: 4rem;

  ul:last-child {
    li {
      span {
        border-bottom: 1px solid #e5e5e5;
      }
    }
  }
  ul {
    width: 100%;
    height: 4.4rem;
    display: flex;
    li:nth-child(3) span:nth-child(2) {
      border-right: 1px solid #e5e5e5;
    }
    li {
      width: 33.3%;
      display: flex;
      height: 100%;
      span:first-child {
        background: rgba(#1960e1, 0.1);
      }
      span {
        height: 100%;
        display: block;
        padding-left: 1rem;
        line-height: 4.4rem;
        font-size: 1.2rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #222222;
        letter-spacing: 1px;
        border-top: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
      }
      .label {
        width: 50%;
      }
      .cont {
        width: 50%;
      }
      .online {
        color: #00c869;
      }
      .outline {
        color: red;
      }
      .inactive {
        color: #f7941d;
      }
    }
  }
}
.map-wrap {
  border:1px solid primary;
  text-align: top;
  justify-content:start;
  width: 100%;
  height: 40rem;
  // padding: 0 4.4rem;
  .inner {
    width: 100%;
    height: 100%;
  }
}
</style>