<template>
  <GlobalLayout>
    <div class="devicelistofproj">
      <div class="device_title">
        <span class="title">设备</span>
        <!-- <span class="total">设备总数:{{ deviceCount }}</span>
        <span class="online">在线设备:{{ onlineNum }}</span> -->
      </div>
      <div class="paths">
        <router-link to="/control/all/device">所有设备</router-link>
        <router-link
          v-for="(item, index) in productList" :key="index"
          :to="
            '/control/all/product/' +
            item.productCode +
            '?companyCode=' +
            item.companyCode +
            '&productCode=' +
            item.productCode
          "
          >{{ item.productName }}</router-link
        >
      </div>
      <hr style="color: #e5e5e5; opacity: 0.2" />
      <router-view></router-view>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  // mixins: [iviewMixin],
  components: {
    GlobalLayout,
  },
  props: {},
  data() {
    return {
      typeofproducts: [],
      deviceCount: 0,
      onlineNum: 0,
      productList: [],
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
      "isGroupUser",
      "getGroup"
    ]),
  },
  created() {
    // this.getProductsOfProject()
  },
  mounted() {
    this.productCode = this.$route.query.productCode;
    this.getDeviceCount();
    this.getProductList();
  },
  watch: {},
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    getProductsOfProject() {
      var params = { projectCode: this.projectcode };
      axios
        .request({
          url: "/project/products",
          method: "get",
          params,
        })
        .then((res) => {
          // console.log(res)
          if (res.data.code == 0) {
            this.typeofproducts = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeviceCount() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/device/count?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/device/count?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      let params = {};
      if(this.productCode){
        params.productCode = this.productCode;
      }
      if(this.isGroupUser()){
        params.groupId = this.getGroup().groupId;
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
          params
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.deviceCount = res.data.data.count;
            this.onlineNum = res.data.data.online;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getProductList() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      let data = {};
      if(this.isGroupUser()){
        let group = this.getGroup();
        data.groupId = group.groupId;
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
          params:data
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.productList = res.data.data;
            // console.log(this.productList)
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped lang="less" rel="stylesheet/less">
.devicelistofproj {
  padding: 4rem;

  .device_title {
    .title {
      font-size: 3.6rem;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #222222;
    }

    .total {
      font-size: 2.4rem;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      color: #222222;
      margin-left: 50px;
    }

    .online {
      font-size: 2.4rem;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;
      color: #222222;
      margin-left: 60px;
    }
  }
  .paths {
    margin: 2.4rem 0;

    a {
      margin-right: 5.0rem;
      display: inline-block;
      line-height: 3.0rem;
      color: #222222;
      font-size: 2.0rem;
      font-family: PingFangHK-Regular, PingFangHK;
      font-weight: 400;

      &.active {
        color: #1960e1;
        font-weight: 600;
      }
    }
  }
}
</style>
 