<template>
  <div>
    <div class="device_data status">
      在线状态:
      <span class="cont outline" v-if="this.deviceData.status == 0"
        >离线</span
      >
      <span class="cont online" v-if="this.deviceData.status == 1">在线</span>
      <span class="cont inactive" v-if="this.deviceData.status == 3"
        >未激活</span
      >
    </div>
    <div class="runstatus">
      <table class="list" cellpadding="0" cellspacing="0">
        <tr style="line-height:50px;background-color:">
          <th>属性名称</th>
          <th>数据类型</th>
          <th>读写类型</th>
          <th>更新时间</th>
          <th>操作</th>
        </tr>
        <tr v-for="(item, index) in deviceState" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ DATATYPE_DESCRIPTION[item.dataType] }}</td>
          <td style="width: 220px">
            <div v-if="item.options">
              <p v-if="item.control==1">读写</p>
              <p v-else>
                <span class="labelN">只读</span><span class="content"></span>
              </p>
            </div>
            <div v-else>
              <p v-if="item.control==1">读写</p>
              <p v-else>
                <span class="labelN">只读</span><span class="content"></span>
              </p>
            </div>
          </td>
          <td>
            {{  formatDateToString(new Date(item.time),"yyyy-MM-dd hh:mm:ss") }}
          </td>
          <td style="width: 220px">
              <!-- <div v-if="null==item.options">
                  <span>未激活或未在线</span>
              </div> -->
              <!-- <div v-else> -->
            <div v-if="item.options&&item.options.length>0">
              <p v-if="item.value!=null && item.control==1">
                <Button
                  type="primary"
                  size="small"
                  v-for="(e, i) in item.options"
                  :key="i"
                  @click="operateAttribute(item, i)"
                  v-if="Object.keys(e)[0]==item.value.toString()"
                >
                  {{ e[Object.keys(e)[0]] }}
                </Button>  
              </p>
              <Button type="primary"
                  size="small" v-else-if="item.value==null" disabled>
                  {{`设备没有上传`}}
              </Button>
              <p v-else>
                <span class="labelN">{{getPropertyValueDisplay(item)}}</span>
                <span class="content"></span>
              </p>
            </div>
            <div v-else>
              <p v-if="item.control==1">
                <Button
                  type="primary"
                  size="small"
                  @click="operateAttribute(item)"
                  >{{ item.value }}</Button
                >
              </p>
              <p v-else>
                  <Button type="primary" size="small" disabled>{{ getPropertyValueDisplay(item) }}</Button>
                  <span class="content"></span>
              </p>
            </div>
            <!-- </div> -->
          </td>
        </tr>
      </table>
    </div>

    <Modal
      v-model="speedShow"
      :title="'设置' + this.settingName"
      @on-ok="ok(operate.currentValue)"
      @on-cancel="cancel"
    >
      <div>
        <p>步长: {{ operate.step }}</p>
        <div style="text-align:center">
        <span>(最小){{operate.min}} </span>
        <InputNumber v-model="operate.currentValue" :min="operate.min"
          :max="operate.max"
          @on-change="changeRange"
          :step="operate.step" controls-outside></InputNumber>
          <span> {{operate.max}}(最大)</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import { mapActions, mapGetters, mapState } from "vuex";
// import {iviewMixin} from '@/mixins/iviewMixin';
import {Websocket } from "@/libs/websocket.js";
import config from "@/config";
import Constants from '@/libs/constants';
import {
    FUNCTION_TYPE_PROPERTY,
    FUNCTION_TYPE_SERVICE,
    FUNCTION_TYPE_EVENT,
    DATATYPE_DESCRIPTION,
    getValueSchema,
    getDataType,
    getDataTypeDescription,
    formatFunctionDataTypeDefination,
    getFunctionPropertyData
} from '@/libs/function';
export default {
  data() {
    return {
      DATATYPE_DESCRIPTION,
      deviceState: [],
      operate: {},
      deviceData:{},
      speedShow: false,
      settingName: "",
      settingInfo: {},
      switchShow: false,
      lockReconnect: false,
    };
  },
  mounted() {
    this.getWebsocketToken4WS('wss://hiot.bjhike.com:3183',this.initWebSocket);
    // this.initWebSocket();
    this.getRunStatus(deviceData=>{
      this.deviceData = deviceData;
      this.deviceState=[];
      let dataProperties = deviceData.properties;
      this.getThingModel((error,thingmodel)=>{
        if(error){
          this.$Notice.error({
            desc:`获取设备物模型失败,信息:${error}`
          });
        }else{
          let modelProperties= thingmodel.properties;
          modelProperties.forEach(modelProp=>{
            let propDataResult = getFunctionPropertyData(modelProp.code,thingmodel,dataProperties);
            if(Constants.ResponseCode.CODE_SUCCESS==propDataResult.code){
              this.deviceState.push(propDataResult.data);
            }else{
              console.error(`获取设备属性数据出错,信息:${propDataResult.message}`);
            }
          });
        }
      });
    })
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    formatDateToString(date,pattern){
      return formatDate(date,pattern);
    },
    getPropertyValueDisplay(prop){
      let control = prop.control;
      let options = prop.options;
      let value = prop.value;
      if(null==value){
        return `设备没有上传`;
      }
      let op = null;
      if(null!=options&&options.length>1){
        op = options.find(option=>{
          let key = Object.keys(option)[0];
          return value.toString()==key;
        })
      }
      if(null==op){
        return value;
      }
      else{
        return Object.values(op)[0];
      }
    },
    getThingModel(callback){
      let productCode = this.$route.query.productCode;
      if(null!=this.thingModel&&this.thingModel.productCode==productCode){
        if(callback){
          callback(null,this.thingModel);
        }
        return;
      }
       axios
        .request({
          url: "/product/thingmodel",
          method: "get",
          params: { productCode: this.$route.query.productCode },
        })
        .then((res) => {
          if(res.data.code == 0 ){
            let thingModel = res.data.data;
            if(callback){
              callback(null,thingModel);
            }
          }else{
            if(callback){
              callback(res.data.message);
            }
          }
        });

    },
    getRunStatus(callback) {
      var params = { deviceCode: this.$route.query.deviceCode };
      axios
        .request({
          url: "/device/data",
          method: "get",
          params
        })
        .then((res) => {
          if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
            if(callback){
              callback(res.data.data);
            }
          }else{
            this.$Notice.error({
              title:`设备数据`,
              desc:`获取设备运行数据错误,信息:${res.data.message}`
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendOperateCode(productCode,deviceCode, functionCode, functionValue) {
      
      var data = {
        productCode:productCode,
        deviceCodes: [deviceCode],
        functionCode: functionCode,
        functionValue: functionValue,
      };
      console.log(`operation data:${JSON.stringify(data)}`)//*
      axios
        .request({
          url: "/device/property/set",
          method: "post",
          data,
        })
        .then((res) => {
          // console.log('res==========')
          console.log(res.data)
          //this.getProductModel()
        })
        .catch((err) => {
          console.log(err);
        });
    },
    operateAttribute(item, currentIdx) {
      // if(0==this.deviceData.status){
      //   this.$Notice.warning({
      //     title:`控制设备`,
      //     desc:`当前设备已离线,无法进行操作`
      //   });
      //   return ;
      // }
      if (item.options&&item.options.length>0) {
        if (currentIdx < item.options.length - 1) {
          currentIdx++;
        } else {
          currentIdx = 0;
        }
        this.sendOperateCode(
          this.$route.query.productCode,
          this.$route.query.deviceCode,
          item.code,
          Object.keys(item.options[currentIdx])[0]
        );
      } else {
        //数值型
        this.speedShow = true;
        this.operate = item;
       
        this.operate.titleline = "设置" + item.name;
        this.operate.deviceCode = this.$route.query.deviceCode;
        switch(item.dataType){
          case 'int32':
          case `int64`:
            if (item.minValue) {
              this.operate.min = parseInt(item.minValue);
            }
            if (item.maxValue) {
              this.operate.max = parseInt(item.maxValue);
            }
            if (item.step) {
              this.operate.step = parseInt(item.step);
            }
            if (item.value) {
              this.operate.currentValue = parseInt(item.value);
            }
            break;
          case `float`:
          case `double`:
            if (item.minValue) {
              this.operate.min = parseFloat(item.minValue);
            }
            if (item.maxValue) {
              this.operate.max = parseFloat(item.maxValue);
            }
            if (item.step) {
              this.operate.step = parseFloat(item.step);
            }
            if (item.value) {
              this.operate.currentValue = parseFloat(item.value);
            }
            break;
        }
        
      }
    },
    getWebsocketHost(callback){
      axios.request({
        url:"/module/info",
        module:"AUTH",
        method:'get',
        params:{
          code:"SMP"
        }
      }).then(res=>{
        if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
          let moduleInfo = res.data.data;
          let host = moduleInfo.domain;
          let port = process.env.NODE_ENV === "development"?3180:3183;
          let protocol = process.env.NODE_ENV === "development"?"ws":"wss";
          let websocketUrl = `${protocol}://${host}:${port}`;
          Websocket.url = websocketUrl;
          if(callback){
            callback(Websocket.url,this.initWebSocket);
          }
        }else{
          this.$Notice.error({
            desc:"获取Websocket服务地址错误"
          })
        }
      })
    },

    getWebsocketToken(callback){
        console.log('get websocket Token')//*
        axios.request({
          url:"/websocket/platform/token",
          method:"get",
          params:{}
        }).then(res=>{
          if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
            let token = res.data.data;
            Websocket.token = token;
            //token有效时间2分钟 设置超时时间110秒 确保token有效
            Websocket.expireTime = new Date().getTime()+110*1000;
            if(callback){
              callback(token);
            }
          }else{
            this.$Notice.error({
              desc:"获取Websocket接入Token失败"
            })
          }
        });
    },
    getWebsocketToken4WS(websocketUrl,initWebsocketFunction){
      this.getWebsocketToken((token)=>{
          if(initWebsocketFunction){
            initWebsocketFunction(websocketUrl,token);
          }
      });
    },
    initWebSocket(url,token) {
      // const wsurl = window.config.wsURL;

      let websocketUrl =
        process.env.NODE_ENV === "development"
          ? config.websocketUrl.dev
          : url;
      Websocket.url = websocketUrl;
      Websocket.on('open',this.websocketonopen);
      Websocket.on('message',this.websocketonmessage);
      Websocket.on('error',this.websocketonerror);
      Websocket.on('close',this.websocketclose);
      console.log(`token:${token}`)//*
      Websocket.createWebsocket(websocketUrl,token);
      Websocket.urlGetter = ()=>{
        return websocketUrl;
      }
      Websocket.tokenGetter = this.getWebsocketToken;
      if(Websocket.isConnected()){
        Websocket.deTagAll();
        Websocket.tagDevice(this.$route.query.deviceCode);
      }
    },
    websocketonopen() {
      console.log("Websocket connected");
      Websocket.deTagAll();
      Websocket.tagDevice(this.$route.query.deviceCode);
    },
    websocketonmessage(result) {
      //返回开关数据的状态
      if (result.data) {
        let message =
          JSON.parse(result.data);
        let code = message.code;
        let messageType = message.messageType;
        if(code=='0'&& messageType=='data'){
          if (message.data.data) {
            let messageData = message.data.data;
            // console.log(`messageData=${JSON.stringify(messageData)}`)//*
            for (let key in messageData) {
              let prop = this.deviceState.find(item=>item.code==key);

              if (prop) {
                prop.value = messageData[key].value;
                let time = messageData[key].time;
                if((""+time).length==12){
                  time = time*10
                }
                prop.time = time;
              }
            }
          }
        }
      }
      
      // this.reset();
    },
    websocketonerror(err) {
      console.log("Webscocket连接发生错误！");
    },
    websocketclose() {
      console.log("Webscocket连接关闭了");
    },
    changeRange(val) {
      this.operate.functionValue = val;
    },
    cancel() {},
    ok() {
      this.sendOperateCode(
        this.$route.query.productCode,
        this.$route.query.deviceCode,
        this.operate.code,
        this.operate.functionValue
      );
    },
  },
};
</script>

<style lang="less" scoped>
.device_data {
  padding: 40px;
  .status {
    .online {
      color: #00c869;
    }
    .outline {
      color: red;
    }
    .inactive {
      color: #f7941d;
    }
  }
  .info {
    width: 100%;
    margin-top: 30px;
    ul:first-child {
      background: rgba(#1960e1, 0.1);
      border-top: 1px solid #e5e5e5;
      font-weight: 600;
    }
    ul:not(:first-child) {
      li:last-child {
        cursor: pointer;
        color: #1960e1;
      }
    }
    ul {
      display: flex;
      width: 100%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      li:last-child {
        border-right: 1px solid #e5e5e5;
      }
      li {
        width: 25%;
        padding-left: 10px;
        border-bottom: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
      }
    }
  }
}
.runstatus {
  .til {
    font-size: 14px;
    font-weight: 500;
    color: #1a1919;
    line-height: 40px;
  }
  .runstatus {
    dl {
      color: #fff;
      dt {
        background-color: rgba(0, 142, 170, 0.1);
        line-height: 66px;
        display: flex;
        justify-content: space-around;
        span {
          display: inline-block;
          text-align: center;
        }
      }
      dd {
        line-height: 54px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        span {
          display: inline-block;
          text-align: center;
        }
      }
    }
   
  }
   .list {
      width: 100%;
      color: #1a1919;
      th {
        background-color: rgba(#1960e1, 0.06);
        line-height: 50px;
        border: none;
      }
      td {
        text-align: center;
        line-height: 50px;
      }
      tr:nth-child(2n) {
        background-color: rgba(255, 255, 255, 0.02);
      }
    }
}
</style>