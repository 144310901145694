<template>
  <GlobalLayout>
      <h1 style="text-align:center;margin-top:400px">开发中，敬请期待！</h1>
    <!-- <div class="page_wrap">
      <div class="pagetitle">页面管理</div>
      <Table
        stripe
        ref="selection"
        :columns="columns"
        :data="dataSource"
        :no-data-text="'没有设备'"
        :loading="loading"
        class="notLineTable"
      >
        <template slot-scope="{ row, index }" slot="operate">
          <router-link :to="'/control/device/detail?deviceCode='+row.deviceCode+'&productCode='+row.productCode+'&status='+row.status"  class="operationTxt" >查看</router-link>
          <a class="operationTxt" @click="deviceUpdate(row)">重命名</a>
          <router-link :to="'/productmanage/productdetail?product_key='+row.product_key"  class="operationTxt" >分配</router-link>
        </template>
      </Table>
      <div class="page flex hcenter">
        <Page
          :total="ipagination.total"
          :page-size="ipagination.pagesize"
          prev-text="上一页"
          next-text="下一页"
          @on-change="changePage"
          show-total
        />
      </div>
    </div> -->
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      companyList: [],
      columns: [
        {
          title: "产品编码",
          key: "productCode",
          align: "center",
        },
        {
          title: "所属厂商",
          key: "companyName",
          align: "center",
        },
        {
          title: "产品名称",
          key: "productName",
          align: "center",
        },
        {
          title: "海克配件",
          key: "groupName",
          align: "center",
        },
        {
          title: "小程序页面",
          key: "pageName",
          align: "center",
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
    };
  },
  created() {},
  mounted() {
    this.getCompanyList();
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    getCompanyList() {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/company/list",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
            // console.log(this.companyList);
            this.getPages();
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getPages() {
      axios
        .request({
          module: "SMP",
          method: "get",
          url: "/wechat/pages/query",
          params: {
            companyCode: this.companyList[0].code,
          },
        })
        .then((res) => {
            if(res.data.code==0){
                console.log(res.data.data)
                this.ipagination.total = res.data.data.total;
                this.dataSource = [];
                var d = new Array();
                for(var i=0;i<res.data.data.length;i++){

                }
            }   else if(res.data.code==105){
              this.$Message.warning('系统时间有误，请重设系统时间')
            }else{
              this.$Message.error(res.data.message)
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(){

    },
  },
};
</script>

<style lang="less" scoped>
@import "./page.less";
</style>