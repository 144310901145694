<template>
  <GlobalLayout>
    <div class="addGroup_wrap">
      <div class="addGroup_title">
        <span class="title"
          ><router-link to="/control/group">群组</router-link>
          <span style="color: #383838"> / 新建子群组</span></span
        >
      </div>

      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 30px;margin-left:5rem"
        :rules="formItemRules"
      >
        <Form-item label="父群组" :label-width="100" prop="parentId">
          <Select
            v-model="formItem.parentId"
            placeholder="请选择父群组"
            class="selector"
            style="width: 20rem"
          >
            <Option
              v-for="(item, index) in groupList"
              :key="index"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>
        
        <Form-item label="群组名称" :label-width="100" prop="groupName">
          <Input
            v-model="formItem.groupName"
            placeholder="请输入群组名称"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="群组类型" :label-width="100" prop="groupType">
          <RadioGroup v-model="formItem.groupType">
                <Radio label="1">客户</Radio>
                <Radio label="2">项目</Radio>
                <Radio label="3">经销代理</Radio>
                <Radio label="9">其他</Radio>
          </RadioGroup>
        </Form-item>
        
        <Form-item label="描述" :label-width="100" prop="groupRemarks">
          <Input
            v-model="formItem.groupRemarks"
            placeholder="请输入群组描述"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="AddGroupConfirm('formItemRef')"
            style="background:#1960e1;border:none;margin-left:-40rem;width:10rem"
            >保存</Button
          >
        </Form-item>
      </Form>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import Tree from "@/components/Tree/tree";
export default {
  components: {
    GlobalLayout,
    Tree,
  },
  props:{

  },
  data() {
    return {
      formItem: {
        parentId: "",
        groupName:'',
        groupType:'1',
        groupRemarks:'',
        companyCode:null
      },
      formItemRules: {
          parentId:[
              {required:false,message: "请选择父群组", trigger: "blur" }
          ],
          groupName:[
              {required:true,message: "请输入群组名称", trigger: "blur" }
          ],
          groupType:[
              {required:true,message: "请选择群组类型", trigger: "blur" }
          ],
          groupRemarks:[
              {required:false,message: "请输入群组描述", trigger: "blur" }
          ],
      },
      groupList: [],
      companyName:'',
      baseUrl:'',
      companycode:''
    };
  },
  computed:{
    ...mapGetters('user',['isAdmin','isPerson','isCompanyUser','getCompanyCode','getCompanyName','setControlMode','isControlMode','getUserType'])
  },
  created(){
    this.formItem.parentId=this.$route.query.groupId;
    this.formItem.companyCode = this.$route.query.companyCode;
    this.formItem.companyName = this.$route.query.companyName;
  },
  mounted() {
    // console.log(this.formItem.companyCode);
    // this.getCompanyList();
    if (this.formItem.companyCode!=null) {
      this.getGroupList();
    }
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    getCompanyList() {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/company/list",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
            // this.getGroupList();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGroupList() {

      console.log(this.formItem.companyCode)
      let base_url="/group/query";
      // if (localStorage.getItem("companyCode") != null && this.usertype() == 0) {
      //   base_url =
      //     "/group/query?companyCode=" + localStorage.getItem("companyCode");
      //   // console.log(base_url)
      // } else if (this.usertype() == 1) {
      //   base_url = "/group/query?companyCode=" + this.companyCode();
      //   // console.log(base_url)
      // } else {
      //   base_url = "/group/query?companyCode=" + this.companyList[0].code;
      //   // console.log(base_url)
      // }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params:{companyCode:this.formItem.companyCode}
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddGroupConfirm(name){
        this.$refs[name].validate((valid) => {
        if (valid) {
          console.log(valid)
          // this.$Message.success("Success!");
          axios.request({
            module:'XAIR',
            method:'post',
            url:'/group/addition',
            data:{
              companyCode:this.formItem.companyCode,
              companyName:this.formItem.companyName,
              name:this.formItem.groupName,
              groupType:this.formItem.groupType,
              subType:"0",
              parentId:this.formItem.parentId
            }
          }).then(res=>{
              // console.log(res.data.data)
            if(res.data.code==0){
              this.$Message.success('添加群组成功');
              this.$router.push({
                path:'/control/group'
              })
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
          })
        } else {
          this.$Message.error("Fail!");
        }
      });
   
    }
  },
};
</script>

<style lang="less" scoped>
.addGroup_wrap {
  padding: 4rem;
  .addGroup_title {
    .title {
      font-size: 3rem;
      font-family: PingFangHK-Semibold, PingFangHK;
      font-weight: 600;
      color: #1960e1;
      a {
        color: #1960e1;
      }
    }
  }
}
</style>