export default {
  ResponseCode:{
    CODE_NOT_LOGON:100,
    CODE_NO_PERMISSION:101,
    CODE_EXPIRED:107,
    
    CODE_SUCCESS:0,
    CODE_FAIL:1
  },
  Modules:{
    SMP:"SMP",
    AUTH:"AUTH",
    XAIR:"XAIR"
  }
}