<template>
  <div class="group-wrap">
    <div class="selectGroup">
      <Button
        type="primary"
        style="
          margin-right: .5rem;
          margin-left: 1.5rem;
          background: #1960e1;
          border: none;
        "
        @click="addRootGroup()"
        >新增群组</Button
      >
      <Input
        style="margin-right: 0px; width: 20rem"
        class="white"
        placeholder="群组名称"
        v-model="groupName"
      />
      <Button
        type="primary"
        style="margin-left: 1.5rem; background: #1960e1; border: none"
        @click="selectGroup()"
        icon="ios-search"
        >搜索</Button
      >
    </div>

    <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有群组'"
      :loading="loading"
      class="notLineTable"
    >
      <template slot-scope="{ row, index }" slot="operate">
        <a
          class="operationTxt"
          @click="openResetName(row)"
          style="margin-left: 1rem"
          >重命名</a
        >
        <a
          class="operationTxt"
          @click="groupDelete(row)"
          style="margin-left: 1rem"
          v-if="row.parentId != '0'"
          >删除</a
        >
      </template>
    </Table>
    
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>

    <!-- 删除群组对话框 -->
    <Modal
      v-model="deletegroup"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">删除群组</h5>
      <p
        class="tip"
        ref="tip"
        style="
          font-size: 2rem;
          color: #e02020;
          margin: 0px auto 5rem auto;
          text-align: center;
        "
      >
        确认要删除该群组吗？
      </p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="closeLDelete()">取消</Button>
        <Button
          style="width: 20%; background: #0060ff"
          type="primary"
          @click="isDelete()"
          >确认</Button
        >
      </div>
    </Modal>

    <!-- 重命名对话框 -->
    <Modal
      v-model="resetname"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">重命名</h5>
      <Form
        :label-width="80"
        style="display: flex; justify-content: space-around"
      >
        <FormItem label="群组名称:">
          <Input
            type="text"
            v-model="resetName"
            :placeholder="resetName"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="resetModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>

    <Modal v-model="addFlag" width="600" :footer-hide="true">
      <Form
        ref="groupForm"
        :model="groupForm"
        :label-width="500"
        style="margin-top: 3rem; margin-left: 5rem"
        :rules="groupFormRules"
      >
        <Form-item label="上级群组" :label-width="100" prop="parentId">
          <Select
            v-model="groupForm.parentId"
            placeholder="请选择父群组"
            class="selector"
            style="width: 20rem"
          >
            <Option
              v-for="(item, index) in groupList"
              :key="index"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>

        <Form-item label="群组名称" :label-width="100" prop="groupName">
          <Input
            v-model="groupForm.groupName"
            placeholder="请输入群组名称"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="群组类型" :label-width="100" prop="groupType">
          <RadioGroup v-model="groupForm.groupType">
            <Radio label="1">客户</Radio>
            <Radio label="2">项目</Radio>
            <Radio label="3">经销代理</Radio>
            <Radio label="9">其他</Radio>
          </RadioGroup>
        </Form-item>

        <Form-item label="描述" :label-width="100" prop="groupRemarks">
          <Input
            v-model="groupForm.remarks"
            placeholder="请输入群组描述"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="confirmAddGroup('groupForm')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -40rem;
              width: 10rem;
            "
            >添加</Button
          >
        </Form-item>
      </Form>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  data() {
    return {
      groupName: "",

      loading: false,
      dataSource: [],
      columns: [
        {
          title: "群组ID",
          key: "groupId",
          align: "center",
        },
        {
          title: "群组名称",
          key: "groupName",
          align: "center",
        },
        {
          title: "所属厂商",
          key: "companyName",
          align: "center",
        },
        {
          title: "群组类型",
          key: "groupType",
          align: "center",
          render: (h, params) => {
            let groupType = params.row.groupType;
            let groupTypeString = "其他类型";
            switch (groupType) {
              case 1:
                groupTypeString = "客户";
                break;
              case 2:
                groupTypeString = "项目";
                break;
              case 3:
                groupTypeString = "经销商";
                break;
              default:
                break;
            }
            return h("div", groupTypeString);
          },
        },
        {
          title: "上级群组",
          key: "parentName",
          align: "center",
          render: (h, params) => {
            let parentName = params.row.parentName;
            if (null == parentName || parentName.length == 0) {
              parentName = "无";
            }
            return h("div", parentName);
          },
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          render: (h, params) => {
            if (params.row.createTime) {
              return h(
                "div",
                formatDate(
                  new Date(params.row.createTime),
                  "yyyy-MM-dd hh:mm:ss"
                )
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],

      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      deletegroup: false,
      groupId: "",
      groupList: [],
      groupForm: {
        addFlag: false,
      },
      groupFormRules: {
        parentId: [
          { required: false, message: "请选择父群组", trigger: "blur" },
        ],
        groupName: [
          { required: true, message: "请输入群组名称", trigger: "blur" },
        ],
        groupType: [
          { required: true, message: "请选择群组类型", trigger: "blur" },
        ],
        groupRemarks: [
          { required: false, message: "请输入群组描述", trigger: "blur" },
        ],
      },
      resetname: false,
      resetName: "",
      addFlag: false,
      groupList: [],
      groupForm: {
        addFlag: false,
        parentId: "",
        groupName: "",
        groupType: "",
        groupRemarks: "",
      },
      groupFormRules: {
        parentId: [
          { required: false, message: "请选择父群组", trigger: "blur" },
        ],
        groupName: [
          { required: true, message: "请输入群组名称", trigger: "blur" },
        ],
        groupType: [
          { required: true, message: "请选择群组类型", trigger: "blur" },
        ],
        groupRemarks: [
          { required: false, message: "请输入群组描述", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("user", [
      "hasRight",
      "isAdmin",
      "isCompanyUser",
      "isPerson",
      "getCompanyCode",
      "getCompanyName",
      "getUserType",
    ]),
  },
  mounted() {
    this.getGroupList();
    this.getGroup();
  },
  methods: {
    confirmAddGroup(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios
            .request({
              module: "XAIR",
              method: "post",
              url: "/group/addition",
              data: {
                companyCode: this.getCompanyCode(),
                companyName: this.getCompanyName(),
                name: this.groupForm.groupName,
                groupType: this.groupForm.groupType,
                parentId: this.groupForm.parentId,
                subType:"0"
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                this.$Message.success("添加群组成功");
                this.getGroupList();
                this.addFlag = false;
              } else {
                this.$Message.warning(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });

          // let companyName = this.getCompanyName();
          // console.log(this.getCompanyName())
          // let data = {
          //   companyCode:this.getCompanyCode(),
          //       companyName:thisgetCompanyName(),
          //       name:this.groupForm.groupName,
          //       groupType:this.groupForm.groupType,
          //       parentId:this.groupForm.parentId
          // };
          // console.log(data)
        }
      });
    },
    getGroup() {
      let base_url
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
      } else {
        base_url = "/group/query?companyCode=" + this.getCompanyCode();
      }
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList = res.data.data;
            console.log(this.groupList)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addRootGroup() {
      this.addFlag = true;
    },
    selectGroup() {
      this.getGroupList(this.groupName);
    },
    getGroupList(groupName) {
      this.loading=true;
      let data = {
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
        groupName: groupName,
      };
      let base_url;
      if (localStorage.getItem("companyCode") != null) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
      } else {
        base_url = "/group/query?companyCode=" + this.getCompanyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.ipagination.total = res.data.data.total;
            // console.log(res.data.data.data)
            this.dataSource = res.data.data.data;
            // this.dataSource = [];
            // var d = new Array();
            // for (var i = 0; i < res.data.data.data.length; i++){
            //   d[i] ={
                
            //   }
            // }
            // this.dataSource = d;
          }
      this.loading=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 重命名modal
    openResetName(row) {
      this.resetname = true;
      this.groupId = row.groupId;
      this.resetName = row.groupName;
    },
    resetModalMakeSure() {
      let code;
      if (localStorage.getItem("companyCode")) {
        code = localStorage.getItem("companyCode");
      } else {
        code = this.getCompanyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/group/updating",
          data: {
            groupId: this.groupId,
            name: this.resetName,
            companyCode: code,
          },
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.code == 0) {
            this.$Message.success("修改群组信息完成");
            this.resetname = false;
            this.getGroupList();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    dissmissResetModal() {
      this.resetname = false;
    },
    // 删除群组
    groupDelete(row) {
      // console.log(row)
      this.groupId = row.groupId;
      this.deletegroup = true;
    },

    isDelete() {
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/group/delete",
          data: {
            groupId: this.groupId,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success("删除成功");
            this.deletegroup = false;
            this.getGroupList();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Notice.warning({
              title: "删除群组",
              desc: `删除群组失败,信息:${res.data.message}`,
            });
            this.deletegroup = false;
          }
        });
    },
    closeLDelete() {
      this.deletegroup = false;
    },
    changePage(val){
      this.ipagination.current = val;
      this.getGroupList();
    }
  },
};
</script>

<style lang="less" scoped>
@import "./group.less";
</style>