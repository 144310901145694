<template>
  <div class="device-wrap">
    <div class="top flex hside vcenter device-choose">
      <div class="deviceTotal" style="width: 70rem">
        设备总数:{{ deviceCount
        }}
        <span class="online" style="margin-left: 3rem"
          >激活设备:{{ activeNum }}</span
        >
        <span class="online" style="margin-left: 3rem"
          >在线设备:{{ onlineNum }}</span
        >
      </div>
      <Button style="margin: 0 2rem 0 0;" type="primary" @click="batchToGroup">分配到群组</Button>
      <div class="choose">
        
        <!-- v-if="is_admin" -->
        <Select
          class="white"
          v-model="groupId"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="所属群组"
          @on-change="handleSelectChoose"
        >
          <Option
            v-for="item in groupList"
            :value="item.groupId"
            :key="item.groupId"
          >
            {{ item.groupName }}
          </Option>
        </Select>
        <Select
          class="white"
          v-model="status"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="在线状态"
          @on-change="statusSelectChoose"
        >
          <Option
            v-for="item in statusList"
            :value="item.state"
            :key="item.state"
          >
            {{ item.value }}
          </Option>
        </Select>
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="设备唯一标识"
          v-model="deviceCode"
        />
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="设备昵称"
          v-model="deviceName"
        />
        <Button
          type="primary"
          style="margin-left: 1.5rem;background:#1960e1;border:none"
          @click="selectDevice()"
          icon="ios-search"
          >搜索</Button
        >
      </div>
    </div>
    <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有设备'"
      :loading="loading"
      class="notLineTable"
      @on-selection-change="changeSelection"
    >
      <template slot-scope="{ row, index }" slot="operate">
        <router-link
          :to="
            '/control/all/device/detail?deviceCode=' +
            row.deviceCode +
            '&productCode=' +
            row.productCode +
            '&status=' +
            row.status
          "
          class="operationTxt"
          >查看</router-link
        >
        <a class="operationTxt" @click="deviceUpdate(row)">重命名</a>
        <a class="operationTxt" @click="deviceGroup(row)">分配</a>
        <a class="operationTxt" @click="toScene(row)">场景</a>
      </template>
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>
    <!-- 重命名对话框 -->
    <Modal
      v-model="resetname"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">重命名</h5>
      <div style="text-align: center; margin-bottom: 3rem; font-size: 1.6rem">
        唯一标识:{{ resetNameCode }}
      </div>
      <Form
        :label-width="80"
        style="display: flex; justify-content: space-around"
      >
        <FormItem label="设备昵称:">
          <Input
            type="text"
            v-model="resetName"
            :placeholder="resetName"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="resetModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>

    <!-- 分配群组对话框 -->
    <Modal
      v-model="groupModal"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">分配群组</h5>
      <div class="sel">
        <div>
          <label for="" style="display: inline-block; width: 6rem"
            >选择群组</label
          >
          <Select
            class="white"
            style="width: calc(100% - 80px); margin-left: 1.5rem"
            placeholder="群组"
            v-model="group1"
          >
            <Option
              v-for="item in groupList"
              :value="item.groupId"
              :key="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </div>

        <p class="tip" ref="tip"></p>
      </div>

      <!-- <p class="tip" ref="tip"></p> -->
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissGroupModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="groupModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      columns: [
        {
          type: "selection",
          width: 60,
          align: "center",
          // fixed:"left"
        },
        {
          title: "设备昵称",
          key: "deviceName",
          align: "center",
        },
        {
          title: "唯一标识",
          key: "deviceCode",
          align: "center",
        },
        {
          title: "在线状态",
          key: "status",
          align: "center",
          render: (h, params) => {
            if (params.row.status == 0) {
              return h(
                "div",
                {
                  style: {
                    color: "#999999",
                  },
                },
                "离线"
              );
            } else if (params.row.status == 1) {
              return h(
                "div",
                {
                  style: {
                    color: "#00C869",
                  },
                },
                "在线"
              );
            } else if (params.row.status == 3) {
              return h(
                "div",
                {
                  style: {
                    color: "#f7941d",
                  },
                },
                "未激活"
              );
            }
          },
          // render: (h, params) => {
          //     var state = ''
          //     if(params.row.status == 0){
          //         state = '离线 '
          //     } else if (params.row.status == 1){
          //         state = '在线'
          //     } else if (params.row.status == 2){
          //         state = '已激活'
          //     }  else if (params.row.status == 3){
          //         state = '未激活'
          //     }
          //     return h('div', state)
          // }
        },
        {
          title: "所属产品",
          key: "productName",
          align: "center",
        },
        {
          title: "所属群组",
          key: "groupName",
          align: "center",
        },
        {
          title: "位置",
          align: "center",
          render(h,params){
            let city = params.row.city;
            let district = params.row.district;
            let content = '--';
            if(city||district){
              if(city==district){
                content = city;
              }else{
                content = `${city} ${district}`
              }
              
            }
            return h('div',content);
          }
        },
        {
          title: "更新时间",
          align: "center",
          render: (h, params) => {
            if (params.row.lastTime) {
              return h(
                "div",
                formatDate(
                  new Date(params.row.lastTime),
                  "yyyy-MM-dd hh:mm:ss"
                )
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      deviceCount: 0,
      onlineNum: 0,
      activeNum:0,
      groupList: [],
      groupList2: [],
      groupList3: [],
      groupId: "",
      status: 1,
      statusList: [
        {
          state: 0,
          value: "离线",
        },
        {
          state: 1,
          value: "在线",
        },
        {
          state: 3,
          value: "未激活",
        },
      ],
      deviceCode: "",
      deviceName: "",
      resetname: false,
      resetName: "",
      resetNameCode: "",
      productList: [],
      groupModal: false,
      group1: "",
      group2: "",
      group3: "",
      groupDeviceCode: "",
      selectedDevices:[],
      batchGroup:false
    };
  },
  created() {},
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  mounted() {
    this.count();
    this.getDeviceList();
    this.getGroupList();
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
     batchToGroup(){
      console.log(`this.selectedDevices.length:${this.selectedDevices.length}`);
      if (this.selectedDevices.length == 0) {
        this.$Message.warning("请选择设备");
        return;
      }
      this.batchGroup = true;
      this.groupModal = true;
    },
    changeSelection(selection) {
      this.selectedDevices=[];
      selection.forEach((item) => {
          this.selectedDevices.push(item);
      });
      // console.log(this.deviceCodeArray)
    },
    count(){
      axios.request({
        module:"SMP",
        method:'get',
        url:'/device/count',
        params:{
          companyCode:this.companyCode()
        }
      }).then(res=>{
        this.deviceCount=res.data.data.count;
        this.onlineNum = res.data.data.online;
        this.activeNum = res.data.data.actived;
      });
    },
    getProductList() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.productList = res.data.data;
            // console.log(this.productList)
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeviceList(groupId, status, deviceCode, deviceName, resetPageCurrent) {
      this.loading=true;
      if (resetPageCurrent === true) {
        //重置pange current
        this.ipagination.current = 1;
      }
      var data = {
        groupId: groupId,
        status: status?status:this.status,
        deviceCode: deviceCode,
        deviceName: deviceName,
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
      };
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/device/query/detail?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/device/query/detail?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.data.length; i++) {
              d.push(Object.assign({},res.data.data.data[i]));
            }
            this.dataSource = d;
            // console.log(this.dataSource)
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.dataSource = [];
          }
      this.loading=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getGroupList(code) {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            parentId: code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getDeviceList();
    },
    handleSelectChoose(groupId, status) {
      // console.log(this.groupId)
      // console.log(this.status)
      this.getDeviceList(this.groupId, this.status ,this.deviceCode,this.deviceName);
    },
    statusSelectChoose(groupId,status) {
      // console.log(this.groupId)
      // console.log(this.status)
      this.getDeviceList(this.groupId, this.status ,this.deviceCode,this.deviceName);
    },
    selectDevice() {
      // console.log(this.deviceName)
      this.getDeviceList(
        this.groupId,
        this.status,
        this.deviceCode,
        this.deviceName
      );
    },

    deviceUpdate(row) {
      this.resetNameCode = row.deviceCode;
      this.resetName = row.deviceName;
      this.resetname = true;
    },
    resetModalMakeSure() {
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/device/updating",
          data: {
            deviceCode: this.resetNameCode,
            deviceName: this.resetName,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success("修改成功");

            this.getDeviceList(
              this.groupId,
              this.status,
              this.deviceCode,
              this.deviceName
            );
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.resetname = false;
    },
    dissmissResetModal() {
      this.resetname = false;
    },

    // 分配群组

    deviceGroup(row) {
      // console.log(row);
      this.$refs.tip.innerText = "";
      this.groupDeviceCode = row.deviceCode;
      this.groupModal = true;
      this.batchGroup = false;
    },
    changeGroup1() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            parentId: this.group1,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            this.group2 = "";
            this.groupList2 = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // changeGroup2() {
    //   let base_url;
    //   if (
    //     localStorage.getItem("companyCode") != null &&
    //     this.getUserType() == 0
    //   ) {
    //     base_url =
    //       "/group/query?companyCode=" + localStorage.getItem("companyCode");
    //     // console.log(base_url)
    //   } else {
    //     base_url = "/group/query?companyCode=" + this.companyCode();
    //     // console.log(base_url)
    //   }
    //   axios
    //     .request({
    //       module: "XAIR",
    //       method: "get",
    //       url: base_url,
    //       params: {
    //         parentId: this.group2,
    //       },
    //     })
    //     .then((res) => {
    //       if (res.data.code == 0) {
    //         // console.log(res.data.data);
    //         this.group3 = "";
    //         this.groupList3 = res.data.data;
    //       } else if (res.data.code == 105) {
    //         this.$Message.warning("系统时间有误，请重设系统时间");
    //       } else {
    //         this.$Message.error(res.data.message);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    // changeGroup3() {},
    dissmissGroupModal() {
      this.groupModal = false;
    },
    groupModalMakeSure() {
      if(this.group1==""){
        this.$Message.warning("请选择群组")
      }else{
        if(this.batchGroup == true){
          axios.request({
            module:"SMP",
            method:'POST',
            url:"/device/group/binding",
            data:{
              deviceCodes:this.selectedDevices.map(d=>d.deviceCode),
              groupId:this.group1,
              groupName:this.groupList.find(g=>g.groupId == this.group1).groupName
            }
          }).then(res=>{
            if(res.data.code==0){
              this.selectedDevices = [];
              this.$Message.success("分配设备到群组完成");
              this.dissmissGroupModal();
              this.selectDevice();
            } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
          });

        }else{
        let data = {
          deviceCode: this.groupDeviceCode,
          groupId: this.group1,
        };
        axios
          .request({
            module: "XAIR",
            method: "post",
            url: "/device/updating",
            data: data,
          })
          .then((res) => {
            // console.log(res.data);
            if (res.data.code == 0) {
              this.getDeviceList();
              this.$Message.success('分配群组完成');
              this.groupModal = false;
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }
      }
    },
    toScene(device){
      this.$router.push("/control/scene/list?deviceCode="+device.deviceCode);
    }
  },
};
</script>

<style lang="less" scoped>
@import "./device.less";
</style>