<template>
  <div class="device-wrap">
    <div class="top flex hside vcenter device-choose">
      <div class="deviceTotal" style="width: 70rem">
        设备总数:{{ deviceCount
        }}<span class="online" style="margin-left: 3rem"
          >在线设备:{{ onlineNum }}</span
        ><span style="margin-left: 3rem"
          >最新版本:{{ firmwareVersionInfo}}</span
        >
      </div>
      <div class="choose">
        <!-- v-if="is_admin" -->
        <Select
          class="white"
          v-model="queryParam.groupId"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="所属群组"
          @on-change="handleSelectChoose"
        >
          <Option
            v-for="item in groupList"
            :value="item.groupId"
            :key="item.groupId"
          >
            {{ item.groupName }}
          </Option>
        </Select>
        <Select
          class="white"
          v-model="queryParam.status"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="在线状态"
          @on-change="statusSelectChoose"
        >
          <Option
            v-for="item in statusList"
            :value="item.state"
            :key="item.state"
          >
            {{ item.value }}
          </Option>
        </Select>
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="设备唯一标识"
          v-model="queryParam.deviceCode"
        />
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="设备昵称"
          v-model="queryParam.deviceName"
        />
        <Button
          type="primary"
          style="margin-left: 1.5rem"
          @click="selectDevice()"
          icon="ios-search"
          >搜索</Button
        >
      </div>
    </div>
    <div style="margin: 3rem 0" v-if="null!=thingModel&&null!=thingModel.properties">
      <span>批量设置</span>
      <span style="margin-left: 1rem" v-for="(item, index) in thingModel.properties" :key="index">
        <Button type="primary" size="default"
          v-if="item.valueSchema.control==1&&item.valueSchema.accessMode>1"
          @click="openBatch(item)"
          >{{ item.name }}</Button
        >
      </span>
      <Button style="margin: 0 0 0 2rem;" type="primary" @click="batchToGroup">分配到群组</Button>
    </div>
    <Table
      stripe
      ref="deviceTable"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有设备'"
      :loading="loading"
      class="notLineTable"
      @on-selection-change="changeSelection"
    >
      <template slot-scope="{ row, index }" slot="operate">
        <router-link
          :to="
            '/control/all/device/detail?deviceCode=' +
            row.deviceCode +
            '&productCode=' +
            row.productCode +
            '&status=' +
            row.status
          "
          class="operationTxt"
          >查看</router-link
        >
        <a class="operationTxt" @click="deviceUpdate(row)">重命名</a>
        <a class="operationTxt" @click="deviceGroup(row)">分配</a>
        <a class="operationTxt" @click="toScene(row)">场景</a>
         <a class="operationTxt" v-if="needsFirmwareUpdate(row)" @click="firmwareUpdateSingle(row)">升级</a>
      </template>
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>
    <!-- 重命名对话框 -->
    <Modal
      v-model="resetname"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">重命名</h5>
      <div style="text-align: center; margin-bottom: 3rem; font-size: 1.6rem">
        唯一标识:{{ resetNameCode }}
      </div>
      <Form
        :label-width="80"
        style="display: flex; justify-content: space-around"
      >
        <FormItem label="设备昵称:">
          <Input
            type="text"
            v-model="resetName"
            :placeholder="resetName"
          ></Input>
        </FormItem>
      </Form>
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="resetModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>
    <!-- 分配群组对话框 -->
    <Modal
      v-model="groupModal"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">分配群组</h5>
      <div class="sel">
        <div>
          <label for="" style="display: inline-block; width: 6rem"
            >群组</label
          >
          <Select
            class="white"
            style="width: calc(100% - 8rem); margin-left: 1.5rem"
            placeholder="选择群组"
            filterable
            v-model="group1"
          >
            <Option
              v-for="item in groupList"
              :value="item.groupId"
              :key="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </div>

        <p class="tip" ref="tip"></p>
      </div>

      <!-- <p class="tip" ref="tip"></p> -->
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissGroupModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="groupModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>

    <!-- 批量控制对话框 -->
    <Modal
      v-model="isBatch"
      width="500"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">设置{{ batchName }}</h5>
      <div style="display:flex;justify-content: center;">
        <div v-if="null!=this.batchOpProperty">
          <RadioGroup v-model="batchOpForm.value" type="button" button-style="solid">
            <Radio
              v-for="(item, index) in batchOpProperty.seqs"
              :key="index"
              :label="item['code']"
            >
              <span>{{ item['desc'] }}</span>
            </Radio>
          </RadioGroup>
        </div>
      </div>
      <!-- <div v-if="this.dataType == 'string'">
        <Input type="text" v-model="this.operate.welcome"></Input>
      </div> -->
      <p class="tip" ref="tip"></p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 30%" @click="dissmissBatchModal()">取消</Button>
        <Button
          style="width: 30%; background: #0060ff"
          type="primary"
          @click="batchModalMakeSure()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
import {getFunctionPropertyData,getPropertyValueDisplay,getPropertyConditionSeqs} from "@/libs/function";
import { websocket } from "@/mixins/websocket";
import {iviewMixin} from "@/mixins/iviewMixin"
import Button from "../../../../node_modules/view-design/src/components/button/button.vue"
const versioning = require(`versioning`);
export default {
  mixins:[iviewMixin],
  components: {
    GlobalLayout,
    Button
},
  data() {
    return {
      url:{
        list:"/device/query/detail",
        module:'SMP'
      },
      thingModel:null,
      columns:[],
      sourceColumns: [
        {
          type: "selection",
          width: 60,
          align: "center",
          // fixed:"left"
        },
        {
          title: "设备昵称",
          key: "deviceName",
          align: "center",
          width:200
        },
        {
          title: "唯一标识",
          key: "deviceCode",
          align: "center",
          width:200
        },
        {
          title: "在线状态",
          key: "status",
          align: "center",
          width:150,
          render: (h, params) => {
            if (params.row.status == 0) {
              return h(
                "div",
                {
                  style: {
                    color: "#999999",
                  },
                },
                "离线"
              );
            } else if (params.row.status == 1) {
              return h(
                "div",
                {
                  style: {
                    color: "#00C869",
                  },
                },
                "在线"
              );
            } else if (params.row.status == 3) {
              return h(
                "div",
                {
                  style: {
                    color: "#f7941d",
                  },
                },
                "未激活"
              );
            }
          }
        },
        {
          title: "所属群组",
          key: "groupName",
          align: "center",
          width:180
        },
        {
          title: "位置",
          align: "center",
          width:200,
          render(h,params){
            let city = params.row.city;
            let district = params.row.district;
            let content = '--';
            if(city||district){
              content = `${city} ${district}`
            }
            return h('div',content);
          }
        },
        {
          title: "更新时间",
          align: "center",
          width:200,
          render: (h, params) => {
            if (params.row.lastTime) {
              return h(
                "div",
                formatDate(new Date(params.row.lastTime), "yyyy-MM-dd hh:mm:ss")
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
          width:230,
          // fixed:"right"
        },
      ],
      loading: false,
      deviceCount: 0,
      onlineNum: 0,
      groupList: [],
      groupId: "",
      status: "",
      statusList: [
        {
          state: 0,
          value: "离线",
        },
        {
          state: 1,
          value: "在线",
        },
        {
          state: 3,
          value: "未激活",
        },
      ],
      deviceCode: "",
      deviceName: "",
      resetname: false,
      resetName: "",
      resetNameCode: "",
      productList: [],
      groupList2: [],
      groupList3: [],
      groupModal: false,
      group1: "",
      group2: "",
      group3: "",
      groupDeviceCode: "",
      isBatch: false,
      batchName: "",
      onoffStatus: "0",
      productInfo: {},
      deviceCodeArray: [],
      selectedDevices: [],
      batchOpProperty:null,
      batchOpForm:{},
      currentValue: "",
      setCode: "",
      dataType: "",
      batchGroup:false,
      latestFirmware:null
    };
  },
  created() {},
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
    firmwareVersionInfo(){
      if(!this.firmwareUpdateAvaliable()){
        return '该产品不支持OTA';
      }
      let firmware = this.latestFirmware;
      if(!firmware){
        return '没有查询到可用固件版本';
      }
      let version = firmware.version;
      if(version){
        return version;
      }
      let message = firmware.message;
      if(message){
        return message;
      }
      return '没有查询到可用固件版本';
    }
    
  },
  mounted() {
    this.latestFirmware = null;
    this.queryParam.status=1;
    this.refresh();
    this.getGroupList();
  },
  watch: {
    $route(to, from) {
      this.refresh();
    },
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    ...mapGetters("user",["isGroupUser","getGroup"]),

    firmwareUpdateSingle(device){
      let currentVersion = device.firmwareVersion;
      let firmware = this.latestFirmware;
      let firmVersion = firmware.version;
      this.$Modal.confirm({
        title:'设备升级',
        content:`<div>
                  <span>设备标识:${device.deviceCode}</span>
                  <br><span>当前版本:${currentVersion}</span>
                  <br><span>目标版本:${firmVersion}</span>
                  <br><span><B>您确定要对设备进行OTA升级吗?</B></span></div>`,
        onOk:()=>{
          axios.request({
            url:'/device/service/invoking',
            method:'post',
            module:'SMP',
            data:{
              productCode:device.productCode,
              deviceCodes:[device.deviceCode],
              functionCode:'FirmwareUpdate',
              inputParams:{
                fileUrl:firmware.url,
                version:firmware.version,
                fw_md5:firmware.md5
              }
            }
          }).then(res=>{
            if(0==res.data.code){
              let result = res.data.data;
              if(result){
                console.log(`${JSON.stringify(result)}`)//*
                let response = result.data[device.deviceCode];
                if(!response){
                  return;
                }
                switch(response.code){
                  case 0:
                    this.$Message.success("已经将OTA升级信息发送给设备");
                    break;
                  case 10001:
                    this.$Message.error("设备不在线,无法OTA升级");
                    break;
                  default:
                    this.$Message.error(response.message);
                    break;
                }
              }
              
            }else{
              this.$Message.error(res.data.message);
            }
          });
        },
        onCancel:()=>{},
      });
    },
    firmwareUpdateAvaliable(){
      if(null!=this.thingModel)
        return null!=this.thingModel.services.find(s=>s.code=='FirmwareUpdate');
      return false;
    },
    isFirmwareAvaliable(){
      let firmware = this.latestFirmware;
      if(!firmware){
        return false;
      }
      let version = firmware.version;
      let url = firmware.url;
      let md5 = firmware.md5;
      if(version&&url&&md5){
        return true;
      }
      return false;
    },
    needsFirmwareUpdate(device){
      if(device.status!=1){
        return false;
        // return true;
      }
      if(!this.firmwareUpdateAvaliable()){
        return false;
      }
      if(!this.isFirmwareAvaliable()){
        return false;
      }
      let deviceVersion = device.firmwareVersion;
      let firmware = this.latestFirmware;
      let productVersion = firmware.version;
      return versioning.gt(productVersion,deviceVersion);
    },
    batchToGroup(){
      console.log(`this.selectedDevices.length:${this.selectedDevices.length}`);
      if (this.selectedDevices.length == 0) {
        this.$Message.warning("请选择设备");
        return;
      }
      this.batchGroup = true;
      this.groupModal = true;
    },
    refresh(){
      this.productCode = this.queryParam.productCode = this.$route.query.productCode;
      this.getProductModel((error,thingModel)=>{
        if(!error){
          this.thingModel = thingModel;
          let properties = this.thingModel.properties;
          //清空当前展示列
          this.columns = [];
          //重建展示列
          this.columns = this.sourceColumns.concat([]);
          let size = 0;
          properties.forEach(prop=>{
            let title = prop.name;
            let width = 150;

            let column = {
              title,
              width,
              align:`center`,
              render(h,params){
                let valueDisplay="--"
                let row = params.row;
                let enable = row.enable;
                if(enable==1){
                  let res = getFunctionPropertyData(prop.code,thingModel,row.properties||{});
                  if(res.code==0){
                    let property = res.data;
                    valueDisplay = getPropertyValueDisplay(property);
                  }
                }
                return h('div',valueDisplay);
              }
            }
            size++;
            // this.columns.push(column);//*
            this.columns.splice(this.columns.length-3,0,column)
          });
          
          
          let clientWidth =Math.floor( $(document).width()*0.9);
          
          let totalTableWidth = this.columns.map(c=>c.width).reduce((pre,cur,idx,arr)=>pre+cur);
          console.log(`totalTableWidth:${totalTableWidth}`)
          if(totalTableWidth<clientWidth){
            let offset = Math.floor((clientWidth-totalTableWidth)/this.columns.length);
            this.columns.forEach(c=>c.width = c.width+offset);
          }else{
            this.columns[0].fixed='left';
            this.columns[this.columns.length-1].fixed='right';
          }
          
        }
        this.getLatestFirmware();
        this.getDeviceCount();
        this.getDeviceList();
      });
    },
    getLatestFirmware(){
      axios.request({
        url:'/product/firmware/latest',
        module:'SMP',
        method:'GET',
        params:{productCode:this.productCode}
      }).then(res=>{
        if(0==res.data.code){
          let firmware = res.data.data;
          this.latestFirmware = firmware;
          console.log(`${JSON.stringify(this.latestFirmware)}`)
        }else if(res.data.code==1300){
          this.latestFirmware = {message:"没有上传可用的固件版本"}
        }else{
          this.latestFirmware = {message:res.data.message};
        }
      });
    },
    changeSelection(selection) {
      this.deviceCodeArray.length = 0;
      this.selectedDevices=[];
      selection.forEach((item) => {
        // if (item.status == 1) {
          this.deviceCodeArray.push(item.deviceCode);
          this.selectedDevices.push(item);
        // } else {
        //   this.$Message.info("该设备已离线");
        // }
      });
      // console.log(this.deviceCodeArray)
    },
    //加载产品物模型
    getProductModel(callback) {
      axios
        .request({
          url: "/product/thingmodel",
          method: "get",
          params: { productCode: this.$route.query.productCode },
        })
        .then((res) => {
          if (res.data.code == 0) {
            let thingModel = res.data.data;
            if(callback){
              callback(null,thingModel)
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取设备统计数据
    getDeviceCount(){
      let params = {productCode:this.$route.query.productCode};
      if(this.isGroupUser()){
        params.groupId = this.getGroup().groupId;
      }
      axios.request({
        url:'/device/count',
        method:'get',
        params
      },this).then(res=>{
        if(0==res.data.code){
          let stat = res.data.data;
          this.deviceCount=stat.count;
          this.onlineNum = stat.online;
        }
      });
    },
    getProductList() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/product/query?companyCode=" + localStorage.getItem("companyCode");
      } else {
        base_url = "/product/query?companyCode=" + this.companyCode();
      }
      axios
        .request({
          module: "SMP",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.productList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDeviceList() {
      this.loading = true;
      this.loadData(1);
    },
    getGroupList() {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(val) {
      this.ipagination.current = val;
      this.loadData();
    },
    handleSelectChoose(groupId, status) {
      // this.queryParam.groupId = groupId;
      // this.queryParam.status = status;
      this.loadData();
    },
    statusSelectChoose(status) {
      this.loadData();
    },
    selectDevice() {
      this.loadData(1)
    },

    deviceUpdate(row) {
      this.resetNameCode = row.deviceCode;
      this.resetName = row.deviceName;
      this.resetname = true;
    },
    resetModalMakeSure() {
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/device/updating",
          data: {
            deviceCode: this.resetNameCode,
            deviceName: this.resetName,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success("修改成功");

            this.getDeviceList(
              this.groupId,
              this.status,
              this.deviceCode,
              this.deviceName
            );
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.resetname = false;
    },
    dissmissResetModal() {
      this.resetname = false;
    },

    reconnect() {
      if (this.lockReconnect) {
        return;
      }
      this.lockReconnect = true;
      // 没连接上重连，设置延迟避免请求过多
      this.timeoutClock && clearTimeout(this.timeoutClock);
      this.timeoutClock = setTimeout(() => {
        this.initWebSocket();
        this.lockReconnect = false;
      }, 3000);
    },

    initWebSocket() {
      // const wsurl = window.config.wsURL;
      const websocketUrl =
        process.env.NODE_ENV === "development"
          ? config.websocketUrl.dev
          : config.websocketUrl.pro;
      this.websocket = new WebSocket(websocketUrl);
      // this.websocket = new WebSocket('ws://192.168.1.26:3180');
      this.websocket.onopen = this.websocketonopen; //连接建立时触发
      this.websocket.onmessage = this.websocketonmessage; //客户端接受服务端数据时触发
      this.websocket.onerror = this.websocketonerror; //通信发生错误时触发
      this.websocket.onclose = this.websocketclose; // 连接关闭时触发
    },
    websocketonopen() {
      console.log("建立连接成功");
    },
    websocketonmessage(result) {
      //返回开关数据的状态
      let currentdeviceCode = "";
      if (result.data) {
        let message =
          JSON.parse(result.data).message && JSON.parse(result.data).message;
        currentdeviceCode = message.deviceCode;
        if (message.data) {
          let messageData = message.data;
          for (let key in messageData) {
            // this.deviceState
            if (this.thingmode.find((item) => item.code == key)) {
              this.thingmode.find((item) => item.code == key).val =
                messageData[key];
            }
          }
        }
      }

      // this.reset();
    },
    websocketonerror(err) {
      console.log("Webscocket连接发生错误！");
      this.reconnect();
    },
    websocketclose() {
      console.log("Webscocket连接关闭了");
      this.reconnect();
    },

    // 分配群组

    deviceGroup(row) {
      this.$refs.tip.innerText = "";
      this.groupDeviceCode = row.deviceCode;
      this.batchGroup = false;
      this.groupModal = true;
    },
    dissmissGroupModal() {
      this.groupModal = false;
    },
    groupModalMakeSure() {
      if (this.group1 == "") {
        this.$Message.warning("请选择分组");
      } else {
        //批量模式
        if(this.batchGroup == true){
          axios.request({
            module:"SMP",
            method:'POST',
            url:"/device/group/binding",
            data:{
              deviceCodes:this.selectedDevices.map(d=>d.deviceCode),
              groupId:this.group1,
              groupName:this.groupList.find(g=>g.groupId == this.group1).groupName
            }
          }).then(res=>{
            if(res.data.code==0){
              this.selectedDevices = [];
              this.$Message.success("分配设备到群组完成");
              this.dissmissGroupModal();
              this.loadData();
            } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
          });

        }else{
          let data = {
            deviceCode: this.groupDeviceCode,
            groupId: this.group1,
          };
          axios.request({
              module: "XAIR",
              method: "post",
              url: "/device/updating",
              data: data,
            })
            .then((res) => {
              if (res.data.code == 0) {
                 this.$Message.success("分配设备到群组完成");
                this.dissmissGroupModal();
                this.loadData();
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },

    //开启批量窗口
    openBatch(item) {
      if (this.deviceCodeArray.length == 0) {
        this.$Message.warning("请选择设备");
      } else {
        this.isBatch = true;
        // console.log(item);
        this.batchOpProperty = getPropertyConditionSeqs(item);
      }
    },
    dissmissBatchModal() {
      this.isBatch = false;
    },
    batchModalMakeSure() {
      this.sendOperateCode(
        this.$route.query.productCode,
        this.deviceCodeArray,
        this.batchOpProperty.code,
        this.batchOpForm.value
      );
      this.isBatch = false;
    },

    sendOperateCode(productCode, deviceCode, functionCode, functionValue) {
      var data = {
        productCode: productCode,
        deviceCodes: deviceCode,
        functionCode: functionCode,
        functionValue: functionValue,
      };
      axios
        .request({
          url: "/device/property/set",
          method: "post",
          module: "SMP",
          data,
        })
        .then((res) => {
          this.getProductModel();
          this.getDeviceList(
            this.groupId,
            this.status,
            this.deviceCode,
            this.deviceName
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeRange(val) {
      // console.log(val)
    },
    toScene(device){
      this.$router.push("/control/scene/list?deviceCode="+device.deviceCode);
    }
  },
};
</script>

<style lang="less" scoped>
@import "./device.less";
</style>