<template>
  <GlobalLayout>
    <div class="addUser-wrap">
      <div class="user_title">
        <span class="title"
          ><a @click="goBack()">用户管理 </a>
          <span style="color: #383838">/ 新建用户</span></span
        >
      </div>
      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 3rem; margin-left: 5rem"
        :rules="formItemRules"
      >
        <Form-item label="厂商" :label-width="150" prop="companyCode">
          <Select
            v-model="formItem.companyCode"
            placeholder="请选择厂商"
            class="selector"
            style="width: 20rem"
            @on-change="chooseGroup"
          >
            <Option
              v-for="(item, index) in companyList"
              :key="index"
              :value="item.code"
            >
              {{ item.shortName }}
            </Option>
          </Select>
        </Form-item>
        <Form-item label="所属群组" :label-width="150" prop="groupId">
          <Select
            v-model="formItem.groupId"
            placeholder="请选择群组"
            class="selector"
            style="width: 20rem"
          >
            <Option
              v-for="(item, index) in groupList"
              :key="item.groupId"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>

        <Form-item
          label="是否管理级联群组"
          :label-width="150"
          prop="isManage"
        >
          <RadioGroup v-model="formItem.isManage">
            <Radio label="1">
              <span>是</span>
            </Radio>
            <Radio label="0">
              <span>否</span>
            </Radio>
          </RadioGroup>
        </Form-item>
        <Form-item label="用户名" :label-width="150" prop="userName">
          <Input
            v-model="formItem.userName"
            placeholder="请输入用户名"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="密码" :label-width="150" prop="password">
          <Input
            v-model="formItem.password"
            placeholder="请输入密码"
            class="dark"
            type="text"
            style="width: 250px"
          ></Input>
          <Button
            type="primary"
            class="submitbutton"
            @click="randomPassword()"
            style="
              margin-left: 10px;
              background: #f5f7f9;
              border: 1px solid #1960e1;
              color: #1960e1;
            "
            >随机生成</Button
          >
        </Form-item>
        <Form-item label="使用人" :label-width="150" prop="nickName">
          <Input
            v-model="formItem.nickName"
            placeholder="请输入使用人"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="电话" :label-width="150" prop="phone">
          <Input
            v-model="formItem.phone"
            placeholder="请输入电话"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="E-mail" :label-width="150" prop="email">
          <Input
            v-model="formItem.email"
            placeholder="请输入E-mail"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="AddUserConfirm('formItemRef')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -350px;
              width: 100px;
            "
            >保存</Button
          >
        </Form-item>
      </Form>
    </div>
  </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";
import md5 from "md5-node";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      formItem: {
        companyCode: "",
        userName: "",
        password: "",
        nickName: "",
        phone: "",
        email: "",
        groupId: "",
        isManage:'1',
      },
      companyList: [],
      groupList: [],
      formItemRules: {
        companyCode: [
          { required: false, message: "请选择厂商", trigger: "blur" },
        ],
        groupId: [
          { required: true, message: "群组不能为空", trigger: "blur" },
          { type: "string", message: "请选择群组", trigger: "blur" },
        ],
        isManage: [
          { required: true, message: "", trigger: "blur" }
        ],
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { type: "email", message: "请输入正确的用户名 例:******@邮箱名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码长度不能小于6位",
            trigger: "blur",
          },
        ],
        nickName: [
          { required: false, message: "请输入使用人", trigger: "blur" },
        ],
        phone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号号码",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          { type: "email", message: "Incorrect email format", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getCompanyList();
    console.log(this.$route.query.companyCode)
    this.formItem.companyCode=this.$route.query.companyCode
      this.getGroupList(this.formItem.companyCode);
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    getCompanyList() {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/company/list",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
            // console.log(this.companyList);
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    AddUserConfirm(name) {
      // console.log(name)
      this.$refs[name].validate((valid) => {
        // console.log(valid);
        if (valid) {
          let mnCas
          if(this.formItem.isManage=='0'){
            mnCas=false
          }else{
            mnCas=true
          }
          // this.$Message.success("Success!");
          axios
            .request({
              module: "XAIR",
              method: "post",
              url: "/user/addition",
              data: {
                companyCode: this.formItem.companyCode,
                userName: this.formItem.userName,
                password: md5(this.formItem.password),
                nickName: this.formItem.nickName,
                phone: this.formItem.phone,
                email: this.formItem.email,
                userType: 1,
                groupId: this.formItem.groupId,
                mnCascade:mnCas
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                // console.log(res.data.data)
                this.$Message.success("创建成功");
                this.$router.go(-1)
                // this.$router.push({
                //   path: "/admin/user",
                // });
              } else if(res.data.code==105){
                this.$Message.warning('系统时间有误，请重设系统时间')
              }else{
                this.$Message.error(res.data.message)
              }
            });
        } else {
          this.$Message.error("Fail!");
        }
      });
    },

    // 随机生成密码
    randomPassword() {
      let ranPwd = "";
      for (var i = 0; i < 6; i++) {
        let num = Math.floor(Math.random() * 10);
        ranPwd += num;
      }
      this.formItem.password = ranPwd;
    },

    chooseGroup(val) {
      // console.log(val)
      this.getGroupList(val);
    },

    getGroupList(code) {
      axios
        .request({
          method: "get",
          module: "XAIR",
          url: "/group/query",
          params: {
            companyCode: code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.groupList = res.data.data;
          // console.log(res.data.data[0].groupName);
            this.formItem.groupId=res.data.data[0].groupId
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.addUser-wrap {
  padding: 40px;
  .ivu-form-item-content {
    margin-left: 0;
  }
}
</style>