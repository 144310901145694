<template>
  <GlobalLayout>
    <div class="detail_wrap">
      <div class="detail_title">
        <span class="title"
          ><router-link to="/control/operate/sale">运维-售后</router-link>
          <span style="color: #383838"> / 新建售后记录</span></span
        >
      </div>

      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 3rem; margin-left: 5rem"
        :rules="formItemRules"
      >
        <Form-item label="设备唯一标识" :label-width="110" prop="deviceCode">
          <Input
            v-model="formItem.deviceCode"
            placeholder="请输入设备唯一标识"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="售后类型" :label-width="110" prop="transType">
          <Select
            v-model="formItem.transType"
            placeholder="请选择售后类型"
            class="selector"
            style="width: 20rem"
          >
            <Option
              v-for="item in transArr"
              :value="item.typeId"
              :key="item.typeId"
            >
              {{ item.value }}
            </Option>
          </Select>
        </Form-item>
        <Form-item label="售后内容" :label-width="110" prop="content">
          <Input
            v-model="formItem.content"
            placeholder="请输入售后内容"
            class="dark"
            style="width: 20rem"
          ></Input>
        </Form-item>
        <Form-item label="售后人员" :label-width="110" prop="processor">
          <Input
            v-model="formItem.processor"
            placeholder="请输入售后人员"
            class="dark"
            style="width: 20rem"
          ></Input>
        </Form-item>
        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="addSaleConfirm('formItemRef')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -40rem;
              width: 10rem;
            "
            >保存</Button
          >
        </Form-item>
      </Form>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      formItem: {
        deviceCode: "",
        transType: "",
        content: "",
        processor: "",
      },
      formItemRules: {
        deviceCode: [
          { required: true, message: "设备唯一标识不能为空", trigger: "blur" },
          { type: "string", message: "请输入设备唯一标识", trigger: "blur" },
        ],
        transType: [
          { required: true, message: "维修类型不能为空", trigger: "blur" },
          { type: "string", message: "请选择维修类型", trigger: "blur" },
        ],
        content: [
          { required: true, message: "售后内容不能为空", trigger: "blur" },
          { type: "string", message: "请输入售后内容", trigger: "blur" },
        ],
        processor: [
          { type: "string", message: "请输入售后人员", trigger: "blur" },
        ],
      },

      transArr: [
        {
          value: "其他",
          label: "其他",
          typeId: 0,
        },
        {
          value: "维修",
          label: "维修",
          typeId: 1,
        },
        {
          value: "退换货",
          label: "退换货",
          typeId: 2,
        },
        {
          value: "保养",
          label: "保养",
          typeId: 3,
        },
      ],
      saleInfo: {},
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
      "isRootGroupUser",
      "getGroup",
    ]),
  },
  mounted() {
  },
  methods: {
    addSaleConfirm() {
      axios
        .request({
          method: "post",
          module: "SMP",
          url: "/aftersale/addition",
          data: {
            transType: this.formItem.transType,
            content: this.formItem.content,
            processor: this.formItem.processor,
            deviceCode: this.formItem.deviceCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success('成功添加售后记录');
            this.$router.push({ path: "/control/operate/sale" });
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.detail_wrap {
  padding: 4rem;
}
</style>