import { render, staticRenderFns } from "./datastatistic.vue?vue&type=template&id=703def96&scoped=true&"
import script from "./datastatistic.vue?vue&type=script&lang=js&"
export * from "./datastatistic.vue?vue&type=script&lang=js&"
import style0 from "./datastatistic.vue?vue&type=style&index=0&id=703def96&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "703def96",
  null
  
)

export default component.exports