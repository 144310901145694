<template>
  <GlobalLayout>
    <div class="set_warp">
      <div class="set-title">用户</div>

      <div class="paths">
        <router-link to="/control/user/user"
          >群组用户</router-link
        >
        <router-link to="/control/user/wx"
          >移动端用户</router-link
        >
      </div>
      <hr style="color: #e5e5e5; opacity: 0.2" />
    <keep-alive>
                <router-view></router-view>
            </keep-alive>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.set_warp{
    padding: 4rem;
    .set-title{
        font-size: 3.6rem;
        font-family: PingFangHK-Semibold, PingFangHK;
        font-weight: 600;
        color: #222222;
    }
    .paths {
        margin: 2.4rem 0;

        a {
            margin-right: 5rem;
            display: inline-block;
            line-height: 3rem;
            color: #222222;
            font-size: 2rem;
            font-family: PingFangHK-Regular, PingFangHK;
            font-weight: 400;

            &.active {
                color: #1960e1;
                font-weight: 600;
            }
        }
    }
}
</style>