<template>
  <div class="login-wrap">
    <div class="login">
      <p class="title">物联网设备管理平台</p>
      <div class="bottom">
        <div class="loginBg"></div>
        <div class="loginInfo">
          <h3>HELLO!</h3>
          <h4>欢迎登录，物联网设备管理平台</h4>
          <Form ref="formInline" :model="formInline" :rules="ruleInline" inline>
            <FormItem prop="user">
              <input
                class="username userbj"
                id="username"
                type="text"
                placeholder="账号"
                v-model="formInline.user"
                autocomplete="off"
                v-on:input="usernameChange(formInline)"
              />
            </FormItem>
            <FormItem prop="password">
              <input
                class="password pwdbj"
                id="password"
                type="password"
                placeholder="密码"
                v-model="formInline.password"
                autocomplete="off"
                v-on:input="passwordChange(formInline)"
              />
            </FormItem>
            <FormItem>
              <Button
                :class="btnShow ? btn1 : btn2"
                type="primary"
                @click="handleSubmit('formInline')"
                >进入</Button
              >
            </FormItem>
          </Form>
          <!-- <form action="">
            <input
              class="username userbj"
              id="username"
              type="text"
              placeholder="账号"
              v-model="username"
              autocomplete="off"
            />
            <br />
            <input
              class="password pwdbj"
              id="password"
              type="password"
              placeholder="密码"
              v-model="password"
              autocomplete
            />
            <br />
            <button class="btn" @click="submit()">登录</button>
          </form> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { getToken, formatDate } from "@/libs/util";
import md5 from "md5-node";
export default {
  data() {
    return {
      username: "",
      password: "",
      btnShow: false,
      btn1: "btn1",
      btn2: "btn2",
      formInline: {
        user: "",
        password: "",
      },
      ruleInline: {
        user: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            type: "string",
            min: 6,
            message: "密码最少6位长度",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("user", [
      "setControlMode",
      "isControlMode",
      "getCompanyCode",
      "getCompanyName",
      "hasRight",
    ]),
    ...mapState("user", ["userInformation"]),
  },
  created(){
    var that= this;
    document.onkeydown = function (e) {
      var key = window.event.keyCode;
      if (key == 13) {
        that.handleSubmit('formInline')
      }
    };
  },
  mounted() {
    // console.log(getToken());
  },
  methods: {
    ...mapGetters(["usertype"]),
    ...mapActions(["handleLogin"]),
    ...mapActions("user", ["saveUserInfo"]),
    handleSubmit(name) {
      if (
        this.formInline.user.length != 0 &&
        this.formInline.password.length != 0
      ) {
        this.btnShow = true;
      } else {
        this.btnShow = false;
      }
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.handleLogin({
            username: this.formInline.user,
            password: md5(this.formInline.password),
            moduleCode: "XAIR",
          }).then((res) => {
            if (res.data.code == 0) {
              // console.log(res.data.data);
              let authentication = res.data.data;
              if (
                this.hasModuleRight(
                  authentication.module.code,
                  authentication.rights
                )
              ) {
                this.saveUserInfo(authentication);
                // console.log(res.data.data);
                if (res.data.data.user.userType == 0) {
                  this.setControlMode(false, null, null);
                  this.$router.push({ path: "/admin" });
                } else {
                  this.setControlMode(
                    true,
                    this.getCompanyCode(),
                    this.getCompanyName()
                  );
                  if (this.hasRight("DEVICE")) {
                    this.$router.push({ path: "/control" });
                  } else if (this.hasRight("GROUP")) {
                    this.$router.push({ path: "/control/group" });
                  } else if (this.hasRight("X_AFS")) {
                    this.$router.push({ path: "/control/group" });
                  } else if (this.hasRight("ACCESSKEY")) {
                    this.$router.push({ path: "/control/dev/setting" });
                  } else if (this.hasRight("AUTH_USER")) {
                    this.$router.push({ path: "/control/user" });
                  } else if (this.hasRight("X_SYSTEM")) {
                    this.$router.push({ path: "/control/setting" });
                  } else if (this.hasRight("DATAV")) {
                    this.$router.push({ path: "/control/datastatic" });
                  } else {
                    this.$Notice.warning({
                      title: "访问受限",
                      desc: "您没有访问权限",
                    });
                  }
                }
              }else {
                    this.$Notice.warning({
                      title: "访问受限",
                      desc: "您没有访问权限",
                    });
                  }
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          });
        } else {
          this.$Message.error("Fail!");
        }
      });
    },

    hasModuleRight(moduleCode, rights) {
      if (null != rights) {
        for (let i in rights) {
          if (rights[i].moduleCode == moduleCode) {
            return true;
          }
        }
      }
      return false;
    },
    usernameChange(formInline) {
      // console.log(formInline)
      if (formInline.user != "" && formInline.password != "") {
        this.btnShow = true;
      } else {
        this.btnShow = false;
      }
    },
    passwordChange(formInline) {
      // console.log(formInline)
      if (formInline.user != "" && formInline.password != "") {
        this.btnShow = true;
      } else {
        this.btnShow = false;
      }
    },
    // submit(){
    //   this.handleLogin({ username: this.form.userName, password: md5(this.form.password), moduleCode:'SMP' }).then(res => {
    //                 if(res.data.code == 0){
    //                   // console.log(res.data)
    //                         this.$router.push({path: '/datastatic'})
    //                     // if(this.role == 0 || this.role == 1 || this.role == 2){
    //                     // } else if(this.role == 3 || this.role == 4){
    //                     //     this.$router.push({path: '/devicemanage/devicelistofproj'})
    //                     // }

    //                 } else {
    //                     this.$Message.error(res.data.message)
    //                 }
    //             })
    // },
  },
};
</script>

<style lang="less" scoped>
@import "./login.less";
</style>