<template>
  <div class="layout back-wrap " style="position: relative" @click="closeMenu">
    
     <Layout>  
  <div class="header-wrap flex hside vcenter borderbox">
    <div class="left">
      <div class="tarbar" v-if="this.$route.path!='/admin' && this.$route.path!='/admin/company' && this.$route.path!='/admin/user' && this.$route.path!='/admin/user/add' && this.$route.path!='/admin/user/detail' && this.$route.path!='/admin/cost' && this.$route.path!='/admin/order' && this.$route.path!='/admin/datastatitic' && this.$route.path!='/admin/company/add' && this.$route.path!='/admin/company/detail' && this.$route.path!='/admin/page' && this.$route.path!='/admin/register'">
        <img src="../header/img/topBar.png" alt=""id="headermenu" style="width: 8.2rem;height: 8.2rem;"/>
      </div>
      <h3>{{systemTitle}}</h3>
    </div>
    <div class="menu"> 
      <ul>
        <li style="width:300px;" v-if="this.$route.path=='/control/datastatic'">{{timestamp}}</li>
        <li v-if="this.$route.path!='/control/datastatic'">
          <a class="control" @click="selectCompany" :class="isControl?yesControl:noControl">控制台</a>
        <!-- <router-link to="/control" class="control">控制台</router-link> -->
        </li>
        <!-- <li v-if="getUserType()==1 && isRootGroupUser()==true && this.$route.path!='/control/datastatic'"><router-link to="/cost/cost" class="cost">费用</router-link></li>
        <li v-if="getUserType()==1 && isRootGroupUser()==true && this.$route.path!='/control/datastatic'"><router-link to="/order/order" class="order">工单</router-link></li> -->
        <li v-if="isAdmin()==true&&getUserType()==0 && this.$route.path!='/control/datastatic'">
          <a class="superAdmin" @click="toAdmin" :class="isAd?yesAd:noAd">超级管理</a>
        </li>

      </ul>
    </div>
    <div class="right">
      <img class="header" src="../header/img/topBar.png" alt="">
      <div class="userInfo">
        <p class="username">{{username}}</p>
        <p class="company" v-if="isAdmin()==false">{{isGroupUser()?(isRootGroupUser()?companyName():groupName()):companyName()}}</p>
      </div>
      <div class="more"> 
        <user></user>
      </div>
    </div>
     <Modal v-model="selectCompanyFlag" width="350" :footer-hide="true" title="进入控制台">
        <label><b>选择厂商:</b></label>
        <Select
          v-model="selectedCompanyCode"
          placeholder="请选择要进入的厂商"
          class="selector"
          style="width: 20rem"
          @on-change="pickCompany"
        >
          <Option
            v-for="(item, index) in companyList"
            :key="index"
            :value="item.code"
          >
            {{ item.showName }}
          </Option>
        </Select>
      </Modal>
  </div>  
    <!-- <Header style="height: 80px">
      
      
        <HeaderM id="headermenu" @openSideMenu="openMenu"> </HeaderM>
      </Header> -->
    <Layout>
      <Sidemenu v-if="this.menuShow == true" class="show-menu"></Sidemenu>
      <Sider v-if="this.$route.path=='/admin' || this.$route.path=='/admin/company' || this.$route.path=='/admin/user/add' || this.$route.path=='/admin/user' || this.$route.path=='/admin/user/detail' || this.$route.path=='/admin/cost' || this.$route.path=='/admin/order' || this.$route.path=='/admin/datastatitic' || this.$route.path=='/admin/company/add' || this.$route.path=='/admin/company/detail' || this.$route.path=='/admin/page' || this.$route.path=='/admin/register'" class="element" style="width: 25.6rem;max-width: 25.6rem; height:calc(100vh - 8rem); 
              overflow-y:scroll; flex: 0 0 25.6rem;scrollbar-width: none;
              min-width: 25.6rem;"><AdminMenu></AdminMenu></Sider>
      <Content style="height:calc(100vh - 8rem); overflow-y:scroll;">
        <div class="bread" v-show="BreadShow">
          <Breadcrumb separator=">">
              <BreadcrumbItem :class="{colorOne: index == 0}"  :to="item.path" v-for="(item, index) in breadList" :key="index">{{item.name}}</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <slot></slot>
      </Content>
    </Layout>
  </Layout>
  </div>
</template>

<script>
import Vue from 'vue';
import HeaderM from "@/components/header/header";
import Sidemenu from "@/components/menu/menu";
import AdminMenu from "@/components/adminMenu/adminMenu";
import { mapGetters } from "vuex";
import acceptTitle from '../../views/sendTitle.js'
import User from '../user/user.vue';
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  name: "GlobalLayout",
  props: {
    BreadShow: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderM,
    Sidemenu,
    AdminMenu,
    User,
  },
  data() {
    return {
      username: "",
      menuShow:false,
      companyList:[],
      showName:'',
      timestamp :'',
      systemTitle:'',
      newTitle:'',
      selectCompanyFlag:false,
      selectedCompanyCode:null,
      isAd:true,
      yesAd:'yesad',
      noAd:'noad',
      isControl:true,
      yesControl:'yesControl',
      noControl:'noControl',
    };
  },
  computed: {
    breadList() {
      // console.log(this.$route.meta.breadList)
      return this.$route.meta.breadList || [];
    },
    ...mapGetters({
      getUsername: "getUsername",
    }),
    ...mapGetters('user',['isAdmin','isPerson','isCompanyUser','isGroupUser','getCompanyCode','isRootGroupUser','getControlModeInfo','getCompanyName','setControlMode','isControlMode','getUserType'])
  },
  mounted() {
    this.username = localStorage.getItem("userName");
    // console.log(this.isAdmin())
    if(this.isAdmin()==true){
      this.getCompanyList()
    }else{
      this.getTitle()
    }
    this.timestamp=formatDate(new Date(),'yyyy-MM-dd hh:mm:ss')
    acceptTitle.$on('title',function(val){
      this.systemTitle=val.settingValue
    })
    // console.log(JSON.stringify(this.$route.path).indexOf('/admin'))
    if(JSON.stringify(this.$route.path).indexOf('/admin')!=-1){
      // console.log(111)
      this.isAd=true
    }else{
      this.isAd=false
    }
    if(JSON.stringify(this.$route.path).indexOf('/control')!=-1){
      // console.log(111)
      this.isControl=true
    }else{
      this.isControl=false
    }
  },
  methods: {
    ...mapGetters(["usertype",'companyName','groupId',"companyCode","groupName"]),
    closeMenu(event){
      let hm=document.getElementById('headermenu')
      // console.log(hm.contains(event.target))
      if(hm){
        if(!hm.contains(event.target)){
          this.menuShow=false
        }else{
          if(this.menuShow==false){
            this.menuShow=true
          }else{
            this.menuShow=false
          }
          // this.menuShow=!this.menuShow
        }
        // console.log(this.menuShow)

      }
    },
    openMenu(){
      // console.log('打开菜单')
      this.menuShow=!this.menuShow
      // this.$emit('openSideMenu',this.menuShow)
    },
    selectCompany(){
      if(this.isAdmin()){
        this.selectCompanyFlag = true;
      }else{
        Vue.ls.set("companyCode",this.getCompanyCode())
        localStorage.setItem("companyCode",this.getCompanyCode());
        this.setControlMode(true,this.getCompanyCode(),this.getCompanyName())
        this.$router.push("/control")
      }
    },
    pickCompany(){
       Vue.ls.set("companyCode",this.selectedCompanyCode)
       localStorage.setItem("companyCode",this.selectedCompanyCode);
       let companyName=null;
       if(null!=this.companyList){
         this.companyList.forEach(company=>{
           if(company.code==this.selectedCompanyCode){
             companyName=company.showName;
              Vue.ls.set("companyName",company.showName)
           }
         })
       }
       this.setControlMode(true,this.selectedCompanyCode,companyName);
       this.$router.push(`/control?companyCode=${this.selectedCompanyCode}`)
      console.log(this.$route.path)
      if(this.$route.path=='/control/all/device'){
        this.$router.go(0)
      }
       this.selectCompanyFlag=false;
    },
    toAdmin(){
      this.setControlMode(false,null,null);
      // console.log(this.setControlMode)
      this.$router.push('/admin')
    },
    openMore(){

    },
    changeMenu(){
      console.log('--------------')
      this.topbar=false
    },
    getTitle(){
      let base_url
      if(this.isAdmin()==true){
        base_url='/company/setting/title?companyCode='+this.companyList[0].code
      }else{
        base_url='/company/setting/title?companyCode='+this.companyCode()
      }
      axios.request({
        module:'XAIR',
        method:'get',
        url:base_url
      }).then(res=>{
        // console.log(res.data)
        if(res.data.code==0){
          // console.log(res.data.data)
          this.systemTitle=res.data.data.settingValue
          localStorage.setItem('systemTitle',res.data.data.settingKey)
          document.title = this.systemTitle;
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    getCompanyList(){
      axios.request({
        module:'XAIR',
        method:'get',
        url:'/company/list'
      }).then(res=>{
        if(res.data.code==0){
          this.companyList=res.data.data
          this.showName=this.companyList[0].showName
          
          this.getTitle()
          this.$forceUpdate()
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
};
</script>

<style lang="less" scoped>
.back-wrap {
  background: #ffffff;
  background-size: 100% 100%;
  background-attachment: fixed;
}.header-wrap {
    box-shadow: 0px .2rem .4rem 0px rgba(0, 0, 0, 0.06);
    width: 100%;
    height: 8.2rem;
    box-sizing: border-box;
    // padding-left: 30px;
    // padding-right: 30px;
    // background: rgba(201,201,202,0.05);
    background: #ffffff;
    position: relative;

    .left {
        display: inline;
        display: flex;
        justify-content: space-around;
        height: 100%;

        .fontIcon {
            width: 2.5rem;
            height: 100%;
            margin-right: 2rem;
            align-items: center;
            cursor: pointer;
        }

        .topbar {
            cursor: pointer;
            width: 8.2rem;
            height: 8.2rem;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .bar1{
            cursor: pointer;
            width: 8.2rem;
            height: 8.2rem;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .bar2{
            display: none;
        }

        h3 {

            height: 8.2rem;
            line-height: 8.2rem;
            font-size: 3.6rem;
            font-family: PingFangSC-Bold, PingFang SC;
            font-weight: bold;
            color: #222222;
            margin-left: 3rem;
        }

        .avatar {
            height: 100%;
        }
    }


    .menu {
        position: absolute;
        right: 40rem;
        // border: 1px solid red;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                font-size: 2rem;
                font-family: PingFangSC-Bold, PingFang SC;
                font-weight: bold;
                color: #222222;
                width: 10rem;
                text-align: center;
                a{
                    color: #222222;
                    &:hover,&.active{                    
                        color: #0060FF;
                        // background-color:rgba(231,241,253,1);
                    } 
                    &.control{
                        &:hover,&.active{
                            color: #0060FF;
                        }
                    }   
                    &.cost{
                        &:hover,&.active{
                            color: #0060FF;
                        }
                    }  
                    &.order{
                        &:hover,&.active{
                            color: #0060FF;
                        }
                    }   
                    &.superAdmin{
                        &:hover,&.active{
                            color: #0060FF;
                        }
                    }       
                }
            }
        }
    }

    .right {
        width: 40rem;
        height: 100%;
        border-left: 1px solid rgba(#BABABA, 0.2);
        // opacity: 0.2;
        padding: 0 30px;
        display: flex;
        align-items: center;
        position: relative;

        .header {
            display: block;
            width: 4.4rem;
            height: 4.4rem;
            border-radius: 50%;
        }

        .userInfo {
            height: 4.4rem;
            font-size: 1.4rem;
            font-family: PingFangHK-Regular, PingFangHK;
            font-weight: 400;
            color: #404040;
            margin-left: 2rem;

            .username {
                height: 2rem;
                line-height: 2rem;
            }

            .company {
                height: 2rem;
                line-height: 2rem;
                margin-top: .4rem;
            }
        }

        .more {
            position: absolute;
            right: 3rem;
            cursor: pointer;
        }
    }

    .project {
        color: #fff;
    }

    .name {
        margin: 0 1.6rem;

        .fullname {
            font-size: 1.4rem;
            font-family: PingFang-SC-Bold, PingFang-SC;
            // font-weight:bold;
            color: #fff;
            line-height: 2rem;
        }

        .username {
            font-size: 1.2rem;
            font-family: PingFangHK-Regular, PingFangHK;
            font-weight: 400;
            color: rgba(153, 153, 153, 1);
            line-height: 1.7rem;
        }
    }
}
.header-wrap .menu ul li .yesControl{
    color: #1960e1;
}
.header-wrap .menu ul li .noControl{
    color:#222222
}
.header-wrap .menu ul li .yesad{
    color: #1960e1;
}
.header-wrap .menu ul li .noad{
    color:#222222
}
</style>