<template>
  <div class="warn_wrap">
    <div class="overdue">
      <span style="margin-right: 20px">告警过期时间:</span>
      <Select
        class="white"
        v-model="overdue"
        style="width: 117px; margin-left: 15px"
        clearable
        @on-change=""
      >
        <Option v-for="item in overdueList" :value="item.code" :key="item.code">
          {{ item.value }}
        </Option>
      </Select>
    </div>
    <div class="clean" style="margin-top:20px">
      <span style="margin-right: 20px">告警清理时间:</span>
      <Select
        class="white"
        v-model="clean"
        style="width: 117px; margin-left: 15px"
        clearable
        @on-change=""
      >
        <Option v-for="item in cleanList" :value="item.code" :key="item.code">
          {{ item.value }}
        </Option>
      </Select>
    </div>
        <Button style="margin-left:100px;background:#1960e1;border:none;color:white;margin-top:30px">提交</Button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      overdue: 1,
      clean: 1,
      overdueList: [
        {
          code: 1,
          value: 1,
        },
        {
          code: 2,
          value: 2,
        },
        {
          code: 3,
          value: 3,
        },
        {
          code: 4,
          value: 4,
        },
        {
          code: 5,
          value: 5,
        },
        {
          code: 6,
          value: 6,
        },
        {
          code: 7,
          value: 7,
        },
      ],
      cleanList: [
        {
          code: 1,
          value: 1,
        },
        {
          code: 2,
          value: 2,
        },
        {
          code: 3,
          value: 3,
        },
        {
          code: 4,
          value: 4,
        },
        {
          code: 5,
          value: 5,
        },
        {
          code: 6,
          value: 6,
        },
        {
          code: 7,
          value: 7,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.warn_wrap {
  padding: 40px;
}
</style>