<template>
  <div>
    <div>
      <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="''"
      :loading="loading"
    >
      <template slot-scope="{ row }" slot="operate">
        <Button type="primary" v-if="status==1&&row.version!=deviceInfo.firmwareVersion" @click="firmwareUpdate(row)">推送此版本</Button>
        <Button type="info" disabled v-if="status!=1">推送此版本</Button>
      </template>
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>
    </div>
  </div>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import { mapActions, mapGetters, mapState } from "vuex";
import Constants from '@/libs/constants';
import { iviewMixin } from "@/mixins/iviewMixin";
const versioning = require(`versioning`);
export default {
  mixins:[iviewMixin],
  data() {
    return {
      url:{
        module:'SMP',
        list:'/product/firmware/query?status=1'
      },
      status:0,
      version:null,
      columns:[
        {
          title:'版本号',
          key:'version',
          align:`center`,
          render:(h,params)=>{
            let version = params.row.version;
            let currentVersion = this.deviceInfo.firmwareVersion;
            if(version==currentVersion){
              return h(`div`,`${version}(使用中)`);
            }else{
              return h(`div`,version);
            }
          }
        },
        {
          title:'版本说明',
          key:'remarks',
          align:'left'
        },
        {
          title:'上传时间',
          align:'center',
          render(h,params){
            return h('div',formatDate(new Date(params.row.updateTime),'yyyy-MM-dd hh:mm'))
          }
        },
        {
          title:'操作',
          slot:'operate',
          align:'center'
        }
      ]
    };
  },
  mounted() {
    this.setPreLoadData(false);
    
    this.deviceCode = this.$route.query.deviceCode;
    this.productCode = this.$route.query.productCode;
    this.queryParam.productCode = this.productCode;
    
    this.deviceInfo = {};
    this.getDeviceInfo((deviceInfo)=>{
      this.version = deviceInfo.firmwareVersion;
      this.status = deviceInfo.status;
      this.loadData();
    });
    
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  methods: {
    ...mapGetters(["companyCode", "usertype"]),
    getDeviceInfo(callback) {
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/device/basicinfo";
      } else if (this.getUserType() == 1) {
        base_url = "/device/basicinfo?companyCode=" + this.companyCode();
      }
      // console.log(base_url)
      axios.request({
          module: "SMP",
          method: "get",
          url: base_url,
          params: {
            deviceCode: this.deviceCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.deviceInfo = res.data.data;
            // this.drawBound()
            if(callback){
              callback(this.deviceInfo)
            }
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
            this.deviceInfo = {};
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    firmwareUpdate(firmware){
      this.$Modal.confirm({
        title:'推送设备固件版本',
        content:`<div>
                  <span>设备标识:${this.deviceCode}</span>
                  <br><span>当前版本:${this.deviceInfo.firmwareVersion}</span>
                  <br><span>目标版本:${firmware.version}</span>
                  ${versioning.lt(firmware.version,this.deviceInfo.firmwareVersion)?'<br><span style="color:red">目标版本小于当前版本</span>':''}
                  <br><span><B>您确定要对设备推送固件版本吗?</B></span></div>`,
        onOk:()=>{
          axios.request({
            url:'/device/service/invoking',
            method:'post',
            module:'SMP',
            data:{
              productCode:this.productCode,
              deviceCodes:[this.deviceCode],
              functionCode:'FirmwareUpdate',
              inputParams:{
                fileUrl:firmware.url,
                version:firmware.version,
                fw_md5:firmware.md5
              }
            }
          }).then(res=>{
            if(0==res.data.code){
              let result = res.data.data;
              if(result){
                console.log(`${JSON.stringify(result)}`)//*
                let response = result.data[this.deviceCode];
                if(!response){
                  return;
                }
                switch(response.code){
                  case 0:
                    this.$Message.success("已经将OTA升级信息发送给设备");
                    break;
                  case 10001:
                    this.$Message.error("设备不在线,无法OTA升级");
                    break;
                  default:
                    this.$Message.error(response.message);
                    break;
                }
              }
              
            }else{
              this.$Message.error(res.data.message);
            }
          });
        },
        onCancel:()=>{},
      });
    }
  }
};
</script>

<style lang="less" scoped>
.device_data {
  padding: 40px;
  .status {
    .online {
      color: #00c869;
    }
    .outline {
      color: red;
    }
    .inactive {
      color: #f7941d;
    }
  }
  .info {
    width: 100%;
    margin-top: 30px;
    ul:first-child {
      background: rgba(#1960e1, 0.1);
      border-top: 1px solid #e5e5e5;
      font-weight: 600;
    }
    ul:not(:first-child) {
      li:last-child {
        cursor: pointer;
        color: #1960e1;
      }
    }
    ul {
      display: flex;
      width: 100%;
      height: 44px;
      text-align: center;
      line-height: 44px;
      li:last-child {
        border-right: 1px solid #e5e5e5;
      }
      li {
        width: 25%;
        padding-left: 10px;
        border-bottom: 1px solid #e5e5e5;
        border-left: 1px solid #e5e5e5;
      }
    }
  }
}
.runstatus {
  .til {
    font-size: 14px;
    font-weight: 500;
    color: #1a1919;
    line-height: 40px;
  }
  .runstatus {
    dl {
      color: #fff;
      dt {
        background-color: rgba(0, 142, 170, 0.1);
        line-height: 66px;
        display: flex;
        justify-content: space-around;
        span {
          display: inline-block;
          text-align: center;
        }
      }
      dd {
        line-height: 54px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        span {
          display: inline-block;
          text-align: center;
        }
      }
    }
   
  }
   .list {
      width: 100%;
      color: #1a1919;
      th {
        background-color: rgba(#1960e1, 0.06);
        line-height: 50px;
        border: none;
      }
      td {
        text-align: center;
        line-height: 50px;
      }
      tr:nth-child(2n) {
        background-color: rgba(255, 255, 255, 0.02);
      }
    }
}
</style>