<template>
  <div class="sale_wrap">
    <div class="top flex hside vcenter sale-choose">
      <div class="saleTotal" style="width: 70rem">
        售后记录 ({{ saleCount }})
      </div>
      <div class="choose">
        <Select
          class="white"
          v-model="transType"
          style="width: 11.7rem; margin-left: 1.5rem"
          clearable
          placeholder="售后类型"
          @on-change="handleSelectChoose"
        >
          <Option
            v-for="item in transArr"
            :value="item.typeId"
            :key="item.typeId"
          >
            {{ item.value }}
          </Option>
        </Select>
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="设备唯一标识"
          v-model="deviceCode"
        />
        <Input
          style="margin-left: 1.5rem; width: 13.7rem"
          class="white"
          placeholder="售后人员"
          v-model="processor"
        />
        <Button
          type="primary"
          style="margin-left: 1.5rem; background: #1960e1; border: none"
          @click="searchSale()"
          icon="ios-search"
          >搜索</Button
        >
        <Button
          type="primary"
          style="margin-left: 1.5rem; background: #1960e1; border: none"
          @click="addSale()"
          >新建</Button
        >
      </div>
    </div>

    <Table
      stripe
      ref="selection"
      :columns="columns"
      :data="dataSource"
      :no-data-text="'没有售后记录'"
      :loading="loading"
      class="notLineTable"
    >
      <template slot-scope="{ row, index }" slot="operate">
        <router-link
          :to="
            '/control/operate/sale/detail?transactionNo=' + row.transactionNo
          "
          class="operationTxt"
          >查看详情</router-link
        >
        <a class="operationTxt" @click="deleteSale(row)">删除</a>
      </template>
    </Table>
    <div class="page flex hcenter">
      <Page
        :total="ipagination.total"
        :page-size="ipagination.pagesize"
        prev-text="上一页"
        next-text="下一页"
        @on-change="changePage"
        show-total
      />
    </div>
    
    <!-- 删除售后记录对话框 -->
    <Modal
      v-model="deletesale"
      width="560"
      :mask-closable="false"
      :footer-hide="true"
    >
      <h5 class="title">删除售后记录</h5>
      <p
        class="tip"
        ref="tip"
        style="
          font-size: 2.4rem;
          color: #e02020;
          margin: 0px auto 5rem auto;
          text-align: center;
        "
      >
        确认要删除该售后记录吗？
      </p>
      <div class="btns" style="display: flex; justify-content: space-around">
        <Button style="width: 20%" @click="closeLDelete()">取消</Button>
        <Button
          style="width: 20%; background: #0060ff"
          type="primary"
          @click="isDelete()"
          >确认</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      columns: [
        {
          title: "设备唯一标识",
          key: "deviceCode",
          align: "center",
        },
        {
          title: "售后类型",
          key: "transType",
          align: "center",
          render: (h, params) => {
            if (params.row.transType == 0) {
              return h("div", "其他");
            } else if (params.row.transType == 1) {
              return h("div", "维修");
            } else if (params.row.transType == 2) {
              return h("div", "退换货");
            } else if (params.row.transType == 3) {
              return h("div", "保养");
            }
          },
        },
        {
          title: "售后人员",
          key: "processor",
          align: "center",
        },
        {
          title: "售后内容",
          key: "content",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
          render: (h, params) => {
            if (params.row.createTime) {
              return h(
                "div",
                formatDate(
                  new Date(params.row.createTime),
                  "yyyy-MM-dd hh:mm:ss"
                )
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      saleCount: 0,
      transType: "",
      deviceCode: "",
      processor: "",
      transArr: [
        {
          value: "其他",
          label: "其他",
          typeId: 0,
        },
        {
          value: "维修",
          label: "维修",
          typeId: 1,
        },
        {
          value: "退换货",
          label: "退换货",
          typeId: 2,
        },
        {
          value: "保养",
          label: "保养",
          typeId: 3,
        },
      ],
      deletesale:false,
      transactionNo:''
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  mounted() {
    this.getSaleList();
  },
  methods: {
    getSaleList(transType, deviceCode, processor) {
      this.loading=true;
      var data = {
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
        transType: transType,
        processor: processor,
        deviceCode: deviceCode,
      };
      axios
        .request({
          method: "get",
          url: "/aftersale/query",
          module: "SMP",
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data);
            this.ipagination.total = res.data.data.total;
            this.saleCount = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.data.length; i++) {
              d[i] = {
                content: res.data.data.data[i].content,
                createTime: res.data.data.data[i].createTime,
                deviceCode: res.data.data.data[i].deviceCode,
                processor: res.data.data.data[i].processor,
                status: res.data.data.data[i].status,
                transType: res.data.data.data[i].transType,
                transactionNo: res.data.data.data[i].transactionNo,
                updateTime: res.data.data.data[i].updateTime,
              };
            }
            this.dataSource = d;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
      this.loading=false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getSaleList();
    },
    // 删除
    deleteSale(row) {
      this.deletesale=true
      this.transactionNo=row.transactionNo
      
    },
    isDelete(){
      axios
        .request({
          module: "SMP",
          method: "post",
          url: "/aftersale/delete",
          data: {
            transactionNo: this.transactionNo,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.success('成功删除售后记录');
            this.getSaleList();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        });
      this.deletesale=false
    },
    closeLDelete(){
      this.deletesale=false
    },
    // 搜索
    searchSale() {
      this.getSaleList(this.transType, this.deviceCode, this.processor);
    },
    handleSelectChoose(val) {
      this.getSaleList(val);
    },
    addSale() {
      this.$router.push({
        path: "/control/operate/sale/add",
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./sale.less";
</style>