<template>
    <GlobalLayout>
        <h1 style="text-align:center;margin-top:400px">开发中，敬请期待！</h1>
    </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";
import {mutations} from 'vuex'
    export default {
        components:{
            GlobalLayout
        },
        data() {
            return {
                
            }
        },
        mounted() {
            
        },
        methods: {
            
        },
    }
</script>

<style lang="less" scoped>
@import 'order.less';
</style>