<template>
  <div class="child_wrap">
    <div class="status">
      在线状态:
      <span class="cont outline" v-if="this.$route.query.status == 0"
        >离线</span
      >
      <span class="cont online" v-if="this.$route.query.status == 1">在线</span>
      <span class="cont inactive" v-if="this.$route.query.status == 3"
        >未激活</span
      >
    </div>

    <Table stripe ref="selection" :columns="columns" :data="dataSource" :no-data-text="tips" :loading="loading" style="margin-top:30px">
                <template slot-scope="{ row, index }" slot="operate" >
                    <span class="pointer fontcolor" style="margin-right: 20px" >编辑</span>
                    <span class="pointer "  >删除</span>
                </template>
        </Table>
        <div class="page flex hcenter">
            <Page :total="ipagination.total"   show-total :page-size="ipagination.pagesize"  prev-text="上一页" next-text="下一页" @on-change="changePage" />
        </div>   
  </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { log } from "util";
import { formatDate } from "@/libs/util.js";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
        
            columns: [
                {
                    title: '子设备唯一标识',
                    key: 'deviceCode',
                    align: 'center'
                },
                {
                    title: '子设备昵称',
                    key: 'deviceName',
                    align: 'center'
                },
                {
                    title: '在线状态',
                    key: '',
                    align: 'center'
                },
                {
                    title: '最后上传时间',
                    key: '',
                    align: 'center'
                },
                {
                    title: '操作',
                    slot: 'operate',
                    align: 'center'
                },
            ],
            loading: false, //加载...
            ipagination: {
                current: 1,
                total: 0,
                pagesize: 10
            },
            dataSource: [],
            tips:''
    };
  },
  mounted() {
        this.getSubDeviceLists();
  },
  methods: {
      getSubDeviceLists(){
          axios.request({
                url: '/device/subdevice/query',
                method: 'get',
                module:'SMP',
                params: { 
                    pageSize: this.ipagination.pagesize,
                    pageNo: this.ipagination.current,
                    deviceCode: this.$route.query.deviceCode
                }
            })
            .then(res => {
              if(res.data.code==0){
                if(res.data.data.length==0){
                  this.tips="没有子设备"
                }else{
                  this.ipagination.total = res.data.data.total;
                  this.dataSource = [];
                  var d = new Array();
                  for(var i=0;i<res.data.data.length;i++){
                    d[i]={
                      batchCode:res.data.data[i].batchCode,
                      companyCode:res.data.data[i].companyCode,
                      deviceCode:res.data.data[i].deviceCode,
                      deviceName:res.data.data[i].deviceName,
                      iotId:res.data.data[i].iotId,
                      productCode:res.data.data[i].productCode,
                      registerTime:res.data.data[i].registerTime,
                      status:res.data.data[i].status,
                    }
                  }
                  this.dataSource=d
                }
              } else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
          this.tips=res.data.message
        }
            })
            .catch(err => {
                console.log(err)
            })
      },
      changePage(val){
            this.ipagination.current = val;
            this.getSubDeviceLists()
        },
  },
};
</script>

<style lang="less" scoped>
.child_wrap {
  padding: 4rem;
  
  .status {
    .online {
      color: #00c869;
    }
    .outline {
      color: red;
    }
    .inactive {
      color: #f7941d;
    }
  }
}
</style>