export const FUNCTION_TYPE_PROPERTY = 1;
export const FUNCTION_TYPE_SERVICE = 2;
export const FUNCTION_TYPE_EVENT = 3;

//Property AccessMode
export const PROPERTY_ACCESS_READONLY = 1;
export const PROPERTY_ACCESS_WRITEONLY = 2;
export const PROPERTY_ACCESS_READWRITE = 3;

//property control
export const PROPERTY_CONTROL_ENABLE = 1;
export const PROPERTY_CONTROL_DISABLE = 0;

//property compute
export const PROPERTY_COMPUTE_ENABLE = 1;
export const PROPERTY_COMPUTE_DISABLE = 0;

export const FUNCTION_DATATYPES = {
    enum: "enum",
    boolean: "boolean",
    int32: "int32",
    int64: "int64",
    float: "float",
    double:"double",
    string: "string",
    array: "array",
    object: "object"
}

export const DATATYPE_DESCRIPTION = {
    "boolean": "布尔型",
    "int32": "整形",
    // "int64":"长整形",
    "float": "浮点型",
    // "double":"双精度浮点型",
    "enum": "枚举型",
    "string": "文本型",
    "array": "数组型",
    "object": "对象型"
}

export const ARRAY_EL_DATATYPE_DESCRIPTION = {
    "boolean": "布尔型",
    "int32": "整形",
    // "int64":"长整形",
    "float": "浮点型",
    // "double":"双精度浮点型",
    "enum": "枚举型",
    "string": "文本型",
    // "array":"数组型",
    "object": "对象型"
}

export const getValueSchema = (func) => {
    let valueSchema = func.valueSchema;
    let type = typeof(valueSchema);
    if(type=='string')
    return JSON.parse(valueSchema);
    if(type=='object')
    return valueSchema;
}

export const getDataType = (func) => {
    switch (func.type) {
        case FUNCTION_TYPE_PROPERTY:
            let valueSchema = getValueSchema(func);
            return valueSchema.dataType;
        default:
            return '--';
    }
}

export const getDataTypeDescription = (func) => {
    switch (func.type) {
        case FUNCTION_TYPE_PROPERTY:
            let valueSchema = getValueSchema(func);
            let dataType = valueSchema.dataType;
            let desc = DATATYPE_DESCRIPTION[dataType] || "--";
            return desc;
        default:
            return '--';
    }
}

export const formatFunctionDataTypeDefination = (render, func) => {
    switch (func.type) {
        case FUNCTION_TYPE_PROPERTY:
            let valueSchema = getValueSchema(func);
            return formatPropertyDataTypeDefination(render, valueSchema);
        default:
            return render('div', '--');
    }
}

export const formatPropertyDataTypeDefination = (render, valueSchema) => {
    let dataType = valueSchema.dataType;
    switch (dataType) {
        case FUNCTION_DATATYPES.array:
            return formatArrayDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.boolean:
            return formatBooleanDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.double:
            return formatDoubleDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.enum:
            return formatEnumDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.float:
            return formatFloatDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.int32:
            return formatInt32DataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.int64:
            return formatInt64DataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.object:
            return formatObjectDataTypeDefination(render, valueSchema);
            break;
        case FUNCTION_DATATYPES.string:
            return formatStringDataTypeDefination(render, valueSchema);
            break;
        default:
            return "--";
    }
}

const formatObjectDataTypeDefination = (render,valueSchema)=>{
    let spans = [];
    let accessMode = valueSchema.accessMode;
    if(null!=accessMode){
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");
    
        let desc = `[${accessModeDesc}]对象型`;
        spans.push(render('div', desc));
    }
    let defs = valueSchema.objDef;
    defs.forEach(def => {
        spans.push(render('div', `${def.desc}[${def.key}]:${def.dataType}`));
    });
    if (valueSchema.unit) {
        spans.push(render('div', `单位:${valueSchame.unit}`));
    }
    return render('div', spans);  
}

const formatEnumDataTypeDefination = (render, valueSchema) => {
    let spans = [];
    let accessMode = valueSchema.accessMode;
    let enumType = valueSchema.enumType;
    if(null!=accessMode){
        if(null==enumType){
            enumType='int32';
        }
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");
    
        let desc = `[${accessModeDesc}]${enumType=='int32'?`整形`:(enumType=='float'?`浮点型`:`文本型`)}枚举`;
        spans.push(render('div', desc));
    }
    
    let condition = valueSchema.condition||0;
    if(1==condition){
        let conditions = valueSchema.conditions;
        let idx = 1;
        conditions.forEach(con=>{
            let wheres = con.where;
            let andOr = con.andOr;
            let idx2 = 0;
            let whereString = "当";
            wheres.forEach(wh=>{
                if(idx2>0){
                    whereString+=` ${andOr==1?'和':"或"} `
                }
                whereString+=`[${wh.name} ${wh.comparative=='eq'?'=':(wh.comparative=='lt'?'<':(wh.comparative=='lte')?'<=':(wh.comparative=='gt'?'>':(wh.comparative=='gte'?'>=':"")))} ${wh.value}]`;
                idx2++;
            })
            spans.push(render('div',`约束${idx}:${whereString}`));
            let schema = con.schema;
            let defs = schema.enum;
            if(null!=defs&&Array.isArray(defs)){
                defs.forEach(def => {
                    let key = Object.keys(def)[0];
                    spans.push(render('div', `${key}:${def[key]}`));
                });
            }
            if (schema.unit) {
                spans.push(render('div', `单位:${schema.unit}`));
            }
        });
    }else{
        let defs = valueSchema.enum;
        defs.forEach(def => {
            let key = Object.keys(def)[0];
            spans.push(render('div', `${key}:${def[key]}`));
        });
        if (valueSchema.unit) {
            spans.push(render('div', `单位:${valueSchame.unit}`));
        }
    }
    return render('div', spans);
}

export const getPropertyValueDisplay=(prop)=>{
    let control = prop.control;
    let options = prop.options;
    let value = prop.targetValue;
    if(null==value){
      value = prop.value;
    }
    if(null==value){
      return `设备没有上传`;
    }
    let op = null;
    if(null!=options&&options.length>1){
      op = options.find(option=>{
        let key = Object.keys(option)[0];
        return value.toString()==key;
      })
    }
    if(null==op){
      return value;
    }
    else{
      return Object.values(op)[0];
    }
  }

const formatNumberDefination = (render,valueSchema,numberType)=>{
    let spans = [];
    let accessMode = valueSchema.accessMode;
    if(null!=accessMode){
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");
        let typeString = "";
        switch(numberType){
            case "int32":
                typeString="整形";
                break;
            case `int64`:
                typeString = "长整型";
                break;
            case `float`:
                typeString="浮点型";
                break;
            case `double`:
                typeString="双精度浮点型"
                break;
        }
        let desc = `[${accessModeDesc}]${typeString}`;
        
        spans.push(render('div', desc));
    }
    let condition = valueSchema.condition||0;
    if(1==condition){
        let conditions = valueSchema.conditions;
        let idx = 1;
        conditions.forEach(con=>{
            let wheres = con.where;
            let andOr = con.andOr;
            let idx2 = 0;
            let whereString = "当";
            wheres.forEach(wh=>{
                if(idx2>0){
                    whereString+=` ${andOr==1?'和':"或"} `
                }
                whereString+=`[${wh.name} ${wh.comparative=='eq'?'=':(wh.comparative=='lt'?'<':(wh.comparative=='lte')?'<=':(wh.comparative=='gt'?'>':(wh.comparative=='gte'?'>=':"")))} ${wh.value}]`;
                idx2++;
            })
            let schema = con.schema;
            spans.push(render(`div`,`约束${idx++}:${whereString}`));
            spans.push(render('div', `最小值:${schema.minValue}`));
            spans.push(render('div', `最大值:${schema.maxValue}`));
            spans.push(render('div', `步长:${schema.step}`));
            if (schema.unit) {
                spans.push(render('div', `单位:${schema.unit}`));
            }
        });
    }else{
        spans.push(render('div', `最小值:${valueSchema.minValue}`));
        spans.push(render('div', `最大值:${valueSchema.maxValue}`));
        spans.push(render('div', `步长:${valueSchema.step}`));
        if (valueSchema.unit) {
            spans.push(render('div', `单位:${valueSchema.unit}`));
        }
    }
    return render('div', spans);
}

const formatDoubleDataTypeDefination = (render, valueSchema) => {
   return formatNumberDefination(render,valueSchema,`double`);
}
const formatFloatDataTypeDefination = (render, valueSchema) => {
    return formatNumberDefination(render,valueSchema,`float`);
}
const formatInt32DataTypeDefination = (render, valueSchema) => {
    return formatNumberDefination(render,valueSchema,`int32`);
}
const formatInt64DataTypeDefination = (render, valueSchema) => {
    return formatNumberDefination(render,valueSchema,`int64`);
}
const formatStringDataTypeDefination = (render, valueSchema) => {
    let spans = [];
    if(null!=accessMode){
        let accessMode = valueSchema.accessMode;
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");
        let desc = `[${accessModeDesc}]文本`;
    
        spans.push(render('div', desc));
    }
    spans.push(render('div', `最大长度:${valueSchema.maxLength}`));
    return render('div', spans);
}

const formatBooleanDataTypeDefination = (render, valueSchema) => {
    let spans = [];
    let accessMode = valueSchema.accessMode;
    if(null!=accessMode){
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");

        let desc = `[${accessModeDesc}]布尔值`;
        
        spans.push(render('div', desc));
    }
    let condition = valueSchema.condition||0;
    if(1==condition){
        let conditions = valueSchema.conditions;
        let idx = 1;
        conditions.forEach(con=>{
            let wheres = con.where;
            let andOr = con.andOr;
            let idx2 = 0;
            let whereString = "当";
            wheres.forEach(wh=>{
                if(idx2>0){
                    whereString+=` ${andOr==1?'和':"或"} `
                }
                whereString+=`[${wh.name} ${wh.comparative=='eq'?'=':(wh.comparative=='lt'?'<':(wh.comparative=='lte')?'<=':(wh.comparative=='gt'?'>':(wh.comparative=='gte'?'>=':"")))} ${wh.value}]`;
                idx2++;
            })
            spans.push(render('div',`约束${idx}:${whereString}`));
            let schema = con.schema;
            let defs = schema.boolean;
            defs.forEach(def => {
                let key = Object.keys(def)[0];
                spans.push(render('div', `${key}:${def[key]}`));
            });
            if (schema.unit) {
                spans.push(render('div', `单位:${schema.unit}`));
            }
        });
    }
    else{
        let defs = valueSchema.boolean;
        defs.forEach(def => {
            let key = Object.keys(def)[0];
            spans.push(render('div', `${key}:${def[key]}`));
        });
    }
    return render('div', spans);
}

const formatArrayDataTypeDefination = (render, valueSchema) => {
    let spans=[];
    let accessMode = valueSchema.accessMode;
    if(null!=accessMode){
        let accessModeDesc = accessMode == 1 ? "只读" : (accessMode == 2 ? "只写" : "读写");
        
        spans.push( render("div", `[${accessModeDesc}]数组`));
    }
    let condition = valueSchema.condition||0;
    if(1==condition){
        let conditions = valueSchema.conditions;
        let idx = 1;
        conditions.forEach(con=>{
            let wheres = con.where;
            let andOr = con.andOr;
            let idx2 = 0;
            let whereString = "当";
            wheres.forEach(wh=>{
                if(idx2>0){
                    whereString+=` ${andOr==1?'和':"或"} `
                }
                whereString+=`[${wh.name} ${wh.comparative=='eq'?'=':(wh.comparative=='lt'?'<':(wh.comparative=='lte')?'<=':(wh.comparative=='gt'?'>':(wh.comparative=='gte'?'>=':"")))} ${wh.value}]`;
                idx2++;
            })
            spans.push(render('div',`约束${idx++}:${whereString}`));
            let schema = con.schema;
            let elSchema = schema.elSchema;
            spans.push(render(`div`,`数组长度:${schema.sizeFixed==1?schema.arraySize:`不固定`}`));
            spans.push(render(`div`,`元素类型:${ARRAY_EL_DATATYPE_DESCRIPTION[elSchema.dataType]}`));
            if(isNumber(elSchema)){
                spans.push(render(`div`,`模式:${elSchema.mode==0?"无":(elSchema.mode==1?"递增":"递减")}`));
                if(elSchema.mode!=0){
                    spans.push(render(`div`,`回差:${elSchema.offset}`));
                }
            }
        });
        
    }else{
        let elSchema = valueSchema.elSchema;
        let arraySize = valueSchema.arraySize;
        spans.push(render(`div`,`数组长度:${valueSchema.sizeFixed==1?arraySize:`不固定`}`));
        spans.push(render(`div`,`元素类型:${ARRAY_EL_DATATYPE_DESCRIPTION[elSchema.dataType]}`));
        if(isNumber(elSchema)){
            spans.push(render(`div`,`模式:${elSchema.mode==0?"无":(elSchema.mode==1?"递增":"递减")}`));
            if(elSchema.mode!=0){
                spans.push(render(`div`,`回差:${elSchema.offset}`));
            }
        }
    }
    return render(`div`,spans);
}

export const getPropertyConditionSeqs=(property)=>{
    let valueSchema = property.valueSchema;
    let dataType = valueSchema.dataType;
    let control = valueSchema.control;
    property.seqs = [];
    if(control==1){
        let condition = valueSchema.condition;
        //存在依赖条件
        if(condition==1){
            //取所有condition下的合集
            let conditions = valueSchema.conditions;
            conditions.forEach(con=>{
                let schema = con.schema;
                if(dataType=='boolean'){
                    for(let i in schema.boolean){
                        let io = schema.boolean[i];
                        let codes = Object.keys(io);
                        codes.forEach(code=>{
                            let seq = property.seqs.find(seq=>{
                                    return seq.code == code;
                                });
                            if(null==seq){
                                property.seqs.push({
                                    code,
                                    desc:io[code]
                                });
                            }else{
                                if(seq.desc!=io[code]){
                                    seq.desc = `${seq.desc}|${io[code]}`;
                                }
                            }
                            
                        })
                    }
                    
                    //property.seqs = property.seqs.concat(booleans);
                }
                if(dataType=='enum'){
                    for(let i in schema.enum){
                        let io = schema.enum[i];
                        let codes = Object.keys(io);
                        codes.forEach(code=>{
                            let seq = property.seqs.find(seq=>{
                                    return seq.code == code;
                                });
                            if(null==seq){
                                property.seqs.push({
                                    code,
                                    desc:io[code]
                                });
                            }else{
                                if(seq.desc!=io[code]){
                                    seq.desc = `${seq.desc}|${io[code]}`;
                                }
                            }
                        })
                    }
                    //property.seqs = property.seqs.concat(enums);
                }
            });
        }
        //不存在依赖条件
        else{
            if(dataType=='boolean'){
                for(let i in valueSchema.boolean){
                    let io = valueSchema.boolean[i];
                    let codes = Object.keys(io);
                    codes.forEach(code=>{
                        let seq = property.seqs.find(seq=>{
                                    return seq.code == code;
                                });
                        if(null==seq){
                            property.seqs.push({
                                code,
                                desc:io[code]
                            });
                        }else{
                            if(seq.desc!=io[code]){
                                seq.desc = `${seq.desc}|${io[code]}`;
                            }
                        }
                    })
                }
            }
            if(dataType=='enum'){
                for(let i in valueSchema.enum){
                    let io = valueSchema.enum[i];
                    let codes = Object.keys(io);
                    codes.forEach(code=>{
                        let seq = property.seqs.find(seq=>{
                                    return seq.code == code;
                                });
                        if(null==seq){
                            property.seqs.push({
                                code,
                                desc:io[code]
                            });
                        }else{
                            if(seq.desc!=io[code]){
                                seq.desc = `${seq.desc}|${io[code]}`;
                            }
                        }
                    })
                }
            }
            if(dataType=='int32'){
                let step = parseInt(valueSchema.step);
                let min = parseInt(valueSchema.minValue);
                let max = parseInt(valueSchema.maxValue);

            }
        }
    }
    return property;
}

export const getFunctionPropertyData=(code,thingModel,deviceDatas)=>{
    let modelProps = thingModel.properties;
    let deviceData = deviceDatas[code];
    let modelProp = findThingModelProperty(code,modelProps);
    let required = modelProp.required;
    if(null==required){
        required = 1;
    }
    let ret = {code:-1,message:`没有符合条件的设备属性数据`};
    let valueSchema = null;
    //必选项
    if(1==required){
        valueSchema =  modelProp.valueSchema;
        let isCondition = valueSchema.condition;
        //兼容老的物模型 没有设置condition
        if(null==isCondition){
            isCondition = 0;
        }
        //设置了条件约束
        if(1==isCondition){
            let conditionResult = findCondition(valueSchema.conditions,deviceDatas);
            //符合约束的valueSchema定义
            if(0==conditionResult.code){
                let con = conditionResult.data;
                valueSchema = Object.assign(valueSchema,con.schema);
                ret.code = 0;
                modelProp.valueSchame = valueSchema;
                let options = getPropertyValueOptions(valueSchema);
                // console.log(`options:${JSON.stringify(options)}`)//*
                let data = Object.assign({},{options},{code:modelProp.code,name:modelProp.name},valueSchema,deviceData)
                ret.data = data;
                return ret;
            }
            else{
                return conditionResult;
            }
        }else{
            let options = getPropertyValueOptions(valueSchema);
            let data = Object.assign({},{options},{code:modelProp.code,name:modelProp.name},valueSchema,deviceData)
            return {code:0,data};
        }
    }
    //可选项
    else{
        let dependsResult = isDependsOK(modelProp.depends,deviceDatas);
        //符合依赖条件
        if(dependsResult){
            valueSchema = modelProp.valueSchema;
            let isCondition = valueSchema.condition;
            //兼容老的物模型 没有设置condition
            if(null==isCondition){
                isCondition = 0;
            }
            //设置了条件约束
            if(1==isCondition){
                console.log(`item:${item.code},condition:${item.condition}`)//*
                let conditionResult = findCondition(valueSchema.conditions,deviceDatas);
                //符合约束的valueSchema定义
                if(0==conditionResult.code){
                    let con = conditionResult.data;
                    valueSchema = con.schema;
                    ret.code = 0;
                    modelProp.valueSchame = valueSchema;
                    let options = getPropertyValueOptions(valueSchema);
                    let data = Object.assign({},{options},{code:modelProp.code,name:modelProp.name},valueSchema,deviceData)
                    ret.data = data;
                    return ret;
                }
                else{
                    return conditionResult;
                }
            }else{
                let options = getPropertyValueOptions(valueSchema);
                let data = Object.assign({},{options},{code:modelProp.code,name:modelProp.name},valueSchema,deviceData)
                return {code:0,data};
            }
        }else{
            return {code:-1,message:"约束条件不符合"};
        }
    }
    return ret;
}

export const getPropertyValueOptions=(valueSchema)=>{
    if(null==valueSchema){
        return [];
    }
    let dataType = valueSchema.dataType;
    switch(dataType){
        case `enum`:
            return valueSchema.enum;
        case `boolean`:
            return valueSchema.boolean;
        default:
            return [];
    }
}

/**
 * 判断存在依赖是否符合条件
 * @param {*} depends 
 * @param {*} deviceDatas 
 * @return {code:0/other,message:ErrorMessage,data:dataOfNeeds}
 */
export const isDependsOK = (depends,deviceDatas)=>{
    let conditions = depends.conditions;
    let con = findCondition(conditions,deviceDatas);
    return 0==con.code;
}

export const findCondition=(conditions,deviceDatas)=>{
    for(let i=0;i<conditions.length;i++){
        let condition = conditions[i];
        let andOr = condition.andOr;
        let wheres = condition.where;
        if(checkWheres(andOr,wheres,deviceDatas)){
            return {code:0,data:condition};
        }
    }
    
    return {code:-1,message:'没有找与物模型设置匹配的设备数据'};
}

/**
 * 判断是否符合约束条件
 * @param {*} andOr 
 * @param {*} wheres 
 * @param {*} deviceDatas 
 * @returns true/false
 */
export const checkWheres = (andOr,wheres,deviceDatas)=>{
    let checkFlags = [];
    wheres.forEach(wh=>{
        let code = wh.code;
        let cp = wh.comparative;
        let v = wh.value;
        let deviceData = deviceDatas[code];
        if(null!=deviceData){
        switch(cp){
            case `eq`:
                checkFlags.push(deviceData.value==v);
                break;
            case `neq`:
                checkFlags.push(deviceData.value!=v);
                break;
            case `lt`:
                checkFlags.push(deviceData.value<v);
                break;
            case `lte`:
                checkFlags.push(deviceData.value<=v);
                break;
            case `gt`:
                checkFlags.push(deviceData.value>v);
                break;
            case `gte`:
                checkFlags.push(deviceData.value>=v);
                break;
            default:
                break;
        }
        }
    });
    //and
    let ff = null;
    if(andOr==1){
        checkFlags.forEach(f=>{
            if(null==ff){
                ff = f;
            }else{
                ff = ff && f; 
            }
        })
    }
    else{
        checkFlags.forEach(f=>{
        if(null==ff){
            ff = f;
        }else{
            ff = ff || f; 
        }
        })
    }
    if(null==ff){
        ff = false;
    }
    return ff;
}

export const findThingModelProperty =(code,properties)=>{
    return properties.find(p=>p.code==code);
}

const NumberTypes = ['int32','int64','float','double'];

export const isString = (param) => {
    return param.dataType == 'string';
}
export const isArray = (param) => {
    return param.dataType == 'array';
}
export const isObject = (param) => {
    return param.dataType == "object";
}
export const isEnum = (param) => {
    return param.dataType == 'enum';
}
export const isBoolean = (param) => {
    return param.dataType == 'boolean';
}
export const isNumber = (param) => {
    return NumberTypes.includes(param.dataType);
}
export const isFloat = (param) => {
    return param.dataType == 'float' || this.form.dataType == 'double'
}
export const isInt = (param) => {
    return param.dataType == 'int32'
}
export const isLong = (param) => {
    return param.dataType == 'int64'
}
