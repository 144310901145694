<template>
  <div class="history-wrap">
    <div style="display:flex;justify-content: space-between;">
      <div>
        <Select
          class="white"
          v-model="variable"
          style="width: 20rem; margin-left: 1.5rem"
          clearable
          placeholder="选择属性"
          @on-change="changeVariable"
        >
          <Option v-for="item in variables" :value="item.code" :key="item.code">
            {{ item.name }}
          </Option>
        </Select>
        <Select
          class="white"
          v-model="chooseDay"
          style="width: 20rem; margin-left: 1.5rem"
          clearable
          placeholder="选择时间"
          @on-change="changeDay"
        >
          <Option v-for="item in days" :value="item.code" :key="item.code">
            {{ item.name }}
          </Option>
        </Select>
      </div>
      <div>
        <Button style="margin-left:2rem" type="primary" v-if="dataLoaded">
          <ToExcel :data="CurrentHistoryData" :fields="excelHeaders" :name="deviceCode+dataTypeName+'.xls'" :worksheet="dataTypeName">导出历史数据</ToExcel>
        </Button>
      </div>
    </div>
    <div class="choose" style="margin-top: 3rem">
      <span style="font-size: 2.4rem; color: #222222">图表</span>
      <RadioGroup v-model="showMethod" style="margin-left: 3rem">
        <Radio label="col">
          <span>柱状图</span>
        </Radio>
        <Radio label="line">
          <span>折线图</span>
        </Radio>
      </RadioGroup>
    </div>
    <ColChart
      style="width: 100rem; height: 55rem"
      v-if="showMethod == 'col' && lineShow"
      :Xdata="Xdata"
      :Ydata="Ydata"
    ></ColChart>
    <LineChart
      style="
        width: 100rem;
        height: 55rem;
        margin-left: -5rem;
        margin-top: -5rem;
      "
      v-else-if="showMethod == 'line' && lineShow"
      :Xdata="Xdata"
      :Ydata="Ydata"
    ></LineChart>
    <div class="detailData">
      <p style="font-size: 2.4rem; color: #222222">详细数据</p>
      <div class="detail_table">
        <Table
          stripe
          ref="selection"
          :columns="columns"
          :data="dataSource"
          :no-data-text="'没有数据'"
          :loading="loading"
          class="notLineTable"
        >
        </Table>
        <div class="page flex hcenter">
          <Page
            :total="ipagination.total"
            :page-size="ipagination.pagesize"
            prev-text="上一页"
            next-text="下一页"
            @on-change="changePage"
            show-total
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import * as echarts from "echarts";
import { mapActions, mapGetters, mapState } from "vuex";
import GlobalLayout from "@/components/globalLayout/globalLayout";
import ColChart from "@/components/charts/colChart";
import LineChart from "@/components/charts/LineChart";
import JsonExcel from "vue-json-excel";

export default {
  components: {
    GlobalLayout,
    ColChart,
    LineChart,
    ToExcel:JsonExcel
  },
  data() {
    return {
      historyList: [],
      variables: [],
      variable: "",
      Xdata: [],
      Ydata: [],
      lineShow: false,
      startTime: new Date().getTime() - 24 * 60 * 60 * 1000,
      endTime: new Date().getTime(),
      buttonLists: [],
      days: [
        { code: 1, name: "近24小时" },
        { code: 2, name: "近7日" },
        { code: 3, name: "近30日" },
        { code: 4, name: "近一年" },
      ],
      preDay:null,
      chooseDay: 4,
      showMethod: "col",
      columns: [
        {
          title: "数据",
          key: "info",
          align: "center",
        },
        {
          title: "时间",
          key: "time",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      dataLoaded:false,
      CurrentHistoryData:[],
      dataTypeName:'年数据',
      excelHeaders:{时间:"time"},
      deviceCode:null,
    };
  },
  mounted() {
    this.deviceCode = this.$route.query.deviceCode;
    // this.getDeviceHistory();
    this.getThingModel();
    // console.log(this.$route.query.deviceCode);
    // console.log(this.$route.query.productCode);
    this.buttonLists.push({
      // deviceName: this.$route.query.deviceName,
      deviceCode: this.$route.query.deviceCode,
    });
  },
  methods: {
    getHistory(deviceCode, variable, day) {
      let pageNo = this.ipagination.current;
      if(null!=this.preDay&&this.preDay.code!=day.code){
        pageNo = this.ipagination.current = 1;
      }
      this.preDay = day;
      return new Promise((resolve, reject) => {
        axios
          .request({
            module: "XAIR",
            url: "/device/data/history",
            method: "get",
            params: {
              deviceCode: deviceCode,
              dataType: day,
              pageSize: this.ipagination.pagesize,
              pageNo
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              // console.log(res.data.data)
              let result = res.data.data.data;
              // for (var i = 0; i < res.data.data.length; i++) {
              //   if (
              //     res.data.data[i].deviceCode == this.$route.query.deviceCode
              //   ) {
              //     result.push(res.data.data[i]);
              //   }
              // }
              this.dataSource = [];
              this.ipagination.total = res.data.data.total;
              var d = new Array();
              for (var j = 0; j < result.length; j++) {
                d[j] = {
                  info: result[j].data[variable],
                  time: formatDate(
                    new Date(result[j].time),
                    "yyyy-MM-dd hh:mm"
                  ),
                };
              }
              this.dataSource = d;
              console.log(d)//*
              // console.log(this.dataSource)
              resolve(result);
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getDeviceHistory(deviceCode, variable, day) {
      this.dataLoaded = false;
      let app = this;
      return new Promise((resolve, reject) => {
        axios
          .request({
            module: "XAIR",
            url: "/device/data/history",
            method: "get",
            params: {
              deviceCode: deviceCode,
              dataType: day,
              // pageSize: this.ipagination.pagesize,
              // pageNo: this.ipagination.current,
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              let dateStyle = 'yyyy-MM-dd';
              if(day==1){
                dateStyle = 'yyyy-MM-dd hh'
              }
              switch(day){
                case 1:
                  app.dataTypeName='近24小时数据';
                  dateStyle = 'yyyy-MM-dd hh'
                  break;
                case 2:
                  app.dataTypeName='近7天数据';
                  break;
                case 3:
                  app.dataTypeName='近30天数据';
                  break;
                case 4:
                  app.dataTypeName='近一年数据';
                  break;
                default:
                  break;
              }
              var xData = [],
                yData = [],
                result = res.data.data;
              app.CurrentHistoryData = result.map(d=>{
                let e = {};
                app.variables.forEach(v=>{
                  let code = v.code;
                  let vv = d[code];
                  if(typeof(vv)=='object'){
                    vv = vv.value;
                  }
                  e[code]=vv;
                })
                e.time = formatDate(new Date(d.time),dateStyle)
                return e;
              });
              app.dataLoaded = true;
              var now = new Date();
              now.setMinutes(0);
              now.setSeconds(0);
              now.setMilliseconds(0);
              // console.log(now)
              let singleHour = 60 * 60 * 1000;
              let last = now;
              for (let i = 0; i < result.length; i++) {
                last = last - singleHour;
                xData.push(last);
              }
              let result1 = [];
              resolve(result);
            } else if (res.data.code == 105) {
              this.$Message.warning("系统时间有误，请重设系统时间");
            } else {
              this.$Message.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getThingModel() {
      axios
        .request({
          url: "/product/thingmodel",
          method: "get",
          params: { productCode: this.$route.query.productCode },
        })
        .then((res) => {
          // if(res.data.code==0){
          //   console.log()
          // }
          if (res.data.code == 0 && res.data.data.properties) {
            let result = res.data.data.properties;
            result.map((e) => {
              if (e.valueSchema.compute == 1) {
                this.variables.push(e);
                this.excelHeaders[e.name]=e.code;
              }
            });
            // console.log(result);

            this.variable = this.variables[0].code;
            var xData = [],
              yData = [];
            this.getDeviceHistory(
              this.$route.query.deviceCode,
              this.variable,
              this.chooseDay
            ).then((fruit) => {
              fruit.map((e) => {
                xData.push(formatDate(new Date(e.time), "yyyy-MM-dd hh:mm"));
                yData.push(e[this.variable]);
              });
              this.Xdata = xData;
              this.Ydata.push(yData);
              this.lineShow = true;
            });
            this.getHistory(
              this.$route.query.deviceCode,
              this.variable,
              this.chooseDay
            );
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    showCharts(func){

    },

    changeVariable() {
      // 如果是一个设备，清空历史数据
      if (this.buttonLists.length == 1) {
        this.Ydata = new Array();
      }
      let xData = [],
        yData = [];
        // if(this.dataLoaded){

        // }else{
          this.getDeviceHistory(
            this.$route.query.deviceCode,
            this.variable,
            this.chooseDay
          ).then((result) => {
            // console.log(result);
            result.map((e) => {
              xData.push(formatDate(new Date(e.time), "yyyy-MM-dd hh:mm"));
              yData.push(e[this.variable]);
            });
            this.Ydata.push(yData);
            // console.log(this.Ydata);
            this.lineShow = false;
            setTimeout(() => {
              this.lineShow = true;
            }, 500);
          });
          this.getHistory(
                  this.$route.query.deviceCode,
                  this.variable,
                  this.chooseDay
                );
        // }
    },
    changeDay() {
      // console.log(this.chooseDay)
      this.Xdata = [];
      this.Ydata = [];
      let xData = [],
        yData = [];
      this.getDeviceHistory(
        this.$route.query.deviceCode,
        this.variable,
        this.chooseDay
      ).then((result) => {
        // console.log(result);
        result.map((e) => {
          xData.push(formatDate(new Date(e.time), "yyyy-MM-dd hh:mm"));
          yData.push(e[this.variable]);
        });
        this.Xdata = xData;
        this.Ydata.push(yData);
        // console.log(this.Xdata);
        this.lineShow = false;
        setTimeout(() => {
          this.lineShow = true;
        }, 500);
      });
      this.getHistory(
              this.$route.query.deviceCode,
              this.variable,
              this.chooseDay
            );
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getHistory(
        this.$route.query.deviceCode,
        this.variable,
        this.chooseDay
      );
    },
  },
};
</script>

<style lang="less" scoped>
.history-wrap {
  padding: 4rem;
  .device_history {
    height: 50rem;
  }
  .detailData {
    margin-top: 3rem;
  }
  .detail_table {
    width: 50rem;
    height: 30rem;
    margin-top: 3rem;
    margin-left: 5rem;
  }
}
</style>