const axios = require('axios')
import store from '@/store'
import { getToken, formatDate } from '@/libs/util'
import Constants from './constants'
import Vue from "vue";
// import { Spin } from 'iview'
const addErrorLog = errorInfo => {
  const { statusText, status, request: { responseURL } } = errorInfo
  let info = {
    type: 'ajax',
    code: status,
    mes: statusText,
    url: responseURL
  }
  if (!responseURL.includes('save_error_logger')) store.dispatch('addErrorLog', info)
}

class HttpRequest {
  constructor (baseUrl = baseURL) {
    this.baseUrl = baseUrl
    this.queue = {}
  }
  getInsideConfig (module) {
    let userInfo = Vue.ls.get('userInfo')||{};
    const config = {
      baseURL: this.getBaseURL(module),
      headers: {
        token: userInfo.token,
        timestamp: new Date().getTime(),
        module: module,
        //"Access-Control-Allow-Origin":"*",
        //"Access-Control-Allow-Credentials":true
      }
    }
    return config
  }
  getBaseURL(module){
    let moduleJson=this.baseUrl[module];
    if(moduleJson){
      return moduleJson;
      // return moduleJson.Host+moduleJson.Context;
    }
    return {};
  }
  destroy (url) {
    delete this.queue[url]
    if (!Object.keys(this.queue).length) {
      // Spin.hide()
    }
  }
  interceptors (instance, url,module) {
    // 请求拦截
    instance.interceptors.request.use(config => {
      
      // 添加全局的loading...
      if (!Object.keys(this.queue).length) {
        // Spin.show() // 不建议开启，因为界面不友好
      }
      this.queue[url] = true
      return config
    }, error => {
      return Promise.reject(error)
    })
    // 响应拦截
    instance.interceptors.response.use(res => {
      // console.log(res.data.code);
      // console.log(window.location.pathname);
      // session 过期， 重新登录
      if(res.data.code == Constants.ResponseCode.CODE_NOT_LOGON){
        console.log(`request failure,not logon,message:${res.data.message},url:${url}`);
        window.location.href = '/login'
      }else if(res.data.code ==Constants.ResponseCode.CODE_NO_PERMISSION){
        if(null!=this.app){
          this.app.$Notice.error({
            title:this.title||"错误提示",
            desc:`您没有执行该操作的权限,\nPATH:${url},\nModule:${module}\n 如想执行此操作请联系管理员`
          });
        }
      }else if(res.data.code!=Constants.ResponseCode.CODE_SUCCESS){
        if(null!=this.app){
          this.app.$Notice.error({
            title:this.title||"错误提示",
            desc:`错误信息:${res.data.message},错误码:${res.data.code}`
          });
        }
      }
      this.destroy(url)
      const { data, status } = res
      return { data, status }
    }, error => {
      this.destroy(url)
      let errorInfo = error.response
      if (!errorInfo) {
        const { request: { statusText, status }, config } = JSON.parse(JSON.stringify(error))
        errorInfo = {
          statusText,
          status,
          request: { responseURL: config.url }
        }
      }
      addErrorLog(errorInfo)
      return Promise.reject(error)
    })
  }
  request (options,app,title) {
    const instance = axios.create()
    this.app = app;
    this.title = title;
    let module = options.module;
    if(!module){
      module = "SMP";
    }
    let config = this.getInsideConfig(module);
    options.url = (config.baseURL.Context||"")+options.url;
    options.baseURL = config.baseURL.Host||"";
    options = Object.assign(config, options)
    // console.log("options:"+JSON.stringify(options));
    this.interceptors(instance, options.url,module)
    return instance(options)
  }
}
export default HttpRequest
