<template>
    <div>
        <h1 style="text-align:center;margin-top:20rem">开发中，敬请期待！</h1>
    </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
import  Constants  from "@/libs/constants";
import {iviewMixin} from '@/mixins/iviewMixin';
    export default {
        mixins:[iviewMixin],
        components: {
            GlobalLayout,
        },
        created(){
            this.setPreLoadData(false);
            this.getDeviceErrors();
        },
        mounted:{

        },
        methods:{
            getDeviceErrors(){
                axios.request({
                    url:'/device/errors/query',
                    module:Constants.Modules.SMP,
                    method:'GET',
                    params:{
                        pageSize:this.ipagination.pagesize,
                        pageNo:this.ipagination.current||1,
                    }
                }).then(res=>{
                    //调用成功
                    if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
                        this.wrapPage(res.data.data);
                    }else{
                        this.$Notice.warning({
                            title:`故障管理`,
                            desc:`获取故障清单错误,信息:${res.data.message},请联系维护人员修复!`
                        })
                    }
                });
            }
        }
    }
</script>

<style lang="less" scoped>
@import './warn.less';
</style>