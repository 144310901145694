<template>
    <div class="menu-wrap">
        <ul class="menu">
            <li>
                <router-link to="/admin/datastatitic" class="datastatic">数据统计</router-link>
            </li>
            <li>
                <router-link to="/admin/company" class="company">厂商管理</router-link>
            </li>
            <!-- <li>
                <router-link to="/admin/cost" class="cost">费用管理</router-link>
            </li>
            <li>
                <router-link to="/admin/order" class="order">工单管理</router-link>
            </li> -->
            <li>
                <router-link to="/admin/user" class="user">用户管理</router-link>
            </li>
            <li>
                <router-link to="/admin/register" class="register">注册管理</router-link>
            </li>
            <li>
                <router-link to="/admin/page" class="pages">页面管理</router-link>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="less" scoped>
@import './adminMenu.less';
</style>