<template>
  <GlobalLayout>
    <div class="company-wrap">
      <div class="company_title">厂商管理</div>
      <div class="companyChoose">
        <div class="choose">
          <Input
            style="margin-left: 15px; width: 250px"
            class="white"
            placeholder="请输入厂商名称"
            v-model="companyName"
          />
          <Button
            type="primary"
            style="margin-left: 15px"
            @click="selectCompany()"
            icon="ios-search"
            >搜索</Button
          >
        </div>
        <Button to="/admin/company/add" type="primary" class="createButton" >新建厂商</Button>
      </div>
      <Table
        stripe
        ref="selection"
        :columns="columns"
        :data="dataSource"
        :no-data-text="'没有厂商'"
        :loading="loading"
        class="notLineTable"
      >
        <template slot-scope="{ row, index }" slot="operate">
          <router-link
            :to="'/admin/company/detail?companyCode=' + row.code "
            class="operationTxt"
            >查看详情</router-link
          >
          <!-- <router-link
            :to="'/admin/user?companyCode=' + row.code"
            class="operationTxt"
            >账号
            </router-link
          > -->
          
          <a class="operationTxt" @click="goToUser(row)">账号</a>
          <a class="operationTxt" @click="deleteCompny(row)">删除</a>
        </template>
      </Table>

      <div class="page flex hcenter">
        <Page
          :total="ipagination.total"
          :page-size="ipagination.pagesize"
          prev-text="上一页"
          next-text="下一页"
          @on-change="changePage"
          show-total
        />
      </div>
    </div>
  </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";
import {mutations,mapGetters} from 'vuex'
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      companyList: [],
      columns: [
        {
          title: "厂商代码",
          key: "code",
          align: "center",
        },
        {
          title: "厂商简称",
          key: "shortName",
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "a",
                {

                  on: {
                    click: () => {
                      localStorage.setItem('companyCode',params.row.code)
                      this.setControlMode(true,params.row.code,params.row.shortName);
                        this.$router.push({
                            path:'/control/all/device?companyCode='+params.row.code
                        })
                    },
                  },
                },
                params.row.shortName
              ),
            ]);
          },
        },
        {
          title: "厂商全称",
          key: "fullName",
          align: "center",
        },
        {
          title: "设备总数",
          key: "deviceCount",
          align: "center",
        },
        {
          title: "产品总数",
          key: "productCount",
          align: "center",
        },
        {
          title: "厂商描述",
          key: "remarks",
          align: "center",
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      deviceCount: 0,
      onlineNum: 0,
      groupList: [],
      groupId: "",
      status: "",
      statusList: [
        {
          state: 0,
          value: "离线",
        },
        {
          state: 1,
          value: "在线",
        },
        {
          state: 3,
          value: "未激活",
        },
      ],
      companyName: "",
    };
  },
  computed: {
    ...mapGetters('user',['setControlMode','isControlMode'])
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    getCompanyList(companyName) {
      var data = {
        name: companyName,
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
      };
      axios
        .request({
          module: "SMP",
          method: "get",
          url: "/company/query",
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data);
            let info = [];
            for (var i = 0; i < res.data.data.data.length; i++) {
              info[i] = {
                code: res.data.data.data[i].code, 

                shortName: res.data.data.data[i].shortName,
                fullName: res.data.data.data[i].fullName,
                deviceCount: res.data.data.data[i].deviceCount,
                productCount: res.data.data.data[i].productCount,
                remarks: res.data.data.data[i].remarks,
              };
            }
            this.dataSource = info;
            this.ipagination.total = this.dataSource.length;
            // console.log(this.dataSource)
          } else if(res.data.code==105){
              this.$Message.warning('系统时间有误，请重设系统时间')
            }else{
              this.$Message.error(res.data.message)
                this.dataSource = [];
            }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    selectCompany() {
      this.getCompanyList(this.companyName);
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getDeviceList();
    },
    deleteCompny(row){
      // console.log(row.code)
    },
    goToUser(row){
      localStorage.setItem('companyCode',row.code)
      this.$router.push({path:'/admin/user?companyCode='+row.code})
    },
  },
};
</script>

<style lang="less" scoped>
@import "./company.less";
</style>