<template>
    <GlobalLayout>
        <div class="scene">            
            <h3 class="headline">设备场景</h3>
            <div class="flex hside vcenter" style="margin-bottom:2rem;margin-top:2rem">
              <div class="flex" style="width: 85rem;margin-left:0px">
                      <span style="font-size:1.6rem;">场景名称:</span>
                      <Input 
                          style="margin: 0 2rem;width:25rem"
                          class="white"
                          v-model="queryParam.name"
                          placeholder="请输入场景名称,支持模糊查询"
                      />
                      <span style="font-size:1.6rem;">设备码:</span>
                      <Input 
                          style="margin: 0 2rem;width:25rem"
                          class="white"
                          v-model="queryParam.deviceCode"
                          placeholder="请输入设备码"
                      />
                      <Button type="primary" @click="searchScenes(1)">检索</Button>
              </div>
              <div>
                <Button type="primary" @click="addScene">添加设备场景</Button>
              </div>
            </div>

            <Table stripe ref="sceneTable" :columns="columns" :data="dataSource" :no-data-text="'无场景数据'" :loading="loading">
                <template slot="sceneStatus"  slot-scope="{ row }">
                  <span style="cursor:pointer;color:#069c88;" v-if="row.active==true" @click="cancelScene(row)">开启({{row.ssid}})</span>
                  <span style="cursor:pointer;color:darkgray" v-else @click="activeScene(row)">停止</span>
                </template>
                <template slot="operate"  slot-scope="{ row }">
                  <span style="cursor:pointer;color:blue" @click="editScene(row)">编辑</span>
                  <span style="cursor:pointer;color:blue;margin-left:.5rem" @click="showSceneTaskLog(row)">执行记录</span>
                  <span style="cursor:pointer;margin-left:.5rem;color:red" @click="deleteScene(row.ownerCode,row.policyCode)">删除</span>
                </template>
            </Table>

            <div class="page flex hcenter">
                <Page :total="ipagination.total"  show-total :page-size="ipagination.pagesize"  prev-text="上一页" next-text="下一页" @on-change="searchScenes" />
            </div>

            <Modal v-model="sceneTaskForm.showTasks" :closable="true"  v-if="sceneTaskForm.policy" width="80%"  :footer-hide="true">
              <SceneTask :policy="sceneTaskForm.policy"/>
            </Modal>
            <Modal v-model="sceneForm.showScene" :closable="false" v-if="(sceneForm.edit&&sceneForm.policy)||sceneForm.edit==false" width="700" :footer-hide="true">
              <SceneInfo :policy="sceneForm.policy" :edit="sceneForm.edit" :container="container" />
            </Modal>
        </div>
    </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import SceneTask from '@/views/control/scene/SceneTask'
import SceneInfo from '@/views/control/scene/SceneInfo'
import {iviewMixin} from '@/mixins/iviewMixin';
import axios from '@/libs/api.request'
import { mapState } from 'vuex'
import { formatDate } from '@/libs/util.js'
import { mapGetters } from 'vuex';
import Constants from '@/config/constants'
export default {
    mixins: [iviewMixin],
    components: {
      GlobalLayout,SceneTask,SceneInfo
    },
    props: {

    },
    data() {
        return {
            container:this,
            loading:true,
            url: { 
                list: `/scene/query`,
                module:`SMP`
            },
            companyList:[],
            sceneForm:{
              showScene:false,
              policy:null,
              showTasks:false,
              sceneCode:null,
              edit:false
            },
            sceneTaskForm:{
              policy:null,
              showTasks:false,
              sceneCode:null
            },
            columns:[
                {
                    title: '场景名称',
                    key: 'name',
                    align: 'left',
                },
                {
                    title: '归属人',
                    key: 'ownerCode',
                    align: 'center',
                    render(h,params){
                      let ownerType = params.row.ownerType;
                      let owner = params.row.ownerCode;
                      let ownerName = params.row.ownerName;
                      if(ownerName){
                        owner = ownerName;
                      }
                      let ownerTypeContent = "--";
                      switch(ownerType){
                        case "System":
                          ownerTypeContent = "平台场景";
                          break;
                        case "Wechat":
                          ownerTypeContent = "个人场景";
                          break;
                        case "Personal":
                          ownerTypeContent = "个人场景";
                          break;
                        case "Company":
                          ownerTypeContent = "租户场景";
                          break;
                        default:break;
                      }
                      return h('div',`${owner}(${ownerTypeContent})`);
                    }
                },
                {
                    title: '状态/节点',
                    slot: "sceneStatus",
                    align: 'center',
                    // render:(h,params)=>{
                    //     let active = params.row.active;
                    //     let status = params.row.status;
                    //     let ssid = params.row.ssid;
                    //     if(active){
                    //       return h('span','激活');
                    //     }else{
                    //       let message = '未激活';
                    //       if(ssid=='#'&&status!=0){
                    //         message+='(无可用节点)';
                    //       }
                    //       return h('span',message);
                    //     }
                    // }
                },
                {
                    title: '场景类型',
                    key: 'sceneType',
                    align: 'center',
                    render(h,params){
                      let sceneType = params.row.sceneType;
                      let content = '--';
                      switch(sceneType){
                        case "Timer":
                          content = "定时场景";
                          break;
                        default:
                          break;
                      }
                      return h('span',content);
                    }
                },
                {
                    title: '执行时间',
                    align: 'center',
                    render: (h, params) => {
                        let ssid = params.row.ssid;
                        let triggerCondition = params.row.triggerCondition;
                        let source = triggerCondition.source;
                        let weekDays = source.weekDays;
                        let hour = source.hour;
                        let minute = source.minute;
                        let seconds = source.seconds;
                        let weekContent = weekDays.map(w=>{
                          switch(w){
                            case 2:
                              return `周一`;
                            case 3:
                              return `周二`;
                            case 4:
                              return `周三`;
                            case 5:
                              return `周四`;
                            case 6:
                              return `周五`;
                            case 7:
                              return `周六`;
                            case 1:
                              return `周日`;
                          }
                        }).join(`,`);
                        let timeContent = `${hour}:${minute}`
                        return h('div',
                          [
                            h(`div`,weekContent),
                            h(`div`,timeContent)
                          ]
                        )
                    }
                },
                {
                    title: '上次启动时间',
                    key: 'startTime',
                    align: 'center',
                    render: (h, params) => {
                        return h('div',
                            formatDate(new Date(params.row.startTime), 'yyyy-MM-dd hh:mm')
                        )
                    }
                },
                {
                    title: '上次停止时间',
                    align: 'center',
                    render: (h, params) => {
                      let cancelTime = params.row.cancelTime;
                      if(cancelTime){
                        return h('div',
                            formatDate(new Date(params.row.cancelTime), 'yyyy-MM-dd hh:mm')
                        )
                      }else{
                        return h('div', "--")
                      }
                    }
                },
                {
                    title: '创建时间',
                    key: 'createTime',
                    align: 'center',
                    render: (h, params) => {
                        return h('div',
                            formatDate(new Date(params.row.createTime), 'yyyy-MM-dd hh:mm')
                        )
                    }
                },
                {
                    title: '操作',
                    slot: 'operate',
                    align: 'center'
                },
            ]
        };
    },
    computed: {
        ...mapGetters('user',['hasRight','isAdmin','isCompanyUser','isGroupUser','getGroup','isPerson','getCompanyCode']),
    },
    created() {
        this.setPreLoadData(true);
    },
    mounted() {
      this.queryParam.deviceCode = this.$route.query.deviceCode;
      if(this.isGroupUser() ){
          
      }
    },
    watch: {

    },
    methods: {
        activeScene(policy){
          axios.request({
            url:"/scene/active",
            method:"POST",
            module:"SMP",
            data:{ownerCode:policy.ownerCode,policyCode:policy.policyCode}
          }).then(res=>{
            if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
              this.searchScenes();
            }
          })
        },
        cancelScene(policy){
          axios.request({
            url:"/scene/cancel",
            method:"POST",
            module:"SMP",
            data:{ownerCode:policy.ownerCode,policyCode:policy.policyCode}
          }).then(res=>{
            if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
              this.searchScenes();
            }
          })
        },
        cancelPolicyForm(){
          this.sceneForm.showScene = false;
        },
        resetSceneForm(){
          this.sceneForm={
            target:{deviceTargets:[]},
            trigger:{},
            edit:false,
            statusBoolean:true,
            name:null,

          }
        },
        addScene(){
          //this.resetSceneForm();
          this.sceneForm.edit = false; 
          this.sceneForm.showScene = true;
        },
        editScene(policy){
          this.sceneForm.showScene = true;
          this.sceneForm.edit = true;
          this.sceneForm.policy = policy;
        },
        showSceneTaskLog(policy){
          this.sceneTaskForm.policy = policy;
          this.sceneTaskForm.showTasks=true;
        },
        deleteScene(ownerCode,policyCode){
          axios.request({
            url:"/scene/delete",
            method:"post",
            module:"SMP",
            data:{ownerCode,policyCode}
          }).then(res=>{
            if(Constants.ResponseCode.CODE_SUCCESS == res.data.code){
              this.$Notice.success({
                title:"删除设备场景",
                desc:'删除设备场景完成'
              });
              this.searchScenes(this.ipagination.current);
            }else{
              this.$Notice.error({
                title:"删除设备场景",
                desc:"删除设备场景失败,信息:"+res.data.message
              });
            }
          })
        },
        searchScenes(pageNo){
          if(!pageNo){
            pageNo = this.ipagination.current||1;
          }
          let params = {pageSize:10,pageNo};
          this.loading = true;
          params = Object.assign(params,this.queryParam);
          axios.request({
            url:this.url.list,
            module:"SMP",
            method:'GET',
            params
          }).then(res=>{
            this.loading = false;
            if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
              let result = res.data.data;
              this.ipagination.total = result.total
              this.dataSource = result.data;
            }
            else{
              this.$Notice.warning({
                title:"查询设备场景",
                desc:"查询设备场景失败,信息:"+res.data.message
              });
            }
          });
        }
    },
};
</script>

<style scoped lang="less" rel="stylesheet/less">
@import './less/Scene.less';
</style>
