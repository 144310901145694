<template>
  <div class="scene">            
      <h3 class="headline">场景执行记录</h3>
      <div class="flex hside vcenter" style="margin-bottom:20px;margin-top:20px">
        <div class="flex" style="width: 700px;margin-left:0px">
          <span style="font-size:20px">场景名:{{null!=this.policy?policy.name:""}}</span>
        </div>
      </div>

      <Table stripe ref="TaskTable" :columns="columns" :data="dataSource" :no-data-text="'没有数据显示'" :loading="loading">
        <template slot="deviceLogs" slot-scope="{ row, index }">
          <table width="100%" cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr style="backgroup-color:rgba(26, 25, 25, 0.02);height:20px">
                  <td style="height:20px"><b>设备码</b></td>
                  <td><b>设备名称(归属人)</b></td>
                  <td><b>设备功能</b></td>
                  <td><b>设备状态(执行时)</b></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item,_id) in row.tasks" :key="_id">
                <td>{{item.deviceCode}}</td>
                <td>{{item.bindingName}}</td>
                <td>{{item.functionCode}}:{{item.functionValue}}</td>
                <td>{{item.status==0?"离线":"在线"}}</td>
              </tr>
            </tbody>
          </table>
        </template>
      </Table>

      <div class="page flex hcenter">
          <Page :total="ipagination.total"  show-total :page-size="ipagination.pagesize"  prev-text="上一页" next-text="下一页" @on-change="changePage" />
      </div>
  </div>
</template>

<script>
import {iviewMixin} from '@/mixins/iviewMixin';
import axios from '@/libs/api.request'
import { mapState } from 'vuex'
import { formatDate } from '@/libs/util.js'
import { mapGetters } from 'vuex';
import Constants from '@/config/constants'
export default {
    mixins: [iviewMixin],
    components: {
      
    },
    props: {
      policy:Object
    },
    data() {
        return {
            container:this,
            url: {
                list: `/scene/tasks/query`,
                module:'SMP'
            },
            companyList:[],
            columns:[
                 {
                    title: '执行时间',
                    align: 'left',
                    width:200,
                    render: (h, params) => {
                        return h('div',
                            formatDate(new Date(params.row.createTime), 'yyyy-MM-dd hh:mm')
                        )
                    }
                },
                {
                    title: '设备执行记录',
                    align: 'left',
                    slot: 'deviceLogs',
                    renderHeader(h,params){
                      return h('div',[h('div',"设备执行记录")]);
                    }
                },
            ]
        };
    },
    computed: {
       
        ...mapGetters('user',['hasRight','isAdmin','isCompanyUser','isGroupUser','getGroup','isPerson','getCompanyCode']),
    },
    created() {
        // this.columns[2].children = this.deviceTaskColumns;
        this.setPreLoadData(false);
    },
    mounted() {
        if(null!=this.policyCode){
          this.queryParam.policyCode = this.policyCode;
          this.loadData(1);
        }
       
    },
    watch: {
      policy:{
        handler:function(newValue,oldValue){
          this.policy = newValue;
          this.queryParam.policyCode = this.policy.policyCode;
          console.log(`policyCode:${newValue.policyCode}`)//*
          this.loadData(1);
        },
        immediate:true
      }
    },
    methods: {
        loadSceneInfo(){
          axios.request({
            url:"/scene/info",
            module:"SMP",
            method:"GET",
            params:{policyCode:this.policyCode}
          }).then(res=>{
            if(Constants.ResponseCode.CODE_SUCCESS == res.data.code){
              let scene = res.data.data;
              Object.assign(this.sceneTaskForm,scene);
              this.sceneTaskForm.sceneName = scene.name;
            }
          });
        }
    },
};
</script>

<style scoped lang="less" rel="stylesheet/less">
@import './less/Scene.less';
</style>
