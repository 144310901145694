const randomString=(e)=> {
  e = e || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
  a = t.length,
  n = "";
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
  return n
}
const stKey = "XAIR_WS_TAGS";

function WebsocketInstance(){
  this.connected=false;
  this.tags=[];
  this.ws=null;
  this.token=null;
  this.expireTime=null;
  this.tokenGetter=null;
  this.urlGetter = null;
  this.listeners={};
  this.getTags=()=>{
    let result = [];
    let storageTagsString = localStorage.getItem(stKey);
    console.log(`storageTagsString:${storageTagsString}`);//*
    if(null!=storageTagsString){
      let stTags = JSON.parse(storageTagsString);
      console.log(`stTags:${stTags}`);//*
      return stTags;
    }
    return result;
  };
  this.stTag=(tag)=>{
    
    let storageTagsString = localStorage.getItem(stKey);
    let stTags = [];
    if(null!=storageTagsString){
      stTags = JSON.parse(storageTagsString);
      if(!stTags.includes(tag)){
        stTags.push(tag);
      }
    }
    localStorage.setItem(stKey,JSON.stringify(stTags));
  }
  this.stDeTag=(tag)=>{
    let storageTagsString = localStorage.getItem(stKey);
    if(null!=storageTagsString){
      let stTags = JSON.parse(storageTagsString);
      let index =  stTags.indexOf(tag);
      if(index>=0){
        stTags.splice(index,1);
      }
      localStorage.setItem(stKey,JSON.stringify(stTags));
    }
  }
  this.createWebsocket=(url,token)=>{
    if(null!=this.ws){
      this.connected = true;
      this.freshOn();
      return this.ws;
    }
    try {
      if ('WebSocket' in window) {
          this.ws =  new WebSocket(url,token);
          console.log(`Create Websocket instance:${JSON.stringify(this.ws)}`);
      } else if ('MozWebSocket' in window) {
          this.ws =  new MozWebSocket(url,token);

      } else {
        console.error("The current Browser not support websocket")
      }
      if(null!=this.ws){
        this.freshOn();
      }
    } catch (e) {
      console.error(e);
    }
    return this;
  };
  this.on=(event,func)=>{
    let _func = (null!=func&&(func instanceof Function))?func:(data)=>{}
    this.listeners[event] = _func;
    this.freshOn();
    return this;
  };
  this.freshOn=()=>{
    if(null!=this.ws){
      this.ws.onopen = this.onOpen(); //连接建立时触发
      this.ws.onmessage = this.onMessage(); //客户端接受服务端数据时触发
      this.ws.onerror = this.onError(); //通信发生错误时触发
      this.ws.onclose = this.onClose(); // 连接关闭时触发
    }
  };
  this.reconnect=()=>{
    console.log("Begin to reconnect Websocket");
    if(null!=this.tokenGetter&&null!=this.urlGetter){
      if(this.tokenGetter instanceof Function && this.urlGetter instanceof Function){
        this.tokenGetter((token)=>{
          this.token = token;
          //token有效时间2分钟 设置超时时间110秒 确保token有效
          this.expireTime = new Date().getTime()+110*1000;
          this.createWebsocket(this.urlGetter(),token);
          this.freshOn();
        })
      }else{
        console.warn(`reconnect break,cause:urlGetter or tokenGetter is not a Function`);
      }
    }else{
      console.warn(`reconnect break,cause:urlGetter or tokenGetter not setted`);
    }
    return;
  }
  this.onOpen=()=>{
    
    let func = this.listeners['open']||(()=>{
      console.log(`websocket open`);//*
    });
    return (message)=>{
      this.connected = true;
      func(message)
    };
  };
  this.onMessage=()=>{
      let func = this.listeners['message']||((message)=>{});
      return func;
  };
  this.onError=()=>{
    return (err)=>{
      this.connected = false;
      let func = this.listeners['error']||((err)=>{});
      func(err);
     
    }
  };
  this.onClose=()=>{
    return (data)=>{
      this.ws = null;
      this.connected=false;
      let func = this.listeners['close']||((data)=>{});
      func(data);
      setTimeout(() => {
        this.reconnect();
      }, 10000);
    }
  };
  this.isConnected=()=>{
    let result =  (null!=this.ws)&&this.connected;
    return result;
  };
  this.deTagAll=()=>{
    this.deTagDevice(this.getTags());
  };
  this.deTagDevice=(deviceCodes)=>{
    if(this.isConnected()){
      deviceCodes.forEach(deviceCode=>{
        this.stDeTag(deviceCode);
      })
      let requestId = randomString(16);
      let message = {
        "target":"device@detag",
        requestId,
        "data":{deviceCodes}
      }
      this.ws.send(JSON.stringify(message));
      return requestId;
    }else{
      console.error(`the websocket instance not created or disconnect`)
    }
  };
  this.tagDevice=(...deviceCodes)=>{
    if(this.isConnected()){
      deviceCodes.forEach(deviceCode=>{
        this.initDevice(deviceCode);
      })
    }
  };
  this.initDevice=(deviceCode)=>{
    if(this.isConnected()){
      let requestId = randomString(16);
      let message = {
        "target":"device@init",
        requestId,
        "data":{
          deviceCode
        }
      }
      
      this.stTag(deviceCode);
      this.tags.push(deviceCode);
      this.ws.send(JSON.stringify(message));
      return requestId;
      
    }else{
      console.error();
    }
    return null;
  }
};
export let Websocket = new WebsocketInstance();
export let create = (url,token)=>{
  let instance = new WebsocketInstance();
  return instance.createWebsocket(url,token);
}
