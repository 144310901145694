<template>
    <div class="c-wrap-block borderbox">
        <div class="flex vcenter ">                
            <div class="back pointer" @click="back()"></div> 
            <h3 class="headline" > 
                {{title}}
            </h3>
        </div>

        <Form ref="policyForm" :rules="policyRules" :model="policyForm"  style="width: 650px;margin-top: 40px;" :label-width="120">
            <FormItem label="场景码" prop="policyCode" v-if="edit">
                <Input class="dark" v-model="policyForm.policyCode" :disabled="true" placeholder=""></Input>
            </FormItem>
            <FormItem label="场景名称" prop="name" required>
                <Input class="dark" v-model="policyForm.name" placeholder="输入场景名称"></Input>
            </FormItem>
            <FormItem label="是否开启" prop="status">
                <i-switch v-model="policyForm.statusBoolean" size="large">
                  <span slot="open">开启</span>
                  <span slot="close">关闭</span>
                </i-switch>
            </FormItem>
            <FormItem label="场景类型" prop="sceneType" required>
                <Select  v-model="policyForm.sceneType" placeholder="选择场景类型">
                    <Option value="Timer">定时</Option>
                </Select>
            </FormItem>
            <div ref="timerCondition" v-if="policyForm.sceneType=='Timer'">
              <FormItem label="执行条件" required>
                  <div class="flex">
                    <div style="width:70px;">
                      每周:
                    </div>
                    <Select multiple v-model="trigger.weekDays" style="margin-left:9px">
                        <Option :value="2">周一</Option>
                        <Option :value="3">周二</Option>
                        <Option :value="4">周三</Option>
                        <Option :value="5">周四</Option>
                        <Option :value="6">周五</Option>
                        <Option :value="7">周六</Option>
                        <Option :value="1">周日</Option>
                    </Select>
                  </div>
                  <div style="margin-top:20px;" class="flex">
                    <div style = "width:70px;">执行时间:</div>
                    <Time-picker v-model="trigger.time" confirm placeholder="选择时间" style="width: 168px"></Time-picker>
                  </div>
                
              </FormItem>
            </div>
            <FormItem label="执行设备" prop="target" required>
                <div v-for="(device,index) in target.deviceTargets" :key="index" style="margin-top:4px">
                    <Select v-model="target.deviceTargets[index].deviceCode" 
                      filterable 
                      @on-query-change="(query)=>{loadValidDevices(index,query)}" 
                      @on-change="selectDevice(index)"  
                      style="width:25rem" 
                      placeholder="输入设备码查询(支持模糊查询)">
                      <Option v-for="(device,_idx) in target.deviceTargets[index].validDevices" :key="_idx" :value="device.deviceCode||''">
                        {{device.deviceName||""}}
                      </Option>
                    </Select>
                    <Select v-if="target.deviceTargets[index].deviceCode" v-model="target.deviceTargets[index].functionCode" style="width:110px;margin-left:5px" placeholder="选择功能名称" @on-change="(functionCode)=>selectProperty(index,functionCode)">
                      <Option v-for="(func,_idx2) in target.deviceTargets[index].properties" :key="_idx2" :value="func.code">{{func.name}}</Option>
                    </Select>
                    <InputFunctionValue v-if="null!=target.deviceTargets[index].property" style="width:120px;margin-left:5px" :property ="target.deviceTargets[index].property" v-model="target.deviceTargets[index].functionValue" />
                  <Icon style="cursor:pointer;" type="md-remove-circle" @click="deleteTargetDevice(index)"/>
                </div>

                <div>
                  <span style="cursor:pointer;color:blue" @click="preAddDevice"><Icon type="md-add-circle" size="large"/>添加设备</span>
                </div>
            </FormItem>
            <FormItem>
                <div v-if="isAdmin()||isCompanyUser()">
                    <Button size="large" style="width: 180px;" @click="cancel('policyForm')">取消</Button>  
                    <Button type="primary" size="large" style="width: 180px;margin-left:20px" @click="savePolicy('policyForm')">保存</Button>     
                </div>                 
            </FormItem>

        </Form>
    </div>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from '@/libs/api.request'
import { mapState } from 'vuex'
import { mapGetters } from 'vuex';
import Constants from '@/config/constants'
import InputFunctionValue from '@/components/function/InputFunctionValue'

export default {
    components: {
        GlobalLayout,InputFunctionValue 
    },
    props: {
        container:Object,
        edit:{type:Boolean,default:false},
        policy:Object
    },
    data() {
        return {
            thingModelMap:{},
            validDevices:[],
            trigger:{
              time:null,
              weekDays:[]
            },
            policyCode:null,
            targetForm:{
              currentQuery:null,
              function:null,
              showAdd:false,
              thingModelLoaded:false,
              property:null,
              properties:[],
              currentIndex:0
            },
            target:{
              deviceTargets:[]
            },
            policyForm: {
              sceneType:'Timer',
              statusBoolean:false,
              name:null,
              target:null,
              triggerCondition:null,
            },
            policyRules: {
                name: [{ trigger: 'blur',validator(rule,value,callback){
                  if(!value){
                    callback(new Error("请输入场景名称"));
                  }else if(value.length>20||value.length<2){
                    callback(new Error("场景名称长度为2-20字符"));
                  }else{
                    callback();
                  }
                } }],
                sceneType:[{required: true,  message:'场景类型必须选择', trigger: 'blur' }],
                target:[{validator:(rule,value,callback)=>{
                  if(this.policyForm.target){
                    callback();
                  }else{
                    callback(new Error("执行设备没有正确设置"));
                  }
                  
                }}]
            }
        };
    },
    computed: {
        title:function(){
            return !this.edit?"添加设备场景":"编辑设备场景"
        },
        ...mapGetters('user',['hasRight','isAdmin','isCompanyUser','isGroupUser','getGroup','isPerson','getCompanyCode']),
    },
    created() {
    },
    mounted() {
    },
    watch: {
      policy:{
        handler:async function(newValue,oldValue){
         
          if(null==newValue){
            
          }else{
            this.target = {deviceTargets:[]};
            this.policyCode = newValue.policyCode;
          }
          if(null!=this.policyCode){
            await this.loadPolicy(this.policyCode);
          }
        },
        //deep:true,
        immediate:true
      },
      edit:async function(newStatus,oldStatus){
          this.edit = newStatus;
          if(this.edit==false){
            this.$refs['policyForm'].resetFields();
            this.resetPolicyForm();
          }else{
            if(null!=this.policyCode){
              await this.loadPolicy(this.policyCode);
            }
          }
      }
    },
    methods: {
        resetPolicyForm(){
          this.policyForm= {
              sceneType:'Timer',
              statusBoolean:false,
              name:null,
              target:null,
              triggerCondition:null,
            }
          this.target = {
              deviceTargets:[]
            };
          this.trigger = {
              time:null,
              weekDays:[]
            }
        },
        async loadPolicy(policyCode){
          let res = await axios.request({
            url:"/scene/info",
            method:"GET",
            module:"SMP",
            params:{policyCode}
          });
          if(res.data.code == Constants.ResponseCode.CODE_SUCCESS){
            this.initializePolicy(res.data.data);
          }
        },
        async initializePolicy(policyData){
          this.policyForm= Object.assign({},policyData);
          this.policyForm.statusBoolean = this.policyForm.status==1?true:false;
          this.target = policyData.target;
          
          this.trigger = policyData.triggerCondition;
          let triggerSource = policyData.triggerCondition.source;
          this.trigger.weekDays = triggerSource.weekDays;

          if(this.target.deviceTargets&&this.target.deviceTargets.length>1){
            this.target.deviceTargets.sort((t1,t2)=>{
              let f1 = t1.functionCode;
              let f2 = t2.functionCode
              if(f1>f2){
                return 1;
              }else if(f1<f2){
                return -1;
              }
              return 0;
            });
          }

          this.trigger.time = `${triggerSource.hour}:${triggerSource.minute}:${triggerSource.seconds}`;
          for(let index in this.target.deviceTargets){
            await this.loadValidDevices(index,this.target.deviceTargets[index].deviceCode);
            this.$forceUpdate();
            //抓取物模型
            await this.selectDevice(index);
            this.$forceUpdate();
            this.selectProperty(index,this.target.deviceTargets[index].functionCode);
            let functionValue = this.target.deviceTargets[index].functionValue;
            //触发InputFunctionValue双向绑定
            this.target.deviceTargets[index].functionValue = functionValue;
          }
        
        },
        deleteTargetDevice(index){
          if(this.target.deviceTargets.length>index){
            this.target.deviceTargets.splice(index,1);
          }
        },
        findProperty(index,functionCode){
          for(let i in this.target.deviceTargets[index].properties){
            let prop = this.target.deviceTargets[index].properties[i];
            if(prop.code==functionCode){
              return prop;
            }
          }
          
        },
        selectProperty(index,functionCode){
          this.target.deviceTargets[index].functionCode = functionCode;
          let prop =  this.findProperty(index,functionCode);
          if(null!=prop){
            this.target.deviceTargets[index].property = prop;
            //this.$forceUpdate();
          }
        },
        async findThingModel(productCode){
          let thingmodel = this.thingModelMap[productCode] ;
          if(!thingmodel){
           let res = await axios.request({
              url:"/product/thingmodel",
              method:"GET",
              module:"SMP",
              params:{productCode}
            });
            if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
              thingmodel = res.data.data;
              this.thingModelMap[productCode] = thingmodel;
            }
          }
          return thingmodel;
        },
        async selectDevice(index){
          let deviceCode = this.target.deviceTargets[index].deviceCode;
          let device = this.findDevice(index,deviceCode);
          if(null!=device){
            this.target.deviceTargets[index].device = device;
            
            let productCode = device.productCode;
            //赋值产品码
            this.target.deviceTargets[index].productCode = productCode;
            let thingModel = await this.findThingModel(productCode);
            if(null!=thingModel){
              this.targetForm.thingModelLoaded=true;
              this.target.deviceTargets[index].properties = thingModel.properties.filter(prop=>{return prop.valueSchema.control==1});
            }
          }
        },
        findDevice(index,deviceCode){
          let fil = this.target.deviceTargets[index].validDevices.filter(device=>{
            return device.deviceCode = deviceCode;
          });
          if(fil&&fil.length>0){
            return fil[0]
          }
          return null;
        },
        preAddDevice(){
          // this.targetForm.showAdd = true;
         
          this.target.deviceTargets.push({properties:[],validDevices:[]});
        },

        async loadValidDevices(index,_query){
          let query = null!=_query?_query.trim():"";
          if(!query||query.length<4){
            this.$Notice.info({
              title:"提示",
              desc:"至少输入四个字符检索设备"
            })
            return;
          }

          let res = await axios.request({
            url:"/device/query/unpage",
            method:"GET",
            module:"SMP",
            params:{
              query_names:query,
            }
          });
          
          if(Constants.ResponseCode.CODE_SUCCESS == res.data.code){
            if(null!=res.data.data){
              this.target.deviceTargets[index].validDevices = res.data.data;
              //this.$forceUpdate();
            }
          }
          return res;
        },
        cancel(form){
            //this.$refs[form].resetFields();

            this.container.cancelPolicyForm();
        },
        validateAndGetTriggerCondition(){
          let time = this.trigger.time;
          if(!time||time.trim().length==0){
            return null; 
          }
          let timeSettings = time.split(':');
          let hour = parseInt(timeSettings[0]);
          let minute = parseInt(timeSettings[1]);
          let seconds = parseInt(timeSettings[2]);
          let weekDays = this.trigger.weekDays;
          if(null==weekDays||weekDays.length==0){
            return null;
          }
          weekDays.sort((a,b)=>{
            return a-b;
          });
          return {weekDays,hour,minute,seconds}
        },
        validateAndGetTarget(){
          let devices = [];
          this.target.deviceTargets.forEach(t=>{
            if(t.deviceCode&&t.productCode&&t.functionCode&&null!=t.functionValue){
              devices.push({deviceCode:t.deviceCode,productCode:t.productCode,functionCode:t.functionCode,functionValue:t.functionValue});
            }
          });
          if(devices.length==0){
            
            return null;
          }
          return {deviceTargets:devices};
        },
        savePolicy(form){
          let triggerCondition = this.validateAndGetTriggerCondition();
          if(null==triggerCondition){
            this.$Notice.warning({
              title:this.title,
              desc:"执行条件没有设定完成,请先选择执行条件"
            });
            return;
          }
          this.policyForm.triggerCondition = triggerCondition;
          let target = this.validateAndGetTarget();
          if(null==target){
            this.$Notice.warning({
                title:this.title,
                desc:"执行设备没有设定完成,请先设定执行设备"
            });
            return;
          }
          this.policyForm.target = target;
          this.policyForm.status = this.policyForm.statusBoolean?1:0;
           if( this.$refs['policyForm'].validate(valid=>{
             if(valid){
               axios.request({
                 url:"/scene/setting",
                 method:"POST",
                 module:"SMP",
                 data:this.policyForm
               }).then(res=>{
                 if(Constants.ResponseCode.CODE_SUCCESS==res.data.code){
                   this.$Notice.success({
                     title:this.title,
                     desc:`${this.title}完成`
                   });
                   this.cancel('policyForm');
                   this.container.searchScenes();
                 }else{
                   this.$Notice.error({
                     title:this.title,
                     desc:`${this.title}失败,信息:${res.data.message}`
                   });
                 }
               })

             }
           }));
        }
    },
    
};
</script>

<style scoped lang="less" rel="stylesheet/less">
</style>
