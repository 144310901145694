<template>
    <div>
        <div class="deviceinfo">
            <h4>设备微信信息</h4>
            <div class="list flex">
                <ul>
                    <li>
                        <span class="label">微信服务类型</span>
                        <!-- <span class="cont">{{deviceInfo.productName}}</span> -->
                        <span class="cont">{{deviceWechatInfo.serviceTypeString}}</span>
                    </li>
                    
                    <li>
                        <span class="label144">{{deviceWechatInfo.serviceTypeString}}{{deviceWechatInfo.codeTypeString}}</span>
                        <div style="display:flex;margin-bottom:0">
                            <div>
                                <img v-if="deviceWechatInfo.codeType=='suncode'" :src="deviceWechatInfo.wechatSuncodeCodeUrl"/>
                                <vue-qr v-if="deviceWechatInfo.codeType=='qrcode'" :text="deviceWechatInfo.miniappQRCode" :size="150"></vue-qr>
                            </div>
                            <div style="display:inline;margin-top:7.5rem">
                                <div style="height:40%;margin-bottom:0px;text-align:center">
                                    <a v-if="null!=deviceWechatInfo.wechatSuncodeSourceUrl&&deviceWechatInfo.wechatSuncodeSourceUrl.length>0" :href="deviceWechatInfo.wechatSuncodeSourceUrl" target="_wechatSuncodeSourceUrl">原图</a>
                                </div>
                                <div style="margin-bottom:0px;">
                                    <Button @click="goRefreshDeviceWechat" type="info" size="small">刷新</Button>
                                </div>
                            </div>
                        </div>
                    </li>
                   
                </ul>
                <ul>
                   <li>
                        <span class="label">微信设备码类型</span>
                        <span class="cont" v-if="deviceWechatInfo.codeType == 'qrcode'">{{deviceWechatInfo.serviceTypeString}} 二维码 </span>
                        <span class="cont" v-else-if="deviceWechatInfo.codeType == 'suncode'">小程序太阳码 </span>
                        <span class="cont" v-else> 无 </span>
                    </li>
                     <li>
                        <span class="label144">客人码</span>
                        <span>
                           <img width="150" height="150" :src="deviceWechatInfo.wechatSuncodeCodeGuestUrl"/>
                            <vue-qr v-if="deviceWechatInfo.guestCodeType=='qrcode'" :text="deviceWechatInfo.miniappGuestQRCode" :size="150"></vue-qr>
                        </span>
                    </li>
                   
                    
                </ul>
               <ul>
                   <li>
                        <span class="label">微信账号</span>
                        <span class="cont"> {{null!=deviceWechatInfo.wechatApp?deviceWechatInfo.wechatApp.appName:"--"}} </span>
                    </li>
                    <li>
                        <span class="label">AppID</span>
                        <span class="cont">
                            {{null!=deviceWechatInfo.wechatApp?deviceWechatInfo.wechatApp.appId:"--"}}
                        </span>
                    </li>
                    <li>
                        <span class="label">使用场景</span>
                        <span class="cont">
                            {{null==deviceWechatInfo.wechatSetting?"无法获取设定场景"
                                :(1==deviceWechatInfo.wechatSetting.sceneType?"私用"
                                :(2==deviceWechatInfo.wechatSetting.sceneType?"公用"
                                :(3==deviceWechatInfo.wechatSetting.sceneType?"商用"
                                :`未知类型(${deviceWechatInfo.wechatSetting.sceneType})`)))}}
                            <Button type="info" size="small" @click="toTransferSceneType">转换</Button>
                        </span>
                    </li>
                    <li>
                        <span class="label">设备主人</span>
                        <span class="cont">
                            {{deviceWechatInfo.hasMaster?`${
                                (null!=deviceWechatInfo.master.nickName
                                &&deviceWechatInfo.master.nickName.length>0)?deviceWechatInfo.master.nickName:'未授权获取'
                            }`:"无"}}
                        </span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="deviceinfo">
            <h4>使用人清单</h4>
            <Table
                stripe ref="DeviceUsersTable"
                :columns="columns"
                :data="dataSource"
                :no-data-text="'没有数据显示'"
                :loading="loading"
            >
                <template slot-scope="{ row, index }" slot="operate">
                    <span class="pointer" @click="guestControlSetting(row,row.guestControl)" v-if="row.master==false">{{row.guestControl?"取消授权":"授权"}}</span>
                    <span class="pointer" style="margin-left: .5rem" @click="toBeMaster(row)" v-if="row.master==false">置为主人</span>
                    <span class="pointer" style="margin-left: .5rem" @click="debindDevice(row)">解绑</span>
                
                </template>
            </Table>
            <div class="page flex hcenter">
                <Page :total="ipagination.total" :page-size="ipagination.pagesize"  prev-text="上一页" next-text="下一页" @on-change="changePage" show-total/>
            </div>
        </div>
        <Modal title="转换设备微信使用场景" v-model="sceneTypeTransFlag" :hide-footer="true" width="500" @on-ok="confirmTransferSceneType">
            <Select v-model="sceneTypeTransForm.sceneType">
                <Option :value="1">私用</Option>
                <Option :value="2">公用</Option>
                <Option :value="3">商用</Option>
            </Select>
        </Modal>
    </div>
</template>

<script>
import axios from '@/libs/api.request'
import { formatDate,isHttps } from '@/libs/util.js'
import { iviewMixin } from "@/mixins/iviewMixin";
import VueQr  from 'vue-qr'
import Constants from '@/libs/constants.js';

export default {
    mixins: [iviewMixin],
    components: {
        VueQr 
    },
    props: {

    },
    data() {
        return {
            url:{
                list:"/wechat/device/users"
            },
            deviceInfo: {},
            productInfo:{
                productName:null
            },
            deviceWechatInfo:{},
            productCode:null,
            deviceCode:null,
            sceneTypeTransFlag:false,
            sceneTypeTransForm:{},
            columns: [
                {
                    title: "OpenID",
                    key: "openId",
                    align: "left",
                    width:"300rem"
                },
                {
                    title: "昵称",
                    key: "nickName",
                    align: "left",
                },
                {
                    title: "角色",
                    key: "master",
                    align: "center",
                    render(h,params){
                        let master = params.row.master;
                        if(master){
                            return h('div','主人');
                        }
                        else{
                            return h('div','客人');
                        }
                    }
                },
                {
                    title: "备注名称",
                    key: "showName",
                    align: "center"
                },
                {
                    title: "关注时间",
                    key: "bindTime",
                    align: "center",
                    render(h,params){
                        return h('div',formatDate(new Date(params.row.bindTime),'yyyy-MM-dd hh:mm:ss'));
                    }
                },
                {
                    title: "操作",
                    slot: "operate",
                    align: "center"
                },
            ]
        };
    },
    computed: {
        sceneTypeName(){
            if(this.deviceWechatInfo.wechatSetting){
                switch(this.deviceWechatInfo.wechatSetting.sceneType){
                    case 1:
                        return '私用';
                    case 2:
                        return '公用';
                    case 3:
                        return '商用';
                    default:
                      return `未知场景(${this.deviceWechatInfo.wechatSetting.sceneType})`  
                }
            }
            return `未知场景(无法获取设定信息)`;
        }
    },
    created() {
        this.setPreLoadData(false);
        this.deviceCode = this.$route.query.deviceCode;
        this.queryParam['deviceCode'] = this.deviceCode;
        this.productCode = this.$route.query.productCode;
        this.refreshDeviceInfo();
        this.getProductInfo();
        this.loadData(1,'SMP');
    },
    mounted() {
        console.log(isHttps());
    },
    watch: {

    },
    methods: {
        /**询问是否确认要转换SceneType */
        confirmTransferSceneType(){
            let preSecenType = this.deviceWechatInfo.wechatSetting.sceneType;
            let sceneType = this.sceneTypeTransForm.sceneType;
            if(preSecenType==sceneType){
                return;
            }
            
            let preSceneTypeName = preSecenType==1?"私用":preSecenType==2?"公用":preSecenType==3?"商用":"未知场景";
            let sceneTypeName = sceneType==1?"私用":sceneType==2?"公用":sceneType==3?"商用":"未知场景";
            let content =`您确定该设备从<b>[${preSceneTypeName}]</b>转换到<b>[${sceneTypeName}]</b>吗?<br><span style="color:red">转换后将解绑所有使用人!</span>`;
            this.$Modal.confirm({
                width:500,
                title:`转换设备使用场景`,
                content,
                onOk:()=>{
                    axios.request({
                        url:"/device/wechat/scene/transfer",
                        method:"post",
                        module:Constants.Modules.SMP,
                        data:this.sceneTypeTransForm
                    }).then(res=>{
                        if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
                            this.$Notice.success({
                                title:"转换设备使用场景",
                                desc:`转换设备使用场景从<b>[${preSceneTypeName}]</b>转换到<b>[${sceneTypeName}]完成`
                            });
                            location.reload();
                            // this.refreshDeviceInfo();
                        }
                    })
                },
                onCancel:()=>{},
            }
            );
        },
        /**进入SceneType转换 */
        toTransferSceneType(){
            this.sceneTypeTransForm.deviceCode = this.deviceCode;
            this.sceneTypeTransForm.sceneType = this.deviceWechatInfo.wechatSetting.sceneType;
            this.sceneTypeTransFlag = true;
        },
        getProductInfo(){
            axios.request({
                url:'/product/info',
                method:'get',
                params:{productCode:this.productCode},
                module:'SMP'
            }).then(res=>{
                if(Constants.ResponseCode.CODE_SUCCESS == res.data.code){
                    this.productInfo = res.data.data;
                }
            })
        },
        refreshDeviceInfo(){
            // location.reload();
            this.getDeviceInfo((data)=>{
                this.getDeviceWechatInfo((data)=>{
                    let deviceCode = data.deviceCode;
                    this.getDeviceGuestSunCode(deviceCode);
                });
            });
        },
        debindDevice(user){
            let wechatSetting = this.deviceWechatInfo.wechatSetting;
            if(!wechatSetting){
                this.$Notice.warning({
                    title:"解绑设备",
                    desc:"设备的微信设定获取失败,请刷新页面然后重试"
                });
                return;
            }
            let sceneType = wechatSetting.sceneType;
            let isMaster = user.master;
            let deviceCode = this.deviceCode;
            let message = '';
            switch(sceneType){
                case 1:
                    message = `该设备为<b>[私用]</b>设备<br>`;
                    if(isMaster){
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[主人]</b><br>解绑后将同时解绑其他客人`;
                    }else{
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b><br>解绑后不影响其他使用人`;
                    }
                    break;
                case 2:
                    message = `该设备为<b>[公用]</b>设备<br>`
                    if(isMaster){
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[主人]</b><br>解绑后不影响其他使用人`;
                    }else{
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b><br>解绑后不影响其他使用人`;
                    }
                    break;
                case 3:
                    message = `该设备为<b>[商用]</b>设备<br>`
                    if(isMaster){
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[主人]</b><br>解绑后将同时解绑其他客人`;
                    }else{
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b><br>解绑后不影响其他使用人`;
                    }
                    break;
                default:
                    break;
            }
            let content = `您确定要解绑设备[${deviceCode}]吗?<br>${message}`;
            
            this.$Modal.confirm({
                width:700,
                title:`解绑设备`,
                content,
                onOk:()=>{
                    axios.request({
                        url:"/wechat/device/debinding",
                        method:"post",
                        module:Constants.Modules.SMP,
                        data:{
                            openId:user.openId,deviceCode
                        }
                    }).then(res=>{
                        if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
                            this.$Notice.success({
                                title:"解绑设备",
                                desc:`解绑设备[${deviceCode}]完成`
                            });
                            location.reload();
                            // this.refreshDeviceInfo();
                        }
                    })
                },
                onCancel:()=>{},
            }
            );
        },
        guestControlSetting(user){
            let control = user.guestControl;
            let content = '';
            let title='';
            if(control){
                content = `您确定要取消用户<b>[${user.nickName}(${user.openId})]</b>的使用权限吗?`
                title = '取消用户授权';
            }else{
                content = `您确定要对用户<b>[${user.nickName}(${user.openId})]</b>进行使用授权吗?` 
                title = '用户授权';
            }
            this.$Modal.confirm({
                width:700,
                title:`设备授权设置`,
                content,
                onOk:()=>{
                    axios.request({
                        url:"/wechat/device/binding/updating",
                        method:"post",
                        data:{
                            openId:user.openId,
                            guestControl:!control,
                            deviceCode:this.deviceCode
                        }
                    }).then(res=>{
                        if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
                            this.$Notice.success({
                                title,
                                desc:`${title}完成`
                            });
                            location.reload();
                            // this.refreshDeviceInfo();
                        }
                    })
                },
                onCancel:()=>{},
            });
        },
        toBeMaster(user){
            let wechatSetting = this.deviceWechatInfo.wechatSetting;
            if(!wechatSetting){
                this.$Notice.warning({
                    title:"设置设备主人",
                    desc:"设备的微信设定获取失败,请刷新页面然后重试"
                });
                return;
            }
            let hasMaster = this.deviceWechatInfo.hasMaster;
            let sceneType = wechatSetting.sceneType;
            let deviceCode = this.deviceCode;
            let message = '';
            switch(sceneType){
                case 1:
                    message = `该设备为<b>[私用]</b>设备<br>`;
                    if(hasMaster){
                        let master = this.deviceWechatInfo.master;
                        message = message+`该设已经有主人<br>设置<b>[${user.nickName}(${user.openId})]</b>为主人<br>
                        将取消<b>[${master.nickName}(${master.openId})]</b>的主人身份<br>原主人将仍可获得设备控制权限`;
                    }else{
                        message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b>`;
                    }
                    break;
                case 2:
                    message = `该设备为<b>[公用]</b>设备<br>`
                    if(hasMaster){
                        let master = this.deviceWechatInfo.master;
                        message = message+`该设已经有主人<br>设置<b>[${user.nickName}(${user.openId})]</b>为主人<br>
                        将取消<b>[${master.nickName}(${master.openId})]</b>的主人身份<br>原主人将仍可获得设备控制权限`;
                    }else{
                       message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b>`;
                    }
                    break;
                case 3:
                    message = `该设备为<b>[商用]</b>设备<br>`
                    if(hasMaster){
                        let master = this.deviceWechatInfo.master;
                        message = message+`该设已经有主人<br>设置<b>[${user.nickName}(${user.openId})]</b>为主人<br>将取消<b>[${master.nickName}(${master.openId})]</b>的主人身份<br>原主人将仍可获得设备控制权限`;
                    }else{
                       message = message+`用户<b>[${user.nickName}(${user.openId})]</b>为该设备<b>[客人]</b>`;
                    }
                    break;
                default:
                    break;
            }
            let content = `您确定要将<b>[${user.nickName}(${user.openId})]</b>置为主人吗?<br>${message}`;
            
            this.$Modal.confirm({
                width:700,
                title:`设置设备主人`,
                content,
                onOk:()=>{
                    axios.request({
                        url:"/wechat/device/master/setting",
                        method:"post",
                        module:Constants.Modules.SMP,
                        data:{
                            openId:user.openId,deviceCode
                        }
                    }).then(res=>{
                        if(res.data.code==Constants.ResponseCode.CODE_SUCCESS){
                            this.$Notice.success({
                                title:"设置设备主人",
                                desc:`设置设备[${deviceCode}]主人完成`
                            });
                            location.reload();
                            // this.refreshDeviceInfo();
                        }
                    })
                },
                onCancel:()=>{},
            });
            
        },
        showSecret() {
            this.$Modal.confirm({
                width:500,
                title:`deviceSecret`,
                content: this.deviceInfo.deviceSecret,
                onOk:()=>{
                    
                },
                onCancel:()=>{},
            });
        },
        getDeviceGuestSunCode(deviceCode){
            axios.request({
                url: '/wechat/device/info',
                method: 'get',
                module:"SMP",
                params:{deviceCode}
            }).then(res=>{
                if(res.data.code==0){
                    let deviceData = res.data.data;

                    this.deviceWechatInfo = Object.assign(this.deviceWechatInfo,deviceData);
                    this.deviceWechatInfo.guestCodeType = this.deviceWechatInfo.codeType;
                    // if(deviceData.hasMaster){
                        // let master = deviceData.master;
                        // let openId = master.openId;
                        this.getDeviceGuestSunCodeToken(deviceCode,null);
                    // }else{
                        this.$forceUpdate();
                    // }
                }
            });
        },
        getDeviceGuestSunCodeToken(deviceCode,openId){
            axios.request({
                url: '/wechat/device/request/guest/qrcode',
                method: 'get',
                module:"SMP",
                params:{deviceCode,openId,pagePath:"pages/index/index"}
            }).then(res=>{
                if(res.data.code==0){
                    let tokenInfo = res.data.data;
                    if(tokenInfo.type=='suncode'){
                        this.deviceWechatInfo.guestCodeType = 'suncode';
                        this.deviceWechatInfo.wechatSuncodeCodeGuestUrl=tokenInfo.url;
                    }else if(tokenInfo.type=='qrcode'){
                        this.deviceWechatInfo.guestCodeType = 'qrcode';
                        this.deviceWechatInfo.miniappGuestQRCode=tokenInfo.qrcode;
                    }
                    this.$forceUpdate();
                }
            })
        },
        getDeviceInfo(callback){
            var params = { deviceCode: this.$route.query.deviceCode }
            axios.request({
                url: '/device/basicinfo',
                method: 'get',
                module:"SMP",
                params
            })
            .then(res => {
                // console.log(res)
                if(res.data.code == 0){
                    this.deviceInfo = res.data.data;
                    if(callback){
                        callback(this.deviceInfo);
                    }
                }
            })
            .catch(err => {
                console.log(err)
            })

        },
        getDeviceWechatInfo(callback){
            var params = { deviceCode: this.$route.query.deviceCode }
            axios.request({
                url: '/device/wechat/info',
                method: 'get',
                module:"SMP",
                params
            })
            .then(res => {
                // console.log(res)
                if(res.data.code == 0){
                    let wechatInfo = res.data.data;
                    this.deviceWechatInfo = Object.assign(this.deviceWechatInfo,wechatInfo);
                    this.deviceWechatInfo.wechatSetting = wechatInfo.wechatSetting;
                    if(wechatInfo.serviceType=="miniapp"){
                        this.deviceWechatInfo.serviceTypeString = "小程序"
                    }
                    if(wechatInfo.serviceType=="official"){
                        this.deviceWechatInfo.serviceTypeString = "公众号"
                    }
                    if(wechatInfo.codeType=="suncode"){
                        this.deviceWechatInfo.codeTypeString="太阳码"
                    }
                    if(wechatInfo.codeType=="qrcode"){
                        this.deviceWechatInfo.codeTypeString="二维码"
                    }
                    if(callback){
                        callback(this.deviceWechatInfo);
                    }
                }
                
            })
            .catch(err => {
                console.log(err)
            })

        },
        goRefreshDeviceWechat(){
            this.refreshDeviceWechat(()=>{
                this.getDeviceWechatInfo(()=>{
                    //刷新后让页面缓存进行刷新
                    this.deviceWechatInfo.wechatSuncodeCodeUrl = `${this.deviceWechatInfo.wechatSuncodeCodeUrl}?v=${new Date().getTime()}`

                    this.$forceUpdate();
                });
            });
        },
        refreshDeviceWechat(callback){
             axios.request({
                url: '/device/wechat/refresh',
                method: 'post',
                module:"SMP",
                data:{
                    deviceCode:this.deviceWechatInfo.deviceCode,
                    serviceType:this.deviceWechatInfo.serviceType,
                    miniAppPage:"pages/index/index"
                }
            })
            .then(res => {
                // console.log(res)
                if(res.data.code == 0){
                    this.$Notice.success({
                        title:"更新设备微信信息",
                        desc:"更新设备微信信息完成"
                    });
                    if(callback){
                        callback(res.data.data);
                    }
                }
            })
            .catch(err => {
                console.error(err)
            })
        }
    },
};
</script>
<style scoped lang="less" rel="stylesheet/less">
@import "./less/device.less";
</style>

