<template>
  <GlobalLayout>
    <div class="user-wrap">
      <div class="user_title">用户管理</div>
      <div class="userChoose">
        <div class="choose">
          <Select
            class="white"
            v-model="companyId"
            style="width: 117px; margin-left: 15px"
            placeholder="请选择厂商"
            @on-change="handleSelectChoose"
          >
            <Option
              v-for="item in companyList"
              :value="item.code"
              :key="item.code"
            >
              {{ item.shortName }}
            </Option>
          </Select>
        </div>
        <Button :to='"/admin/user/add?companyCode="+this.companyId' type="primary" class="createButton"
          >新建用户</Button
        >
      </div>

      <Table
        stripe
        ref="selection"
        :columns="columns"
        :data="dataSource"
        :no-data-text="'没有用户'"
        :loading="loading"
        class="notLineTable"
      >
        <template slot-scope="{ row, index }" slot="operate">
          <router-link
            :to="'/admin/user/detail?companyCode=' + row.companyCode+'&userCode='+row.userCode+'&userName='+row.userName+'&password='+row.password+'&nickName='+row.nickName+'&phone='+row.phone+'&email='+row.email+'&groupId='+row.groupId+'&mnCascade='+row.mnCascade"
            class="operationTxt"
            >查看详情</router-link
          >
          <a class="operationTxt" @click="resetPassword(row)">重置密码</a>
          <a class="operationTxt" @click="deleteUser(row)">删除</a>
        </template>
      </Table>

      <div class="page flex hcenter">
        <Page
          :total="ipagination.total"
          :page-size="ipagination.pagesize"
          prev-text="上一页"
          next-text="下一页"
          @on-change="changePage"
          show-total
        />
      </div>

      <!-- 重置用户密码对话框 -->
      <Modal
        v-model="resetPwd"
        width="560"
        :mask-closable="false"
        :footer-hide="true"
      >
        <h5 class="title">重置密码</h5>
        <Form
          ref="resetPasswordRef"
          :model="password"
          :rules="rules"
          :label-width="80"
        >
        <FormItem label="新密码" prop="newPassword">
          <Input
            type="password"
            v-model="password.newPassword"
            placeholder="请输入新密码"
          ></Input>
        </FormItem>
          <FormItem label="确认密码" prop="resetPassword">
            <Input
              type="password"
              v-model="password.resetPassword"
              placeholder="再次输入新密码"
            ></Input>
          </FormItem>
        </Form>
        <p class="tip" ref="tips" style="color:red;margin-left:80px"></p>
        <div class="btns" style="display: flex; justify-content: space-around">
          <Button style="width: 20%" @click="dissmissResetModal()">取消</Button>
          <Button
            style="width: 60%; background: #0060ff"
            type="primary"
            @click="resetModalMakeSure()"
            >确认</Button
          >
        </div>
      </Modal>
      <!-- 删除用户对话框 -->
      <Modal
        v-model="deleteuser"
        width="560"
        :mask-closable="false"
        :footer-hide="true"
      >
        <h5 class="title">删除用户</h5>
        <p
          class="tip"
          ref="tip"
          style="
            font-size: 24px;
            color: #e02020;
            margin: 0px auto 50px auto;
            text-align: center;
          "
        >
          确认要删除该用户吗？
        </p>
        <div class="btns" style="display: flex; justify-content: space-around">
          <Button style="width: 20%" @click="closeLDelete()">取消</Button>
          <Button
            style="width: 20%; background: #0060ff"
            type="primary"
            @click="isDelete()"
            >确认</Button
          >
        </div>
      </Modal>
    </div>
  </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";

import md5 from "md5-node";
import { mapGetters } from 'vuex';
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      companyId: "",
      companyList: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      dataSource: [],
      loading: false,
      
      columns: [
        {
          title: "用户编码",
          key: "userCode",
          align: "center",
        },
        {
          title: "用户名",
          key: "userName",
          align: "center",
        },
        {
          title: "所属厂商",
          key: "companyName",
          align: "center",
        },
        {
          title: "所属群组",
          key: "groupName",
          align: "center",
        },
        {
          title: "群组级别",
          key: "groupLevel",
          align: "center",
        },
        {
          title: "使用人",
          key: "nickName",
          align: "center",
        },
        {
          title: "创建者",
          key: "creator",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      resetPwd: false,  
      resetNewPwd: "",
      userCode: "",
      password: {
        newPassword: "",
        resetPassword: "",
      },
      passwordrules: [
        {
          required: true,
          message: "不能为空",
          trigger: "blur",
          min: 6,
          max: 16,
        },
      ],
      deleteuser: false,
    };
  },
  computed: {
    rules() {
      return {
        newPassword: this.passwordrules,
        resetPassword: this.passwordrules,
      };
    },
  },
  mounted() {
    console.log(this.$route.query.companyCode);
    // console.log(localStorage.getItem('companyCode'))
    this.getCompanyList();
    this.companyId=this.$route.query.companyCode
  },
  methods: {
    ...mapGetters(['companyCode']),
    getCompanyList() {
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: "/company/query",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
            this.getUserList();
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getUserList(companyId, resetPageCurrent) {
      console.log(companyId)
      if(companyId==undefined){
        this.companyId=this.companyList[0].code
      }
      
      if (resetPageCurrent === true) {
        //重置pange current
        this.ipagination.current = 1;
      }
      var data = {
        companyCode: companyId,
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
      };
      let base_url;
      if (localStorage.getItem('companyCode')!=null) {
        base_url = "/user/list?companyCode=" + localStorage.getItem('companyCode');
        // console.log(base_url)
      } else {
        base_url = "/user/list?companyCode=" + this.companyList[0].code;
        console.log(base_url)
      }
      axios
        .request({
          method: "get",
          module: "XAIR",
          url:  base_url,
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data)
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.data.length; i++) {
              res.data.data.data[i].createTime=formatDate(new Date(res.data.data.data[i].createTime),"yyyy-MM-dd hh:mm:ss")
              d[i] = {
                userCode: res.data.data.data[i].code,
                userName: res.data.data.data[i].userName,
                companyName: res.data.data.data[i].companyName,

                groupId: res.data.data.data[i].groupId,
                groupName: res.data.data.data[i].groupName,
                groupLevel: res.data.data.data[i].groupLevel,
                nickName: res.data.data.data[i].nickName,
                creator: res.data.data.data[i].creator,
                createTime: res.data.data.data[i].createTime,

                address: res.data.data.data[i].address,
                companyCode: res.data.data.data[i].companyCode,
                contact: res.data.data.data[i].contact,
                email: res.data.data.data[i].email,
                parentCode: res.data.data.data[i].parentCode,
                password: res.data.data.data[i].password,
                phone: res.data.data.data[i].phone,
                remarks: res.data.data.data[i].remarks,
                userType: res.data.data.data[i].userType,
                mnCascade:res.data.data.data[i].mnCascade,
              };
            }
            this.dataSource = d;
            // console.log(this.dataSource)
          } else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
            this.dataSource=[]
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelectChoose() {
      // this.getUserList(this.companyId);
      let base_url=""
      if(this.companyId==undefined){
        base_url = "/user/list?companyCode=" + this.companyList[0].code;
      }else{
        base_url='/user/list'
      }
      var data = {
        companyCode: this.companyId,
        pageSize: this.ipagination.pagesize,
        pageNo: this.ipagination.current,
      };
      axios
        .request({
          method: "get",
          module: "XAIR",
          url: base_url,
          params: data,
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data.data)
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for (var i = 0; i < res.data.data.data.length; i++) {
              d[i] = {
                userCode: res.data.data.data[i].code,
                userName: res.data.data.data[i].userName,
                companyName: res.data.data.data[i].companyName,

                groupId: res.data.data.data[i].groupId,
                groupName: res.data.data.data[i].groupName,
                groupLevel: res.data.data.data[i].companyCode,
                nickName: res.data.data.data[i].nickName,
                creator: res.data.data.data[i].creator,
                createTime: res.data.data.data[i].createTime,

                address: res.data.data.data[i].address,
                companyCode: res.data.data.data[i].companyCode,
                contact: res.data.data.data[i].contact,
                email: res.data.data.data[i].email,
                parentCode: res.data.data.data[i].parentCode,
                password: res.data.data.data[i].password,
                phone: res.data.data.data[i].phone,
                remarks: res.data.data.data[i].remarks,
                userType: res.data.data.data[i].userType,
              };
            }
            this.dataSource = d;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
            this.dataSource=[]
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePage(val) {
      this.ipagination.current = val;
      this.getUserList();
    },

    // 重置密码对话框
    resetPassword(row) {
      this.userCode = row.userCode;
      this.resetPwd = true;
    },
    // 确认
    resetModalMakeSure() {
      this.$refs.tips.innerText = "";
      this.$refs.resetPasswordRef.validate((valid) => {
        // if(valid==true){

        // }
        if (this.password.newPassword == this.password.resetPassword){
          axios
          .request({
            module: "AUTH",
            method: "post",
            url: "/user/pwd/reset",
            data: {
              code: this.userCode,
              password: md5(this.password.resetPassword),
            },
          })
          .then((res) => {
            if (res.data.code == 0) {
              this.$Message.info("更新用户密码完成");
              this.resetPwd = false;
              this.password.resetPassword = "";
              this.getUserList();
            } else if(res.data.code==105){
              this.$Message.warning('系统时间有误，请重设系统时间')
            }else{
              this.$Message.error(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err);
          });
        }else{
          this.$refs.tips.innerText = "两次密码输入不一致";
        }
        
      });
    },
    // 取消
    dissmissResetModal() {
      this.resetPwd = false;
    },

    // 删除用户
    deleteUser(row) {
      (this.userCode = row.userCode), (this.deleteuser = true);
    },
    isDelete() {
      axios
        .request({
          module: "AUTH",
          method: "post",
          url: "/user/delete",
          data: {
            code: this.userCode,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$Message.info("删除用户完成");
            this.getUserList();
            this.deleteuser = false;
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error('删除用户失败')
            this.deleteuser=false
          }
        });
    },
    closeLDelete() {
      this.deleteuser = false;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./user.less";
</style>