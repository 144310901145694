<template>
    <div class="system_wrap">
        <label for="" style="font-size:1.8rem">软件标题:</label>
        <input style="margin-left:3rem;width:25rem;height:4rem;border:1px solid #eeeeee;padding-left:1rem" v-model="systemTitle"></input>
        <Button style="margin-left:2rem;background:#1960e1;border:none;color:white" @click="changeTitle">提交</Button>
    </div>
</template>

<script>
import sendTitle from "../../sendTitle.js";
import { mapGetters } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  data() {
    return {
      companyList: [],
      systemTitle: "",
    };
  },
  mounted() {
    if (this.isAdmin() == true) {
      this.getCompanyList();
    } else {
      this.getTitle();
    }
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  methods: {
    ...mapGetters(["usertype", "companyName", "groupId", "companyCode"]),
    getCompanyList() {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/company/list",
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data)

            this.companyList = res.data.data;
            this.getTitle();
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getTitle() {
      let base_url;
      if (this.getUserType() == 0) {
        base_url =
          "/company/setting/title?companyCode=" + this.companyList[0].code;
      } else {
        base_url = "/company/setting/title?companyCode=" + this.companyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
        })
        .then((res) => {
          // console.log(res.data)
          if (res.data.code == 0) {
            // console.log(res.data.data)
            this.systemTitle = res.data.data.settingValue;
            document.title = this.systemTitle;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changeTitle() {
      let base_url;
      if (this.getUserType() == 0) {
        base_url = this.companyList[0].code;
      } else {
        base_url = this.companyCode();
      }
      axios
        .request({
          module: "XAIR",
          method: "post",
          url: "/company/setting/title",
          data: {
            companyCode: base_url,
            systemTitle: this.systemTitle,
          },
        })
        .then((res) => {
          // console.log(res.data)
          if (res.data.code == 0) {
            this.$router.go(0);
            sendTitle.$emit("title", this.systemTitle);
            document.title = this.systemTitle;
           
            //   this.systemTitle=res.data.data.settingValue
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    systemtitle() {},
  },
};
</script>

<style lang="less" scoped>
.system_wrap {
  padding: 4rem;
}
</style>