<template>
  <GlobalLayout>
    <div class="register_wrap">
        <div class="registertitle">注册管理</div>
        <div class="choose">
        <Select
            class="white"
            v-model="code"
            style="width: 200px;margin-left:15px"
            clearable
            placeholder="所属厂商"
            @on-change='handleSelectChoose'
          >
            <Option
              v-for="item in companyList"
              :value="item.code"
              :key="item.code"
            >
              {{ item.shortName }}
            </Option>
          </Select>
          <Button type="primary" style="margin-left:15px;background:#1960e1;border:none;width:120px" @click="registerDevice()" >注册设备</Button>
        </div>
      <Table
        stripe
        ref="selection"
        :columns="columns"
        :data="dataSource"
        :no-data-text="'没有设备'"
        :loading="loading"
        class="notLineTable"
      >
        <template slot-scope="{ row, index }" slot="operate">
          <a class="operationTxt" @click="registerDevice(row)">再次注册</a>
          <a class="operationTxt" @click="exportDevice(row)">导出</a>
        </template>
      </Table>
      <div class="page flex hcenter">
        <Page
          :total="ipagination.total"
          :page-size="ipagination.pagesize"
          prev-text="上一页"
          next-text="下一页"
          @on-change="changePage"
          show-total
        />
      </div>
      
      <!-- 注册设备对话框 -->
      <Modal
        v-model="registerModal"
        width="560"
        :mask-closable="false"
        :footer-hide="true"
      >
        <h5 class="title">注册设备</h5>

        
        <Form
          ref="batchaddValidate"
          :model="batchAddObj"
          :label-width="120"
          :rules="batchaddRules"
          label-position="left"
        >
            <FormItem label="选择厂商" prop="companyCode">
            <Select
              v-model="batchAddObj.companyCode"
              placeholder="请选择所属厂商"
              clearable
              @on-change='selectCompany'
            >
              <Option
                v-for="(item, index) in companyList"
                :key="item.code"
                :value="item.code"
              >
                {{ item.shortName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="选择产品" prop="productCode">
            <Select
              v-model="batchAddObj.productCode"
              placeholder="请选择所属产品"
              clearable
            >
              <Option
                v-for="(item, index) in productList"
                :key="index"
                :value="item.productCode"
              >
                {{ item.productName }}
              </Option>
            </Select>
          </FormItem>
          <FormItem label="设备数量" v-if="isAutoWay" prop="count">
            <InputNumber
            style="width:406px"
              v-model="batchAddObj.count"
              type="number"
              placeholder="请输入要生成的设备数量"
              :min="0"
            ></InputNumber>
          </FormItem>
          <FormItem label="注册微信服务"  prop="wechatService">
            <Select
              v-model="batchAddObj.wechatService" 
              placeholder="请选择微信服务"
            >
              <Option value="miniapp">微信小程序</Option>
              <!-- <Option value="official">微信公众号</Option> -->
            </Select>
          </FormItem>
           <FormItem v-if="batchAddObj.wechatService=='miniapp'" label="小程序页面"  prop="miniAppPage">
              <Input
              v-model="batchAddObj.miniAppPage"
              placeholder="小程序的页面路径"/>
              <span style="color:red">**:如果不输入则不会生成设备的小程序码</span>
           </FormItem>
            <FormItem v-if="batchAddObj.wechatService=='miniapp'" label="小程序码"  prop="codeType">
              <Select
              v-model="batchAddObj.miniAppCodeType" 
              placeholder="请选择生成小程序码的方式"
            >
              <Option value="suncode">小程序码(推荐)</Option>
              <Option value="qrcode">小程序二维码(受限)</Option>
            </Select>
           </FormItem>
          <!-- <FormItem class="flex hend" style="margin-top: 30px" v-if="isAutoWay"> -->
          <FormItem class="flex hend" style="margin-top: 30px">
            <Button
              style="width: 80px; margin-right: 14px"
              @click="batchaddReset('batchaddValidate')"
              >取消</Button
            >
            <Button
              style="width: 200px"
              type="primary"
              @click="batchaddConfirm('batchaddValidate')"
              >确认</Button
            >
          </FormItem>
        </Form>
        <Spin fix v-if="isRegisterShow">注册中...</Spin>
        <!-- <div class="sel">
             <label for="" style="display:inline-block;width:30px">厂商</label>
             <Select
            class="white"
            v-model="registerCompany"
            style="width: calc(100% - 50px);margin-left:15px"
            placeholder="所属厂商"
            @on-change='selectCompany'
          >
            <Option
              v-for="item in companyList"
              :value="item.code"
              :key="item.code"
            >
              {{ item.shortName }}
            </Option>
          </Select>
             <label for="" style="display:inline-block;width:30px;margin-top:30px">产品</label><Select
            class="white"
            v-model="registerProduct"
            style="width: calc(100% - 50px);margin-left:15px"
            placeholder="所属产品"
            @on-change='handleSelectChoose'
          >
            <Option
              v-for="item in productList"
              :value="item.productCode"
              :key="item.productCode"
            >
              {{ item.productName }}
            </Option>
          </Select>
             <label for="" style="display:inline-block;width:30px;margin-right:15px;margin-top:30px">数量</label>
             <Input v-model="registerNum   " placeholder="请输入设备数量" style="width:calc(100% - 50px)" type="number"></Input>

        </div> -->
             
        <!-- <p class="tip" ref="tip"></p> -->
        <!-- <div class="btns" style="display: flex; justify-content: space-around">
          <Button style="width: 30%" @click="dissmissResetModal()">取消</Button>
          <Button
            style="width: 30%; background: #0060ff"
            type="primary"
            @click="resetModalMakeSure()"
            >确认</Button
          > -->
        <!-- </div> -->
      </Modal>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    return {
      companyList: [],
      code: "",
      columns: [
        {
          title: "厂商代码",
          key: "companyCode",
          align: "center",
        },
        {
          title: "厂商简称",
          key: "shortName",
          align: "center",
        },
        {
          title: "产品类型",
          key: "productName",
          align: "center",
        },
        {
          title: "海克配件",
          key: "fitting",
          align: "center",
        },
        {
          title: "注册数量",
          key: "num",
          align: "center",
        },
        {
          title: "注册时间",
          key: "registerTime",
          align: "center",
          render: (h, params) => {
            if (params.row.registerTime) {
              return h(
                "div",
                formatDate(
                  new Date(params.row.registerTime),
                  "yyyy-MM-dd hh:mm:ss"
                )
              );
            } else {
              return h("div", "--");
            }
          },
        },
        {
          title: "操作",
          slot: "operate",
          align: "center",
        },
      ],
      dataSource: [],
      ipagination: {
        current: 1,
        total: 0,
        pagesize: 10,
      },
      loading: false,
      registerModal:false,
      registerCompany:'',
      registerProduct:'',
      registerNum:0,
      productList:[],
      
      batchaddShow: false,
      batchaddRules: {
        productCode: [
          { required: true, message: "产品必须选择", trigger: "blur" },
        ],
        companyCode:[
          { required: true, message: "厂商必须选择", trigger: "blur" },
        ],
        count: [
          { required: true,type:"number", message: "设备数量必须填写", trigger: "blur" },
        ],
      },
      batchAddObj: { 
        way: "自动生成" ,
        wechatService:"miniapp",
        miniAppPage:"pages/index/index",
        miniAppCodeType:"suncode"  ,
        companyCode:'',
        count:0,
        productCode:''
      },
      isAutoWay: true,
      deviceName: "",
      totalDevice: {},
      isRegisterShow: false,
    };
  },
  mounted() {
    this.getCompanyList();
    // this.getProductList(this.batchAddObj.companyCode)
    // this.getRegisterList();
  },
  methods: {
    getCompanyList() {
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: "/company/list",
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.companyList = res.data.data;
            // console.log(this.companyList);
            this.getRegisterList(this.companyList[0].code);
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getRegisterList(code) {
        var data={
            pageSize: this.ipagination.pagesize,
            pageNo: this.ipagination.current,
            companyCode:code
        }
      let base_url;
      axios
        .request({
          module: "SMP",
          method: "get",
          url: "/device/batch/query",
          params:data
        })
        .then((res) => {
          if(res.data.code==0){
          // console.log(res.data.data);
            this.ipagination.total = res.data.data.total;
            this.dataSource = [];
            var d = new Array();
            for(var i=0;i<res.data.data.data.length;i++){
                d[i]={
                    companyCode:res.data.data.data[i].companyCode,
                    shortName:res.data.data.data[i].companyName,
                    productName:res.data.data.data[i].productName,
                    fitting:res.data.data.data[i].batchCode,
                    batchCode:res.data.data.data[i].batchCode,
                    num:res.data.data.data[i].deviceCount,
                    registerTime:res.data.data.data[i].createTime,
                    productCode:res.data.data.data[i].productCode,
                    fileUrl:res.data.data.data[i].fileUrl,
                }
            }
            this.dataSource=d
            // console.log(this.dataSource)
          }else if(res.data.code==105){
            this.$Message.warning('系统时间有误，请重设系统时间')
          }else{
            this.$Message.error(res.data.message)
          }
        });
    },

    getProductList(code){
      axios.request({
        module:'SMP',
        method:'get',
        url:'/product/query',
        params:{
          companyCode:code
        }
      }).then(res=>{
        if(res.data.code==0){
          // console.log(res.data.data)
          this.productList=res.data.data
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },


    changePage(val) {
        this.ipagination.current = val;
        if(this.code==''){
            this.getRegisterList(this.companyList[0].code);
        }else{
            this.getRegisterList(this.code);
        }
            
    },
    handleSelectChoose(){
      if(this.code){
        this.getRegisterList(this.code)
      }else{
        this.getRegisterList(this.companyList[0].code)
      }
    },
    registerDevice(row){
      // console.log(row)
        this.batchAddObj.companyCode=''
        this.batchAddObj.productCode=''
        this.batchAddObj.count=0
      if(row){
        this.batchAddObj.companyCode=row.companyCode
        this.getProductList(this.batchAddObj.companyCode)
        this.batchAddObj.productCode=row.productCode
        this.batchAddObj.count=row.num
        // console.log(this.batchAddObj)
      }else{
        this.batchAddObj.companyCode=''
        this.batchAddObj.productCode=''
      }
        // console.log(this.batchAddObj)
        this.registerModal=true
    },
    dissmissResetModal(){
        this.registerModal=false
    },
    batchaddReset(){
      this.registerModal=false
    },
    batchaddConfirm(name){
      this.$refs[name].validate((valid) => {
        if(valid){
          // console.log(this.batchAddObj)
          axios.request({
            module:'SMP',
            method:'post',
            url:'/device/register/batch',
            data:{
              companyCode:this.batchAddObj.companyCode,
              productCode:this.batchAddObj.productCode,
              count:this.batchAddObj.count,
              miniAppPage:this.batchAddObj.miniAppPage,
              miniAppCodeType:this.batchAddObj.miniAppCodeType,
              wechatService:this.batchAddObj.wechatService,
            }
          }).then(res=>{
            // console.log(res.data.data.url)
            this.getCompanyList()
            this.registerModal=false
            this.$Message.success('注册设备成功')
          }).catch(err=>{
            console.log(err)
          })
        }
      });
    },
    selectCompany(){
      this.getProductList(this.batchAddObj.companyCode)
    },

    // 导出
    exportDevice(row){
      console.log(row.fileUrl)
      window.open(row.fileUrl)
    },
  },
};
</script>

<style lang="less" scoped>
@import "./register.less";
</style>