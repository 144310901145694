import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

import login from '../views/login/login.vue'
import control from '../views/control/device/device.vue'
import controlDevice from '../views/control/device/device.vue'
import admin from '../views/admin/company/company.vue'
import controlGroup from '../views/control/group/group.vue'
import controlUser from '../views/control/user/userList.vue'  
import controlUserList from '../views/control/user/user.vue'  
import controlWxUser from '../views/control/user/wxUser.vue'
import controlAddUser from '../views/control/user/addUser.vue'
import controlUserDetail from '../views/control/user/UserDetail.vue'

import All from '../views/control/device/all.vue'

import controlDeviceDetail from '../views/control/device/deviceDetail.vue'
import controlDeviceInfo from '../views/control/device/deviceInfo.vue'
import controlDeviceData from '../views/control/device/deviceData.vue'
import controlDeviceHistory from '../views/control/device/deviceHistory.vue'
import WechatService from '../views/control/device/WechatService.vue'
import DeviceFirmware from '../views/control/device/deviceFirmware.vue'

import controlDatastatic from '../views/control/datastatic/datastatic.vue'
import controlDeviceChild from '../views/control/device/subDeviceManage.vue'

import controlGroupTree from '../views/control/group/groupDetail.vue'
import controlAddGroup from '../views/control/group/addGroup.vue' 
import controlDeviceProduct from '../views/control/device/productDevice.vue'

import controlDevSetting from "../views/control/developer/setting.vue"

import controlSetting from '../views/control/setting/set.vue'
import controlSettingSystem from '../views/control/setting/system.vue'
import controlSettingWarn from '../views/control/setting/warn.vue'
import controlSettingBigScreen from '../views/control/setting/bigScreen.vue'

import adminCompany from '../views/admin/company/company.vue'
import adminUser from '../views/admin/user/user.vue'
import adminAddUser from '../views/admin/user/addUser.vue'
import adminUserDetail from '../views/admin/user/userDetail.vue'

import adminCost from '../views/admin/cost/cost.vue'
import adminOrder from '../views/admin/order/order.vue'
import adminDatastatistic from '../views/admin/datastatistic/datastatistic.vue'
import adminAddCompany from '../views/admin/company/addCompany.vue'
import adminCompanyDetail from '../views/admin/company/companyDetail.vue'

import adminPage from '../views/admin/pages/page.vue'

import adminRegister from '../views/admin/register/register.vue'


import cost from '../views/cost/cost/cost.vue'
import order from '../views//order/order/order.vue'
import controlOperate from '../views/control/operations/operation.vue'
import controlOperateSale from '../views/control/operations/sale/sale.vue'
import controlOperateWarn from '../views/control/operations/warn/warn.vue'
import Errors from '../views/control/operations/errors/errors.vue'
import controlSaleDetail from '../views/control/operations/sale/saleDetail.vue'
import controlAddSale from '../views/control/operations/sale/addSale.vue'

import controlAllGroup from '../views/control/group/all.vue'

import SceneList from '../views/control/scene/SceneList.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => console.log(err))
}

Vue.use(VueRouter)

const routes = [

  {
    path:'/',
    name:'home',
    component:Home,
    redirect: '/login'
  },
  {
    path:'/login',
    name:'login',
    component:login,
  },
  // 控制台
  // {
  //   path:'/control',
  //   name:'control',
  //   component:control,
  //   redirect: '/control/device',
  //   children:[
  //     {
  //       path:'/control/device',
  //       name:'controlDevice',
  //       component:controlDevice
  //     },
  //   ]
  // },
  {
    path:'/control',
    name:'control',
    component:control,
    redirect: '/control/all'
  },
  {
    path:'/control/all',
    name:'all',
    component:All,
    redirect: '/control/all/device',
    children: [
      {
        path: 'device',
        name: 'controlDevice',
        component: controlDevice
      },
      {
        path:'/control/all/product/:productCode',
        name:'controlDeviceProduct',
        component:controlDeviceProduct
      },
      // {
      //   path: 'singledevicetype/:productCode',
      //   name: 'SingleDevicetype',
      //   component: SingleDevicetype
      // }
    ]
  },
  {
    path:'/control/operate',
    name:'controlOperate',
    component:controlOperate,
    redirect:'/control/operate/sale',
    children:[
      {
        path:'/control/operate/sale',
        name:'controlOperateSale',
        component:controlOperateSale
      },
      {
        path:'/control/operate/warn',
        name:'controlOperateWarn',
        component:controlOperateWarn
      }
      ,
      {
        path:'/control/operate/errors',
        name:'Errors',
        component:Errors
      }
    ]
  },
  {
    path:'/control/operate/sale/detail',
    name:'controlSaleDetail',
    component:controlSaleDetail
  },
  {
    path:'/control/operate/sale/add',
    name:'controlAddSale',
    component:controlAddSale
  },

  {
    path:'/control/setting',
    name:'controlSetting',
    component:controlSetting,
    redirect: '/control/setting/system',
    children:[
      {
        path:'system',
        name:'controlSettingSystem',
        component:controlSettingSystem
      },
      {
        path:'warn',
        name:'controlSettingWarn',
        component:controlSettingWarn
      },
      {
        path:'bigScreen',
        name:'controlSettingBigScreen',
        component:controlSettingBigScreen
      },
    ]
  },
  {
    path:'/control/allgroup',
    name:'controlAllGroup',
    component:controlAllGroup,
    redirect: "/control/allgroup/group",
    children:[
      {
        path:'/control/allgroup/group',
        name:'controlGroup',
        component:controlGroup
      },
      {
        path:'/control/allgroup/grouptree',
        name:'controlGroupTree',
        component:controlGroupTree
      },
    ]
  },
  
  
  {
    path:'/control/group/add',
    name:'controlAddGroup',
    component:controlAddGroup
  },

  {
    path:'/control/user',
    name:'controlUserList',
    component:controlUserList,
    redirect: '/control/user/user',
    children:[
      {
        path:'user',
        name:'controlUser',
        component:controlUser,
      },
      {
        path:'wx',
        name:'controlWxUser',
        component:controlWxUser
      },
    ]
  },

  {
    path:'/control/user/add',
    name:'controlAddUser',
    component:controlAddUser
  },
  {
    path:'/control/user/detail',
    name:'controlUserDetail',
    component:controlUserDetail
  },
  {
    path:'/control/all/device/detail',
    name:'controlDeviceDetail',
    component:controlDeviceDetail,
    redirect: '/control/all/device/detail/deviceInfo',
    children:[
      {
        path:'/control/all/device/detail/deviceInfo',
        name:'controlDeviceInfo',
        component:controlDeviceInfo,
      },
      {
        path:'/control/all/device/detail/deviceData',
        name:'controlDeviceData',
        component:controlDeviceData,
      },
      {
        path:'/control/all/device/detail/wechat',
        name:'wechatService',
        component:WechatService,
      },
      {
        path:'/control/all/device/detail/deviceHistory',
        name:'controlDeviceHistory',
        component:controlDeviceHistory,
      },
      {
        path:'/control/all/device/detail/child',
        name:'controlDeviceChild',
        component:controlDeviceChild
      },{
        path:'/control/all/device/detail/firmware',
        name:'controlDeviceFirmware',
        component:DeviceFirmware,
      },
    ]
  },
  {
    path:'/control/datastatic',
    name:'controlDatastatic',
    component:controlDatastatic,
  },
  {
    path:"/control/dev/setting",
    name:"controlDevSetting",
    component:controlDevSetting
  },
  {
    path:'/cost/cost',
    name:'cost',
    component:cost
  },
  {
    path:'/order/order',
    name:'order',
    component:order
  },
  {
    path:`/control/scene/list`,
    name:'SceneList',
    component:SceneList
  },

  // 超级管理员
  {
    path:'/admin',
    name:'admin',
    component:admin,
    redirect: '/admin/company',
    children:[
      {
        path:'/admin/company',
        name:'adminCompany',
        component:adminCompany
      },
    ],
  },
  {
    path:'/admin/user',
    name:'adminUser',
    component:adminUser,
  },
  {
    path:'/admin/user/add',
    name:'adminAddUser',
    component:adminAddUser,
  },
  {
    path:'/admin/user/detail',
    name:'adminUserDetail',
    component:adminUserDetail,
  },
  {
    path:'/admin/cost',
    name:'adminCost',
    component:adminCost,
  },
  {
    path:'/admin/order',
    name:'adminOrder',
    component:adminOrder,
  },
  {
    path:'/admin/datastatitic',
    name:'adminDatastatistic',
    component:adminDatastatistic
  },
  {
    path:'/admin/company/add',
    name:'adminAddCompany',
    component:adminAddCompany
  },
  {
    path:'/admin/company/detail',
    name:'adminCompanyDetail',
    component:adminCompanyDetail
  },
  {
    path:'/admin/page',
    name:'adminPage',
    component:adminPage
  },
  {
    path:'/admin/register',
    name:'adminRegister',
    component:adminRegister
  },

  // {
  //   path: '/',
  //   name: 'Home',
  //   component: Home
  // },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/About.vue')
  //   }
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})

export default router
