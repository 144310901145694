<template>
  <GlobalLayout>
    <div class="addCompany-wrap">
      <div class="company_title">
        <span class="title"
          ><router-link to="/admin/company">厂商管理 </router-link>
          <span style="color: #383838">/ 新建厂商</span></span
        >
      </div>

      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 30px;posiiton:relative;margin-left:50px"
        :rules="formItemRules"
      >
        <Form-item label="厂商编码" :label-width="100" prop="code">
          <Input
            v-model="formItem.code"
            placeholder="请输入厂商编码"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="厂商全称" :label-width="100" prop="fullName">
          <Input
            v-model="formItem.fullName"
            placeholder="请输入厂商全称"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="厂商简称" :label-width="100" prop="shortName">
          <Input
            v-model="formItem.shortName"
            placeholder="请输入厂商简称"
            class="dark"
            type="text"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="厂商地址" :label-width="100" prop="address">
          <Input
            v-model="formItem.address"
            placeholder="请输入厂商地址"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="负责人" :label-width="100" prop="owner">
          <Input
            v-model="formItem.owner"
            placeholder="请输入负责人"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="企业邮箱" :label-width="100" prop="email">
          <Input
            v-model="formItem.email"
            placeholder="请输入企业邮箱"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="企业网站" :label-width="100" prop="website">
          <Input
            v-model="formItem.website"
            placeholder="请输入企业网站"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <Form-item label="企业规模" :label-width="100" prop="scale">
          <InputNumber
            v-model="formItem.scale"
            placeholder="请输入企业规模"
            class="dark"
            style="width: 250px"
            :min='0'
          ></InputNumber>
        </Form-item>
        <Form-item label="企业电话" :label-width="100" prop="phone">
          <Input
            v-model="formItem.phone"
            placeholder="请输入企业电话"
            class="dark"
            style="width: 250px"
          ></Input>
        </Form-item>
        <hr style="background-color: #e5e5e5; height: 1px; border: none;margin-bottom:30px" />
        
        <Form-item label="联系人1" :label-width="100" prop="contacts1" class="contact_inline1">
          <Input
            v-model="formItem.contacts1"
            placeholder="请输入联系人1姓名"
            class="dark"
            style="width: 200px"
          ></Input>
        </Form-item>
        <Form-item prop="cphone1" :label-width="0" class="contact_inline1">
          <Input
            v-model="formItem.cphone1"
            placeholder="请输入联系人1电话"
            class="dark"
            style="width: 200px; margin-left: 10px;"
          ></Input>
        </Form-item>
        <Form-item prop="cposition1" :label-width="0" class="contact_inline1">
          <Input
            v-model="formItem.cposition1"
            placeholder="请输入联系人1职位"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
        <Form-item prop="cemail1" :label-width="0" class="contact_inline1">
          <Input
            v-model="formItem.cemail1"
            placeholder="请输入联系人1邮箱"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
        <br>


        <Form-item label="联系人2" :label-width="100" prop="contacts_2" class="contact_inline2">
          <Input
            v-model="formItem.contacts_2"
            placeholder="请输入联系人2姓名"
            class="dark"
            style="width: 200px"
          ></Input>
        </Form-item>
        <Form-item prop="cphone2" :label-width="0" class="contact_inline2">
          <Input
            v-model="formItem.cphone2"
            placeholder="请输入联系人2电话"
            class="dark"
            style="width: 200px; margin-left: 10px;"
          ></Input>
        </Form-item>
        <Form-item prop="cposition2" :label-width="0" class="contact_inline2">
          <Input
            v-model="formItem.cposition2"
            placeholder="请输入联系人2职位"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
        <Form-item prop="cemail2" :label-width="0" class="contact_inline2">
          <Input
            v-model="formItem.cemail2"
            placeholder="请输入联系人2邮箱"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
<br>

        <Form-item label="联系人3" :label-width="100" prop="contacts3" class="contact_inline3">
          <Input
            v-model="formItem.contacts3"
            placeholder="请输入联系人3姓名"
            class="dark"
            style="width: 200px"
          ></Input>
        </Form-item>
        <Form-item prop="cphone3" :label-width="0" class="contact_inline3">
          <Input
            v-model="formItem.cphone3"
            placeholder="请输入联系人3电话"
            class="dark"
            style="width: 200px; margin-left: 10px;"
          ></Input>
        </Form-item>
        <Form-item prop="cposition3" :label-width="0" class="contact_inline3">
          <Input
            v-model="formItem.cposition3"
            placeholder="请输入联系人3职位"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
        <Form-item prop="cemail3" :label-width="0" class="contact_inline3">
          <Input
            v-model="formItem.cemail3"
            placeholder="请输入联系人3邮箱"
            class="dark"
            style="width: 200px; margin-left: 10px"
          ></Input>
        </Form-item>
        
      <hr style="background-color: #e5e5e5; height: 1px; border: none;margin-bottom:30px" />

        <Form-item prop="province" label="所属地址" :label-width="100" class="address_inline1">
          <Select
            v-model="formItem.province"
            placeholder="请选择省"
            @on-change="changeProvince"
            style="width: 200px"
          >
            <Option
              v-for="(item, index) in provinceArr"
              :key="item.code"
              :value="item.code"
              >{{ item.name }}</Option
            >
          </Select>
        </Form-item>
        <Form-item prop="city" class="address_inline2">
          <Select
            v-model="formItem.city"
            placeholder="请选择市"
            @on-change="changeCity"
            :label-width="0"
            style="width: 200px"
          >
            <Option
              v-for="(item, index) in citiesArr"
              :key="item.code"
              :value="item.code"
              >{{ item.name }}</Option
            >
          </Select>
        </Form-item>
        <Form-item prop="county" class="address_inline3">
          <Select
            v-model="formItem.county"
            placeholder="请选择区"
            :label-width="0"
            style="width: 200px"
            @on-change="changeCounty"
          >
            <Option
              v-for="(item, index) in countyArr"
              :key="item.code"
              :value="item.code"
              >{{ item.name }}</Option
            >
          </Select>
        </Form-item>

        <Form-item label="厂商描述" :label-width="100" prop="remarks">
          <Input
            v-model="formItem.remarks"
            placeholder="请输入厂商描述"
            class="dark"
            style="width: 250px"
          ></Input>
           </Form-item>
          <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            style="
              background: #1960e1;
              border: none;
              margin-left: -400px;
              width: 100px;
            "
            @click="AddCompanyConfirm('formItemRef')"
            >保存</Button
          >
          </Form-item>
      </Form>

    </div>
  </GlobalLayout>
</template>

<script>
import axios from "@/libs/api.request";
import { formatDate } from "@/libs/util.js";
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mutations } from "vuex";
import provinces from "../../../assets/provinces.json";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^(400-?\d{7,8})|((0\d{2,3}-?\d{7,8})|(1[3465789]\d{9}))$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      provinceArr: [],
      citiesArr: [],
      countyArr: [],

      formItem: {
          code:'',
        fullName: "",
        shortName: "",
        address: "",
        owner: "",
        email: "",
        website: "",
        scale: 0,
        phone: "",
        contacts1:'',
        cphone1: "",
        cposition1: "",
        cemail1: "",
        contacts_2:'',
        cphone2: "",
        cposition2: "",
        cemail2: "",
        contacts3:'',
        cphone3: "",
        cposition3: "",
        cemail3: "",
        province: "",
        city: "",
        county: "",
        remarks:''
      },
      formItemRules: {
        code: [
          { required: true, message: "请填写厂商编码 ", trigger: "blur" },
        ],
        fullName: [
          { required: true, message: "请填写厂商全称", trigger: "blur" },
        ],
        shortName: [
          { required: true, message: "请填写厂商简称", trigger: "blur" },
        ],
        address: [
          { required: false, message: "请填写厂商地址", trigger: "blur" },
        ],
        owner: [{ required: false, message: "请填写负责人", trigger: "blur" }],
        email: [
          { required: true, message: "请填写企业邮箱", trigger: "blur" },
          { type: "email", message: "填写正确邮箱格式", trigger: "blur" },
        ],
        website: [
          { required: false, message: "请填写企业网站", trigger: "blur" },
        ],
        scale: [
          { required: false, type:"number",message: "请填写企业规模", trigger: "blur" },
        ],
        phone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请正确输入手机号或者固定电话",
            trigger: ["blur", "change"],
          },
        ],
        
        cphone1: [
          {
            type: "number",
            validator: checkPhone,
            message: "请正确输入手机号或者固定电话",
            trigger: ["blur", "change"],
          },
        ],
        cphone2: [
          {
            type: "number",
            validator: checkPhone,
            message: "请正确输入手机号或者固定电话",
            trigger: ["blur", "change"],
          },
        ],
        cphone3: [
          {
            type: "number",
            validator: checkPhone,
            message: "请正确输入手机号或者固定电话",
            trigger: ["blur", "change"],
          },
        ],
        cemail1: [
          { type: "email", message: "填写正确邮箱格式", trigger: "blur" },
        ],
        cemail2: [
          { type: "email", message: "填写正确邮箱格式", trigger: "blur" },
        ],
        cemail3: [
          { type: "email", message: "填写正确邮箱格式", trigger: "blur" },
        ],

      },
      regionCode:''
    };
  },
  created() {
    //   this.$axios.get('../../../assets/provinces.json').then((data) => {
    //       console.log(data.data.data)
    //             this.provinceArr = data.data.data
    //     })
  },
  mounted() {
    // console.log(JSON.parse(JSON.stringify(provinces)).data);
    // this.provinceArr = JSON.parse(JSON.stringify(provinces)).data;
    this.getRegionList()
  },
  methods: {
    changeProvince(val) {
      // console.log(val)
      axios.request({
        module:'AUTH',
        method:'get',
        url:'/region/list',
        params:{
          code:val
        }
      }).then(res=>{
        if(res.data.code==0){
          // console.log(res.data.data)
          this.citiesArr=res.data.data
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    changeCity(val) {
      // console.log(val)
      axios.request({
        module:'AUTH',
        method:'get',
        url:'/region/list',
        params:{
          code:val
        }
      }).then(res=>{
        if(res.data.code==0){
          // console.log(res.data.data)
          this.countyArr=res.data.data
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
    changeCounty(val){
      this.regionCode=val
    },
    AddCompanyConfirm(name) {
      console.log(this.formItem)
        this.$refs[name].validate((valid) => {
        if (valid) {
            var data={
                code:this.formItem.code,
                fullName:this.formItem.fullName,
                shortName:this.formItem.shortName,
                email:this.formItem.email,

                address:this.formItem.address,
                owner:this.formItem.owner,
                website:this.formItem.website,
                phone:this.formItem.phone,
                scale:this.formItem.scale,
                contacts1:this.formItem.contacts1,
                cphone1:this.formItem.cphone1,
                cposition1:this.formItem.cposition1,
                cemail1:this.formItem.cemail1,
                contacts_2:this.formItem.contacts_2,
                cphone2:this.formItem.cphone2,
                cposition2:this.formItem.cposition2,
                cemail2:this.formItem.cemail2,
                contacts3:this.formItem.contacts3,
                cphone3:this.formItem.cphone3,
                cposition3:this.formItem.cposition3,
                cemail3:this.formItem.cemail3,
                remarks:this.formItem.remarks,
                locationCode:this.regionCode,
            }
            axios.request({
                module:'AUTH',
                method:'post',
                url:'/company/addition',
                data:data
            }).then(res=>{
              if(res.data.code==0){
                this.$Message.success("新建厂商成功!");
                this.$router.push({path:'/admin/company'})
              }else if(res.data.code==105){
                this.$Message.warning('系统时间有误，请重设系统时间')
              }else{
                this.$Message.error(res.data.message)
              }
            })
        } else {
          this.$Message.error("失败!");
        }
      });
   
    },
    
    getRegionList(code){
      axios.request({
        module:'AUTH',
        method:'get',
        url:'/region/list',
        params:{
          code:86
        }
      }).then(res=>{
        if(res.data.code==0){
          // console.log(res.data.data)
          this.provinceArr=res.data.data
        }else if(res.data.code==105){
          this.$Message.warning('系统时间有误，请重设系统时间')
        }else{
          this.$Message.error(res.data.message)
        }
      }).catch(err=>{
        console.log(err)
      })
    },
  },
};
</script>

<style scoped>
.addCompany-wrap {
  padding: 40px;
}
.contact_inline1{
    display: inline-block;
}
.contact_inline2{
    display: inline-block;
}
.contact_inline3{
    display: inline-block;
}
.address_inline1{
    display: inline-block;
    width: 250px;
    margin-left: 0px;
}
.address_inline2{
    display: inline-block;
    width: 250px;
    margin-left: -440px;
}
.address_inline3{
    display: inline-block;
    width: 250px;
    margin-left: -40px;
}
</style>