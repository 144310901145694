<template>
    <GlobalLayout>
        <h1 style="text-align:center;margin-top:40rem">开发中，敬请期待！</h1>
    </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import { mapActions, mapGetters, mapState } from "vuex";
import axios from "@/libs/api.request";
import { getToken, formatDate } from "@/libs/util";
    export default {
        components: {
            GlobalLayout,
        },
    }
</script>

<style lang="less" scoped>
@import './order.less';
</style>