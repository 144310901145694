<template>
  <GlobalLayout>
    <div class="userdetail_wrap">
      <div class="user_title">
        <span class="title"
          ><router-link to="/control/user">用户-群组用户 </router-link>
          <span style="color: #383838">/ 用户详情</span></span
        >
      </div>

      <Form
        ref="formItemRef"
        :model="formItem"
        :label-width="500"
        style="margin-top: 3rem; margin-left: 5rem"
        :rules="formItemRules"
      >
        <Form-item label="群组" :label-width="150" prop="groupId">
          <Select
            v-model="formItem.groupId"
            placeholder="请选择群组"
            class="selector"
            style="width: 20rem"
            disabled
          >
            <Option
              v-for="(item, index) in groupList"
              :key="item.groupId"
              :value="item.groupId"
            >
              {{ item.groupName }}
            </Option>
          </Select>
        </Form-item>
        <Form-item label="用户名" :label-width="150" prop="userName">
          <Input
            v-model="formItem.userName"
            placeholder="请输入用户名"
            class="dark"
            style="width: 25rem"
            disabled
          ></Input>
        </Form-item>
        <Form-item label="密码" :label-width="150" prop="password">
          <Input
            v-model="formItem.password"
            placeholder="请输入密码"
            class="dark"
            type="password"
            style="width: 25rem"
            disabled
          ></Input>
        </Form-item>
        
        <Form-item
          label="是否管理级联群组"
          :label-width="150"
          prop="isManage"
          v-if="this.isAdmin()"
        >
          <RadioGroup v-model="formItem.isManage">
            <Radio label="1">
              <span>是</span>
            </Radio>
            <Radio label="0">
              <span>否</span>
            </Radio>
          </RadioGroup>
          <span style="color:#D4D4D4;margin-left:2rem">选择‘是’可以管理所有子孙及群组，选择‘否’只能管理子级群组</span>
        </Form-item>
        <Form-item label="使用人" :label-width="150" prop="nickName">
          <Input
            v-model="formItem.nickName"
            placeholder="请输入使用人"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <Form-item label="电话" :label-width="150" prop="phone">
          <Input
            v-model="formItem.phone"
            placeholder="请输入电话"
            class="dark"
            style="width: 25rem"
          ></Input>
        </Form-item>
        <!-- <Form-item label="权限" :label-width="150" prop="permission">
            <CheckboxGroup v-model="permissionList" v-for="(item,index) in formItem.permission" @on-change="changeBox">
              <Checkbox :label="item.code" true-value=false>{{item.name}}
                  <CheckboxGroup>
                      <Checkbox v-for="(e,i) in item.aCLs" :label="e.name">
                      </Checkbox>
                  </CheckboxGroup>
              </Checkbox>
          </CheckboxGroup>
        </Form-item> -->
        <div v-if="!this.isAdmin()">
          <span v-if="this.userPer.length != 0" style="margin-left: 6rem"
            >权限</span
          >
          <Form-item
            :label-width="150"
            prop="permission"
            v-for="(item, index) in userPer"
            :key="item.code"
          >
            <Checkbox v-model="item.checkAll" @on-change="changeBox(item)"
              >{{ item.name }}
            </Checkbox>
            <br />
            <!-- <CheckboxGroup v-model="formItem.permission"> -->
            <Checkbox
              v-for="e in item.allRule"
              :key="e.code"
              :label="e.name"
              v-model="e.isCheck"
              :disabled="e.isDisabled"
            >
              {{ e.name }}
            </Checkbox>
            <!-- </CheckboxGroup> -->
          </Form-item>
        </div>

        <Form-item>
          <Button
            type="primary"
            class="submitbutton"
            @click="updateUserConfirm('formItemRef')"
            style="
              background: #1960e1;
              border: none;
              margin-left: -40rem;
              width: 10rem;
            "
            >保存</Button
          >
        </Form-item>
      </Form>
    </div>
  </GlobalLayout>
</template>

<script>
import GlobalLayout from "@/components/globalLayout/globalLayout";
import axios from "@/libs/api.request";
import { mapActions, mapGetters, mapState } from "vuex";
import { getToken, formatDate } from "@/libs/util";
export default {
  components: {
    GlobalLayout,
  },
  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    return {
      formItem: {
        groupId: "",
        userName: "",
        password: "",
        nickName: "",
        phone: "",
        email: "",
        permission: [],
        isManage:'1',
      },
      groupList: [],
      formItemRules: {
        groupId: [{ required: false, message: "请选择群组", trigger: "blur" }],
        userName: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
          { type: "email", message: "请输入正确的用户名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          {
            type: "string",
            min: 6,
            message: "密码长度不能小于6位",
            trigger: "blur",
          },
        ],
        nickName: [
          { required: false, message: "请输入使用人", trigger: "blur" },
        ],
        phone: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入11位有效手机号号码",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          { type: "email", message: "Incorrect email format", trigger: "blur" },
        ],
      },
      userPer: [],
      permissionList: [],
      isDisabled: false,
    };
  },
  computed: {
    ...mapGetters("user", [
      "isAdmin",
      "isPerson",
      "isCompanyUser",
      "getCompanyCode",
      "getCompanyName",
      "setControlMode",
      "isControlMode",
      "getUserType",
    ]),
  },
  mounted() {
    // console.log(this.isAdmin())
    this.formItem.groupId = this.$route.query.groupId;
    this.formItem.userName = this.$route.query.userName;
    this.formItem.nickName = this.$route.query.nickName;
    this.formItem.password = this.$route.query.password;
    this.formItem.phone = this.$route.query.phone;
    if(this.$route.query.mnCascade=='true'){
      this.formItem.isManage='1'
    }else{
      this.formItem.isManage='0'
    }
    this.getUserInfo();
    // this.userPer = this.rights();
    this.initRights();
    this.getGroupList(this.$route.query.groupId);
  },
  methods: {
    ...mapGetters(["companyCode", "usertype", "rights"]),
    initRights(){
      let rights = this.rights();
      let rightCodes = [];
      let idxs = [];
      for(let i in rights){
        let r = rights[i];
        if(rightCodes.includes(r.code)){
          continue;
        }else{
          rightCodes.push(r.code);
          idxs.push(i);
        }
      }
      let tmpRights = [];
      for(let i in idxs){
        let idx = idxs[i];
        tmpRights.push(rights[idx]);
      }
      this.userPer = tmpRights.filter((right) => {
        return !right.managed && (right.aCLs && right.aCLs.length>0);
      });
    },
    getUserInfo() {
      axios
        .request({
          module: "AUTH",
          method: "get",
          url: "/user/rights",
          params: {
            code: this.$route.query.code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            // this.permissionList = res.data.data.extraRights;
            this.formItem.permission = res.data.data.extraRights;
            // console.log(this.formItem.permission);
            // this.userPer=this.rights()
            this.userPer.forEach((item) => {
              // console.log(JSON.stringify(res.data.data.extraRights).indexOf(JSON.stringify(item.code)))
              if (
                JSON.stringify(res.data.data.extraRights).indexOf(
                  JSON.stringify(item.code)
                ) != -1
              ) {
                res.data.data.extraRights.forEach((v) => {
                  // console.log(item)
                  if (item.code == v.code) {
                    // console.log(item)
                    item.checkAll = true;
                    item.allRule = [];
                    // console.log(item.aCLs.indexOf(v.aCLs))
                    for (var i = 0; i < item.aCLs.length; i++) {
                      // console.log(JSON.stringify(item.aCLs));
                      // console.log(JSON.stringify(v.aCLs[i]));
                      // console.log(
                      //   JSON.stringify(item.aCLs).indexOf(
                      //     JSON.stringify(v.aCLs[i])
                      //   )
                      // );
                      if (
                        JSON.stringify(v.aCLs).indexOf(
                          JSON.stringify(item.aCLs[i])
                        ) != -1
                      ) {
                        item.allRule.push({
                          ...item.aCLs[i],
                          isCheck: true,
                          isDisabled: false,
                        });
                      } else {
                        item.allRule.push({
                          ...item.aCLs[i],
                          isCheck: false,
                          isDisabled: false,
                        });
                      }
                    }
                  }
                });
              } else {
                item.checkAll = false;
                item.allRule = [];
                for (var i = 0; i < item.aCLs.length; i++) {
                  item.allRule.push({
                    ...item.aCLs[i],
                    isCheck: false,
                    isDisabled: true,
                  });
                }
              }
            });
            // this.userPer = res.data.data.extraRights.map((v) => ({
            //   ...v,
            //   checkAll: true,
            //   allRule: v.aCLs.map((i) => ({
            //     ...i,
            //     isCheck: true,
            //   isDisabled:false
            //   })),
            // }));
            // console.log(this.userPer);
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getGroupList(code) {
      // console.log(code);
      let base_url;
      if (
        localStorage.getItem("companyCode") != null &&
        this.getUserType() == 0
      ) {
        base_url =
          "/group/query?companyCode=" + localStorage.getItem("companyCode");
        // console.log(base_url)
      } else {
        base_url = "/group/query?companyCode=" + this.companyCode();
        // console.log(base_url)
      }
      axios
        .request({
          module: "XAIR",
          method: "get",
          url: base_url,
          params: {
            groupId: code,
          },
        })
        .then((res) => {
          if (res.data.code == 0) {
            // console.log(res.data.data);
            this.groupList = res.data.data;
          } else if (res.data.code == 105) {
            this.$Message.warning("系统时间有误，请重设系统时间");
          } else {
            this.$Message.error(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateUserConfirm(name) {
      let rights = [];
      // console.log(this.userPer);
      this.userPer.forEach((item) => {
        if (item.checkAll == true) {
          let info = {};
          info["code"] = item.code;
          // console.log(info)
          let aclsinfo = [];
          for (var i = 0; i < item.allRule.length; i++) {
            // console.log(item.allRule[i].code)
            if (item.allRule[i].isCheck == true) {
              aclsinfo.push(item.allRule[i].code);
              info["acls"] = aclsinfo;
            }
          }
          // console.log(info)
          rights.push(info);
        }
      });
      // console.log(rights);
      this.$refs[name].validate((valid) => {
        if (valid) {
          // this.$Message.success("Success!");\
          let mnCas
          if(this.formItem.isManage=='0'){
            mnCas=false
          }else{
            mnCas=true
          }
          axios
            .request({
              module: "AUTH",
              method: "post",
              url: "/user/updating",
              data: {
                code: this.$route.query.code,
                nickName: this.formItem.nickName,
                phone: this.formItem.phone,
                rights: rights,
                mnCascade:mnCas
              },
            })
            .then((res) => {
              if (res.data.code == 0) {
                // console.log(res.data.data)
                this.$Message.success("修改成功");
                this.$router.push({
                  path: "/control/user",
                });
              } else if (res.data.code == 105) {
                this.$Message.warning("系统时间有误，请重设系统时间");
              } else {
                this.$Message.error(res.data.message);
              }
            });
        } else {
          this.$Message.error("失败!");
        }
      });
    },
    checkAllGroupChange(val) {
      console.log(val);
    },
    changeBox(item) {
      console.log(item);
      if (item.checkAll == false) {
        for (var i = 0; i < item.allRule.length; i++) {
          item.allRule[i].isCheck = false;
          item.allRule[i].isDisabled = true;
        }
      } else {
        for (var i = 0; i < item.allRule.length; i++) {
          item.allRule[i].isCheck = true;
          item.allRule[i].isDisabled = false;
        }
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" scoped>
.userdetail_wrap {
  padding: 4rem;
}
</style>