<template>
    <Select v-if="property.valueSchema.dataType=='boolean'" v-model="cValue"  @on-change="updateVModel">
        <Option v-for="(pair,index) in property.valueSchema.boolean" :key="index" :value="parseInt(Object.keys(pair)[0])">{{pair[Object.keys(pair)[0]]}}</Option>
    </Select>
    <Select v-else-if="property.valueSchema.dataType=='enum'" v-model="cValue"  @on-change="updateVModel">
        <Option v-for="(pair,index) in property.valueSchema.enum" :key="index" :value="parseInt(Object.keys(pair)[0])">{{pair[Object.keys(pair)[0]]}}</Option>
    </Select>
    <InputNumber v-else-if="property.valueSchema.dataType=='int32'" type="number" :step="parseInt(property.valueSchema.step)" :min="parseInt(property.valueSchema.minValue)" :max="parseInt(property.valueSchema.maxValue)" v-model="cValue" @on-change="handleInputInt"/>
    <InputNumber v-else-if="property.valueSchema.dataType=='float'||property.valueSchema.dataType=='double'" type="number" :step="parseFloat(property.valueSchema.step)" :min="parseFloat(property.valueSchema.minValue)" :max="parseFloat(property.valueSchema.maxValue)" v-model="cValue" @on-change="handleInputFloat"/>
    <Input v-else-if="property.valueSchema.dataType=='string'" type="text" v-model="cValue" :maxlength="property.valueSchema.maxLength" @on-change="hanleInputText"/>
</template>
<script>
export default {
    components: {
    },
    props: {
        property:Object,
        value:{
            type:[Number,String,Object,null],
            default:null
        },
        fstyle:String
    },
    data() {
        return {
            cValue : this.value
        };
    },
    computed: {
      //...mapGetters('user',['hasRight','isAdmin','isCompanyUser','isGroupUser','getGroup','isPerson','getCompanyCode']),
    },
    created() {
        
    },
    mounted() {
    },
    watch: {
      property:{
        handler:function(newValue,oldValue){
                    this.property = newValue;
                },
        deep:true,
        immediate:true
      },

      value:{
        handler:function(newValue,oldValue){
            if(this.property.valueSchema.dataType=='enum'){
                this.cValue= parseInt(newValue); 
            }
            else if(this.property.valueSchema.dataType=='boolean'){
                this.cValue= parseInt(newValue); 
            }
            else if(this.property.valueSchema.dataType=='int32'){
                this.cValue= parseInt(newValue);
                if(this.cValue<this.property.valueSchema.minValue){
                    this.cValue = parseInt(this.property.valueSchema.minValue)
                }
                if(this.cValue>this.property.valueSchema.maxValue){
                    this.cValue = parseInt(this.property.valueSchema.maxValue)
                }
            }
            else if(this.property.valueSchema.dataType=='float'||this.property.valueSchema.dataType=='double'){
                this.cValue= parseFloat(newValue);

                if(this.cValue<this.property.valueSchema.minValue){
                    this.cValue = parseFloat(this.property.valueSchema.minValue)
                }
                if(this.cValue>this.property.valueSchema.maxValue){
                    this.cValue = parseFloat(this.property.valueSchema.maxValue)
                }
            }
        },
        immediate:true,
        deep:true
      }
    },
    methods: {
        handleInputInt(event){
            this.updateVModel();
        },
        handleInputFloat(event){
            this.updateVModel();
        },
        handleInputText(event){
            this.updateVModel();
        },
        handleInputNumber(event){

        },
        updateVModel(){
            this.$emit('input', this.cValue); 
        }
    },
    
};
</script>

<style scoped lang="less" rel="stylesheet/less">
</style>
